/*
==========================
Template: Spa - Beauty Spa Business HTML5 Template
Author: thewebmax team
Version: 2.0  
==========================
*/



/*=======================================
  || CSS TABLE CONTENT ||  
=======================================

=======================================
1. GENERAL 
=======================================

=======================================
2. HEADING & TITLE 
=======================================

=======================================
3. TEXT & PARAGRAPH 
=======================================

	-------------------------------------
	1. text align
	-------------------------------------
	2. text vertical align
	-------------------------------------
	3. text no line brack
	-------------------------------------
	4. text transform
	-------------------------------------
	5. text size
	-------------------------------------
	6. text bold

======================================
4. LISTING STYLES 
======================================

	-------------------------------------
	1. listing before icon color css
	-------------------------------------
	2. listing before numbers
	-------------------------------------

======================================
5. BOX SPACING (Padding & Margin)
======================================

	-------------------------------------
	1.1 padding (around)
	-------------------------------------
	1.2 Padding (top)
	-------------------------------------
	1.3 Padding (bottom)
	-------------------------------------
	1.4 Padding (left)
	-------------------------------------
	1.5 Padding (right)
	-------------------------------------
	1.6 Padding (left right)
	-------------------------------------
	1.7 Padding (top bottom)
	-------------------------------------
	2.1 margin (around)
	-------------------------------------
	2.2 marging (top)
	-------------------------------------
	2.3 marging (bottom)
	-------------------------------------
	2.4 marging (left)
	-------------------------------------
	2.5 marging (right)
	-------------------------------------
	2.6 marging (left right)
	-------------------------------------
	2.7 marging (top bottom)
	-------------------------------------
	2.8 colum gap less
	-------------------------------------

======================================
6. POSITIONS ( absolute , relative , statice & z-index)
======================================

	-------------------------------------
	1. position
	-------------------------------------
		1.1 relative		
		1.2 absolute		
		1.3 static	
	-------------------------------------
	2. z-index
	-------------------------------------

======================================
7. COLOR
======================================

	-------------------------------------
	1. text
	-------------------------------------
	2. background color
	-------------------------------------
	3. background image
	-------------------------------------

======================================
8. OVERLAY ( transparient box style  )
======================================

	use for box
	-------------------------------------
	1. show for text
	-------------------------------------

======================================
9. BOXES CSS 
======================================

	-------------------------------------
	1. box content
	-------------------------------------
	2. For icons with box
	-------------------------------------
	3. Border
	-------------------------------------
	4. For only icons
	-------------------------------------
	5. place icon with box
	-------------------------------------
	6. icon box left align
	-------------------------------------
	7. icon box right align
	-------------------------------------
	8. icon box center align
	-------------------------------------
	9. media with content box css
	-------------------------------------
	10. before & after for line css
	-------------------------------------
	11. for images
	-------------------------------------

======================================
10. IMAGES OVERLAY EFFECTS (overly animation on images)  
======================================

	-------------------------------------
	1. effect 1
	-------------------------------------
	2. effect 2
	-------------------------------------
	3. effect 3
	-------------------------------------
	4. effect 4
	-------------------------------------
	5. effect 5
	-------------------------------------
	6. effect 6
	-------------------------------------
	7. effect 7
	-------------------------------------
	8. effect 8
	-------------------------------------
	9. effect 9
	-------------------------------------
	10. effect 10
	-------------------------------------

======================================
11. shortcodeS 
======================================

	-------------------------------------
	1. Buttons 
	------------------------------------- 
		1.1 theme default button 		
		1.2 button text uppercase		
		1.3 button size 		
		1.4 button rounded		
		1.5 button colors 		
		1.6 white 		
		1.7 black 		
		1.8 Gray 		
		1.9 pink 		
		1.10 Blue		
		1.11 Green		
		1.12 Orange		
		1.13 Red 		
		1.14 Brown 
		1.15 Yellow 
		1.16 purple 
		1.17 Graphical 
		1.18 3D 
		1.19 Outline 
		1.20 Outline white  
		1.21 Outline black 
		1.22 Outline gray 
		1.23 Outline pink 
		1.24 Outline blue 
		1.25 Outline green 
		1.26 Outline orange 
		1.27 Outline red 
		1.28 Outline brown 
		1.29 Outline yellow 
		1.30 Outline purple 
		1.31 Outline hover 
		1.32 button text link 
		1.33 text link white 
		1.34 text link black 
		1.35 text link Gray 
		1.36 text link pink 
		1.37 text link Blue 
		1.38 text link Green 
		1.39 text link Orange 
		1.40 text link Red 
		1.41 text link Brown 
		1.42 text link Yellow 
		1.43 text link purple 
		1.44 app strore 
		1.45 filter buttons css 
		1.45(1) Masonry filter alignment [Left-Right-Center] 
		1.45(2) Masonry filter link style 
		1.45(3) Masonry filter button style 
		1.45(4) Masonry filter button outline style 
	-------------------------------------
	2. Title separators 
	-------------------------------------
		Separator defualt		
		Separator liner		
		Separator square		
		Separator icon
	-------------------------------------
	3. Deviders 
	-------------------------------------
	4. TABS 
	-------------------------------------
		4.1 tabs style 1 [ default ] 		
		tabs nav center 		
		4.2 tabs bg  [ tabs background ] 		
		4.3 tabs bg  [ tabs background ]		
		4.4 tabs bg & top border  [ tabs background & top border in active ]		
		4.4 tabs style 2 [ content with border outer ]		
		4.5 tabs style 3  [ left-nav ] 		
		4.6 tabs style 4  [ left-nav & content with border ] 		
		4.7 tabs style 5  [ right-nav ] 		
		4.8 tabs style 6  [ right-nav & content with border ]
	-------------------------------------
	5. Accordians 
	-------------------------------------
	Accodian open close indicator css
		1. Accordion defult		
		2. Accordion outline		
		3. Accordion bg gray		
		4. Accordion bg primary		
		5. Accordion bg dark
	-------------------------------------
	6. Carousels 
	-------------------------------------
		6.1 Default Carousel		
		6.2 Blog carousel		
		empty media 		
		6.3 Event carousel		
		empty media 		
		6.4 Client carousel 1
	-------------------------------------
	7. Testimonials 
	-------------------------------------
		7.1 testimonial with background image
		7.2 testimonial 1
		7.3 testimonial 2
		7.4 testimonial 2 with background image
		7.5 testimonial 3
		7.6 testimonial 4
	-------------------------------------
	8. Pricing table
	-------------------------------------
		8.1 Pricing table
		8.2 pricing table style 2
	-------------------------------------
	9. Alert box 
	-------------------------------------
	10. Image effects 
	-------------------------------------
		10.1 image-opacity 
		10.2 image-zoom 
		10.3 image-zoom-slow 
		10.4 image-shrink 
		10.5 image-side-pan 
		10.6 image-vertical-pan 
		10.7 image-fade-in 

		10.8 image-fade-out 
		10.9 image-rotate 
		10.10 image-sepia 
		10.11 image-blurr 
		10.12 image-blurr-invert 
		10.13 image off color 
		10.14 image on color 
	-------------------------------------
	11. Modal pop 
	-------------------------------------
	12. Social icons 
	-------------------------------------
		12.1 social icon default 
		12.2 social icon with border 
		12.3 social icon dark 
		12.4 social share icon style 1 
	-------------------------------------
	13. Breadcrumb 
	-------------------------------------
		13.1 breadcrumb-row-1
		13.2 Breadcrumb-1 
		13.3 Breadcrumb-2 
		13.4 breadcrumb-3
	-------------------------------------
	14. Google map 
	-------------------------------------
	15. Progress Bar 
	-------------------------------------
	16. Countdown clock 
	-------------------------------------
	17. Form elements 
	-------------------------------------  
		17.1 input group 
		17.2 select box 
		17.3 radio & checkbox 
		17.4 Browse Button css   
		17.5 touchspin input type number   
		17.6 rating star 
		17.7 simple rating list 
		17.8 validation symbol 
		17.9 error for select box 
		17.10 succes for select box 
		17.11 textarea 
			17.11(1) resize-vertical
			17.11(2) resize-horizontal
			17.11(3) resize-none
	-------------------------------------
	18. Loading 
	-------------------------------------  
		18.1 loading - 1
		18.2 loading - 2
	-------------------------------------
	19. Widgets 
	------------------------------------- 
		19.1 widget listing		
		19.2 widget search custom		
		19.3 widget search wp-default		
		19.4 widget recent-posts		
		19.5 widget recent-comment		
		19.6 widget meta		
		19.7 widget calender		
		19.8 widget tags-list		
		19.9 widget archive		
		19.10 widget text		
		19.11 widget categories	
		19.12 widget RSS		
		19.13 widget get in tuch 		
		19.14 widget gallery		
		19.15 widget Categories Dropdown 
	-------------------------------------
	20. Table 
	-------------------------------------
	21. Video 
	-------------------------------------
	22. Icons 
	-------------------------------------
	23. Call to action
	-------------------------------------

======================================
12. WP DEFAULT  
======================================

======================================
13. BLOG  
====================================== 

	-------------------------------------
	1. post date syle css
	-------------------------------------
	2. post without media
	-------------------------------------
	if no image with blog post
	-------------------------------------
	3. if no image with blog post
	-------------------------------------
	4. blog post half image
	-------------------------------------
	5. Blog paging Next & Prev css
	-------------------------------------
	6. Post navigation
	-------------------------------------
	7. post pagination
	-------------------------------------
		7.1 Sticky Post
	-------------------------------------
	8. Post password form
	-------------------------------------
	9. gallery css for blog post
	-------------------------------------
	10. side bar
	-------------------------------------
	11. Blog single
	-------------------------------------
	12. comment list
	-------------------------------------
	13. comment form
	-------------------------------------

======================================
14. HEADER (top part of page)
======================================

	1. header full screen
	-------------------------------------
	2. without top bar
	------------------------------------- 
	3. map page header
	-------------------------------------
	4. Skew header block
	-------------------------------------
	logo with boxed view
	logo with boxed view
	
======================================
15. PAGE TOP BAR (left & right content)
======================================

	1. eamil-phone (left) 
	-------------------------------------
	2. social-links (right) 
	-------------------------------------
	3. language-list () 


======================================
16. LOGO ( company identify  symbol )
======================================

	1. logo for header 
	-------------------------------------
	2. when header is sticky 
	
======================================
17. MAIN BAR ( place logo & main-navigation )
======================================

	main bar with box view
	-------------------------------------
	1. Top Part Search Bar

======================================
18. NAVIGATIONS 
======================================

	-------------------------------------
	1. mena-menu
	-------------------------------------
	2. nav dark version
	-------------------------------------
	3. when header is sticky
	-------------------------------------  
	4. when header is sticky
	-------------------------------------  
	5. header full width
	-------------------------------------
	6. header with bg primary color 1
	-------------------------------------
	7. header style 2 (center content)
	-------------------------------------
	8. header style 3 (header transperent)
	------------------------------------- 
 		header 3 fixed  
	-------------------------------------
	9. header style 4 (header white overlay)
	------------------------------------- 
		header 4 fixed  
	-------------------------------------
	10. header style 5 (header black overlay)
	------------------------------------- 
		header 5 fixed  

======================================
19. BANNER
======================================

	-------------------------------------
	1. inner page banner 
	-------------------------------------

======================================
20. SLIDER
======================================

	-------------------------------------
	1. owl slider 
	-------------------------------------
		1.1  next pre btn
		1.2 owl dots button
		1.3 owl button top to center
		1.4 owl button top to left
		1.5 owl button top to right
		1.6 owl button bottom to center [it is default position ]
		1.7 owl button bottom to left
		1.8 owl button bottom to right
		1.9 owl button vertical to center
		1.10 owl button show on hover
		1.11 owl slider button
		1.12 owl dots nav position

======================================
21. PAGINATION 
======================================

	1. pagination style-1 
	2. pagination style-2 
	3. Pager 

======================================
22. FOOTER 
======================================

	-------------------------------------
	1. footer fixed on bottom css
	-------------------------------------
		1.1 footer fixed with fullscreen view
		1.2 footer fixed with boxed view
		1.3 Constrot strip line
	-------------------------------------
	2. Footer dark version css
	-------------------------------------
	3. Footer light version css
	-------------------------------------
	4. widget li in footer
	-------------------------------------
	5. widget Getintuch
	-------------------------------------
	6. scroll top btn css
	-------------------------------------

======================================
23. PAGE-CONTENT
======================================

	-------------------------------------
	1. Section Head Part 
	-------------------------------------
	2. Section Content Part 
	-------------------------------------

======================================
24. AFTER-LOGIN-CONTENT
======================================

	-------------------------------------
	1. calender css
	-------------------------------------
	2. Contact us page 
	-------------------------------------
	3.page not found
	-------------------------------------

======================================
25. THEMEFOREST REQUIRED CLASSES
======================================

	-------------------------------------
	1. Text meant only for screen readers
	-------------------------------------

======================================
26. HOME PAGE VERSION-1 
======================================

======================================
27. BOX MAX-WIDTH css (  )
======================================

======================================
28. SHOP & PRODUCT DETAIL (Woocommerce)
======================================

	-------------------------------------
	1. Product Box 
	-------------------------------------
	2. Product Detail 
	-------------------------------------
	3.  Product Review 
	-------------------------------------

======================================
29. content show on image box () 
======================================

 	info box show 

======================================
30. Customize  Grouping css 
======================================

	-------------------------------------
	1.set-radius 
	------------------------------------- 
	2. transition 
	------------------------------------- 
	3. float-clearfix 
	-------------------------------------

======================================
32. Inner pages 
======================================

	------------------------------------- 
	1. Contact us 
	-------------------------------------
		1.1 Contact us 1 
	------------------------------------- 
	2. Out Team
	-------------------------------------
		2.1. Out Team one
		2.2. Out Team two
		2.3. Out Team three
		2.4. Out Team four
		2.5. Out Team five

	------------------------------------- 
	3. Social-new block
	------------------------------------- 
	4. portfolio block
	------------------------------------- 
	5. services
	------------------------------------- 
	6. latest project
	-------------------------------------

======================================
33. Box-corner
======================================

	corner-sm 
 
======================================
34. Menu list block
======================================

======================================
35. Company Detail skew
======================================

======================================
36. Clients
======================================

======================================
37. FAQ
======================================

======================================
38. Cart
======================================

======================================
39. Product
======================================

======================================
40. Glass reflection
======================================

======================================
40. Glass reflection
======================================

======================================
53. Background Effects
======================================



*/


@media only screen and (min-width: 1200px) {

	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl {
		max-width: 1170px;
	}
}

/*======================================
1. GENERAL 
======================================*/

body {
	background-color: #FFF;
	font-family: 'Poppins', sans-serif;
	font-size: 14px;
	font-weight: 400;
	color: #767676;
	padding: 0;
	margin: 0;
	overflow-x: hidden;
}

a {
	color: #ec5598;
	outline: 0 none;
	text-decoration: none;
}

a:hover,
a:focus {
	text-decoration: none;
	outline: 0 none;
}

a:active,
a:hover {
	color: #333333;
}

p a {
	color: #333333;
}

img {
	border-style: none;
	height: auto;
	max-width: 100%;
	vertical-align: middle;
}

hr {
	clear: both;
}

section,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
summary {
	display: block;
}

.inline-block {
	display: inline-block;
}

.display-block {
	display: block;
}

address {
	font-style: italic;
}

table {
	background-color: transparent;
	width: 100%;
}

table thead th {
	color: #333;
}

table td {
	padding: 15px 10px;
}



@media only screen and (max-width:360px) {
	table td {
		word-break: break-all;
	}
}

p,
address,
pre,
hr,
ul,
ol,
dl,
dd,
table {
	margin-bottom: 24px;
}

.overflow-hide {
	overflow: hidden;
}

.overflow-auto {
	overflow: auto;
}

.clear {
	clear: both;
}

.pull-none {
	float: none;
}

::selection {
	background: #ec5598;
	color: #fff;
}

::-moz-selection {
	background: #ec5598;
	color: #fff;
}

::-webkit-selection {
	background: #ec5598;
	color: #fff;
}

@media only screen and (max-width:600px) {
	.col-xs-100pc {
		width: 100%;
	}
}


/*======================================
2. HEADING & TITLE 
======================================*/

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 24px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: #3d474a;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	color: #ec5598;
}


h1 {
	font-size: 42px;
	line-height: 48px;
	color: #3f3e3e;
	font-weight: normal;
}

h2 {
	font-size: 36px;
	line-height: 42px;
	color: #3f3e3e;
	font-weight: normal;
}

h3 {
	font-size: 26px;
	line-height: 32px;
	color: #3f3e3e;
	font-weight: normal;
	margin-bottom: 20px;
}

h4 {
	font-size: 18px;
	line-height: 24px;
	color: #3f3e3e;
	font-weight: normal;
	margin-bottom: 15px;
}

h5 {
	font-size: 16px;
	line-height: 22px;
	color: #3f3e3e;
	font-weight: normal;
	margin-bottom: 10px;
}

h6 {
	font-size: 14px;
	line-height: 18px;
	color: #3f3e3e;
	font-weight: normal;
	margin-bottom: 10px;
}


/*======================================
3. TEXT & PARAGRAPH 
======================================*/

p {
	line-height: 24px;
	margin-bottom: 24px;
}

strong {
	font-weight: 600;
}

/*-------------------------------------
	1. text align
------------------------------------ */
.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-justify {
	text-align: justify
}

/*-------------------------------------
	2. text vertical align
------------------------------------ */
.v-align-t {
	vertical-align: top;
}

.v-align-m {
	vertical-align: middle;
}

.v-align-b {
	vertical-align: bottom;
}

/*-------------------------------------
	3. text no line brack
------------------------------------ */
.text-nowrap {
	white-space: nowrap
}

/*-------------------------------------
	4. text transform
------------------------------------ */
.text-lowercase {
	text-transform: lowercase
}

.text-uppercase {
	text-transform: uppercase
}

.text-capitalize {
	text-transform: capitalize
}

/*-------------------------------------
	5. text size
------------------------------------ */
.font-14 {
	font-size: 14px;
	line-height: 20px;
}

.font-16 {
	font-size: 16px;
	line-height: 22px;
}

.font-18 {
	font-size: 18px;
	line-height: 24px;
}

.font-20 {
	font-size: 20px;
	line-height: 26px;
}

.font-22 {
	font-size: 22px;
	line-height: 28px;
}

.font-24 {
	font-size: 24px;
	line-height: 30px;
}

.font-26 {
	font-size: 26px;
	line-height: 32px;
}

.font-50 {
	font-size: 50px;
	line-height: 56px;
}

.font-60 {
	font-size: 60px;
	line-height: 66px;
}

.font-70 {
	font-size: 70px;
	line-height: 76px;
}

.font-80 {
	font-size: 80 px;
	line-height: 86px;
}


/*-------------------------------------
	6. text bold
------------------------------------ */
.font-weight-300 {
	font-weight: 300;
}

.font-weight-400 {
	font-weight: 400;
}

.font-weight-500 {
	font-weight: 500;
}

.font-weight-600 {
	font-weight: 600;
}

.font-weight-700 {
	font-weight: 700;
}

.font-weight-800 {
	font-weight: 800;
}

.font-weight-900 {
	font-weight: 900;
}



/*======================================
4. LISTING STYLES 
======================================*/

dl,
ul,
ol {
	list-style-position: outside;
	padding: 0;
}

ul,
ol {
	margin-bottom: 24px;
}

ul li,
ol li {
	padding: 0;
}

dl {
	margin-left: 0;
	margin-bottom: 30px;
}

dl dd {
	margin-bottom: 10px;
}

.list-simple li {
	margin-bottom: 10px;
}

.list-simple li ul {
	margin-left: 15px;
	margin-top: 10px;
}

.list-simple.list-unstyled li ul {
	margin-left: 30px;
	margin-top: 10px;
}

.list-circle,
.list-angle-right,
.list-arrow,
.list-check,
.list-checked,
.list-check-circle,
.list-chevron-circle,
.list-arrow-circle,
.list-times-circle {
	margin: 0 0 20px 0;
	padding: 0;
	list-style: none;
}

.list-circle li,
.list-angle-right li,
.list-arrow li,
.list-check li,
.list-checked li,
.list-check-circle li,
.list-chevron-circle li,
.list-arrow-circle li,
.list-times-circle li {
	padding: 5px 5px 5px 20px;
	position: relative;
}

.list-circle li:before,
.list-angle-right li:before,
.list-arrow li:before,
.list-check li:before,
.list-checked li:before,
.list-check-circle li:before,
.list-chevron-circle li:before,
.list-arrow-circle li:before,
.list-times-circle li:before {
	font-family: "FontAwesome";
	position: absolute;
	left: 0;
	top: 6px;
	display: block;
	font-size: 15px;
	color: #777;
}

.list-circle li:before {
	top: 10px;
	font-size: 10px;
}

.list-circle li:before {
	content: "\f111";
}

.list-angle-right li:before {
	content: "\f105";
}

.list-arrow li:before {
	content: "\f0da";
}

.list-check li:before {
	content: "\f00c";
}

.list-checked li:before {
	content: "\f046";
}

.list-check-circle li:before {
	content: "\f058";
}

.list-chevron-circle li:before {
	content: "\f138";
}

.list-arrow-circle li:before {
	content: "\f0a9";
}

.list-times-circle li:before {
	content: "\f057";
}

/*-------------------------------------
	1. listing before icon color css
-------------------------------------*/
ul.primary li:before {
	color: #ffbc13;
}

ul.secondry li:before {
	color: #77C04B;
}

ul.black li:before {
	color: #000;
}

ul.white li:before {
	color: #fff;
}

ul.orange li:before {
	color: #FF6600;
}

ul.green li:before {
	color: #00CC00;
}

ul.red li:before {
	color: #FF3737;
}

dl.no-margin,
ul.no-margin,
ol.no-margin {
	margin: 0px !important;
	list-style: none;
}

/*-------------------------------------
	2. listing before numbers
-------------------------------------*/
ol.list-num-count {
	counter-reset: li;
	padding-left: 0;
}

ol.list-num-count>li {
	position: relative;
	margin: 0 0 6px 30px;
	padding: 4px 8px;
	list-style: none;
}

ol.list-num-count>li:before {
	content: counter(li, decimal);
	counter-increment: li;
	position: absolute;
	top: 0;
	left: -28px;
	width: 28px;
	height: 28px;
	padding: 5px;
	color: #fff;
	background: #FFBC13;
	font-weight: bold;
	text-align: center;
	font-size: 12px;
	border-radius: 100px;
}

ol.list-num-count.lower-alpha>li:before {
	content: counter(li, lower-alpha);
}

ol.list-num-count.upper-alpha>li:before {
	content: counter(li, upper-alpha);
}

ol.list-num-count.list-outline>li:before {
	border: 1px solid #EC5598;
	background-color: transparent;
	color: #787878;
}

ol.list-num-count.list-outline-none>li:before {
	background-color: transparent;
	color: #787878;
}

/*======================================
5. BOX SPACING (Padding & Margin)
======================================*/

/*-------------------------------------
	1.1 padding (around)
-------------------------------------*/
.p-a0 {
	padding: 0;
}

.p-a5 {
	padding: 5px;
}

.p-a10 {
	padding: 10px;
}

.p-a15 {
	padding: 15px;
}

.p-a20 {
	padding: 20px;
}

.p-a25 {
	padding: 25px;
}

.p-a30 {
	padding: 30px;
}

.p-a40 {
	padding: 40px;
}

.p-a50 {
	padding: 50px;
}

.p-a60 {
	padding: 60px;
}

.p-a70 {
	padding: 70px;
}

.p-a80 {
	padding: 80px;
}

.p-a90 {
	padding: 90px;
}

.p-a100 {
	padding: 100px;
}

.p-a110 {
	padding: 110px;
}

.p-a120 {
	padding: 120px;
}

.p-a130 {
	padding: 130px;
}

.p-a140 {
	padding: 140px;
}

.p-a150 {
	padding: 150px;
}

/*-------------------------------------
	1.2 Padding (top)
-------------------------------------*/
.p-t0 {
	padding-top: 0;
}

.p-t5 {
	padding-top: 5px;
}

.p-t10 {
	padding-top: 10px;
}

.p-t15 {
	padding-top: 15px;
}

.p-t20 {
	padding-top: 20px;
}

.p-t30 {
	padding-top: 30px;
}

.p-t40 {
	padding-top: 40px;
}

.p-t50 {
	padding-top: 50px;
}

.p-t60 {
	padding-top: 60px;
}

.p-t70 {
	padding-top: 70px;
}

.p-t80 {
	padding-top: 80px;
}

.p-t90 {
	padding-top: 90px;
}

.p-t100 {
	padding-top: 100px;
}

.p-t110 {
	padding-top: 110px;
}

.p-t120 {
	padding-top: 120px;
}

.p-t130 {
	padding-top: 130px;
}

.p-t140 {
	padding-top: 140px;
}

.p-t150 {
	padding-top: 150px;
}

/*-------------------------------------
    1.3 Padding (bottom)
-------------------------------------*/
.p-b0 {
	padding-bottom: 0;
}

.p-b5 {
	padding-bottom: 5px;
}

.p-b10 {
	padding-bottom: 10px;
}

.p-b15 {
	padding-bottom: 15px;
}

.p-b20 {
	padding-bottom: 20px;
}

.p-b30 {
	padding-bottom: 30px;
}

.p-b40 {
	padding-bottom: 40px;
}

.p-b50 {
	padding-bottom: 50px;
}

.p-b60 {
	padding-bottom: 60px;
}

.p-b70 {
	padding-bottom: 70px;
}

.p-b80 {
	padding-bottom: 80px;
}

.p-b90 {
	padding-bottom: 90px;
}

.p-b100 {
	padding-bottom: 100px;
}

.p-b110 {
	padding-bottom: 110px;
}

.p-b120 {
	padding-bottom: 120px;
}

.p-b130 {
	padding-bottom: 130px;
}

.p-b140 {
	padding-bottom: 140px;
}

.p-b150 {
	padding-bottom: 150px;
}

/*-------------------------------------
	1.4 Padding (left)
-------------------------------------*/
.p-l0 {
	padding-left: 0;
}

.p-l5 {
	padding-left: 5px;
}

.p-l10 {
	padding-left: 10px;
}

.p-l15 {
	padding-left: 15px;
}

.p-l20 {
	padding-left: 20px;
}

.p-l30 {
	padding-left: 30px;
}

.p-l40 {
	padding-left: 40px;
}

.p-l50 {
	padding-left: 50px;
}

.p-l60 {
	padding-left: 60px;
}

.p-l70 {
	padding-left: 70px;
}

.p-l80 {
	padding-left: 80px;
}

.p-l90 {
	padding-left: 90px;
}

.p-l100 {
	padding-left: 100px;
}

.p-l110 {
	padding-left: 110px;
}

.p-l120 {
	padding-left: 120px;
}

.p-l130 {
	padding-left: 130px;
}

.p-l140 {
	padding-left: 140px;
}

.p-l150 {
	padding-left: 150px;
}

/*-------------------------------------
	1.5 Padding (right)
-------------------------------------*/
.p-r0 {
	padding-right: 0;
}

.p-r5 {
	padding-right: 5px;
}

.p-r10 {
	padding-right: 10px;
}

.p-r15 {
	padding-right: 15px;
}

.p-r20 {
	padding-right: 20px;
}

.p-r30 {
	padding-right: 30px;
}

.p-r40 {
	padding-right: 40px;
}

.p-r50 {
	padding-right: 50px;
}

.p-r60 {
	padding-right: 60px;
}

.p-r70 {
	padding-right: 70px;
}

.p-r80 {
	padding-right: 80px;
}

.p-r90 {
	padding-right: 90px;
}

.p-r100 {
	padding-right: 100px;
}

.p-r110 {
	padding-right: 110px;
}

.p-r120 {
	padding-right: 120px;
}

.p-r130 {
	padding-right: 130px;
}

.p-r140 {
	padding-right: 140px;
}

.p-r150 {
	padding-right: 150px;
}

/*-------------------------------------
	1.6 Padding (left right)
-------------------------------------*/
.p-lr0 {
	padding-left: 0;
	padding-right: 0;
}

.p-lr5 {
	padding-left: 5px;
	padding-right: 5px;
}

.p-lr10 {
	padding-left: 10px;
	padding-right: 10px;
}

.p-lr15 {
	padding-left: 15px;
	padding-right: 15px;
}

.p-lr20 {
	padding-left: 20px;
	padding-right: 20px;
}

.p-lr30 {
	padding-left: 30px;
	padding-right: 30px;
}

.p-lr40 {
	padding-left: 40px;
	padding-right: 40px;
}

.p-lr50 {
	padding-left: 50px;
	padding-right: 50px;
}

.p-lr60 {
	padding-left: 60px;
	padding-right: 60px;
}

.p-lr70 {
	padding-left: 70px;
	padding-right: 70px;
}

.p-lr80 {
	padding-left: 80px;
	padding-right: 80px;
}

.p-lr90 {
	padding-left: 90px;
	padding-right: 90px;
}

.p-lr100 {
	padding-left: 100px;
	padding-right: 100px;
}

.p-lr120 {
	padding-left: 120px;
	padding-right: 120px;
}

.p-lr150 {
	padding-left: 150px;
	padding-right: 150px;
}

/*-------------------------------------
	1.7 Padding (top bottom)
-------------------------------------*/
.p-tb0 {
	padding-bottom: 0;
	padding-top: 0;
}

.p-tb5 {
	padding-bottom: 5px;
	padding-top: 5px;
}

.p-tb10 {
	padding-bottom: 10px;
	padding-top: 10px;
}

.p-tb15 {
	padding-bottom: 15px;
	padding-top: 15px;
}

.p-tb20 {
	padding-bottom: 20px;
	padding-top: 20px;
}

.p-tb30 {
	padding-bottom: 30px;
	padding-top: 30px;
}

.p-tb40 {
	padding-bottom: 40px;
	padding-top: 40px;
}

.p-tb50 {
	padding-bottom: 50px;
	padding-top: 50px;
}

.p-tb60 {
	padding-bottom: 60px;
	padding-top: 60px;
}

.p-tb70 {
	padding-bottom: 70px;
	padding-top: 70px;
}

.p-tb80 {
	padding-bottom: 80px;
	padding-top: 80px;
}

.p-tb90 {
	padding-bottom: 90px;
	padding-top: 90px;
}

.p-tb100 {
	padding-bottom: 100px;
	padding-top: 100px;
}

.p-tb120 {
	padding-bottom: 120px;
	padding-top: 120px;
}

.p-tb150 {
	padding-bottom: 150px;
	padding-top: 150px;
}

/*-------------------------------------
	2.1 margin (around)
-------------------------------------*/
.m-a-1 {
	margin: -1px
}

.m-a0 {
	margin: 0;
}

.m-a5 {
	margin: 5px;
}

.m-a10 {
	margin: 10px;
}

.m-a15 {
	margin: 15px;
}

.m-a20 {
	margin: 20px;
}

.m-a30 {
	margin: 30px;
}

.m-a40 {
	margin: 40px;
}

.m-a50 {
	margin: 50px;
}

.m-a60 {
	margin: 60px;
}

.m-a70 {
	margin: 70px;
}

.m-a80 {
	margin: 80px;
}

.m-a90 {
	margin: 90px;
}

.m-a100 {
	margin: 100px;
}

.m-a110 {
	margin: 110px;
}

.m-a120 {
	margin: 120px;
}

.m-a130 {
	margin: 130px;
}

.m-a140 {
	margin: 140px;
}

.m-a150 {
	margin: 150px;
}

/*-------------------------------------
	2.2 marging (top)
-------------------------------------*/
.m-t0 {
	margin-top: 0;
}

.m-t5 {
	margin-top: 5px;
}

.m-t10 {
	margin-top: 10px;
}

.m-t15 {
	margin-top: 15px;
}

.m-t20 {
	margin-top: 20px;
}

.m-t30 {
	margin-top: 30px;
}

.m-t40 {
	margin-top: 40px;
}

.m-t50 {
	margin-top: 50px;
}

.m-t60 {
	margin-top: 60px;
}

.m-t70 {
	margin-top: 70px;
}

.m-t80 {
	margin-top: 80px;
}

.m-t90 {
	margin-top: 90px;
}

.m-t100 {
	margin-top: 100px;
}

.m-t110 {
	margin-top: 110px;
}

.m-t120 {
	margin-top: 120px;
}

.m-t130 {
	margin-top: 130px;
}

.m-t140 {
	margin-top: 140px;
}

.m-t150 {
	margin-top: 150px;
}

/*-------------------------------------
	2.3 marging (bottom)
-------------------------------------*/
.m-b0 {
	margin-bottom: 0;
}

.m-b5 {
	margin-bottom: 5px;
}

.m-b10 {
	margin-bottom: 10px;
}

.m-b15 {
	margin-bottom: 15px;
}

.m-b20 {
	margin-bottom: 20px;
}

.m-b30 {
	margin-bottom: 30px;
}

.m-b40 {
	margin-bottom: 40px;
}

.m-b50 {
	margin-bottom: 50px;
}

.m-b60 {
	margin-bottom: 60px;
}

.m-b70 {
	margin-bottom: 70px;
}

.m-b80 {
	margin-bottom: 80px;
}

.m-b90 {
	margin-bottom: 90px;
}

.m-b100 {
	margin-bottom: 100px;
}

.m-b110 {
	margin-bottom: 110px;
}

.m-b120 {
	margin-bottom: 120px;
}

.m-b130 {
	margin-bottom: 130px;
}

.m-b140 {
	margin-bottom: 140px;
}

.m-b150 {
	margin-bottom: 150px;
}

/*-------------------------------------
	2.4 marging (left)
-------------------------------------*/
.m-l0 {
	margin-left: 0;
}

.m-l5 {
	margin-left: 5px;
}

.m-l10 {
	margin-left: 10px;
}

.m-l15 {
	margin-left: 15px;
}

.m-l20 {
	margin-left: 20px;
}

.m-l30 {
	margin-left: 30px;
}

.m-l40 {
	margin-left: 40px;
}

.m-l50 {
	margin-left: 50px;
}

.m-l60 {
	margin-left: 60px;
}

.m-l70 {
	margin-left: 70px;
}

.m-l80 {
	margin-left: 80px;
}

.m-l90 {
	margin-left: 90px;
}

.m-l100 {
	margin-left: 100px;
}

.m-l110 {
	margin-left: 110px;
}

.m-l120 {
	margin-left: 120px;
}

.m-l130 {
	margin-left: 130px;
}

.m-l140 {
	margin-left: 140px;
}

.m-l150 {
	margin-left: 150px;
}

/*-------------------------------------
	2.5 marging (right)
-------------------------------------*/
.m-r0 {
	margin-right: 0;
}

.m-r5 {
	margin-right: 5px;
}

.m-r10 {
	margin-right: 10px;
}

.m-r15 {
	margin-right: 15px;
}

.m-r20 {
	margin-right: 20px;
}

.m-r30 {
	margin-right: 30px;
}

.m-r40 {
	margin-right: 40px;
}

.m-r50 {
	margin-right: 50px;
}

.m-r60 {
	margin-right: 60px;
}

.m-r70 {
	margin-right: 70px;
}

.m-r80 {
	margin-right: 80px;
}

.m-r90 {
	margin-right: 90px;
}

.m-r100 {
	margin-right: 100px;
}

.m-r110 {
	margin-right: 110px;
}

.m-r120 {
	margin-right: 120px;
}

.m-r130 {
	margin-right: 130px;
}

.m-r140 {
	margin-right: 140px;
}

.m-r150 {
	margin-right: 150px;
}

/*-------------------------------------
	2.6 marging (left right)
-------------------------------------*/
.m-lr0 {
	margin-left: 0;
	margin-right: 0;
}

.m-lr5 {
	margin-left: 5px;
	margin-right: 5px;
}

.m-lr10 {
	margin-left: 10px;
	margin-right: 10px;
}

.m-lr15 {
	margin-left: 15px;
	margin-right: 15px;
}

.m-lr20 {
	margin-left: 20px;
	margin-right: 20px;
}

.m-lr30 {
	margin-left: 30px;
	margin-right: 30px;
}

.m-lr40 {
	margin-left: 40px;
	margin-right: 40px;
}

.m-lr50 {
	margin-left: 50px;
	margin-right: 50px;
}

.m-lr60 {
	margin-left: 60px;
	margin-right: 60px;
}

.m-lr70 {
	margin-left: 70px;
	margin-right: 70px;
}

.m-lr80 {
	margin-left: 80px;
	margin-right: 80px;
}

.m-lr90 {
	margin-left: 90px;
	margin-right: 90px;
}

.m-lr100 {
	margin-left: 100px;
	margin-right: 100px;
}

.m-lr120 {
	margin-left: 120px;
	margin-right: 120px;
}

.m-lr150 {
	margin-left: 150px;
	margin-right: 150px;
}

/*-------------------------------------
	2.7 marging (top bottom)
-------------------------------------*/
.m-tb0 {
	margin-bottom: 0;
	margin-top: 0;
}

.m-tb5 {
	margin-bottom: 5px;
	margin-top: 5px;
}

.m-tb10 {
	margin-bottom: 10px;
	margin-top: 10px;
}

.m-tb15 {
	margin-bottom: 15px;
	margin-top: 15px;
}

.m-tb20 {
	margin-bottom: 20px;
	margin-top: 20px;
}

.m-tb30 {
	margin-bottom: 30px;
	margin-top: 30px;
}

.m-tb40 {
	margin-bottom: 40px;
	margin-top: 40px;
}

.m-tb50 {
	margin-bottom: 50px;
	margin-top: 50px;
}

.m-tb60 {
	margin-bottom: 60px;
	margin-top: 60px;
}

.m-tb70 {
	margin-bottom: 70px;
	margin-top: 70px;
}

.m-tb80 {
	margin-bottom: 80px;
	margin-top: 80px;
}

.m-tb90 {
	margin-bottom: 90px;
	margin-top: 90px;
}

.m-tb100 {
	margin-bottom: 100px;
	margin-top: 100px;
}

.m-tb120 {
	margin-bottom: 120px;
	margin-top: 120px;
}

.m-tb150 {
	margin-bottom: 150px;
	margin-top: 150px;
}

/*-------------------------------------
	2.8 colum gap less
-------------------------------------*/
.no-col-gap [class*="col-xs-"],
.no-col-gap [class*="col-sm-"],
.no-col-gap [class*="col-md-"],
.no-col-gap [class*="col-lg-"] {
	padding-left: 0;
	padding-right: 0;
}



/*======================================
6. POSITIONS ( absolute , relative , statice & z-index)
======================================*/

/*-------------------------------------
	1. position
-------------------------------------*/
/*1.1 relative*/
.relative {
	position: relative;
}

/*1.2 absolute*/
.absolute {
	position: absolute;
}

/*1.3 static*/
.static {
	position: static;
}

/*-------------------------------------
	2. z-index
-------------------------------------*/
.z-index1 {
	z-index: 1;
}

.z-index2 {
	z-index: 2;
}

.z-index3 {
	z-index: 3;
}

.z-index4 {
	z-index: 4;
}

.z-index5 {
	z-index: 5;
}

.z-index6 {
	z-index: 6;
}

.z-index7 {
	z-index: 7;
}

.z-index8 {
	z-index: 8;
}

.z-index9 {
	z-index: 9;
}

.z-index10 {
	z-index: 10;
}

.z-index100 {
	z-index: 100;
}

.z-index999 {
	z-index: 999;
}

/*======================================
7. COLOR
======================================*/

/*-------------------------------------
	1. text
-------------------------------------*/
.text-white {
	color: #fff;
}

.site-text-primary {
	color: #FFBC13;
}

.text-secondry {
	color: #1a1a1a;
}

.text-black {
	color: #000;
}

.text-gray {
	color: #f5f6f6;
}

.text-gray-dark {
	color: #d3d3d3;
}

.text-red {
	color: #99090A;
}

.text-green {
	color: #557131;
}

.text-yellow {
	color: #99910A;
}

.text-light-blue {
	color: #004F83;
}

/*-------------------------------------
	2. background color
-------------------------------------*/
.bg-transparent {
	background-color: transparent;
}

.site-site-bg-primary {
	background-color: #ec5598;
}

.bg-secondry {
	background-color: #4b3941;
}

.bg-white {
	background-color: #FFF;
}

.bg-black {
	background-color: #000;
}

.bg-black-light {
	background-color: #212121;
}

.bg-gray {
	background-color: #f5f6f6;
}

.bg-gray-light {
	background-color: #fafafa;
}

.bg-gray-dark {
	background-color: #d3d3d3;
}

.bg-red {
	background-color: #fb5455;
}

.bg-green {
	background-color: #a5de5c;
}

.bg-yellow {
	background-color: #fff000;
}

.bg-dark-blue {
	background-color: #161b23;
}

.bg-light-blue {
	background-color: #3097db;
}

/*-------------------------------------
	3. background image
-------------------------------------*/
.bg-parallax {
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

@media only screen and (max-width:768px) {
	.bg-parallax {
		background-attachment: fixed !important;
		background-position: center !important;
		background-repeat: no-repeat;
		background-size: cover;
	}
}


/*======================================
8. OVERLAY ( transparient box style  )

======================================*/

.overlay-wraper {
	position: relative;
}

.overlay-main {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.opacity-01 {
	opacity: 0.1;
}

.opacity-02 {
	opacity: 0.2;
}

.opacity-03 {
	opacity: 0.3;
}

.opacity-04 {
	opacity: 0.4;
}

.opacity-05 {
	opacity: 0.5;
}

.opacity-06 {
	opacity: 0.6;
}

.opacity-07 {
	opacity: 0.7;
}

.opacity-08 {
	opacity: 0.8;
}

.opacity-09 {
	opacity: 0.9;
}



.overlay-light .overlay-main {
	opacity: 0.3;
}

.overlay-dark .overlay-main {
	opacity: 0.9;
}

.overlay-wraper>.container,
.overlay-wraper>.container-fluid,
.overlay-wraper>.wt-icon-box-wraper,
.overlay-wraper>.wt-left-part,
.overlay-wraper>.wt-right-part {
	position: relative;
	z-index: 1;
}

/*left right part css*/
.wt-left-part,
.wt-right-part {
	width: 560px;
}

.wt-left-part {
	float: right;
}

@media only screen and (max-width:1200px) {

	.wt-left-part,
	.wt-right-part {
		width: 100%;
	}

	.wt-left-part {
		clear: both;
		float: none;
		padding-left: 30px;
	}

	.wt-right-part {
		padding-right: 30px;
	}

}

/*use for box*/
.overlay-bx {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	z-index: 1;
	opacity: 0;
	visibility: hidden;
}

.overlay-icon {
	list-style: none;
	width: 160px;
	height: 40px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -20px -75px;
	z-index: 1 !important;
}

.overlay-icon a {
	display: inline-block;
	padding: 0;
	margin: 0 2px;
	border: 1px solid #fff;
	color: #fff;
}

.overlay-icon .wt-icon-box-xs {
	line-height: 40px;
}

.overlay-icon a:hover {
	color: #fff;
	background-color: #ffbc13;
	border: 1px solid transparent;
}


.overlay-bx:hover a>i,
.wt-thum-bx:hover .overlay-bx a>i,
.wt-box:hover .overlay-bx a>i {
	opacity: 1;
	visibility: visible;
	transition: all .5s ease-in-out;

}

.overlay-bx:hover,
.wt-thum-bx:hover .overlay-bx,
.wt-box:hover .overlay-bx,
.ow-img:hover .overlay-bx,
.ow-portfolio-img:hover .overlay-bx {
	opacity: 1;
	visibility: visible;
}

/*-------------------------------------
	1. show for text
-------------------------------------*/
.overlay-text {
	width: 100%;
	position: absolute;
	padding: 20px;
	color: #FFF;
	left: 0;
	bottom: 20px;
}

.wt-address-bx {
	display: table;
	text-align: left;
	padding: 20px 20px 20px 25px;
	width: 100%;
	height: 100%;
	position: relative;
	font-size: 14px;
	vertical-align: middle;
	font-weight: 600;
}

.wt-address-bx .fa {
	position: absolute;
	top: 22px;
	left: 0;
	font-size: 22px;
}

.col-md-3 .overlay-text {
	padding: 5px;
}

.col-md-4 .overlay-text {
	padding: 20px;
}

.wt-address2-bx {
	display: table;
	background: #eaeaea;
	padding: 5px 10px;
	margin-bottom: 10px
}


/*======================================
9. BOXES CSS 
======================================*/

.rounded-bx,
.wt-box,
.wt-icon-box,
.wt-icon-box-small,
.wt-thum-bx,
.wt-post-thum {
	position: relative;
}


/*-------------------------------------
	1. box content
-------------------------------------*/
.wt-box {
	position: relative;
}

.wt-box.no-margin {
	margin-bottom: 0;
}

/*-------------------------------------
	2. For icons with box
-------------------------------------*/
.wt-icon-box-xld,
.wt-icon-box-xl,
.wt-icon-box-lg,
.wt-icon-box-md,
.wt-icon-box-sm,
.wt-icon-box-xs {
	display: inline-block;
	text-align: center;
}

.wt-icon-box-xld {
	width: 180px;
	height: 180px;
}

.wt-icon-box-xl {
	width: 150px;
	height: 150px;
}

.wt-icon-box-lg {
	width: 120px;
	height: 120px;
}

.wt-icon-box-md {
	width: 100px;
	height: 100px;
}

.wt-icon-box-sm {
	width: 80px;
	height: 80px;
}

.wt-icon-box-xs {
	width: 40px;
	height: 40px;
}

.wt-icon-box-xld.radius,
.wt-icon-box-xl.radius,
.wt-icon-box-lg.radius,
.wt-icon-box-md.radius,
.wt-icon-box-sm.radius,
.wt-icon-box-xs.radius {
	border-radius: 100%;
}

.wt-icon-box-xld i,
.wt-icon-box-xl i,
.wt-icon-box-lg i,
.wt-icon-box-md i,
.wt-icon-box-sm i,
.wt-icon-box-xs i {
	vertical-align: middle;
}

.wt-icon-box-xld i {
	font-size: 100px;
}

.wt-icon-box-xl i {
	font-size: 80px;
}

.wt-icon-box-lg i {
	font-size: 60px;
}

.wt-icon-box-md i {
	font-size: 45px;
}

.wt-icon-box-sm i {
	font-size: 30px;
}

.wt-icon-box-xs i {
	font-size: 20px;
}

.wt-icon-box-xld img,
.wt-icon-box-xl img,
.wt-icon-box-lg img,
.wt-icon-box-md img,
.wt-icon-box-sm img,
.wt-icon-box-xs img {
	vertical-align: middle;
	max-width: 100%;
	width: auto;
}

.wt-icon-box-xld img {
	height: 90px;
}

.wt-icon-box-xl img {
	height: 80px;
}

.wt-icon-box-lg img {
	height: 55px;
}

.wt-icon-box-md img {
	height: 40px;
}

.wt-icon-box-sm img {
	height: 30px;
}

.wt-icon-box-xs img {
	height: 20px;
}

/*-------------------------------------
	3. Border
-------------------------------------*/
.bdr-white {
	border-color: #ffffff;
}

.bdr-black {
	border-color: #000000;
}

.bdr-primary {
	border-color: #ec5598;
}

.bdr-gray {
	border-color: #dddddd;
}

.bdr-gray-light {
	border-color: #eeeeee;
}

.bdr-gray-dark {
	border-color: #333333;
}

.bdr-1,
.bdr-2,
.bdr-3,
.bdr-4,
.bdr-5 {
	border-style: solid;
}

.bdr-dot-1,
.bdr-dot-2,
.bdr-dot-3,
.bdr-dot-4,
.bdr-dot-5 {
	border-style: dotted;
}

.bdr-dash-1,
.bdr-dash-2,
.bdr-dash-3,
.bdr-dash-4,
.bdr-dash-5 {
	border-style: dashed;
}

.bdr-1 {
	border-width: 1px;
}

.bdr-2 {
	border-width: 2px;
}

.bdr-3 {
	border-width: 3px;
}

.bdr-4 {
	border-width: 4px;
}

.bdr-5 {
	border-width: 5px;
}



/*-------------------------------------
	4. For only icons
-------------------------------------*/
.icon-xld,
.icon-xl,
.icon-lg,
.icon-md,
.icon-sm,
.icon-xs {
	display: inline-block;
	text-align: center;
}

.icon-xld i,
.icon-xld [class*="flaticon-"]::before,
.icon-xld [class*="flaticon-"]::after {
	font-size: 100px;
}

.icon-xl i,
.icon-xl [class*="flaticon-"]::before,
.icon-xl [class*="flaticon-"]::after {
	font-size: 80px;
}

.icon-lg i,
.icon-lg [class*="flaticon-"]::before,
.icon-lg [class*="flaticon-"]::after {
	font-size: 60px;
}

.icon-md i,
.icon-md [class*="flaticon-"]::before,
.icon-md [class*="flaticon-"]::after {
	font-size: 45px;
}

.icon-sm i,
.icon-sm [class*="flaticon-"]::before,
.icon-sm [class*="flaticon-"]::after {
	font-size: 30px;
}

.icon-xs i,
.icon-xs [class*="flaticon-"]::before,
.icon-xs [class*="flaticon-"]::after {
	font-size: 20px;
}

.icon-xld i,
.icon-xl i,
.icon-lg i,
.icon-md i,
.icon-sm i,
.icon-xs i {
	vertical-align: middle;
}

.icon-xld {
	width: 120px;
}

.icon-xld i {
	font-size: 100px;
}

.icon-xl {
	width: 100px;
}

.icon-xl i {
	font-size: 80px;
}

.icon-lg {
	width: 80px;
}

.icon-lg i {
	font-size: 60px;
}

.icon-md {
	width: 60px;
}

.icon-md i {
	font-size: 45px;
}

.icon-sm {
	width: 40px;
}

.icon-sm i {
	font-size: 30px;
}

.icon-xs {
	width: 30px;
}

.icon-xs i {
	font-size: 20px;
}

.icon-xld img,
.icon-xl img,
.icon-lg img,
.icon-md img,
.icon-sm img,
.icon-xs img {
	vertical-align: middle;
	max-width: 100%;
	width: auto;
}

.icon-xld img.img-full {
	width: 100%;
	height: 100%
}

.icon-xl img.img-full {
	width: 100%;
	height: 100%
}

.icon-xld img {
	height: 90px;
}

.icon-xld img.img-full {
	width: 100%;
	height: 100%
}

.icon-xl img {
	height: 80px;
}

.icon-lg img {
	height: 70px;
}

.icon-md img {
	height: 50px;
}

.icon-sm img {
	height: 30px;
}

.icon-xs img {
	height: 20px;
}

/*-------------------------------------
	5. place icon with box
-------------------------------------*/
.about-types .wt-icon-box-wraper {
	border-width: 0px 0px 0px 3px;
}

.wt-icon-box-wraper {
	position: relative;
}

.wt-icon-box-wraper .wt-tilte {
	margin-top: 0;
}

.wt-icon-box-wraper .after-titile-line {
	margin-bottom: 10px;
}

.wt-icon-box-wraper p:last-child {
	margin: 0px;
}

.icon-content {
	overflow: hidden;
}

/* .icon-content p{
	word-break:break-all;
} */
.wt-icon-box-xld,
.wt-icon-box-xl,
.wt-icon-box-lg,
.wt-icon-box-md,
.wt-icon-box-sm,
.wt-icon-box-xs {
	display: table;
}

.wt-icon-box-xld .icon-cell,
.wt-icon-box-xl .icon-cell,
.wt-icon-box-lg .icon-cell,
.wt-icon-box-md .icon-cell,
.wt-icon-box-sm .icon-cell,
.wt-icon-box-xs .icon-cell {
	display: table-cell;
	vertical-align: middle;
}


/*-------------------------------------
	6. icon box left align
-------------------------------------*/
.wt-icon-box-wraper.left .wt-icon-box-xld,
.wt-icon-box-wraper.left .wt-icon-box-xl,
.wt-icon-box-wraper.left .wt-icon-box-lg,
.wt-icon-box-wraper.left .wt-icon-box-md,
.wt-icon-box-wraper.left .wt-icon-box-sm,
.wt-icon-box-wraper.left .wt-icon-box-xs {
	float: left;
	margin-right: 20px;
}

.wt-icon-box-wraper.left .icon-xld,
.wt-icon-box-wraper.left .icon-xl,
.wt-icon-box-wraper.left .icon-lg,
.wt-icon-box-wraper.left .icon-md,
.wt-icon-box-wraper.left .icon-sm,
.wt-icon-box-wraper.left .icon-xs {
	float: left;
	margin-right: 10px;
}

/*-------------------------------------
	7. icon box right align
-------------------------------------*/
.wt-icon-box-wraper.right {
	text-align: right;
}

.wt-icon-box-wraper.right .wt-icon-box-xld,
.wt-icon-box-wraper.right .wt-icon-box-xl,
.wt-icon-box-wraper.right .wt-icon-box-lg,
.wt-icon-box-wraper.right .wt-icon-box-md,
.wt-icon-box-wraper.right .wt-icon-box-sm,
.wt-icon-box-wraper.right .wt-icon-box-xs {
	float: right;
	display: table;
	margin-left: 20px;
}

.wt-icon-box-wraper.right .icon-xld,
.wt-icon-box-wraper.right .icon-xl,
.wt-icon-box-wraper.right .icon-lg,
.wt-icon-box-wraper.right .icon-md,
.wt-icon-box-wraper.right .icon-sm,
.wt-icon-box-wraper.right .icon-xs {
	float: right;
	margin-left: 10px;
}

/*-------------------------------------
	8. icon box center align
-------------------------------------*/
.wt-icon-box-wraper.center {
	text-align: center;
}

.wt-icon-box-wraper.center .wt-icon-box-xld,
.wt-icon-box-wraper.center .wt-icon-box-xl,
.wt-icon-box-wraper.center .wt-icon-box-lg,
.wt-icon-box-wraper.center .wt-icon-box-md,
.wt-icon-box-wraper.center .wt-icon-box-sm,
.wt-icon-box-wraper.center .wt-icon-box-xs {
	margin-left: auto;
	margin-right: auto;
}



.wt-icon-box-wraper.bx-style-1,
.wt-icon-box-wraper.bx-style-2 {
	border-width: 1px;
	border-style: solid;
	border-color: #ddd;
}

.wt-icon-box-wraper.bx-style-2.center [class*="wt-icon-box-"],
.wt-icon-box-wraper.bx-style-2.left [class*="wt-icon-box-"],
.wt-icon-box-wraper.bx-style-2.right [class*="wt-icon-box-"] {
	position: absolute;
}

.wt-icon-box-wraper.bx-style-2.center [class*="wt-icon-box-"] {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateY(-50%);
}

.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-xl {
	margin-left: -75px;
}

.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-lg {
	margin-left: -60px;
}

.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-md {
	margin-left: -50px;
}

.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-sm {
	margin-left: -40px;
}

.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-xs {
	margin-left: -20px;
}

.wt-icon-box-wraper.bx-style-2.left [class*="wt-icon-box-"] {
	position: absolute;
	top: auto;
	left: 0;
	transform: translateX(-50%);
}

.wt-icon-box-wraper.bx-style-2.right [class*="wt-icon-box-"] {
	position: absolute;
	top: auto;
	right: 0;
	transform: translateX(50%);
}

/*-------------------------------------
	9. media with content box css
-------------------------------------*/
.wt-box,
.wt-info,
.wt-tilte,
.wt-tilte-inner {
	position: relative;
}

.wt-tilte-inner {
	display: inline-block;
}

.wt-tilte-inner.skew-title:after {
	content: "";
	position: absolute;
	right: -15px;
	top: 0;
	width: 50px;
	height: 100%;
	z-index: -1;
	-moz-transform: skewX(20deg);
	-webkit-transform: skewX(20deg);
	-o-transform: skewX(20deg);
	-ms-transform: skewX(20deg);
	transform: skewX(20deg);
}

.wt-box[class*="border-"],
.wt-info[class*="border-"],
.wt-icon-box-wraper[class*="border-"] {
	border-color: #eee;
}

.wt-info.border-1,
.wt-info.border-2,
.wt-info.border-3,
.wt-info.border-4,
.wt-info.border-5 {
	border-top: none;
}

/*-------------------------------------
	10. before & after for line css
-------------------------------------*/
.left-border,
.right-border {
	position: relative;
}

.left-border:before,
.right-border:before {
	content: "";
	position: absolute;
	top: 5px;
	width: 1px;
	height: 90%;
	background: #CCC;
}

.right-border:before {
	right: 0;
}

/*-------------------------------------
	11. for images
-------------------------------------*/
.wt-thum-bx,
.wt-post-thum {
	background-color: #000;
}

.wt-thum-bx img,
.wt-post-thum img {
	width: 100%;
	height: auto;
}


/*======================================
10. IMAGES OVERLAY EFFECTS (overly animation on images)  
======================================*/

.wt-img-overlay1,
.wt-img-overlay2,
.wt-img-overlay3,
.wt-img-overlay4,
.wt-img-overlay5,
.wt-img-overlay6,
.wt-img-overlay7,
.wt-img-overlay8,
.wt-img-overlay9 .wt-img-overlay10 {
	position: relative;
}

.wt-img-overlay1:before,
.wt-img-overlay1:after,
.wt-img-overlay2:before,
.wt-img-overlay2:after,
.wt-img-overlay3:before,
.wt-img-overlay3:after,
.wt-img-overlay4:before,
.wt-img-overlay4:after,
.wt-img-overlay5:before,
.wt-img-overlay5:after,
.wt-img-overlay6:before,
.wt-img-overlay6:after,
.wt-img-overlay7:before,
.wt-img-overlay7:after,
.wt-img-overlay8:before,
.wt-img-overlay8:after,
.wt-img-overlay9:before,
.wt-img-overlay9:after {
	content: "";
	background: #000;
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	height: 0;
	opacity: 0.4;
	z-index: 1;
	transition: all 0.3s linear;
}


/*-------------------------------------
	1. effect 1
-------------------------------------*/
.wt-img-overlay1:before {
	width: 100%;
	height: 100%;
	opacity: 0;
}

.wt-img-overlay1:hover:before,
.wt-box:hover .wt-img-overlay1:before {
	opacity: 0.5;
}

/*-------------------------------------
	2. effect 2
-------------------------------------*/
.wt-img-overlay2:before {
	width: 100%;
	height: 100%;
	opacity: 0;
	background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	/*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );*/
	/* IE6-9 */

}

.wt-img-overlay2:hover:before,
.wt-box:hover .wt-img-overlay2:before {
	opacity: 0.9;
}

/*-------------------------------------
	3. effect 3
-------------------------------------*/
.wt-img-overlay3:before {
	left: 50%;
	top: 50%;
}

.wt-img-overlay3:hover:before,
.wt-box:hover .wt-img-overlay3:before {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

/*-------------------------------------
	4. effect 4
-------------------------------------*/
.wt-img-overlay4:before {
	left: 50%;
	top: 0;
	height: 100%;
}

.wt-img-overlay4:hover:before,
.wt-box:hover .wt-img-overlay4:before {
	width: 100%;
	left: 0;
}

/*-------------------------------------
	5. effect 5
-------------------------------------*/
.wt-img-overlay5:before {
	left: 0;
	top: 50%;
	width: 100%;
}

.wt-img-overlay5:hover:before,
.wt-box:hover .wt-img-overlay5:before {
	height: 100%;
	top: 0;
}

/*-------------------------------------
	6. effect 6
-------------------------------------*/
.wt-img-overlay6:before {
	left: 0;
	top: auto;
	bottom: 0;
	width: 100%;
}

.wt-img-overlay6:hover:before,
.wt-box:hover .wt-img-overlay6:before {
	height: 100%;
}

/*-------------------------------------
	7. effect 7
-------------------------------------*/
.wt-img-overlay7:before,
.wt-img-overlay7:after {
	height: 100%;
}

.wt-img-overlay7:after {
	left: auto;
	right: 0;
	z-index: 0;
}

.wt-img-overlay7:hover:before,
.wt-img-overlay7:hover:after,
.wt-box:hover .wt-img-overlay7:before,
.wt-box:hover .wt-img-overlay7:after {
	width: 50%;
}

/*-------------------------------------
	8. effect 8
-------------------------------------*/
.wt-img-overlay8:before,
.wt-img-overlay8:after {
	height: 100%;
}

.wt-img-overlay8:after {
	left: auto;
	right: 0;
}

.wt-img-overlay8:hover:before,
.wt-img-overlay8:hover:after,
.wt-box:hover .wt-img-overlay8:before,
.wt-box:hover .wt-img-overlay8:after {
	width: 100%;
	opacity: 0.3;
}

/*-------------------------------------
	9. effect 9
-------------------------------------*/

.wt-img-overlay9:after {
	left: auto;
	right: 0;
	top: auto;
	bottom: 0;

}

.wt-img-overlay9:hover:before,
.wt-img-overlay9:hover:after,
.wt-box:hover .wt-img-overlay9:before,
.wt-box:hover .wt-img-overlay9:after {
	width: 100%;
	height: 100%;
	opacity: 0.3;
}

/*-------------------------------------
	10. effect 10
-------------------------------------*/
.wt-img-overlay10:before,
.wt-img-overlay10:after {
	width: 100%;
	height: 100%;
	opacity: 0;
}

.wt-img-overlay10:hover:before,
.wt-box:hover .wt-img-overlay10:before {
	opacity: 0.5;
}

.wt-img-overlay10:hover:after,
.wt-box:hover .wt-img-overlay10:after {
	width: 90%;
	height: 80%;
	left: 5%;
	top: 10%;
	opacity: 0.5;
	background: none;
	border: 1px dashed #FFFFFF;
}

/*-------------------------------------
	10. effect 11
-------------------------------------*/
.wt-img-overlay11:hover .wt-img-overlay11-content {
	-moz-transform: scale(1);
	-webkit-transform: scale(1);
	-o-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

.wt-img-overlay11-content {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 1;
	transition: all 0.3s linear;
	transform: scale(0);
}

.wt-img-overlay11-content .overlay-11-detail {
	display: table;
	height: 100%;
}

.wt-img-overlay11-content .overlay-11-detail .overlay-11-info {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
}

.wt-img-overlay11-content .overlay-11-detail .overlay-11-info h4 a {
	color: #fff;
}

@media only screen and (max-width:991px) {
	.wt-img-overlay11-content .overlay-11-detail p {
		font-size: 13px;
		line-height: 18px;
	}
}

.wt-img-overlay11-content .bg-color {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: -1;
	left: 0px;
	top: 0px;
}

/*======================================
11. shortcodeS 
======================================*/

/*-------------------------------------
	1. Buttons 
--------------------------------------- */


/* 1.1 theme default button */
.btn {
	padding-top: 9px;
	padding-bottom: 9px;
}

.site-button,
.site-button-secondry {
	color: #fff;
	padding: 10px 20px;
	display: inline-block;
	font-size: 14px;
	outline: none;
	cursor: pointer;
	outline: none;
	border-width: 0;
	border-style: solid;
	border-color: transparent;
	line-height: 1.42857;
	margin-left: -1px;
	text-decoration: none !important;
}

.site-button {
	background-color: #ec5598;
}

.site-button:active,
.site-button:hover,
.site-button:focus,
.active>.site-button {
	background-color: #e4a101;
	color: #fff;
}

.site-button-secondry {
	background-color: #4b3941;
}

.site-button-secondry:active,
.site-button-secondry:hover,
.site-button-secondry:focus,
.active>.site-button-secondry {
	background-color: #192639;
	color: #fff;
}

/* 1.2 button text uppercase */
.site-button.text-uppercase {
	text-transform: uppercase;
}

/* 1.3 button size */
.button-sm {
	padding: 5px 10px;
	font-size: 12px;
}

.button-lg {
	padding: 20px 35px;
	font-size: 16px;
}

.button-xl {
	padding: 30px 50px;
	font-size: 24px;
}

@media only screen and (max-width:575px) {
	.button-lg {
		padding: 10px 20px;
		font-size: 12px;
	}

	.button-xl {
		padding: 10px 20px;
		font-size: 16px;
	}
}

/* 1.4 button rounded */
.radius-no {
	border-radius: 0;
}

.radius-sm {
	border-radius: 3px;
}

.radius-xl {
	border-radius: 100px;
}

.radius-bx {
	border-radius: 100%;
}

/* 1.5 button colors */

/* 1.6 white */
.site-button.white {
	background-color: #fff;
	color: #777;
}

.site-button.white:hover,
.site-button.white:active,
.site-button.white:focus {
	background-color: #F4F4F4;
	color: #555;
}

/* 1.7 black */
.site-button.black {
	background-color: #171717;
	color: #fff;
}

.site-button.black:hover,
.site-button.black:active,
.site-button.black:focus {
	background-color: #000;
	color: #fff;
}

/* 1.8 Gray */
.site-button.gray {
	background-color: #666666;
	color: #fff;
}

.site-button.gray:hover,
.site-button.gray:active,
.site-button.gray:focus {
	background-color: #555555;
	color: #fff;
}

/* 1.9 pink */
.site-button.pink {
	background-color: #e63f75;
	color: #fff;
}

.site-button.pink:hover,
.site-button.pink:active,
.site-button.pink:focus {
	background-color: #d22b61;
	color: #fff;
}

/* 1.10 Blue */
.site-button.blue {
	background-color: #42B8D4;
	color: #fff;
}

.site-button.blue:hover,
.site-button.blue:active,
.site-button.blue:focus {
	background-color: #2ca2be;
	color: #fff;
}

/* 1.11 Green */
.site-button.green {
	background-color: #35B494;
	color: #fff;
}

.site-button.green:hover,
.site-button.green:active,
.site-button.green:focus {
	background-color: #26a585;
	color: #fff;
}

/* 1.12 Orange */
.site-button.orange {
	background-color: #E56713;
	color: #fff;
}

.site-button.orange:hover,
.site-button.orange:active,
.site-button.orange:focus {
	background-color: #d55703;
	color: #fff;
}

/* 1.13 Red */
.site-button.red {
	background-color: #D93223;
	color: #fff;
}

.site-button.red:hover,
.site-button.red:active,
.site-button.red:focus {
	background-color: #c51e0f;
	color: #fff;
}

/*1.14 Brown */
.site-button.brown {
	background-color: #69441F;
	color: #fff;
}

.site-button.brown:hover,
.site-button.brown:active,
.site-button.brown:focus {
	background-color: #5f3a15;
	color: #fff;
}

/* 1.15 Yellow */
.site-button.yellow {
	background-color: #ecc731;
	color: #fff;
}

.site-button.yellow:hover,
.site-button.yellow:active,
.site-button.yellow:focus {
	background-color: #d4af19;
	color: #fff;
}

/* 1.16 purple */
.site-button.purple {
	background-color: #AE1AF7;
	color: #fff;
}

.site-button.purple:hover,
.site-button.purple:active,
.site-button.purple:focus {
	background-color: #9804e1;
	color: #fff;
}

/* 1.17 Graphical */
.site-button.graphical {
	color: #fff;
	text-shadow: 0 -1px rgba(0, 0, 0, 0.4);
	box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.3), 0 1px 3px -1px rgba(45, 60, 72, 0.5);
	border: 1px solid rgba(0, 0, 0, 0.15);
}

.site-button.graphical:active {
	box-shadow: 0 2px 2px rgba(0, 0, 0, .25) inset;
}

/* 1.18 3D */
.site-button.button-3d {
	color: #fff;
	border-bottom: 4px solid rgba(0, 0, 0, 0.2);
	border-left: none;
	border-right: none;
	border-top: none;
}

/* 1.19 Outline */
.site-button.outline {
	color: #888;
	background: none;
	border-width: 2px;
	border-style: solid;
	border-color: #eee;
}

/* 1.20 Outline white  */
.site-button.outline.white {
	color: #e7e7e7;
	border-color: #e7e7e7;
}

.site-button.outline.white:hover {
	color: #666666;
}

/* 1.21 Outline black */
.site-button.outline.black {
	color: #171717;
	border-color: #171717;
}

/* 1.22 Outline gray */
.site-button.outline.gray {
	color: #666666;
	border-color: #666666;
}

/* 1.23 Outline pink */
.site-button.outline.pink {
	color: #e63f75;
	border-color: #e63f75;
}

/* 1.24 Outline blue */
.site-button.outline.blue {
	color: #42b8d4;
	border-color: #42b8d4;
}

/* 1.25 Outline green */
.site-button.outline.green {
	color: #35b494;
	border-color: #35b494;
}

/* 1.26 Outline orange */
.site-button.outline.orange {
	color: #e56713;
	border-color: #e56713;
}

/* 1.27 Outline red */
.site-button.outline.red {
	color: #d93223;
	border-color: #d93223;
}

/* 1.28 Outline brown */
.site-button.outline.brown {
	color: #69441f;
	border-color: #69441f;
}

/* 1.29 Outline yellow */
.site-button.outline.yellow {
	color: #ec5598;
	border-color: #ec5598;
}

/* 1.30 Outline purple */
.site-button.outline.purple {
	color: #ae1af7;
	border-color: #ae1af7;
}

/* 1.31 Outline hover */
.site-button.outline:hover {
	border-color: rgba(0, 0, 0, 0);
	color: #fff;
}

/* 1.32 button text link */
.site-button-link {
	display: inline-block;
	font-weight: 600;
	font-family: 'Roboto', sans-serif;
}

/* 1.33 text link white */
.site-button-link.white {
	color: #e7e7e7;
}

.site-button-link.white:hover,
.site-button-link.white:active,
.site-button-link.white:focus {
	color: #CCC;
}

/* 1.34 text link black */
.site-button-link.black {
	color: #171717;
}

.site-button-link.black:hover,
.site-button-link.black:active,
.site-button-link.black:focus {
	color: #000;
}

/* 1.35 text link Gray */
.site-button-link.gray {
	color: #666666;
}

.site-button-link.gray:hover,
.site-button-link.gray:active,
.site-button-link.gray:focus {
	color: #555555;
}

/* 1.36 text link pink */
.site-button-link.pink {
	color: #e63f75;
}

.site-button-link.pink:hover,
.site-button-link.pink:active,
.site-button-link.pink:focus {
	color: #2ca2be;
}

/* 1.37 text link Blue */
.site-button-link.blue {
	color: #42B8D4;
}

.site-button-link.blue:hover,
.site-button-link.blue:active,
.site-button-link.blue:focus {
	color: #2ca2be;
}

/* 1.38 text link Green */
.site-button-link.green {
	color: #35B494;
}

.site-button-link.green:hover,
.site-button-link.green:active,
.site-button-link.green:focus {
	color: #26a585;
}

/* 1.39 text link Orange */
.site-button-link.orange {
	color: #E56713;
}

.site-button-link.orange:hover,
.site-button-link.orange:active,
.site-button-link.orange:focus {
	color: #d55703;
}

/* 1.40 text link Red */
.site-button-link.red {
	color: #D93223;
}

.site-button-link.red:hover,
.site-button-link.red:active,
.site-button-link.red:focus {
	color: #c51e0f;
}

/* 1.41 text link Brown */
.site-button-link.brown {
	color: #69441F;
}

.site-button-link.brown:hover,
.site-button-link.brown:active,
.site-button-link.brown:focus {
	color: #5f3a15;
}

/* 1.42 text link Yellow */
.site-button-link.yellow {
	color: #ecc731;
}

.site-button-link.yellow:hover,
.site-button-link.yellow:active,
.site-button-link.yellow:focus {
	color: #d4af19;
}

/* 1.43 text link purple */
.site-button-link.purple {
	color: #ae1af7;
}

.site-button-link.purple:hover,
.site-button-link.purple:active,
.site-button-link.purple:focus {
	color: #9804e1;
}

/* 1.44 app strore */
.site-button.button-app {
	text-align: left;
	padding: 15px 25px;
}

.site-button.button-app i {
	display: inline-block;
	font-size: 45px;
	margin-right: 15px;
}

.site-button.button-app strong {
	display: block;
	font-size: 16px;
}

/* 1.45 filter buttons css */

.masonry-filter {
	display: table;
	margin-bottom: 0;
}

.masonry-filter>li {
	display: inline-block;
	margin-right: 30px;
	position: relative;
}

.masonry-filter>li a {
	color: #666666;
	font-size: 14px;
}


.masonry-filter>li.active a,
.masonry-filter>li a:hover,
.masonry-filter>li a:active,
.masonry-filter>li a:focus {
	color: #f7c20a;
}

@media only screen and (max-width: 600px) {
	.masonry-filter>li {
		margin-bottom: 10px;
	}

	.masonry-filter>li a {
		font-size: 14px !important;
	}
}

/* 1.45(1) Masonry filter alignment [Left-Right-Center] */
.filter-wrap.right>.masonry-filter {
	float: right;
}

@media only screen and (max-width:991px) {
	.filter-wrap.right>.masonry-filter {
		float: none;
	}
}

.filter-wrap.center>.masonry-filter {
	margin-left: auto;
	margin-right: auto;
}

/* 1.45(2) Masonry filter link style */
.masonry-filter.link-style>li a {
	font-size: 15px;
	font-weight: 600;
}

.masonry-filter.link-style>li:after {
	content: "/";
	position: relative;
	right: -18px;
	opacity: 0.3;
}

.masonry-filter.link-style.white>li:after {
	color: #fff;
}

.masonry-filter.link-style>li:last-child:after {
	display: none;
}

.masonry-filter.link-style.white>li.active a,
.masonry-filter.link-style.white>li a:hover,
.masonry-filter.link-style.white>li a:active,
.masonry-filter.link-style.white>li a:focus {
	color: #f7c20a;
}

.masonry-filter.has-bg>li a {
	color: #fff;
}

.masonry-filter.link-style.has-bg>li.active a,
.masonry-filter.link-style.has-bg>li:hover a,
.masonry-filter.link-style.has-bg>li:active a,
.masonry-filter.link-style.has-bg>li:focus a {
	color: #ec5598;
}

/* 1.45(3) Masonry filter button style */
.masonry-filter.button-style>li {
	margin-right: 10px;
	margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
	.masonry-filter.button-style>li {
		margin-bottom: 10px;
	}
}

.masonry-filter.button-style>li a {
	background-color: #666666;
	color: #fff;
	padding: 10px 20px;
	font-weight: 600;
	display: inline-block;
}

.masonry-filter.button-style>li.active a,
.masonry-filter.button-style>li a:hover,
.masonry-filter.button-style>li a:active,
.masonry-filter.button-style>li a:focus {
	background-color: #ec5598;
	color: #fff;
}

.masonry-filter.button-style.has-bg>li a {
	background-color: #fff;
	color: #666;
}

.masonry-filter.button-style.has-bg>li.active a,
.masonry-filter.button-style.has-bg>li a:hover,
.masonry-filter.button-style.has-bg>li a:active,
.masonry-filter.button-style.has-bg>li a:focus {
	background-color: #ec5598;
	color: #fff;
}

/* 1.45(4) Masonry filter button outline style */
.masonry-filter.outline-style>li {
	margin-right: 10px;
}

.masonry-filter.outline-style>li a {
	border-width: 2px;
	border-style: solid;
	border-color: #666666;
	color: #666666;
	padding: 10px 20px;
	font-weight: 600;
	display: inline-block;
	margin-bottom: 10px;
}

.masonry-filter.outline-style.rounded-corner>li a {
	border-radius: 100px;
}

.masonry-filter.outline-style>li.active a,
.masonry-filter.outline-style>li a:hover,
.masonry-filter.outline-style>li a:active,
.masonry-filter.outline-style>li a:focus {
	border-color: #ec5598;
	color: #ec5598;
}

.masonry-filter.outline-style.has-bg>li a {
	border-color: #fff;
	color: #fff;
}

.masonry-filter.outline-style.has-bg>li.active a,
.masonry-filter.outline-style.has-bg>li a:hover,
.masonry-filter.outline-style.has-bg>li a:active,
.masonry-filter.outline-style.has-bg>li a:focus {
	border-color: #ec5598;
	color: #ec5598;
}

@media only screen and (max-width:767px) {
	.masonry-item {
		width: 100%;
	}
}



/*-------------------------------------
	2. Title separators 
-------------------------------------*/
.wt-separator-outer {
	overflow: hidden;
}

/*Separator defualt*/
.wt-separator {
	display: inline-block;
	height: 3px;
	width: 50px;
	position: relative;
}

.wt-separator .separator-left,
.wt-separator .separator-right {
	position: absolute;
	top: 50%;
	width: 70px;
	height: 2px;
	margin-top: -1px;
}

.wt-separator .separator-left {
	left: -80px;
}

.wt-separator .separator-right {
	right: -80px;
}

/*Separator liner*/
.wt-separator.style-liner {
	width: 20px;
	height: 4px;
}

/*Separator square*/
.wt-separator.style-square {
	width: 10px;
	height: 10px;
	background-color: transparent;
	border-width: 3px;
	border-style: solid;
	border-color: #2d3239;
}

.wt-separator.style-square .separator-left,
.wt-separator.style-square .separator-right {
	height: 3px;
}

.wt-separator.style-square.has-bg {
	border-color: #fff;
}

/*Separator icon*/
.wt-separator.style-icon {
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	font-size: 18px;
	color: #dedede;
}




/*-------------------------------------
	3. Deviders 
-------------------------------------*/
.wt-divider {
	height: 1px;
	position: relative;
	margin: 30px 0;
}

.wt-divider.divider-2px {
	height: 2px;
}

.wt-divider.divider-3px {
	height: 3px;
}

.wt-divider.divider-4px {
	height: 4px;
}

.wt-divider i {
	position: absolute;
	width: 30px;
	height: 30px;
	text-align: center;
	line-height: 30px;
	display: block;
	border-radius: 100%;
	transform: translateY(-50%);
}

.wt-divider.icon-left {
	margin-left: 40px;
}

.wt-divider.icon-left i {
	left: -40px;
}

.wt-divider.icon-right {
	margin-right: 40px;
}

.wt-divider.icon-right i {
	left: auto;
	right: -40px;
}

.wt-divider.icon-center i {
	left: 50%;
	margin-left: -5px;
}

/*-------------------------------------
	4. TABS 
-------------------------------------*/
.wt-tabs:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
}

@media only screen and (max-width: 767px) {
	.wt-tabs .nav-tabs>li {
		float: none;
	}
}

.wt-tabs p:last-child {
	margin-bottom: 0;
}

/*4.1 tabs style 1 [ default ] */
.wt-tabs .tab-pane {
	padding: 20px 0;
}

.wt-tabs .nav-tabs>li>a {
	color: #1a1a1a;
	font-weight: 600;
	font-size: 13px;
	border-radius: 0px;
	padding: 15px;
}

.wt-tabs .nav-tabs>li>a.active,
.wt-tabs .nav-tabs>li>a.active:focus,
.wt-tabs .nav-tabs>li>a.active:hover {
	background-color: #fff;
	border-color: #ddd;
	border-bottom: 1px solid #FFF;
	color: #1a1a1a;
}

.wt-tabs .nav>li>a:focus,
.wt-tabs .nav>li>a:hover {
	background-color: transparent;
}

.wt-tabs .nav li a:hover {
	border: 1px solid transparent;
}

.wt-tabs .nav-tabs>li>a i {
	margin-right: 5px;
}

.wt-tabs.tabs-default.has-bg .nav-tabs>li>a {
	color: #fff;
}


.wt-tabs.tabs-default.has-bg .nav-tabs>li>a.active,
.wt-tabs.tabs-default.has-bg .nav-tabs>li>a.active:focus,
.wt-tabs.tabs-default.has-bg .nav-tabs>li>a.active:hover {
	color: #1a1a1a;
	border-color: #ddd #ddd #fff;
}

.wt-tabs.tabs-default.has-bg .tab-pane {
	color: #fff;
}

/* tabs nav center */
.wt-tabs.nav-center>.nav-tabs {
	display: table;
	margin-left: auto;
	margin-right: auto;
}

/*4.2 tabs bg  [ tabs background ] */
.wt-tabs.bg-tabs .nav-tabs>li>a {
	background-color: #f0f0f0;
	border: 1px solid #ddd;
	margin-right: -1px;
}

.wt-tabs.bg-tabs .nav-tabs>li>a.active {
	border-bottom: 1px solid transparent;
	background-color: #fff;
}

.wt-tabs.bg-tabs.has-bg .tab-pane {
	color: #fff;
}

/*4.3 tabs bg  [ tabs background ] */
.wt-tabs.vertical.bg-tabs .nav-tabs>li>a {
	border: 1px solid #ddd;
}

.wt-tabs.vertical.bg-tabs .nav-tabs>li>a.active {
	border-right: 1px solid transparent;
}

.wt-tabs.vertical.right.bg-tabs .nav-tabs>li>a {
	border: 1px solid #ddd;
}

.wt-tabs.vertical.right.bg-tabs .nav-tabs>li>a.active {
	border-left: 1px solid transparent;
}

.wt-tabs.border-top {
	border-top: 0px !important;
}

/*4.4 tabs bg & top border  [ tabs background & top border in active ] */
.wt-tabs.border-top .nav-tabs>li>a.active {
	color: #1a1a1a;
	position: relative;
}

.wt-tabs.border-top.border.bg-tabs .nav-tabs {
	border: 0px;
}

.wt-tabs.border-top .nav-tabs>li>a.active:after {
	content: "";
	position: absolute;
	top: -1px;
	left: 0;
	width: 100%;
	height: 3px;
	background-color: #FFBC13;
}

.wt-tabs.border-top.vertical .nav-tabs>li>a.active:after {
	top: 0px;
	left: -1px;
	width: 3px;
	height: 100%;
}

/*4.4 tabs style 2 [ content with border outer ] */
.wt-tabs.border {
	border: none !important;
}

.wt-tabs.border .tab-pane {
	padding: 10px;
	border: 1px solid #ddd;
	margin-top: -1px;
}

/*4.5 tabs style 3  [ left-nav ] */
.wt-tabs.vertical.tabs-default.border .nav-tabs {
	border-right: 0px;
}

.wt-tabs.vertical .nav-tabs {
	float: left;
	width: 170px;
	border-bottom: none;
	border-right: 1px solid #DDD;
}

.wt-tabs.border-top.vertical.bg-tabs .nav-tabs {
	border-right: 0px;
}

@media only screen and (max-width:720px) {
	.wt-tabs.vertical .nav-tabs {
		width: 100%;
		float: none;
		border-right: 0px;
	}

	.wt-tabs.vertical .tab-content {
		width: 100%;
		margin-left: 0px !important;
	}
}

.wt-tabs.vertical .tab-pane {
	padding: 10px 0 10px 20px;
}

.wt-tabs.vertical .nav-tabs li {
	float: none;
	margin-right: 0px;
	width: 100%;
}

.wt-tabs.vertical .nav-tabs li a {
	margin-right: -1px;
	border-right: none;
	border-radius: 0px;
	color: #1a1a1a;
}

.wt-tabs.border-top.vertical.border.bg-tabs .nav-tabs li a {
	margin-right: -2px;
}

.wt-tabs.vertical .nav-tabs li a.active {
	border-bottom: 1px solid #ddd;
	background-color: #fff;
}

.wt-tabs.vertical .tab-content {
	border-left: 1px solid #DDD;
	margin-left: 169px;
}

/*4.6 tabs style 4  [ left-nav & content with border ] */
.wt-tabs.vertical.border .tab-pane {
	padding: 20px;
	margin-left: -1px;
}

/*4.7 tabs style 5  [ right-nav ] */
.wt-tabs.vertical.right .nav-tabs {
	/* border-left: 1px solid #ddd; */
	border-right: none;
	float: right;
}

.wt-tabs.vertical.right .nav-tabs li {
	margin-right: 0;
	margin-left: -1px;
}

.wt-tabs.vertical.right .nav-tabs li a {
	border-right: 1px solid transparent;
	border-left: none;
}

.wt-tabs.vertical.right .nav-tabs li a.active {
	border-right: 1px solid #ddd;
	border-left: none;
}

.wt-tabs.vertical.right .tab-content {
	border-left: none;
	border-right: 1px solid #ddd;
	margin-right: 169px;
	margin-left: 0;
}

.wt-tabs.vertical.right .tab-pane {
	padding: 10px 20px 10px 0;
}

/*4.8 tabs style 6  [ right-nav & content with border ]*/
.wt-tabs.vertical.right.border .tab-pane {
	padding: 20px;
	margin-right: -1px;
}


@media only screen and (max-width:720px) {
	.wt-tabs.tabs-default .nav.nav-tabs {
		display: block;
	}

	.wt-tabs.tabs-default .nav.nav-tabs li a.active {
		border: 1px solid #ddd;
		margin-right: 0px;
	}

	.pricing-tab-content-block .wt-tabs.vertical .tab-content {
		border: 0px solid #ddd;
	}

	.wt-tabs.vertical .tab-content {
		border: 1px solid #DDD;
	}

	.wt-tabs.vertical.right .nav-tabs {
		float: none;
		border-left: 0px;
	}

	.wt-tabs.vertical.right .tab-content {
		border: 1px solid #DDD;
	}

	.wt-tabs.vertical.right .tab-pane {
		padding: 10px;
	}

	.wt-tabs.vertical.tabs-default.border .tab-content {
		border: 0px;
	}

	.wt-tabs.bg-tabs ul {
		display: block;
	}

	.wt-tabs.vertical.bg-tabs .nav-tabs>li>a {
		margin-right: 0px;
	}

	.wt-tabs.vertical.bg-tabs .nav-tabs>li>a.active {
		border-right: 1px solid #ddd;
	}

	/* .wt-tabs.border .tab-pane{border: 0px;} */
	.wt-tabs.vertical.right.tabs-default.has-bg .nav-link.active {
		margin-right: -1px;
	}

	.wt-tabs.vertical.right.bg-tabs.has-bg .nav-link {
		margin-right: -1px;
	}
}

/*-------------------------------------
	5. Accordians 
-------------------------------------*/
.wt-accordion .wt-panel {
	background-color: transparent;
	border: none;
	margin-bottom: 10px;
	border-radius: 0;
	box-shadow: none;

}

.acod-head {
	position: relative;
}

.acod-title {
	margin-top: 0;
	margin-bottom: 0;
}

.acod-title a {
	position: relative;
	z-index: 1;
	overflow: hidden;
}

.acod-head .fa {
	margin-right: 5px;
}

.acod-head a {
	display: block;
	padding: 15px 40px 15px 15px;
}

.acod-head a,
.acod-head a:hover,
.acod-head a:active,
.acod-head a:focus {
	color: #3d474a;
}

.acod-body {
	color: #777;
}

/*Accodian open close indicator css*/
.acod-head .indicator {
	padding: 15px;
	color: #ababab;
	position: absolute;
	right: 0;
	top: 0;
}

.acod-head .indicator .fa,
.acod-head.acc-actives .indicator .fa {
	-moz-transform: rotate(0deg);
	-webkit-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	transform: rotate(0deg);
}

.acod-head.acc-actives .indicator .fa,
.acod-head [aria-expanded="true"] .indicator .fa {
	-moz-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

/* 1. Accordion defult */
.acc-default .acod-title a {
	padding: 5px 0;
}

.acc-default .acod-head .indicator {
	padding: 0;
}

.acc-default.acc-has-bg .acod-head a,
.acc-default.acc-has-bg .acod-head a:hover,
.acc-default.acc-has-bg .acod-head a:active,
.acc-default.acc-has-bg .acod-head a:focus,
.acc-default.acc-has-bg .acod-head .indicator,
.acc-default.acc-has-bg .acod-body {
	color: #fff;
}

/* 2. Accordion outline */
.acc-outline .acod-title a {
	border: 1px solid #ddd;
}

.acc-outline.acc-has-bg .acod-head a,
.acc-outline.acc-has-bg .acod-head a:hover,
.acc-outline.acc-has-bg .acod-head a:active,
.acc-outline.acc-has-bg .acod-head a:focus,
.acc-outline.acc-has-bg .acod-head .indicator,
.acc-outline.acc-has-bg .acod-body {
	color: #fff;
}

/* 3. Accordion bg gray */
.acc-bg-gray a {
	background-color: #F5F5F5;
}

.acc-bg-gray .acod-head .indicator {
	color: #777;
}

.acc-bg-gray.acc-has-bg .acod-body {
	color: #fff;
}

/* 4. Accordion bg primary */
.acc-site-bg-primary a {
	background-color: #ec5598;
}

.acc-site-bg-primary .acod-head a,
.acc-site-bg-primary .acod-head a:hover,
.acc-site-bg-primary .acod-head a:active,
.acc-site-bg-primary .acod-head a:focus {
	color: #fff;
}

.acc-site-bg-primary .acod-head .indicator {
	color: #fff;
}

.acc-site-bg-primary.acc-has-bg .acod-body {
	color: #fff;
}

/* 5. Accordion bg dark */
.acc-bg-dark a {
	background-color: #212427;
}

.acc-bg-dark .acod-head a,
.acc-bg-dark .acod-head a:hover,
.acc-bg-dark .acod-head a:active,
.acc-bg-dark .acod-head a:focus {
	color: #fff;
}

.acc-bg-dark .acod-head .indicator {
	color: #fff;
}

.acc-bg-dark.acc-has-bg .acod-body {
	color: #fff;
}



/*-------------------------------------
	6. Carousels 
-------------------------------------*/
/* 6.1 Default Carousel */
.owl-imgbx,
.ow-portfolio-img {
	position: relative;
}

.ow-entry-content {
	border-width: 0 1px 1px 1px;
	border-style: solid;
	border-color: #eee;
	background-color: #fff;
	padding: 20px;
	margin-bottom: 5px;
}

.ow-entry-title {
	font-size: 16px;
	font-weight: 600;
	padding: 5px 0;
}

.ow-entry-title,
.ow-entry-title a {
	color: #3d474a;
}

.ow-entry-text p:last-child {
	margin: 0;
}

/* 6.2 Blog carousel */
.ow-post-title .post-title {
	margin: 0 0 10px;
}

.ow-post-text {
	margin-bottom: 10px;
}

.ow-post-text p:last-child {
	margin: 0;
}

.ow-post-readmore {
	margin-bottom: 10px;
}

.ow-post-readmore a {
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	text-decoration: underline;
}

.ow-post-meta {
	margin-bottom: 10px;
	font-family: 'Crete Round', serif;
}

.ow-post-meta {
	margin-bottom: 10px;
	font-family: 'Roboto', sans-serif;
}

.ow-post-meta ul {
	margin: 0 -4px;
	list-style: none;
}

.ow-post-meta ul li {
	padding: 0;
	display: inline-block;
}

.ow-post-meta li:after {
	content: "/";
	display: inline-block;
	font-weight: normal;
	margin-left: 5px;
	opacity: 0.5;
}

.ow-post-meta li:last-child:after {
	display: none;
}

.ow-post-meta a {
	color: #a9a9a9;
}

.ow-post-meta li i {
	color: #7b7b7b;
	margin: 0 5px;
}

.ow-post-tags {
	border-top: 1px solid #E9E9E9;
	padding-top: 10px;
}

.ow-post-tags .post-comment {
	float: left;
	font-weight: bold;
	text-transform: uppercase;
}

.ow-post-tags .post-comment a {
	color: #a9a9a9;
}

.ow-post-tags .post-tags {
	margin: 0 -3px;
	list-style: none;
}

.ow-post-tags .post-tags a {
	border: 1px solid #ebebeb;
	padding: 2px 8px 1px;
	color: #777;
	margin: 0 3px;
	display: inline-block;
	text-transform: uppercase;
	font-size: 11px;
}

.ow-post-tags .post-tags a:hover,
.ow-post-tags .post-tags a:active,
.ow-post-tags .post-tags a:focus {
	background-color: #ec5598;
	color: #fff;
}

.date-style-3 .ow-post-info {
	position: relative;
}

.date-style-3 .ow-post-media {
	border-bottom: 3px solid #ec5598;
}

/*  empty media */
.no-image-blog.date-style-2 .ow-post-info {
	padding-top: 70px;
}

/* 6.3 Event carousel */
.ow-event-title .event-title {
	margin: 0 0 10px;
}

.ow-event-text {
	margin-bottom: 10px;
}

.ow-event-text p:last-child {
	margin: 0;
}

.ow-event-readmore {
	margin-bottom: 10px;
}

.ow-event-readmore a {
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	font-style: italic;
	text-decoration: underline;
}

.ow-event-meta ul {
	margin: 0;
}

.ow-event-meta ul li {
	padding: 0;
	display: inline-block;
	font-style: italic;
	font-size: 14px;
	font-family: 'Roboto', sans-serif;
}

.ow-event-meta ul li i {
	color: #7b7b7b;
	margin-right: 3px;
}

/* empty media */
.no-image-event.date-style-2 .ow-post-info {
	padding-top: 70px;
}

/* 6.4 Client carousel 1*/
.ow-client-logo {
	background-color: #fff;
	display: table;
	width: 100%;
}

.client-logo {
	display: table-cell;
	text-align: center;
	vertical-align: middle;
	padding: 10px;
}

.owl-carousel .ow-client-logo img {
	max-height: 100%;
	width: auto;
	display: inline-block;
}

.client-logo-media img {
	max-width: 100% !important;
	margin: 0px auto !important;
	width: auto !important;
}

/*-------------------------------------
	7. Testimonials 
-------------------------------------*/
.testimonial-pic {
	background: #FFF;
	width: 100px;
	height: 100px;
	position: relative;
	display: inline-block;
	border: 5px solid #FFF;
}

.testimonial-pic.radius {
	border-radius: 100%;
}

.testimonial-pic.radius img {
	width: 100%;
	border-radius: 100%;
}

.testimonial-pic.shadow {
	box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
}

.quote-left,
.quote-right {
	position: relative;
}

.quote-left:before,
.quote-right:after {
	font-family: "FontAwesome";
	position: absolute;
	z-index: 10;
	top: 15px;
}

.quote-left:before {
	content: "\f10d";
	left: 0;
}

.quote-right:after {
	content: "\f10e";
	left: auto;
	right: 0;
}

.testimonial-text {
	padding: 15px;
	position: relative;
}

.testimonial-text p:last-child {
	margin: 0;
}

.testimonial-detail {
	padding: 5px;
}

.testimonial-name,
.testimonial-position {
	display: block;
}

.testimonial-position {
	font-style: italic;
}

.testimonial-text p {
	margin: 0;
}

/*7.1 testimonial with background image*/
.testimonial-bg {
	color: #fff;
}

/*7.2 testimonial 1*/
.testimonial-1,
.testimonial-grid-1 {
	text-align: center;
}

.testimonial-1 .testimonial-position,
.testimonial-grid-1 .testimonial-position {
	color: #ec5598;
}

.testimonial-1 .testimonial-name,
.testimonial-1 .testimonial-position,
.testimonial-grid-1 .testimonial-name,
.testimonial-grid-1 .testimonial-position {
	display: block;
}

.testimonial-1 .quote-left:before,
.testimonial-grid-1 .quote-left:before {
	width: 36px;
	height: 36px;
	line-height: 36px;
	font-size: 16px;
	background-color: #ec5598;
	color: #FFF;
	text-align: center;
	left: 0;
	top: 65px;
	border-radius: 100%;
}

/*7.3 testimonial 2*/
.testimonial-2 .testimonial-text,
.testimonial-grid-2 .testimonial-text {
	background-color: #F4F5F7;
	padding: 25px 30px 25px 50px;
}

.testimonial-2 .testimonial-text:after,
.testimonial-grid-2 .testimonial-text:after {
	content: "";
	position: absolute;
	bottom: -15px;
	left: 50px;
	width: 0;
	height: 0;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-top: 15px solid #F4F5F7;
}

.testimonial-2 .testimonial-text .fa-quote-left,
.testimonial-grid-2 .testimonial-text .fa-quote-left {
	position: absolute;
	left: 15px;
	top: 30px;
	font-size: 24px;
	color: #ec5598;
}

.testimonial-2 .testimonial-detail,
.testimonial-grid-2 .testimonial-detail {
	padding: 25px 0 20px 20px;
	background-color: #ec5598;
}

.testimonial-2 .testimonial-pic,
.testimonial-grid-2 .testimonial-pic {
	float: left;
	margin-right: 15px;
	width: 80px;
	height: 80px;
}

.testimonial-2 .testimonial-name,
.testimonial-2 .testimonial-position,
.testimonial-grid-2 .testimonial-name,
.testimonial-grid-2 .testimonial-position {
	padding: 2px 0;
}

.testimonial-2 .testimonial-name,
.testimonial-grid-2 .testimonial-name {
	padding-top: 20px;
	color: #000;
}

.testimonial-2 .testimonial-position,
.testimonial-grid-2 .testimonial-position {
	color: #fff;
}

.testimonial-2 .quote-left:before,
.testimonial-grid-2 .quote-left:before {
	top: 50px;
}

/*7.4 testimonial 2 with background image*/
.testimonial-2.testimonial-bg .testimonial-text,
.testimonial-grid-2.testimonial-bg .testimonial-text {
	color: #777;
	background-color: rgba(255, 255, 255, 1);
}

.testimonial-2.testimonial-bg .testimonial-text:after,
.testimonial-grid-2.testimonial-bg .testimonial-text:after {
	border-top-color: rgba(255, 255, 255, 1);
}

/*7.5 testimonial 3*/
.testimonial-3,
.testimonial-grid-3 {
	text-align: center;
}

.testimonial-3 .quote-left:before,
.testimonial-grid-3 .quote-left:before {
	position: static;
	font-size: 40px;
	color: #ec5598;
}

.testimonial-3 .testimonial-position,
.testimonial-grid-3 .testimonial-position {
	color: #ec5598;
}

.testimonial-3 .testimonial-name,
.testimonial-3 .testimonial-position,
.testimonial-grid-3 .testimonial-name,
.testimonial-grid-3 .testimonial-position {
	display: inline-block;
}

/*7.6 testimonial 4*/


.testimonial-4 .testimonial-text,
.testimonial-grid-4 .testimonial-text {
	background-color: #4b3941;
	color: #fff;
	padding: 40px 30px;
}

.testimonial-4 .testimonial-pic,
.testimonial-grid-4 .testimonial-pic {
	float: left;
	border: 3px solid #ec5598;
	width: 90px;
	height: 90px;
	position: relative;
	z-index: 2;
}

.testimonial-4 .testimonial-pic:after,
.testimonial-grid-4 .testimonial-pic:after {
	border-right: 3px solid #ec5598;
	height: 150%;
	content: "";
	position: absolute;
	left: 36px;
	top: -21px;
	-webkit-transform: skew(25deg);
	-moz-transform: skew(25deg);
	-o-transform: skew(25deg);
	-ms-transform: skew(25deg);
	transform: skew(25deg);
	z-index: -1;
}

@media only screen and (max-width:400px) {

	.testimonial-4 .testimonial-pic,
	.testimonial-grid-4 .testimonial-pic {
		width: 60px;
		height: 60px;
	}

	.testimonial-4 .testimonial-pic:after,
	.testimonial-grid-4 .testimonial-pic:after {
		height: 140%;
		left: 24px;
		position: absolute;
		top: -11px;
		-webkit-transform: skew(30deg);
		-moz-transform: skew(30deg);
		-o-transform: skew(30deg);
		-ms-transform: skew(30deg);
		transform: skew(30deg);
	}
}

@media only screen and (max-width:400px) {
	.testimonial-grid-4 .testimonial-pic {
		margin-bottom: 30px;
		float: none;
	}

	.testimonial-grid-4 .testimonial-paragraph {
		padding-left: 0px;
		overflow: hidden;
	}
}

.testimonial-4 .testimonial-paragraph {
	overflow: hidden;
	padding-left: 20px;
}

.testimonial-4 .testimonial-detail,
.testimonial-grid-4 .testimonial-detail {
	background-color: #ec5598;
	color: #fff;
	padding: 15px 20px 15px 70px;
	display: inline-block;
	position: relative;
	z-index: 0;
}

@media only screen and (max-width:400px) {

	.testimonial-4 .testimonial-detail,
	.testimonial-grid-4 .testimonial-detail {
		min-width: 220px;
	}

}

.testimonial-4 .testimonial-detail .testimonial-name,
.testimonial-grid-4 .testimonial-detail .testimonial-name,
.testimonial-4 .testimonial-detail .testimonial-position,
.testimonial-grid-4 .testimonial-detail .testimonial-position {
	display: inline-block;
	margin: 0px 5px;
	position: relative;
}

.testimonial-4 .testimonial-detail .testimonial-position,
.testimonial-grid-4 .testimonial-detail .testimonial-position {
	color: #000;
	margin: 0px 10px;
}

@media only screen and (max-width:400px) {

	.testimonial-4 .testimonial-detail .testimonial-name,
	.testimonial-grid-4 .testimonial-detail .testimonial-name,
	.testimonial-4 .testimonial-detail .testimonial-position,
	.testimonial-grid-4 .testimonial-detail .testimonial-position {
		display: block;
		margin: 0;
	}
}

.testimonial-4 .testimonial-detail .testimonial-position:after,
.testimonial-grid-4 .testimonial-detail .testimonial-position:after {
	border: 1px solid #000;
	height: 50%;
	content: "";
	position: absolute;
	left: -10px;
	top: 5px;
	-webkit-transform: skew(-25deg);
	-moz-transform: skew(-25deg);
	-o-transform: skew(-25deg);
	-ms-transform: skew(-25deg);
	transform: skew(-25deg);
}

@media only screen and (max-width:400px) {

	.testimonial-4 .testimonial-detail .testimonial-position:after,
	.testimonial-grid-4 .testimonial-detail .testimonial-position:after {
		display: none;
	}
}

.testimonial-4 .testimonial-detail .fa-quote-left,
.testimonial-grid-4 .testimonial-detail .fa-quote-left {
	background-color: #4b3941;
	color: #ec5598;
	position: absolute;
	left: 0;
	top: 0;
	font-size: 30px;
	width: 55px;
	line-height: 55px;
	height: 100%;
	text-align: center;
}



/*7.6 testimonial 5*/

.testimonial-5,
.testimonial-grid-5 {
	margin-left: 70px;
	position: relative;
}

.testimonial-5 .testimonial-pic-block,
.testimonial-grid-5 .testimonial-pic-block {
	left: -13%;
	position: absolute;
	top: 20%;
}

.testimonial-5 .testimonial-text,
.testimonial-grid-5 .testimonial-text {
	padding: 40px 30px 40px 90px;
}

.testimonial-5 .testimonial-pic,
.testimonial-grid-5 .testimonial-pic {
	float: left;
	width: 130px;
	height: 130px;
	position: relative;
	z-index: 2;
	border: none;
}

.testimonial-5 .testimonial-pic-block.radius-bx .testimonial-pic:after,
.testimonial-grid-5 .testimonial-pic-block.radius-bx .testimonial-pic:after {
	content: "";
	border-radius: 100%;
	position: absolute;
	background-color: #ffbc13;
	width: 110%;
	height: 110%;
	left: -2%;
	top: -2%;
	z-index: -1;
}

.testimonial-5 .testimonial-name,
.testimonial-grid-5 .testimonial-name,
.testimonial-5 .testimonial-position,
.testimonial-grid-5 .testimonial-position {
	display: block;
}

.testimonial-5 .fa-quote-left,
.testimonial-grid-5 .fa-quote-left {
	font-size: 36px;
}

.testimonial-5 .testimonial-paragraph p,
.testimonial-grid-5 .testimonial-paragraph p {
	padding: 5px 0px;
}


.testimonial-5 .testimonial-name,
.testimonial-grid-5 .testimonial-name {
	color: #2a2a2a;
	text-transform: uppercase;
	font-size: 15px;
}

.testimonial-5 .testimonial-position,
.testimonial-grid-5 .testimonial-position {
	font-style: normal;
}

.testimonial-5 .testimonial-detail,
.testimonial-grid-5 .testimonial-detail {
	padding-left: 0px;
	padding-right: 0px;
}

@media only screen and (max-width:480px) {

	.testimonial-5,
	.testimonial-grid-5 {
		margin-left: 0%;
		position: relative;
	}

	.testimonial-5 .testimonial-pic-block,
	.testimonial-grid-5 .testimonial-pic-block {
		left: inherit;
		position: inherit;
		text-align: center;
		top: inherit;
		padding-top: 30px;
	}

	.testimonial-5 .testimonial-text,
	.testimonial-grid-5 .testimonial-text {
		padding: 40px;
	}

	.testimonial-5 .testimonial-pic,
	.testimonial-grid-5 .testimonial-pic {
		float: none;
	}

}


/*-------------------------------------
	8. Pricing table
 -------------------------------------*/

/*8.1 Pricing table*/
.pricingtable-5 [class*="col-"] {
	width: 20%;
}

@media only screen and (max-width: 991px) {
	.pricingtable-5 [class*="col-"] {
		width: 100%;
	}
}

.pricingtable-inner {
	text-align: center;
}

.pricingtable-price {
	padding: 10px;
	background-color: #ec5598;
}

.pricingtable-bx {
	font-size: 44px;
	font-family: Arial;
	color: #666666;
	color: #4b3941;
}

.pricingtable-type {
	font-size: 20px;
	text-transform: uppercase;
	font-size: 24px;
	color: #fff;
}

.pricingtable-type:before {
	content: "/";
	margin-right: 3px;

}

.pricingtable-title {
	background-color: #4b3941;
	padding: 20px;
	text-transform: uppercase;
}

.pricingtable-title * {
	margin: 0;
	color: #fff;
	font-size: 20px;
}

.pricingtable-features {
	margin: 0;
	padding: 0;
	list-style: none;
	border: 1px solid #E9E9E9;
}

.pricingtable-features li {
	padding: 10px;
	border-bottom: 1px solid #E9E9E9;
}

.pricingtable-features li i {
	margin: 0 3px;
}

.pricingtable-features li:nth-child(even) {
	background-color: #F4F7F8;
}

.pricingtable-features li:last-child {
	border-bottom: none;
}

.pricingtable-footer {
	margin-top: -1px;
	padding: 20px;
	background-color: #4b3941;
}

.pricingtable-highlight {
	margin: -20px 0;
	box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
	position: relative;
	z-index: 99;
}

.pricingtable-highlight .pricingtable-price {
	padding: 20px 10px;
}

.pricingtable-highlight .pricingtable-footer {
	padding: 30px 20px;
}

.no-col-gap .pricingtable-wrapper {
	margin-left: -1px;
}

/*8.2 pricing table style 2*/
.pricing-table-style-2 .pricingtable-price {
	position: relative;
	z-index: 0;
	overflow: hidden;
}

.pricing-table-style-2 .pricingtable-price:after {
	position: absolute;
	height: 90%;
	width: 96%;
	content: "";
	top: 0px;
	left: -40px;
	background-color: #4b3941;
	z-index: -1;
}

.pricing-table-style-2 .pricingtable-price .pricingtable-bx {
	color: #fff;
	font-size: 48px;
}

.pricing-table-style-2 .pricingtable-title {
	background-color: #ec5598;
}

.pricing-table-style-2 .pricingtable-title * {
	color: #000;
}

@media only screen and (max-width:1200px) {
	.pricing-table-style-2 .pricingtable-price .pricingtable-bx {
		font-size: 36px;
	}
}

@media only screen and (max-width:991px) {
	.pricing-table-style-2 .pricingtable-price .pricingtable-bx {
		font-size: 20px;
	}

	.pricing-table-style-2 .pricingtable-price .pricingtable-type {
		font-size: 14px;
	}
}

@media only screen and (max-width:766px) {
	.pricing-table-style-2 .pricingtable-price .pricingtable-bx {
		font-size: 70px;
	}

	.pricing-table-style-2 .pricingtable-price .pricingtable-type {
		font-size: 24px;
	}
}

@media only screen and (max-width:420px) {
	.pricing-table-style-2 .pricingtable-price .pricingtable-bx {
		font-size: 40px;
	}

	.pricing-table-style-2 .pricingtable-price .pricingtable-type {
		font-size: 18px;
	}

	.pricingtable-bx {
		font-size: 40px;
	}

	.pricingtable-type {
		font-size: 18px;
	}
}

/*8.2 pricing table style 3*/

.pricing-table-style-3 .pricingtable-inner {
	position: relative;
	z-index: 1;
	overflow: hidden;
}

.pricing-table-style-3 .pricingtable-inner.radius {
	border-radius: 40px 40px 40px 40px;
}

.pricing-table-style-3 .pricingtable-inner.pricingtable-highlight {
	z-index: 2;

}

.pricing-table-style-3 .pricingtable-type {
	color: #4b3941;
}

.pricing-table-style-3 .pricingtable-title {
	background-color: inherit;
	padding: 10px 0px 10px 0px;
	width: 190px;
	margin: 0px auto;
}

.pricing-table-style-3 .pricingtable-title * {
	color: #4b3941;
	font-size: 18px;
	font-weight: 600;
}

.pricing-table-style-3 .pricingtable-price {
	background-color: inherit;
}

.pricing-table-style-3 .pricingtable-features li:nth-child(2n) {
	background-color: inherit;
}

.pricing-table-style-3 .pricingtable-footer {
	background-color: inherit;
}

.pricing-table-style-3 .pricingtable-features {
	border: none;
}

.pricing-table-style-3 .pricingtable-features li {
	border-bottom: 0px;
	font-weight: 600;
	font-size: 18px;
}

.pricing-table-style-3 .overlay-main {
	z-index: -1;
}

/*8.2 pricing table style 4*/


.pricing-table-style-4 .pricingtable-inner {
	position: relative;
	overflow: hidden;
	border-bottom: 4px solid #EC5598;
}

.pricing-table-style-4 .pricingtable-inner.radius {
	border-radius: 40px 40px 40px 40px;
}

.pricing-table-style-4 .pricingtable-type {
	color: #fff;
}

.pricing-table-style-4 .pricingtable-inner-overlay {
	position: relative;
	z-index: 1;
}

.pricing-table-style-4 .pricingtable-inner-overlay .overlay-main {
	z-index: -1;
}

.pricing-table-style-4 .pricingtable-title {
	background-color: inherit;
	color: #fff;
	padding: 60px 0px 0px 0px;
}

.pricing-table-style-4 .pricingtable-title * {
	color: #fff;
}

.pricing-table-style-4 .pricingtable-bx {
	color: #fff;
}

.pricing-table-style-4 .pricingtable-price {
	padding: 20px 10px;
}

.pricing-table-style-4 .pricingtable-highlight .pricingtable-price {
	padding: 30px 10px;
}

.pricing-table-style-4 .pricingtable-price {
	background-color: inherit;
}

.pricing-table-style-4 .pricingtable-features li:nth-child(2n) {
	background-color: inherit;
}

.pricing-table-style-4 .pricingtable-footer {
	background-color: inherit;
}

.pricing-table-style-4 .pricingtable-features {
	border: none;
	padding-top: 20px;
}

.pricing-table-style-4 .pricingtable-features li {
	border-bottom: 0px;
}


/*-------------------------------------
	9. Alert box 
-------------------------------------*/
.alert.alert-sm {
	padding: 5px 15px;
	font-size: 12px;
}

.alert.alert-lg {
	padding: 25px 15px;
	font-size: 16px;
}

.alert.alert-xl {
	padding: 35px 15px;
	font-size: 18px;
}

.alert[class*="alert-"] i {
	margin-right: 8px;
}

.alert.no-radius {
	border-radius: 0;
}

.alert.no-bg {
	background-color: transparent;
	border-width: 2px;
}

.alert[class*="alert-"] ul {
	padding-left: 25px;
	margin-top: 10px;
}

.alert[class*="alert-"] ul li:before {
	color: #a94442;
}

/*-------------------------------------
	10. Image effects 
-------------------------------------*/
.wt-img-effect {
	position: relative;
	overflow: hidden;
	display: block;
}

.wt-img-effect img {
	display: block;
	margin: 0;
	width: 100%;
	height: auto;
	box-shadow: 0 0 0 rgba(0, 0, 0, 0);
	transition: all 0.25s;
	-moz-transition: all 0.25s;
	-webkit-transition: all 0.25s;
	-o-transition: all 0.25s;
}

/* 10.1 image-opacity */
.wt-img-effect.opacity img:hover {
	opacity: 0.8;
}

/*10.2 image-zoom */
.wt-img-effect.zoom-slow img {
	transition: all 10s;
	-moz-transition: all 10s;
	-webkit-transition: all 10s;
	-o-transition: all 10s;
}

.wt-img-effect.zoom-slow:hover img {
	-moz-transform: scale(2);
	-webkit-transform: scale(2);
	-o-transform: scale(2);
	-ms-transform: scale(2);
	transform: scale(2);
}

/*10.3 image-zoom-slow */
.wt-img-effect.zoom:hover img {
	-moz-transform: scale(1.5);
	-webkit-transform: scale(1.5);
	-o-transform: scale(1.5);
	-ms-transform: scale(1.5);
	transform: scale(1.5);
}

@media only screen and (max-width:420px) {
	.wt-img-effect.zoom:hover img {
		transform: none;
	}
}

/*10.4 image-shrink */
.wt-img-effect.shrink:hover img {
	transform: scale(0.8);
	-ms-transform: scale(0.8);
	-webkit-transform: scale(0.8);
	-o-transform: scale(0.8);
	-moz-transform: scale(0.8);
}

/*10.5 image-side-pan */
.wt-img-effect.side-pan:hover img {
	margin-left: -9%;
	transform: scale(1.2);
	-ms-transform: scale(1.2);
	-webkit-transform: scale(1.2);
	-o-transform: scale(1.2);
	-moz-transform: scale(1.2);
}

/*10.6 image-vertical-pan */
.wt-img-effect.vertical-pan:hover img {
	margin-top: -10%;
	transform: scale(1.2);
	-ms-transform: scale(1.2);
	-webkit-transform: scale(1.2);
	-o-transform: scale(1.2);
	-moz-transform: scale(1.2);
}

/*10.7 image-fade-in */
.wt-img-effect.fade-in {
	background: #000
}

.wt-img-effect.fade-in img {
	opacity: 0.65;
}

.wt-img-effect.fade-in:hover img {
	opacity: 1;
}

/*10.8 image-fade-out */
.wt-img-effect.fade-out {
	background: #000
}

.wt-img-effect.fade-out:hover img {
	opacity: 0.7;
}

/*10.9 image-rotate */
.wt-img-effect.rotate:hover img {
	-moz-transform: scale(1.5) rotate(-20deg);
	-webkit-transform: scale(1.5) rotate(-20deg);
	-o-transform: scale(1.5) rotate(-20deg);
	-ms-transform: scale(1.5) rotate(-20deg);
	transform: scale(1.5) rotate(-20deg);
}

/*10.10 image-sepia */
.wt-img-effect.sepia img {
	-webkit-filter: sepia(100%);
	filter: sepia(100%);
}

.algo-image-hover.sepia:hover img {
	-webkit-filter: sepia(0);
	filter: sepia(0);
}

/*10.11 image-blurr */
.wt-img-effect.blurr img {
	transition: all 0.2s;
	-moz-transition: all 0.2s;
	-webkit-transition: all 0.2s;
	-o-transition: all 0.2s;
}

.wt-img-effect.blurr:hover img {
	filter: blur(3px);
}

/*10.12 image-blurr-invert */
.wt-img-effect.blurr-invert img {
	transition: all 0.2s;
	-moz-transition: all 0.2s;
	-webkit-transition: all 0.2s;
	-o-transition: all 0.2s;
}

.wt-img-effect.blurr-invert img {
	filter: blur(3px);
}

.wt-img-effect.blurr-invert:hover img {
	filter: blur(0px);
}

/*10.13 image off color */
.wt-img-effect.off-color img {
	filter: grayscale(0);
}

.wt-img-effect.off-color:hover img {
	filter: grayscale(1);
}

/*10.14 image on color */
.wt-img-effect.on-color img {
	filter: grayscale(1);
}

.wt-img-effect.on-color:hover img {
	filter: grayscale(0);
}

/*-------------------------------------
	11. Modal pop 
-------------------------------------*/
.modal-xlg {
	width: 1000px;
}

.provider_registration .bootstrap-select div.dropdown-menu ul li a {
	font-weight: 600;
}

.provider_registration .bootstrap-select div.dropdown-menu ul li a span.childcat {
	font-weight: 400;
	color: #7c7c7c;
}


@media only screen and (max-width: 1024px) {

	.modal-xlg {
		width: auto;
		margin-left: 15px;
		margin-right: 15px;
	}

}

/*-------------------------------------
	12. Social icons 
-------------------------------------*/
.social-icons {
	list-style: none;
	margin-left: -3px;
	margin-right: -3px;
	font-size: 12px;
}

.social-icons li {
	display: inline-block;
	text-align: center;
}

.social-icons li a {
	display: block;
	padding: 3px 5px;
}

.social-icons.social-md {
	font-size: 18px;
}

.social-icons.social-lg li a {
	font-size: 24px;
}

.social-icons.has-bg li a:hover {
	color: #fff;
}

.social-icons.social-light a {
	color: #fff;
}

.social-icons.social-light.has-bg li a:hover {
	color: #ec5598;
}

.social-icons.social-dark a {
	color: #777;
}

.social-icons.social-dark.has-bg li a:hover {
	color: #ec5598;
}

.social-icons.social-square a,
.social-icons.social-radius a {
	border: 1px solid rgba(0, 0, 0, 0.2);
	width: 24px;
	height: 24px;
	line-height: 24px;
	padding: 0;
}

.social-icons.social-square.social-md a,
.social-icons.social-radius.social-md a {
	width: 36px;
	height: 36px;
	line-height: 36px;
	padding: 0;
}

.social-icons.social-square.social-lg a,
.social-icons.social-radius.social-lg a {
	width: 40px;
	height: 40px;
	line-height: 40px;
	padding: 0;
}

.social-icons.social-square.social-primary a,
.social-icons.social-radius.social-primary a {
	background-color: #ec5598;
	color: #FFF;
	border: 1px solid rgba(0, 0, 0, 0.1);
}

.social-icons.social-square.social-primary a:hover,
.social-icons.social-radius.social-primary a:hover {
	background-color: #4b3941;
}

.social-icons.social-square.social-primary.has-bg a:hover,
.social-icons.social-radius.social-primary.has-bg a:hover {
	border: 1px solid rgba(255, 255, 255, 1);
}

.social-icons.social-square.social-dark a,
.social-icons.social-radius.social-dark a {
	background-color: #fff;
}

.social-icons.social-square.social-dark a:hover,
.social-icons.social-radius.social-dark a:hover {
	background-color: #4b3941;
	color: #fff;
}

.social-icons.social-square.social-dark.has-bg a:hover,
.social-icons.social-radius.social-dark.has-bg a:hover {
	border: 1px solid #ec5598;
}

.social-icons.social-square.social-darkest a,
.social-icons.social-radius.social-darkest a {
	border: 1px solid #4a4c4c;
}

.social-icons.social-darkest a {
	color: #454747;
}

.social-icons.social-square.social-darkest a:hover,
.social-icons.social-radius.social-darkest a:hover {
	background-color: #ec5598;
	color: #4a4c4c;
}

.social-icons.social-radius a {
	border-radius: 100px;
}

.social-icons.social-square.social-dark.white-border a,
.social-icons.social-radius.social-dark.white-border a {
	background: none;
	color: #fff;
	border: 1px solid rgba(255, 255, 255, 1);
}

/*12.1 social icon default */
.wt-social-icon {
	display: inline-block;
	margin: 0 -3px;
	padding: 0;
}

.wt-social-icon li {
	display: inline-block;
	padding: 0;
	font-size: 12px;
}

.wt-social-icon li a {
	display: inline-block;
	width: 24px;
	height: 24px;
	padding: 4px;
}

.wt-social-icon li .fa {
	vertical-align: middle;
}

/*12.2 social icon with border */
.wt-social-icon.border li {
	padding: 0 3px;
}

.wt-social-icon.border li a {
	border: 1px solid #efeded;
	border-radius: 2px;
}

/*12.3 social icon dark */
.wt-social-links.dark li {
	padding: 0 3px;
}

.wt-social-icon.dark li a {
	border: 1px solid #777777;
	color: #777777;
	border-radius: 2px;
}

/*12.4 social share icon style 1 */
.wt-share-icon li {
	border: none;
	width: 36px;
	display: block;
	float: left;
	white-space: nowrap;
	overflow: hidden;
	margin-right: 0;
}

.wt-share-icon li a {
	color: #FFF;
}

.wt-share-icon li i {
	border-right: none;
	font-size: 14px;
	color: #FFF;
	width: 36px;
	height: 34px;
	line-height: 34px;
	padding: 0;
	text-align: center;
}

.wt-share-icon li.fb {
	background: #354d89;
}

.wt-share-icon li.fb i {
	background: #3a5799;
}

.wt-share-icon li.gp {
	background: #d34b2b;
}

.wt-share-icon li.gp i {
	background: #e35736;
}

.wt-share-icon li.tw {
	background: #029fdf;
}

.wt-share-icon li.tw i {
	background: #00abf0;
}

.wt-share-icon li.dig {
	background: #1d61aa;
}

.wt-share-icon li.dig i {
	background: #2B6FB8;
}

.wt-share-icon li.lin {
	background: #0176be;
}

.wt-share-icon li.lin i {
	background: #0082CA;
}

.wt-share-icon li.pin {
	background: #ac0104;
}

.wt-share-icon li.pin i {
	background: #BD0E15;
}

.wt-share-icon li:hover {
	width: 90px;
}

/*-------------------------------------
	13. Breadcrumb 
-------------------------------------*/

/*13.1 breadcrumb-row-1*/

.wt-breadcrumb {
	margin: 0;
	list-style: none;

}

.wt-breadcrumb li {
	padding: 0;
	margin-right: 3px;
	color: #333333;
	position: relative;
	display: inline-block;
}

.wt-breadcrumb li a {
	color: #ec5598;
}

.wt-breadcrumb li:last-child {
	color: #676767;
}

.wt-breadcrumb li:last-child:after {
	display: none;
}


/*13.2 Breadcrumb-1*/
.breadcrumb-style-1 li:after {
	content: "/";
	margin-left: 7px;
}

/*13.3 Breadcrumb-2*/
.breadcrumb-style-2 li:after {
	content: "\f101";
	margin-left: 7px;
	font-family: 'FontAwesome';
}

/*13.4 breadcrumb-3*/
.breadcrumb-style-3 li {
	padding: 20px 15px 20px 20px;
}

.breadcrumb-style-3 li:after,
.breadcrumb-style-3 li:before {
	content: "";
	position: absolute;
	height: 50%;
	width: 1px;
	background-color: #e8e9e9;
	border-right: 1px solid #ccc;
	right: 0px;
}

.breadcrumb-style-3 li:after {
	top: 0px;
	-webkit-transform: skew(30deg);
	-moz-transform: skew(30deg);
	-o-transform: skew(30deg);
	-ms-transform: skew(30deg);
	transform: skew(30deg);
}

.breadcrumb-style-3 li:before {
	bottom: 0px;
	-webkit-transform: skew(-30deg);
	-moz-transform: skew(-30deg);
	-o-transform: skew(-30deg);
	-ms-transform: skew(-30deg);
	transform: skew(-30deg);

}

.breadcrumb-style-3 li:last-child:before {
	display: none;
}




/*-------------------------------------
	14. Google map 
-------------------------------------*/
.google-map {
	width: 100%;
}

.google-map iframe {
	width: 100%;
	height: 300px;
}

.google-map-gray iframe {
	width: 100%;
	border: 0px;
	filter: grayscale(100%);
}


/*-------------------------------------
	15. Progress Bar 
-------------------------------------*/
.progress .tooltip {
	position: relative;
	float: right;
	margin-top: 5px;
}

.progress .tooltip>.tooltip-inner {
	background-color: #212121;
	padding: 3px 10px;
	color: #fff;
	font-weight: bold;
	font-size: 12px;
	border-radius: 0;
}

.progress .popOver+.tooltip>.tooltip-arrow {
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid #212121;
}

.progress {
	border-radius: 0;
	overflow: visible;
}

.progress-bar {
	transition: width 1.5s ease-in-out;
}

.wt-probar-1 {
	height: 4px;
}

.with-overlay.progress .tooltip>.tooltip-inner {
	background-color: #fff;
	color: #000;
}

.with-overlay.progress .popOver+.tooltip>.tooltip-arrow {
	border-top: 5px solid #fff;
}

/*-------------------------------------
	16. Countdown clock 
-------------------------------------*/
.count-row {
	background: #333;
}

#countdown-clock {
	display: table;
	text-align: center;
	width: 80%;
	margin-left: -15px;
	border: 1px solid #3f3f3f;
	margin: 17px 0;
}

#countdown-clock span {
	display: table-cell;
	width: 1%;
	color: #FFF;
	padding: 5px 15px;
	text-transform: uppercase;
	font-weight: 600;
	color: #999;
	border-right: 1px solid #3f3f3f;
}

#countdown-clock span:last-child {
	border-right: none;
}

#countdown-clock span b {
	display: block;
	font-size: 25px;
	font-weight: 900;
	color: #FFF;
}

@media only screen and (max-width:991px) {
	#countdown-clock {
		width: 100%;
	}
}

/*-------------------------------------
	17. Form elements 
-------------------------------------*/
label {
	font-weight: 600;
	margin-bottom: 10px;
}

.panel-default,
.panel-default>.panel-heading {
	border-color: #e7ecf1;
	color: inherit;
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
	border-top-color: #e7ecf1;
}

.form-group {
	margin-bottom: 25px;
}

.form-control {
	border-color: #e1e6eb;
	box-shadow: none;
	height: 40px;
	font-size: 13px;
	line-height: 20px;
	padding: 9px 12px;
}

.form-control:focus {
	border-color: #e1e1e1;
	outline: 0;

}

.form-control:focus,
.has-error .form-control:focus {
	box-shadow: none;
}

.form-control.kv-fileinput-caption {
	height: 39px;
}

.form-group.form-inline .radio,
.form-group.form-inline .checkbox {
	margin-right: 15px;
}

/* 17.1 input group */
.input-group {
	width: 100%;
}

.input-group .form-control,
.input-group-addon,
.input-group-btn {
	display: table-cell;
}

.input-group-addon {
	background: #fff;
	border-color: #e1e1e1;
	padding: 6px 14px;
	font-size: 16px;
}

.input-group-addon.font-size-20 {
	font-size: 20px;
}

.input-group-addon.fixed-w {
	text-align: center;
	padding: 6px 0;
	width: 40px;
}

.input-group-addon.v-align-t {
	line-height: 30px;
}

.input-group-addon {
	background: #fff;
	border-color: #e1e1e1;
	padding: 6px 14px;
	font-size: 16px;
	color: #545454;
	border-width: 1px;
	border-style: solid;
}

/* 17.2 select box */
.dropdown-menu {
	font-size: 13px;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
	width: 100%;
}

.bootstrap-select .dropdown-toggle {
	border: 1px solid #e7ecf1 !important;
	background-color: #fff !important;
	height: 40px;
	font-size: 13px;
	color: #999;
}

.bootstrap-select .dropdown-toggle:after {
	display: none;
}

.bootstrap-select .dropdown-toggle:active,
.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select .dropdown-toggle:hover {
	background-color: #fff !important;
	border: 1px solid #e9e9e9 !important;
	box-shadow: none !important;
	outline: 0px !important;
}

.bootstrap-select:active,
.bootstrap-select:focus {
	border: 0px !important;
	box-shadow: none !important;
	outline: 0px !important;
}

.bootstrap-select.open {
	box-shadow: none !important;
	outline: 0px !important;
}

.bootstrap-select.open .dropdown-toggle {
	box-shadow: none !important;
	outline: 0px !important;
}

.bootstrap-select div.dropdown-menu {
	border: 1px solid #e9e9e9;
	border-radius: 0;
	box-shadow: none;
	margin-top: -1px;
	padding: 0;
	font-size: 13px;
}

.bootstrap-select div.dropdown-menu ul li {
	padding: 0;
}

.bootstrap-select div.dropdown-menu ul li a {
	padding: 5px 15px;
}

.bootstrap-select div.dropdown-menu ul li a img {
	border-radius: 100%;
	margin-right: 5px;
}

.bootstrap-select .bs-searchbox .form-control {
	padding: 5px 10px;
	height: 30px;
}

/* 17.3 radio & checkbox */
input[type=checkbox],
input[type=radio] {
	opacity: 0;
	margin-left: 1px !important;
}

input[type=checkbox]+label,
input[type=radio]+label {
	display: block;
	padding-left: 20px !important;
	position: relative;
}

input[type=checkbox]+label:hover:before,
input[type=radio]+label:hover:before {
	border-color: #3396d1;
}

input[type=checkbox]+label:before,
input[type=radio]+label:before {
	background-color: #fff;
	border: 2px solid #b6b7b8;
	border-radius: 0px;
	content: "";
	display: inline-block;
	height: 16px;
	line-height: 1;
	margin-left: -20px;
	position: absolute;
	top: 2px;
	transition: all linear 0.1s;
	width: 16px;
}

input[type=checkbox]+label:after {
	color: #fff;
	content: "\f00c";
	font-family: FontAwesome;
	font-size: 0px;
	left: 6px;
	position: absolute;
	top: 7px;
	transition: all linear 0.1s;
}

input[type=checkbox]:checked+label:before {
	border-width: 7px;
	border-color: #3396d1;
}

input[type=checkbox]:checked+label:after {
	font-size: 10px;
	left: 2px;
	top: 2px;
}

input[type=radio]+label:before {
	border-radius: 50%;
	content: "";
}

input[type=radio]:checked+label:before {
	border-color: #3396d1;
	border-width: 5px;
}

.form-inline .checkbox input[type="checkbox"],
.form-inline .radio input[type="radio"] {
	position: absolute;
	left: 0;
	bottom: 0;
}

/*17.4 Browse Button css */
.btn-file {
	overflow: hidden;
	position: relative;
}

.btn-file input[type="file"] {
	background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
	cursor: inherit;
	display: block;
	min-height: 100%;
	min-width: 100%;
	opacity: 0;
	position: absolute;
	right: 0;
	text-align: right;
	top: 0;
}

/*17.5 touchspin input type number */
.bootstrap-touchspin .input-group-btn-vertical {
	position: relative;
	white-space: nowrap;
	vertical-align: middle;
	display: table-cell;
}

.bootstrap-touchspin .input-group-btn-vertical>.btn {
	display: block;
	float: none;
	width: 100%;
	max-width: 100%;
	padding: 0px 10px 0px;
	margin-left: -1px;
	position: relative;
	background: #151414;
	height: 21px;
	line-height: 20px;
	border: 1px solid #ddd;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
	border-radius: 0;
	border-top-right-radius: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
	margin-top: -2px;
	border-radius: 0;
	border-bottom-right-radius: 0;
}

.bootstrap-touchspin .input-group-btn-vertical i {
	position: absolute;
	top: 4px;
	left: 5px;
	font-size: 9px;
	font-weight: normal;
	color: #9fa0a1;
}

/*17.6 rating star */
.input-rating input[type=radio] {
	display: none;
}

.input-rating input[type=radio]+label {
	display: inline-block;
	margin-left: -4px;
	padding-left: 0px !important;
	padding-right: 0px;
	width: 24px;
}

.input-rating input[type=radio]+label:first-of-type {
	margin-left: 0px;
}

.input-rating input[type=radio]+label:before {
	background-color: transparent;
	border: none;
	color: #ffd925;
	content: "\f006";
	display: inline-block;
	font-family: FontAwesome;
	font-size: 20px;
	height: 14px;
	line-height: 1;
	margin-left: 0px;
	position: static;
	text-align: center;
	top: 2px;
	transition: none;
	width: 14px;
}

.input-rating input[type=radio]:checked+label:before,
.input-rating input[type=radio]+label.marked:before,
.input-rating input[type=radio]+label.filled:before,
.input-rating input[type=radio]+label.hovered.filled:before {
	content: "\f005";
}

.input-rating input[type=radio]+label.hovered:before {
	content: "\f006";
}

/*17.7 simple rating list */
.rating-bx {
	color: #ffd925;
	font-size: 14px;
}

.rating-bx i {
	margin: 0 2px;
}

.rating-container .rating-stars::before {
	text-shadow: none;
}

.rating-container {
	color: #c8c8c8;
}

.rating-container .rating-stars {
	color: #3396d1;
}

/*17.8 validation symbol */
.has-feedback label~.form-control-feedback {
	top: 31px;
}

/*17.9 error for select box */
.has-error .bootstrap-select .dropdown-toggle,
.has-error .bootstrap-select .dropdown-toggle:hover,
.has-error .bootstrap-select .dropdown-toggle:focus {
	border-color: #a94442 !important;
}

/*17.10 succes for select box */
.has-success .bootstrap-select .dropdown-toggle,
.has-success .bootstrap-select .dropdown-toggle:hover,
.has-success .bootstrap-select .dropdown-toggle:focus {
	border-color: #3c763d !important;
}

/*17.11 textarea */

/*17.11(1) resize-vertical*/
.resize-vertical {
	resize: vertical;
}

/*17.11(2) resize-horizontal*/
.resize-horizontal {
	resize: horizontal
}

/*17.11(3) resize-none*/
.resize-none {
	resize: none
}

/*-------------------------------------
	18. Loading 
-------------------------------------*/
/*18.1 loading - 1*/

.loading-area {
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 9999;
	overflow: hidden;
}

.loading-box {
	width: 100%;
	height: 100%;
	background-color: #FFF;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 1;
	z-index: 9999;
}

.loading-pic {
	width: 100%;
	position: absolute;
	top: 50%;
	z-index: 99999;
	text-align: center;
	transform: translateY(-50%);
}

/*18.2 loading - 2*/
.loading-cover,
.loading-srh-bar,
.loading-map {
	background: rgba(255, 255, 255, 0.8);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
}

.loading-cover .fa-spinner,
.loading-srh-bar .fa-spinner,
.loading-map .fa-spinner {
	position: absolute;
	left: 50%;
	top: 50%;
	margin: -10px;
	font-size: 25px;
	color: #333;
}

.wt-banner-outer {
	margin-bottom: 0;
}

.wt-success-top,
.wt-error-top {
	margin-bottom: 0;
}

.alert-bx.alert-info {
	padding: 15px;
	border: 1px solid #bce8f1;
}

/*-------------------------------------
	19. Widgets 
-------------------------------------*/

.widget {
	margin-bottom: 40px;
}

.widget-title {
	margin: 0 0 25px;
}

.widget-title,
.widget-title-two,
.widget-title-three {
	padding-bottom: 15px;
	position: relative;
	margin-bottom: 30px;
}

.widget-title-two:after,
.widget-title-two:before,
.widget-title-three:after,
.widget-title-three:before {
	content: "";
	position: absolute;
	margin: 10px 0;
}

.widget-title:after {
	content: "";
	position: absolute;
	bottom: -10px;
	left: 30px;
	height: 3px;
	margin: 0 0 0;
	width: 70px;
	margin: 10px 0;
	background-color: #ec5598;
}

.widget-title:before {
	content: "\f06c";
	font-size: 18px;
	font-family: 'FontAwesome';
	position: absolute;
	bottom: -8px;
	left: 0;
	display: inline-block;
	width: 10px;
	height: 10px;
	margin-bottom: 10px;
}


.widget .widget-title,
.widget .widget-title-two,
.widget .post-title {
	text-transform: none;
}

.recent-posts-entry ul,
.category-entry ul {
	margin: 0;
	list-style: none;
	padding: 0;
}

/* 19.1 widget listing*/
.widget_categories ul,
.widget_archive ul,
.widget_meta ul,
.widget_pages ul,
.widget_recent_comments ul,
.widget_nav_menu ul,
.widget_rss ul,
.widget_recent_entries ul,
.widget_getintuch ul,
.widget_services ul {
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
}

.widget_categories ul li,
.widget_archive ul li,
.widget_meta ul li,
.widget_pages ul li,
.widget_recent_comments ul li,
.widget_nav_menu li,
.widget_recent_entries ul li,
.widget_services ul li {
	padding-bottom: 18px;
	margin-bottom: 13px;
	border-bottom: 1px solid #e1e1e1;
	position: relative;
	padding: 10px 10px 10px 15px;
	margin-bottom: 0;
	line-height: 20px;
}

.widget_archive ul li span.badge {
	padding: 4px 7px;
	background-color: #ffbc13;
	margin: 0px 5px;
}

.widget_categories ul li a,
.widget_archive ul li a,
.widget_meta ul li a,
.widget_pages ul li a,
.widget_recent_comments ul li a,
.widget_nav_menu li a,
.widget_recent_entries ul li a,
.widget_services ul li a {
	color: #767676;
}

.widget_categories ul li a:hover,
.widget_archive ul li a:hover,
.widget_meta ul li a:hover,
.widget_pages ul li a:hover,
.widget_recent_comments ul li a:hover,
.widget_nav_menu li a:hover,
.widget_recent_entries ul li a:hover,
.widget_services ul li a:hover {
	color: #ec5598;
}


.widget_categories ul li:before,
.widget_archive ul li:before,
.widget_meta ul li:before,
.widget_pages ul li:before,
.widget_recent_comments ul li:before,
.widget_nav_menu ul li:before,
.widget_useful_links ul li:before,
.widget_recent_entries ul li:before,
.widget_services ul li:before {
	content: "\f105";
	position: absolute;
	left: 0;
	top: 10px;
	display: block;
	font-family: "FontAwesome";
}

.widget_categories ul li li,
.widget_archive ul li li,
.widget_meta ul li li,
.widget_pages ul li li,
.widget_recent_comments ul li li,
.widget_nav_menu li li,
.widget_services li li {
	border-bottom: none;
	padding-left: 10px;
	padding-right: 5px;
	padding-top: 0;
	padding-bottom: 0;
	line-height: 28px;
}

.widget_categories ul li li:before,
.widget_archive ul li li:before,
.widget_meta ul li li:before,
.widget_pages ul li li:before,
.widget_recent_comments ul li li:before,
.widget_nav_menu li li:before,
.widget_services li li:before {
	top: 0;
	left: -8px;
}

/*19.2 widget search custom*/
.search-bx .btn {
	border-left-color: #FFF;
	padding: 9px 10px;
	color: #3396d1;
}

/*19.3 widget search wp-default*/
.widget_search .screen-reader-text {
	display: block;
}

.searchform {
	position: relative;
}

.searchform input[type="text"] {
	width: 100%;
	height: 40px;
	padding: 10px 90px 10px 15px;
	border: 1px solid #CCCCCC;
}

.searchform input[type="submit"] {
	height: 40px;
	padding: 10px 15px;
	background-color: #ec5598;
	position: absolute;
	right: 0;
	bottom: 0;
	color: #ffffff;
	border-left: none;
	border-right: none;
	border-top: none;
	border-bottom: none;
}

.searchform input[type="submit"]:hover,
.searchform input[type="submit"]:focus,
.searchform input[type="submit"]:active {
	background-color: #6ab33e;
	color: #ffffff !important;
	border-bottom-color: #5a9e2f;
}

/*19.4 widget recent-posts*/
.recent-posts-entry .post-date,
.tweets-feed-entry .tweet-date {
	color: #3396d1;
	font-style: normal;
}

.widget .post-title {
	font-size: 13px;
	line-height: 16px;
	margin-bottom: 8px;
}

.recent-posts-entry .widget-post {
	margin-bottom: 5px;
	padding: 5px;
}

.recent-posts-entry .widget-post-bx:last-child {
	border-bottom: none;
}

.recent-posts-entry .wt-post-media {
	float: left;
	width: 65px;
}

.recent-posts-entry .wt-post-info {
	background: transparent;
	padding: 0;
	margin-left: 80px;
	border: none;
}

.recent-posts-entry .post-meta span {
	margin-right: 10px;
}

/*19.5 widget recent-comment*/
.widget_recent_comments ul li:before {
	content: "\f0e6";
}

.widget_recent_comments ul li {
	padding-left: 20px;
	color: #999;
}

/*19.6 widget meta*/
.widget_meta ul li a abbr[title] {
	color: #333;
	border-bottom: none;
}

/*19.7 widget calender*/
.widget_calendar caption::after {
	color: #707070;
	content: ">";
	font-family: "FontAwesome";
	margin: 0 0 0 5px;
}

.widget_calendar table {
	border-collapse: separate;
	border-spacing: 2px;
	width: 100%;
}

.widget_calendar caption {
	background-color: #E0E0E0;
	padding: 8px 10px;
	color: #1a1a1a;
	text-transform: uppercase;
	font-weight: 600;
}

.widget_calendar thead {
	background-color: #E63F75;
}

.widget_calendar tfoot tr td {
	border: none;
	padding: 0px
}

.widget_calendar tfoot tr td a {
	background-color: #fff;
	padding: 4px 10px
}

.widget_calendar table thead tr th {
	font-size: 11px;
	padding: 5px;
	text-align: center;
	border: none;
	color: #fff;
}

.widget_calendar table tbody td {
	font-size: 13px;
	padding: 6px 5px;
	text-align: center;
	background-color: #f9f9f9;
	border: none;
	color: #444;

}

.widget_calendar table tbody td#today {
	background-color: #FFBC13;
	color: #FFF;
}

.widget_calendar table tbody td#today a {
	color: #FFF;
}

/*19.8 widget tags-list*/
.widget_tag_cloud a {
	padding: 12px 14px;
	background-color: #f6f7f8;
	font-size: 14px;
	display: inline-block;
	margin: 0 0 5px;
	color: #333;
	border-radius: 20px;
}

.side-bar .widget_tag_cloud a:hover {
	background-color: #CCCCCC;
}

/*19.9 widget archive*/
.widget_archive select {
	width: 100%;
	padding: 5px;
	border: 1px solid #CCC;
}

/*19.10 widget text*/
.widget_text select {
	width: 100%;
	padding: 5px;
	border: 1px solid #CCC;
}

.widget_text select option {
	width: 100%;
}

/*19.11 widget categories*/
.widget_categories li {
	text-align: right;
}

.widget_categories li a {
	float: left;
}

.widget_categories li span.badge {
	padding: 4px 7px;
	background-color: #ffbc13;
}

.widget_categories .dropdown-menu li:before {
	left: 5px;
	top: 5px;
}

.widget_categories li a:hover {
	color: #ec5598;
}

/*19.12 widget RSS*/
.widget_rss ul {
	margin: 0;
	line-height: 20px;
}

.widget_rss ul li {
	line-height: 20px;
	margin-bottom: 15px;
}

.widget_rss ul .rsswidget {
	color: #333;
}

.widget_rss ul .rss-date {
	color: #999999;
	font-style: italic;
}

.widget_rss ul .rssSummary {
	padding: 5px 0;
}

.widget_rss ul cite {
	color: #333;
	font-weight: 600;
}

/* 19.13 widget get in tuch */
.widget_getintuch {
	padding-top: 10px;
}

.widget_getintuch li {
	margin-bottom: 20px;
	position: relative;
	padding-left: 40px;
}

.widget_getintuch b,
.widget_getintuch strong {
	display: block;
	text-transform: uppercase;
}

.widget_getintuch i {
	color: #fff;
	position: absolute;
	left: 0;
	top: 5px;
	text-align: center;
	font-size: 14px;
	width: 30px;
	height: 30px;
	line-height: 30px;
	background-color: #EC5598;
}

/* 19.14 widget gallery */
.widget_gallery ul {
	padding-left: 0;
	display: flex;
	flex-wrap: wrap;
}

.widget_gallery li {
	display: inline-block;
	margin-bottom: 0px;
	width: 25%;
	background-color: #000;
}

.widget_gallery li a {
	position: relative;
}

.widget_gallery li a:after {
	content: "\f00e ";
	font-family: FontAwesome;
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: 16px;
	color: #fff;
	margin: -10px;
	opacity: 0;
}

.widget_gallery li:hover img {
	margin-bottom: 0;
	opacity: 0.6;
}

.widget_gallery li:hover a:after {
	opacity: 1;
}

.widget_gallery a {
	display: block;
}

@media only screen and (max-width:991px) {
	.widget_gallery li {
		width: 25%;
	}
}

@media only screen and (max-width:480px) {
	.widget_gallery li {
		width: 33%;
	}
}

/* 19.15 widget Categories Dropdown */
.widget_categories .dropdown-menu ul li::before {
	display: none;
}

.widget_categories .dropdown-menu ul li {
	border: none;
	text-align: left;
}

.widget_categories .dropdown-menu ul li a {
	float: none;
}

/* 19.16 widget Form */
.widget-quick-form .form-group {
	margin-bottom: 10px;
}

.widget-quick-form input,
.widget-quick-form button,
.widget-quick-form textarea {
	border-radius: 20px;
	padding: 10px 20px;
	resize: none;
	border: none;
}

.widget-quick-form input,
.widget-quick-form textarea {
	background-color: #f6f7f8;
}

.widget-quick-form .form-control::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #1a1a1a;
	font-weight: bold;
}

.widget-quick-form .form-control::-moz-placeholder {
	/* Firefox 19+ */
	color: #1a1a1a;
	font-weight: bold;
}

.widget-quick-form .form-control:-ms-input-placeholder {
	/* IE 10+ */
	color: #1a1a1a;
	font-weight: bold;
}

.widget-quick-form .form-control:-moz-placeholder {
	/* Firefox 18- */
	color: #1a1a1a;
	font-weight: bold;
}



.widget_newsletter-2 .newsletter-bx {
	background-color: #f6f7f8;
	position: relative;
	z-index: 9;
	overflow: hidden;
}

.widget_newsletter-2 .newsletter-bx .newsletter-icon {
	position: absolute;
	right: 5px;
	top: -5px;
}

.widget_newsletter-2 .newsletter-bx .newsletter-icon i {
	font-size: 75px;
	z-index: 0;
	color: rgba(0, 0, 0, 0.05);
}

/* 19.18 widget client slider */
.widget-client {
	background-color: #f6f7f8;
}

.widget-client .ow-client-logo {
	background-color: #f6f7f8;
}

/* 19.19 widget_twitter */

.widget_twitter ul {
	margin-left: 15px;
	margin-bottom: 0px;
	list-style: none;
}

.widget_twitter ul li {
	position: relative;
	padding-bottom: 15px;
}

.widget_twitter ul li .twitter-w-icon {
	position: absolute;
	left: -24px;
	font-size: 18px;
	top: 2px;
}

.widget_twitter a.site-button.outline.black {
	padding: 6px 14px;
	border-color: #E6E6E6;
}

/*-------------------------------------
	20. Table 
-------------------------------------*/
table.table-bordered {
	border-color: #ddd !important;
}

.table-behavior {
	display: table;
}

.table-behavior .table-cell-behavior {
	display: table-cell;
	float: none;
}

/* 20.1 Full-responsive */
.wt-responsive-table td,
.wt-responsive-table th {
	padding: 8px !important;
}

@media only screen and (max-width: 800px) {

	#no-more-tables table,
	#no-more-tables thead,
	#no-more-tables tbody,
	#no-more-tables th,
	#no-more-tables td,
	#no-more-tables tr {
		display: block;
	}

	#no-more-tables thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
		display: none;
	}

	#no-more-tables tr {
		border: 1px solid #ccc;
	}

	#no-more-tables td {
		border: none;
		border-bottom: 1px solid #eee;
		position: relative;
		padding-left: 50% !important;
		white-space: normal;
		text-align: left;
	}

	#no-more-tables td:before {
		position: absolute;
		top: 6px;
		left: 6px;
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
		text-align: left;
		font-weight: bold;
	}

	#no-more-tables td:before {
		content: attr(data-title);
	}
}


/*-------------------------------------
	21. Video 
-------------------------------------*/

.yt-thum-box img {
	margin: -9.5% 0%;
}

/*-------------------------------------
	22. Icons 
-------------------------------------*/


.bs-glyphicons-wrap [class*="col-"],
.fa-icon-wrap [class*="col-"],
.outline-fonts-wrap [class*="col-"] {
	display: block;
	border: 1px solid #fff;
	min-height: 100px;
	padding: 10px;
}

.outline-fonts-wrap [class*="col-"] {
	min-height: 120px;
}

.bs-glyphicons-wrap [class*="col-"] a,
.fa-icon-wrap [class*="col-"] a,
.outline-fonts-wrap [class*="col-"] a {
	display: grid;
}

.bs-glyphicons-wrap [class*="col-"] span,
.fa-icon-wrap [class*="col-"] span,
.outline-fonts-wrap [class*="col-"] span {
	display: block;
	text-align: center;
	color: #767676;
}

.bs-glyphicons-wrap [class*="col-"] span.glyphicon,
.fa-icon-wrap [class*="col-"] span.fa {
	display: table;
	padding: 5px;
	font-size: 24px;
	margin: 0px auto;
}

.bs-glyphicons-wrap [class*="col-"] span.glyphicon-class,
.fa-icon-wrap [class*="col-"] span.fa-icon-info,
.outline-fonts-wrap [class*="col-"] span.flaticon-info {
	font-size: 13px;
}

.bs-glyphicons-wrap [class*="col-"]:hover,
.fa-icon-wrap [class*="col-"]:hover,
.outline-fonts-wrap [class*="col-"]:hover {
	background-color: #4b3941;
	color: #fff;
}

.bs-glyphicons-wrap [class*="col-"]:hover span,
.fa-icon-wrap [class*="col-"]:hover span,
.outline-fonts-wrap [class*="col-"]:hover span {
	color: #fff;
}

/*-------------------------------------
	23. Call to action
-------------------------------------*/
.call-to-action-left {
	color: #fff;
}

.call-to-action-left h1,
.call-to-action-left h2,
.call-to-action-left h3,
.call-to-action-left h4,
.call-to-action-left h5,
.call-to-action-left h6,
.call-to-action-left p {
	color: #fff;
}

.call-to-action-left p:last-child {
	margin-bottom: 0;
}

.call-to-action-right {
	text-align: right;
}

@media only screen and (max-width:767px) {
	.call-to-action-right {
		text-align: left;
		padding-top: 0px;
		padding-bottom: 20px;
	}
}

.call-to-action-skew .call-to-action-left {
	position: relative;
}

@media only screen and (max-width:767px) {
	.call-to-action-skew .call-to-action-left::after {
		right: -15px;
		border-right: none;
	}

	.call-to-action-skew .call-to-action-left.p-r50 {
		padding-right: 0;
	}

	.call-to-action-skew .call-to-action-right {
		padding-top: 10px;
	}
}

.call-to-action-skew .call-to-action-left>* {
	position: relative;
	z-index: 2;
}



/*======================================
12. WP DEFAULT  
======================================*/

.alignnone {
	margin: 0;
}

.aligncenter,
div.aligncenter {
	display: block;
	margin: 5px auto 15px;
}

.alignright {
	float: right;
	margin: 5px 0 25px 25px;
}

.alignleft {
	float: left;
	margin: 5px 25px 25px 0;
}

a img.alignright {
	float: right;
	margin: 5px 0 25px 25px;
}

a img.alignnone {
	margin: 5px 0 25px 0;
}

a img.alignleft {
	float: left;
	margin: 5px 25px 25px 0;
}

a img.aligncenter {
	display: block;
	margin: 5px auto 15px;
}

.wp-caption {
	max-width: 100%;
	text-align: center;
}

.wp-caption img[class*="wp-image-"] {
	display: block;
	margin: 0;
}

.wp-caption.alignnone {
	margin: 5px 0 25px 0;
}

.wp-caption.alignleft {
	margin: 5px 25px 25px 0;
}

.wp-caption.alignright {
	margin: 5px 0 25px 25px;
}

.wp-caption img {
	border: 0 none;
	height: auto;
	margin: 0;
	max-width: 100%;
	padding: 0;
	width: auto;
}

.wp-caption p.wp-caption-text {
	color: #9d9d9d;
	font-size: 13px;
	line-height: 18px;
	margin: 0;
	padding: 10px 0;
	text-align: left;
}

blockquote {
	padding: 30px 20px 30px 80px;
	font-size: 18px;
	font-style: italic;
	color: #555555;
	margin: 40px 0;
	line-height: 30px;
	position: relative;
	background: #f6f6f6;
	clear: both;
	border: none;
	font-family: 'Crete Round', serif;
}

blockquote p {
	font-size: 14px;
	color: #555555;
	line-height: 30px;
	margin-bottom: 0px;
}

blockquote:before {
	content: '\f10d';
	font-family: "FontAwesome";
	color: #555555;
	font-size: 35px;
	position: absolute;
	left: 20px;
	top: 38px;
	font-style: normal;
}

.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
	max-width: 100%;
	height: auto;
}

/*======================================
13. BLOG  
======================================*/

.date-style-3 .wt-post-media {
	border-bottom: 3px solid #ec5598;
}

.blog-post {
	position: relative;
	margin-bottom: 40px;
}

.wt-post-title {
	margin-bottom: 15px;
}

.wt-post-title .post-title {
	margin: 0;
	font-weight: normal;
	font-size: 18px;
	line-height: 28px;
}

.wt-post-meta {
	margin-bottom: 10px;
}

.wt-post-meta ul {
	margin: 0 -4px;
	list-style: none;
}

.wt-post-meta li {
	padding: 0;
	display: inline-block;
	font-size: 12px;
	font-family: 'Roboto', sans-serif;
}

.wt-post-meta a {
	color: #a9a9a9;
}

.wt-post-meta i {
	color: #7b7b7b;
	margin: 0 5px;
}

.wt-post-meta li:after {
	content: "/";
	display: inline-block;
	font-weight: normal;
	margin-left: 5px;
	opacity: 0.5;
}

.wt-post-meta li:last-child:after {
	display: none;
}

.wt-post-text {
	margin-bottom: 15px;
}

.wt-post-text p:last-child {
	margin: 0;
}

.wt-post-readmore {
	margin-bottom: 15px;
}

.wt-post-readmore a {
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	font-style: italic;
	text-decoration: underline;
}

.wt-post-tags {
	padding-top: 10px;
}

.wt-post-tags .post-tags {
	margin: 0 -3px;
	list-style: none;
}

.wt-post-tags .post-tags a {
	border: 1px solid #ddd;
	padding: 2px 8px;
	color: #a9a9a9;
	margin: 0 3px 5px 3px;
	display: inline-block;
	text-transform: uppercase;
	font-size: 10px;
	font-weight: 600;
}

.wt-post-tags .post-tags a:hover {
	background-color: #ec5598;
	color: #fff;
}

/*-------------------------------------
	1. post date syle css
-------------------------------------*/

.date-style-2 .post-date {
	color: #fff;
	position: absolute;
	left: 20px;
	top: 0;
	width: 50px;
	text-align: center;
	text-transform: uppercase;
	background-color: #ec5598;
	z-index: 1;
}

.date-style-2 .post-date strong,
.date-style-2 .post-date span {
	display: block;
	padding: 10px 5px;
}

.date-style-2 .post-date strong {
	font-size: 10px;
	padding: 10px 5px 5px;
	position: relative;
	background-color: #4b3941;
}

.date-style-2 .post-date span {
	font-size: 16px;
	padding: 5px 5px 10px;
}

.date-style-2 .post-date:after,
.date-style-2 .post-date .fa,
.date-style-3 .post-date:after {
	display: none;
}

.date-style-3 .wt-post-info {
	position: relative;
}

.date-style-3 .post-date {
	background-color: #4b3941;
	text-transform: uppercase;
	position: absolute;
	padding: 8px 15px;
	color: #fff;
	top: -39px;
	left: 0;
	height: 36px;
	z-index: 1;
}

.date-style-3 .post-date i {
	position: relative;
	background: #ec5598;
	color: #fff;
	display: inline-block;
	text-align: center;
	width: 40px;
	height: 36px;
	line-height: 36px;
	margin-top: -8px;
	margin-left: -15px;
	margin-right: 15px;
}

.date-style-3 .post-date strong {
	color: #ec5598;
	margin-right: 2px;
}

.date-style-3.date-skew .post-date i {
	margin-right: 35px;
}

.date-style-3.date-skew .post-date i::after {
	background-color: #ec5598;
	content: "";
	height: 100%;
	position: absolute;
	right: -12px;
	width: 25px;
	-webkit-transform: skew(30deg);
	-moz-transform: skew(30deg);
	-o-transform: skew(30deg);
	-ms-transform: skew(30deg);
	transform: skew(30deg);
}

.date-style-3.date-skew .post-date:before {
	content: "";
	position: absolute;
	background-color: #4b3941;
	right: -12px;
	top: 0px;
	height: 100%;
	width: 25px;
	z-index: 1;
	display: block;
	-webkit-transform: skew(30deg);
	-moz-transform: skew(30deg);
	-o-transform: skew(30deg);
	-ms-transform: skew(30deg);
	transform: skew(30deg);
}


.recent-posts-entry-date .widget-post:last-child {
	border: none;
}

.recent-posts-entry-date .wt-post-date {
	background-color: #0e0e0e;
	width: 50px;
	height: 60px;
	float: left;
	padding: 10px 0px;
}

.recent-posts-entry-date .wt-post-date strong {
	display: block;
	font-size: 24px;
	line-height: 24px;
}

.recent-posts-entry-date .wt-post-date span {
	display: block;
	font-size: 11px;
	text-transform: uppercase;
	font-weight: 600;
}

.recent-posts-entry-date .wt-post-info {
	margin-left: 70px;
}

.recent-posts-entry-date .wt-post-info .wt-post-meta ul li {
	font-weight: normal;
	font-size: 12px;
}

.recent-posts-entry-date .wt-post-info .post-title {
	font-weight: bold;
	margin-top: 0px;
}

/*-------------------------------------
	2. post without media
-------------------------------------*/
.no-image-blog.date-style-2 .algo-post-info {
	padding-top: 70px;
}

.no-image-blog.date-style-2 .post-date {
	top: 0;
	left: 20px;
}

/*if no image with blog post*/
.blog-post.no-img-post .post-date {
	position: static;
	float: left;
}

/*-------------------------------------
	3. if no image with blog post
-------------------------------------*/
.blog-md .wt-post-media {
	width: 350px;
	float: left;
	margin-right: 30px;
}

.blog-md .wt-post-info {
	border: none;
}

.blog-md .wt-post-tags {
	border: none;
	display: inline-block;
	padding: 0;
}

@media only screen and (max-width:767px) {
	.blog-md.blog-post .wt-post-media {
		float: none;
		margin: 0 0 20px;
		width: auto;
	}
}

/*-------------------------------------
	4. blog post half image
-------------------------------------*/
.nav-links:after {
	clear: both;
	content: "";
	display: table;
}

.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute !important;
	width: 1px;
}

.pagination .page-numbers {
	background-color: #fff;
	border: 1px solid #e0e0e0;
	color: #767676;
	padding: 9px 15px;
	font-weight: 600;
	font-size: 12px;
	display: block;
	float: left;
	margin-left: -1px;
}

.pagination .page-numbers.current,
.pagination a.page-numbers:hover {
	background: #3396D1;
	color: #fff;
}

/*-------------------------------------
	5. Blog paging Next & Prev css
-------------------------------------*/
.nav-links .nav-previous a,
.nav-links .nav-next a {
	display: inline-block;
	color: #3396d1;
	font-weight: 600;
	margin-bottom: 20px;
	outline: none !important;
}

.nav-links .nav-previous a:hover,
.nav-links .nav-next a:hover {
	color: #0099CC;
}

.nav-links .nav-previous a:after,
.nav-links .nav-next a:after {
	content: "\f105";
	font-family: "FontAwesome";
	vertical-align: middle;
	margin-left: 2px;
	color: #333;
}

/*-------------------------------------
	6. Post navigation
-------------------------------------*/
.post-navigation {
	background-color: #fff;
	padding: 30px;
	border-bottom: 2px solid #e3e3e3;
}

.post-navigation .screen-reader-text {
	margin-top: 0;
}

.post-navigation .nav-previous,
.post-navigation .nav-next {
	display: inline-block;
	width: 50%;
}

.post-navigation .nav-next {
	text-align: right;
}

.post-navigation .nav-previous a:after,
.post-navigation .nav-next a:after {
	display: none;
}

.post-navigation .nav-previous .post-title,
.post-navigation .nav-next .post-title {
	display: block;
	font-size: 15px;
	color: #777777;
	margin-top: 10px;
}

/*-------------------------------------
	7. post pagination
-------------------------------------*/
.wt-link-pages {
	padding: 20px 0 30px;
}

.wt-link-pages a {
	color: #fff;
	padding: 2px 6px;
	font-size: 12px;
	font-weight: 600;
	background-color: #CCC;
	margin: 0 2px;
}

/*7.1 Sticky Post*/
.sticky-post {
	position: absolute;
	right: 0;
	top: 0;
	background: #77c04b;
	color: #FFF;
	padding: 10px 15px;
	text-transform: uppercase;
	font-weight: 600;
}

/*-------------------------------------
	8. Post password form
-------------------------------------*/
.post-password-form {
	position: relative;
	clear: both;
}

.post-password-form input[type="password"] {
	width: 100%;
	border: 1px solid #ddd;
	padding: 10px 100px 10px 15px;
	height: 40px;
}

.post-password-form label {
	display: block;
}

.post-password-form input[type="submit"] {
	position: absolute;
	bottom: 0;
	right: 0;
	padding: 10px 20px;
	background-color: #333;
	color: #FFF;
	border: none;

	height: 40px;
	text-transform: uppercase;
	font-weight: 600;
}

/*-------------------------------------
	9. gallery css for blog post
-------------------------------------*/
.blog [class*="galleryid-"] {
	clear: both;
	margin: 0 auto;
	overflow: hidden
}

.gallery .gallery-item {
	float: left;
	list-style: none;
	margin: 0px 0 0;
	overflow: hidden;
	padding: 0px 0px 5px 15px;
	text-align: center
}

.gallery .gallery-caption {
	padding-top: 10px;
	font-size: 13px;
}

.gallery .gallery-item img {
	float: left;
	padding: 0 0px;
	width: 100%;
	border: none !important;
}

.gallery-columns-2 .gallery-item {
	max-width: 50%;
}

.gallery-columns-3 .gallery-item {
	max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
	max-width: 25%;
}

.gallery-columns-5 .gallery-item {
	max-width: 20%;
}

.gallery-columns-6 .gallery-item {
	max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
	max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
	max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
	max-width: 11.11%;
}

.gallery-icon img {
	margin: 0 auto;
}

.gallery-caption {
	color: #707070;
	color: rgba(51, 51, 51, 0.7);
	display: block;
	font-size: 12px;
	font-size: 1.2rem;
	line-height: 1.5;
	padding: 0.5em 0;
}

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
	display: none;
}

/*-------------------------------------
	10. side bar
-------------------------------------*/
.side-bar .widget {
	margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
	.side-bar {
		margin-bottom: 40px;
	}
}

/*-------------------------------------
	11. Blog single
-------------------------------------*/
.blog-detail .wt-post-media {
	margin-bottom: 30px;
}

/*-------------------------------------
	12. comment list
-------------------------------------*/
.comments-area {
	padding: 0;
}

.comments-area .comments-title {
	text-transform: uppercase;
	font-size: 20px;
}

ol.comment-list {
	list-style: none;
	margin-bottom: 0;
	padding-left: 0;
}

ol.comment-list li.comment {
	position: relative;
	padding: 0;
}

ol.comment-list li.comment .comment-body {
	position: relative;
	padding: 20px 30px 20px 60px;
	margin-bottom: 40px;
	margin-left: 40px;
	position: relative;
	border: 1px solid #f2f2f2;
	border-left: 5px solid #f2f2f2;
}

ol.comment-list li.comment .comment-author {
	display: block;
	margin-bottom: 10px;
}

ol.comment-list li.comment .comment-author .avatar {
	position: absolute;
	top: 20px;
	left: -50px;
	width: 90px;
	height: 90px;
	border-radius: 100%;
	border: 2px solid #F2F2F2;
	padding: 5px;
	background-color: #fff;
}

ol.comment-list li.comment .comment-author .fn {
	display: inline-block;
	color: #555555;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 600;
	font-style: normal;
}

ol.comment-list li.comment .comment-author .says {
	display: none;
	color: #999999;
	font-weight: 600;
}

ol.comment-list li.comment .comment-meta {
	color: #8d8d8d;
	margin-bottom: 15px;
	font-family: 'Crete Round', serif;
	font-size: 16px;
}

ol.comment-list li.comment .comment-meta a {
	color: #8d8d8d;
}

ol.comment-list li.comment .comment-meta a {
	color: #8d8d8d;
}

ol.comment-list li.comment .comment-meta:before,
ol.comment-list li.comment .reply a:before {
	font-family: "FontAwesome";
	font-size: 16px;
	vertical-align: top;
}

ol.comment-list li.comment .comment-meta:before {
	content: "\f133";
}

ol.comment-list li.comment .reply a:before {
	content: "\f112";
	font-weight: normal;
	color: #555555;
	margin-right: 5px;
	vertical-align: middle;
}

ol.comment-list li.comment p {
	line-height: 18px;
	margin: 0 0 5px;
}

ol.comment-list li.comment .reply a {
	position: absolute;
	top: 50px;
	right: 30px;
	margin-top: -5px;
	color: #ffbc13;
	font-weight: 600;
}

ol.comment-list li .children {
	list-style: none;
	margin-left: 80px;
}

ol.comment-list li .children li {
	padding: 0;
}

@media only screen and (max-width: 767px) {
	.comments-area .padding-30 {
		padding: 15px;
	}

	ol.comment-list li.comment .comment-body {
		margin-bottom: 30px;
		margin-left: 30px;
	}

	ol.comment-list li.comment .comment-author .avatar {
		left: -35px;
		height: 70px;
		width: 70px;
	}

	ol.comment-list li .children {
		margin-left: 20px;
	}

	ol.comment-list li.comment .reply a {
		position: static;
	}
}

@media only screen and (max-width: 480px) {
	ol.comment-list li.comment .comment-body {
		margin-left: 26px;
	}

	ol.comment-list li.comment .comment-author .avatar {
		left: -30px;
		top: 12px;
		width: 60px;
		height: 60px;
	}

	ol.comment-list li.comment .comment-body {
		padding-left: 40px;
	}
}

/*-------------------------------------
	13. comment form
-------------------------------------*/

.comment-respond .comment-reply-title {
	text-transform: uppercase;
	font-size: 20px;
}

.comment-respond .comment-reply-title {
	font-size: 16px;
	font-weight: 600;
}

.comments-area .comment-form {
	margin: 0 -15px;
}

.comments-area .comment-form .comment-notes {
	display: none;
}

.comments-area .comment-form p {
	width: 33.333%;
	float: left;
	padding: 0 15px;
	margin-bottom: 30px;
	position: relative;
	font-weight: 600;
}

.comments-area .comment-form p.form-allowed-tags {
	width: 100%;
}

ol.comment-list li.comment .comment-respond .comment-form p {
	padding: 0 15px !important;
}

.comments-area .comment-form p label {
	display: none;
	line-height: 18px;
	margin-bottom: 10px;
}

.comments-area p::before {
	font-family: "FontAwesome";
	display: inline-block;
	position: absolute;
	left: 15px;
	top: 0px;
	font-size: 16px;
	color: #8f8f8f;
	width: 40px;
	height: 40px;
	line-height: 40px;
	border: none;
	text-align: center;

}

.comments-area p.comment-form-author:before {
	content: "\f007";
}

.comments-area p.comment-form-email:before {
	content: "\f0e0";
}

.comments-area p.comment-form-url:before {
	content: "\f0ac";
}

.comments-area p.comment-form-comment:before {
	content: "\f040";
}

.comments-area .comment-form p input[type="text"],
.comments-area .comment-form p textarea {
	width: 100%;
	height: 40px;
	padding: 10px 10px 10px 50px;
	border: none;
	background-color: #f3f3f3;

}

.comments-area .comment-form p.comment-form-comment {
	width: 100%;
	display: block;
	clear: both;
}

.comments-area .comment-form p textarea {
	height: 120px;
	resize: none;
}

.comments-area .comment-form p.form-submit {
	clear: both;
	float: none;
	width: 100%;
	margin: 0;
}

.comments-area .comment-form p input[type="submit"] {
	background-color: #77c04b;
	border-radius: 3px;
	border-width: 0;
	color: #fff;
	display: inline-block;
	font-weight: 600;
	padding: 10px 20px;
	text-transform: uppercase;
}

.comments-area .comment-form p input[type="submit"]:hover,
.comments-area .comment-form p input[type="submit"]:focus,
.comments-area .comment-form p input[type="submit"]:active {
	background-color: #6ab33e;
	border-color: #6ab33e;
	color: #fff;
}

.comment-respond .form-submit .site-button {
	width: 100%;
	font-weight: 700;
	padding: 15px 20px;
}

@media only screen and (max-width: 767px) {
	.comments-area .comment-form p {
		width: 100%;
		float: none;
		margin-bottom: 20px;
	}

	.comment-respond {
		padding: 20px;
	}
}


/*-------------------------------------
	14. blog block style-1
-------------------------------------*/

.blog-post-demo-one [class*="col-"]:nth-child(even) .wt-post-media {
	display: table-footer-group;
}

.blog-post-demo-one [class*="col-"]:nth-child(even) .wt-post-media a {
	display: block;
	overflow: hidden;
}

.blog-post-demo-one [class*="col-"]:nth-child(even) .wt-post-info {
	display: table-header-group;
}

.blog-post-demo-one [class*="col-"]:nth-child(even) .date-style-3 .wt-post-info,
.blog-post-demo-one [class*="col-"]:nth-child(even) .date-style-2 .wt-post-info {
	position: static;
}

.blog-post-demo-one [class*="col-"]:nth-child(even) .date-style-3 .post-date,
.blog-post-demo-one [class*="col-"]:nth-child(even) .date-style-2 .post-date {
	bottom: 0px;
	top: auto;
}

.blog-block-img {
	background-position: center center;
	background-size: cover;
	display: block;
	height: 250px;
	width: 100%;
}

.wt-post-info-inner {
	padding: 50px;
	height: 250px;
}


.blog-post-demo-one .wt-post-media {
	position: relative;
}

.wt-blog-post-direction {
	text-align: center;
	position: absolute;
	width: 100%;
	bottom: -20px;
	color: #F5F6F6;
}

.wt-blog-post-direction span {
	display: inline-block;
	font-size: 40px;
}


.blog-post-demo-one .wt-post-media:after {
	position: absolute;
	content: "";
	bottom: 0px;
	left: 50%;
	margin-left: -8px;
	width: 0;
	height: 0;
	border-left: 14px solid transparent;
	border-right: 14px solid transparent;
	border-bottom: 14px solid #F5F6F6;

}



.blog-post-demo-one [class*="col-"]:nth-child(even) .wt-post-media:after {
	top: 0px;
	bottom: auto;
	border-left: 14px solid transparent;
	border-right: 14px solid transparent;
	border-top: 14px solid #F5F6F6;
	border-bottom: none;

}

@media only screen and (max-width:1280px) {

	.wt-post-info-inner {
		padding: 20px;
	}

}

@media only screen and (max-width:767px) {

	.wt-post-info-inner {
		height: auto;
	}

}

/*-------------------------------------
	15. blog grid-post
-------------------------------------*/

.blog-post .grid-post li {
	float: left;
	width: 50%;
	border-right: 4px solid #fff;
	border-bottom: 4px solid #fff;
	list-style: none;
}

.blog-post .grid-post li .portfolio-item {
	width: 100%;
}

.portfolio-item {
	width: 100%;
	position: relative;
	overflow: hidden;
	color: #fff;
	width: 100%;
	border: 0;
	position: relative;
}

.blog-post .grid-post li img {
	width: 100%;
}

/*-------------------------------------
	16. if no image with blog post Latest-blog-3
-------------------------------------*/
.latest-blog-3.post-overlay .wt-post-info {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	display: table;
}

.latest-blog-3.post-overlay {
	height: 526px;
}

.latest-blog-3.post-overlay .wt-post-info .post-overlay-position {
	display: table-cell;
	vertical-align: bottom;
}

.latest-blog-3.post-overlay .wt-post-meta a {
	color: #fff;
}

.latest-blog-3.post-overlay .wt-post-meta i {
	color: #fff;
}

.latest-blog-3.post-overlay.date-style-3 .post-date {
	top: 0px;
}

.latest-blog-3.post-overlay.date-style-3 .wt-post-meta .post-date i {
	color: #fff;
}


.latest-blog-3.blog-md .wt-post-media {
	width: 230px;
	float: left;
	margin-right: 30px;
}

.latest-blog-3.blog-md .wt-post-info {
	border: none;
}

.latest-blog-3.blog-md .wt-post-tags {
	border: none;
	display: inline-block;
	padding: 0;
}

.latest-blog-3.blog-md .wt-post-meta i {
	color: #222222;
}

.latest-blog-3.blog-md.date-style-3 .post-date {
	bottom: -10px;
	top: auto;
}

.latest-blog-3.blog-md.date-style-3 .wt-post-meta .post-date i {
	color: #fff;
}

@media only screen and (max-width:991px) {
	.latest-blog-3.post-overlay {
		height: 360px;
	}
}

@media only screen and (max-width:640px) {
	.latest-blog-3.blog-md .wt-post-media {
		width: 100%;
	}
}


/*======================================
14. HEADER (top part of page)
======================================*/

.site-header {
	position: relative;
	z-index: 996;
}

@media only screen and (max-width:1200px) {
	.site-header .container {
		width: auto;
	}
}

.site-header ul,
.site-header ol {
	margin-bottom: 0;
}

/*-------------------------------------
	1. header full screen
-------------------------------------*/
.nav-wide .container {
	width: 100%;
}

/*-------------------------------------
	2. without top bar
-------------------------------------*/
.extra-nav {
	float: right;
	padding: 23px 0;
	position: relative;
	z-index: 9;
}

.extra-nav .extra-cell {
	display: inline-block;
	margin-left: 10px;
}

@media only screen and (max-width:480px) {
	.extra-nav {
		width: 100%;
		clear: both;
		margin: 0;
		text-align: right;
	}
}

/*-------------------------------------
	3. map page header
-------------------------------------*/
#header-part.fix-map-header {
	height: 90px;
}

#header-part.fix-map-header .main-bar {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
}

@media only screen and (max-width:480px) {
	#header-part.fix-map-header {
		height: 160px;
	}
}


/*======================================
15. PAGE TOP BAR (left & right content)
======================================*/

.top-bar {
	color: #ffffff;
	padding: 10px 0;
	background-color: #ec5598;
}

.top-bar .fa {
	vertical-align: middle;
	background-color: rgba(0, 0, 0, 0.08);
	border: 1px solid rgba(0, 0, 0, 0.01);
	padding: 3px;
	width: 22px;
	height: 22px;
	text-align: center;
	border-radius: 1px;
}





/*-------------------------------------
	1. eamil-phone (left) 
-------------------------------------*/
.e-p-bx li {
	display: inline;
	font-size: 12px;
	padding-right: 10px;
}

.e-p-bx li a {
	color: #FFF;
}

.e-p-bx li i {
	margin-right: 5px;
	vertical-align: middle;
}

/*-------------------------------------
	2. social-links (right) 
-------------------------------------*/
.social-bx,
.login-bx {
	margin: 0 0 0 15px;
	float: right;
}

@media only screen and (max-width: 480px) {

	.social-bx,
	.e-p-bx,
	.login-bx {
		margin-top: 5px;
		margin-bottom: 5px !important;
	}
}

.social-bx li,
.login-bx li {
	font-size: 12px;
	margin: 0;
	position: relative;
}

.social-bx li {
	padding-left: 2px;
	padding-right: 2px;
}

.social-bx li a,
.login-bx li a {
	color: #ffffff;
}

.social-bx li i,
.login-bx li i {
	vertical-align: baseline;
	margin: 0 5px 0 0;
}

.social-bx li span,
.login-bx li span {
	vertical-align: baseline;
	margin: 0 2px 0;
	font-size: 8px;
}

.login-bx li ul {
	top: 25px;
	left: auto;
	right: 0;
	border: none;
	padding: 0;
}

.login-bx li ul li {
	border-bottom: 1px solid #F7F7F7;
}

.login-bx li ul li a {
	color: #767676;
	padding: 9px 15px;
}

.arrow-up-border:before,
.arrow-up:after {
	position: absolute;
	display: inline-block;
	content: '';
}

.arrow-up-border:before {
	top: -7px;
	right: 19px;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #F00;
	border-left: 7px solid transparent;
	border-bottom-color: rgba(0, 0, 0, 0.2);
}

.arrow-up:after {
	top: -6px;
	right: 20px;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #ffffff;
	border-left: 6px solid transparent;
}

.arrow-left:before {
	position: absolute;
	top: -7px;
	left: 9px;
	display: inline-block;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #ccc;
	border-left: 7px solid transparent;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	content: '';
}

.arrow-left:after {
	position: absolute;
	top: -6px;
	left: 10px;
	display: inline-block;
	border-right: 6px solid transparent;
	border-bottom: 6px solid #ffffff;
	border-left: 6px solid transparent;
	content: '';
}

/*-------------------------------------
	3. language-list () 
-------------------------------------*/
.language-bx li {
	display: inline;
	margin: 0 10px;
}



/*======================================
16. LOGO ( company identify  symbol )
======================================*/

/*-------------------------------------
	1. logo for header 
-------------------------------------*/
.logo-header {
	display: table;
	float: left;
	vertical-align: middle;
	padding: 0;
	font-size: 36px;
	color: #1bbce8;
	margin-top: 0;
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 0;
	width: 320px;
	height: 90px;
	position: relative;
	z-index: 9;
}

@media only screen and (max-width:991px) {
	.logo-header {
		width: 280px;
	}

	.header-skew::before,
	.header-skew::after {
		bottom: -10px;
		height: 127%;
	}
}

@media only screen and (max-width:480px) {
	.logo-header {
		width: 320px;
	}

	.header-skew::before,
	.header-skew::after {
		bottom: -9px;
		height: 120%;
	}
}

.logo-footer {
	display: table;
	vertical-align: middle;
	padding: 0;
	font-size: 36px;
	color: #1bbce8;
	margin-top: 15px;
	margin-bottom: 15px;
	margin-left: 0;
	margin-right: 0;
	max-width: 200px;
	height: 60px;
}

.logo-header>a,
.logo-footer>a {
	display: table-cell;
	vertical-align: middle;
}

.logo-header.center-block {
	margin-left: auto;
	margin-right: auto;
	float: none;
}

.logo-header img,
.logo-footer img {
	max-width: 100%;
	width: auto;
	height: auto;
	vertical-align: middle;
}

.logo-header span,
.logo-footer span {
	font-size: 20px;
	color: #1bbce8;
	letter-spacing: 20px;
}

/*-------------------------------------
	2. when header is sticky 
-------------------------------------*/
.is-fixed .logo-header {
	height: 70px;
}



/*======================================
17. MAIN BAR ( place logo & main-navigation )
======================================*/

.main-bar {
	position: relative;
	width: 100%;
}

.main-bar::after,
.main-bar::after {
	clear: both;
	content: "";
	display: table;
}

/*main bar with box view*/
.boxed .is-fixed .main-bar {
	max-width: 1200px;
	margin: 0 -600px;
	left: 50%;
}

@media only screen and (max-width: 1200px) {
	.boxed .is-fixed .main-bar {
		margin: 0;
		left: 0;
	}
}

.boxed .site-header .container {
	width: auto;
}




/*header info css*/
.header-info {
	float: right;
	padding: 10px 0;
}

.header-info>ul {
	list-style: none;
	display: table;
}

.header-info>ul li {
	display: table-cell;
	padding: 15px 10px;
	vertical-align: middle;
}

.header-info>ul li .icon-sm {
	float: left;
	margin-right: 15px;
}

.header-info>ul li strong {
	display: block;
	font-size: 16px;
	text-transform: uppercase;
	color: #222222;
}

@media only screen and (max-width: 991px) {
	.header-info>ul li {
		display: none;
	}

	.header-info>ul li.btn-col-last {
		display: block;
		padding-left: 0;
		padding-right: 0;
	}
}

@media only screen and (max-width:767px) {

	.header-info>ul li.btn-col-last,
	.header-info {
		display: none;
	}
}





.header-botton {
	background-color: #4B3941;
}

.header-botton .header-nav .nav {
	float: none;
}

.header-botton .header-nav .nav>li>a {
	padding: 20px 12px;
	color: #fff;
}

.header-botton .extra-nav {
	padding: 13px 0;
}

.header-botton .extra-nav .site-search-btn {
	color: #fff;
	padding: 7px 15px;
}

.header-botton .extra-nav .wt-cart .woo-cart-count {
	border: 2px solid #fff;
	color: #fff;
	min-width: 36px;
	padding: 5px 5px 5px;
}

.header-botton .extra-nav .wt-cart .woo-cart-count::before {
	border-color: #fff;
}

@media only screen and (max-width:991px) {
	.header-botton .navbar-toggle {
		float: left;
		margin: 9px 0;
	}

	.header-botton .navbar-toggle span {
		background-color: #fff;
	}

	.header-botton .header-nav .nav>li>a {
		padding: 12px 12px;
		color: #777;
	}
}

@media only screen and (max-width:767px) {
	.header-middle .logo-header {
		float: none;
		margin: 9px auto;
	}
}

/*-------------------------------------
	1. Top Part Search Bar
-------------------------------------*/
.site-search-btn {
	background: none;
	padding: 10px 15px;
	border: none;
	outline: none;
	color: #555;
}

#search {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.9);
	transition: all 0.5s ease-in-out;
	transform: translate(0px, -100%) scale(0, 0);
	opacity: 0;
	display: none;
}

#search.open {
	transform: translate(0px, 0px) scale(1, 1);
	opacity: 1;
	z-index: 999;
	display: block;
}

#search form {
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -51px;
	width: 60%;
	margin-left: 20%;
	color: #ffffff80;
	background: transparent;
	border: 1px solid rgba(255, 255, 255, 0.1);
	font-size: 40px;
	font-family: Roboto;
	font-weight: 300;
	text-align: left;
	outline: none;
	padding: 10px;
}

#search input[type="search"] {
	width: 100%;
	background: none;
	border: none;
	padding: 0px 30px;

}

#search .search-btn {
	border: none;
	background: none;
	padding: 0px 30px;

}

#search .search-btn i {
	font-size: 36px;
}

#search .close {
	position: fixed;
	top: 15px;
	right: 15px;
	opacity: 1;
	font-size: 27px;
	color: #fff;
	width: 30px;
	height: 30px;
}

#search .close:after,
#search .close:before {
	content: "";
	position: absolute;
	width: 2px;
	height: 32px;
	background-color: #fff;
	right: 20px;
	top: 0px;
}

#search .close:after {
	transform: rotate(45deg);
}

#search .close:before {
	transform: rotate(-45deg);
}

#search .close:hover:after,
#search .close:hover:before {
	cursor: pointer;
}

@media only screen and (max-width:768px) {
	#search form {
		width: 90%;
		margin-left: 5%;
		font-size: 26px;
	}

	#search input[type="search"] {
		padding: 0px 0px 0px 30px;
	}

	#search .search-btn {
		padding: 0px 15px;
	}

	#search .search-btn i {
		font-size: 24px;
	}
}


@media only screen and (max-width:400px) {
	#search form {
		font-size: 16px;
	}

	#search input[type="search"] {
		padding: 0px 0px 0px 10px;
	}

	#search .search-btn {
		padding: 0px 15px;
	}

	#search .search-btn i {
		font-size: 18px
	}
}

.wt-quik-search .form-control::-moz-placeholder {
	color: #fff;
}

.wt-quik-search .form-control:-moz-placeholder {
	color: #fff;
}

.wt-quik-search .form-control:-ms-input-placeholder {
	color: #fff;
}

.wt-quik-search .form-control::-webkit-input-placeholder {
	color: #fff;
}

@media only screen and (max-width:767px) {
	.site-search {
		width: 100%;
	}

	.site-search .site-button {
		padding: 0;
	}

	.site-search form {
		padding-left: 0;
	}
}

/*======================================
18. NAVIGATIONS 
======================================*/

/*-------------------------------------
	1. mena-menu
-------------------------------------*/
.navbar-toggle {
	border: none;
	margin-top: 24px;
	margin-bottom: 24px;
	margin-left: 15px;
	margin-right: -10px;
	padding: 12px 10px;
}

.navbar-toggle .icon-bar {
	background: #555;
	height: 3px;
	border-radius: 0;
}

@media only screen and (max-width:480px) {
	.navbar-toggle {
		margin-left: 10px;
	}

	.extra-nav .extra-cell {
		margin-left: 0;
	}
}

.is-fixed .navbar-toggle {
	margin-top: 14px;
	margin-bottom: 14px;
}

.header-nav {
	position: relative;
	padding: 0;
}

.header-nav .nav {
	float: right;
}

.header-nav .nav i {
	font-size: 9px;
	margin-left: 3px;
	margin-top: -3px;
	vertical-align: middle;
	opacity: 0.7;
}

.header-nav .nav>li {
	margin: 0px;
	font-weight: 400;
	text-transform: uppercase;
	position: relative;
}

.header-nav .nav>li>a {
	border-radius: 0;
	color: #555;
	font-size: 14px;
	font-weight: 600;
	padding: 35px 12px;
	cursor: pointer;
}

.header-nav .nav>li>a:hover {
	background-color: transparent;
	color: #212427;
}

.header-nav .nav>li>a:active,
.header-nav .nav>li>a:focus {
	background-color: transparent;
}

.header-nav .nav>li.active>a,
.header-nav .nav>li.current-menu-item>a {
	background-color: transparent;
	color: #ec5598;
}

.header-nav .nav>li:hover>a {
	color: #ec5598;
}

.header-nav .nav>li:hover>.sub-menu,
.header-nav .nav>li:hover>.mega-menu {
	opacity: 1;
	visibility: visible;
	margin-top: 0;
	transition: all 0.3s ease;

}

.header-nav .nav>li .sub-menu {
	background-color: #ffffff;
	border: 1px solid #f4f4f4;
	display: block;
	left: 0;
	list-style: none;
	margin: 20px 0 0;
	opacity: 0;
	padding: 0px;
	position: absolute;
	visibility: hidden;
	width: 220px;
	z-index: 10;
}

.header-nav .nav>li .sub-menu li {
	border-bottom: 1px solid #f4f4f4;
	position: relative;
}

.header-nav .nav>li .sub-menu li a {
	color: #474747;
	display: block;
	font-size: 13px;
	padding: 10px 20px;
	font-weight: 600;
	text-transform: none;
}

.header-nav .nav>li .sub-menu li a:hover {
	background-color: #F2F2F2;
	color: #1BBCE8;
	text-decoration: none;
}

.header-nav .nav>li .sub-menu li:hover>a {
	color: #1BBCE8;
}

.header-nav .nav>li .sub-menu li:last-child {
	border-bottom: 0px;
}

.header-nav .nav>li .sub-menu li .fa {
	color: #363636;
	display: block;
	float: right;
	position: absolute;
	right: 10px;
	top: 12px;
}

.header-nav .nav>li .sub-menu li>.sub-menu {
	left: 220px;
	margin: 0 0 0 20px;
	transition: all 0.3s ease;
}

.header-nav .nav>li .sub-menu li:hover>.sub-menu {
	left: 220px;
	margin: 0px;
	opacity: 1;
	top: -1px;
	visibility: visible;
}

.header-nav .nav>li .sub-menu li:hover>.sub-menu:before {
	background-color: transparent;
	bottom: 0px;
	content: '';
	display: block;
	height: 100%;
	left: -6px;
	position: absolute;
	top: 0px;
	width: 6px;
}

.header-nav .nav>li.has-mega-menu {
	position: inherit;
}

.header-nav .nav>li .mega-menu li a>i {
	font-size: 13px;
	margin-right: 5px;
	min-width: 20px;
}

.header-nav .nav>li .mega-menu {
	background-color: #ffffff;
	border: 1px solid #e9e9e9;
	display: table;
	left: 0px;
	list-style: none;
	opacity: 0;
	position: absolute;
	right: 0px;
	visibility: hidden;
	width: 100%;
	margin-top: 20px;
	z-index: 10;
}

.header-nav .nav>li .mega-menu>li {
	display: table-cell;
	padding: 10px 0;
	position: relative;
	vertical-align: top;
	width: 25%;
}

.header-nav .nav>li .mega-menu>li:after {
	content: "";
	background-color: rgba(204, 204, 204, 0.4);
	position: absolute;
	right: 0px;
	top: 0px;
	display: block;
	width: 1px;
	height: 100%;
}

.header-nav .nav>li .mega-menu>li:last-child:after {
	display: none;
}

.header-nav .nav>li .mega-menu>li>a {
	color: #555555;
	display: block;
	font-size: 14px;
	padding: 0 20px;
}

.header-nav .nav>li .mega-menu>li ul {
	list-style: none;
	margin: 10px 0px 0px 0px;
	padding: 0px;
}

.header-nav .nav>li .mega-menu>li ul a {
	color: #474747;
	display: block;
	font-size: 13px;
	line-height: 34px;
	padding: 2px 20px;
	font-weight: 600;
	text-transform: none;
}

.header-nav .nav>li .mega-menu>li ul a:hover {
	color: #1BBCE8;
	background-color: #F2F2F2;
}

.header-nav .nav>li.menu-item-has-children:before {
	content: "\f078";
	display: block;
	font-family: "FontAwesome";
	right: 4px;
	position: absolute;
	top: 50%;
	color: #999;
	margin-top: -8px;
	font-size: 8px;
}

/*-- Submenu direction---*/
.header-nav .nav>li.submenu-direction .sub-menu {
	left: auto;
	right: 0;
}

.header-nav .nav>li.submenu-direction .sub-menu li>.sub-menu {
	left: auto;
	right: 220px;
	margin: 0 20px 0 0;
}

.header-nav .nav>li.submenu-direction .sub-menu li:hover>.sub-menu {
	left: auto;
	right: 219px;
	margin: 0 0 0 0;
}

.has-child .submenu-toogle {
	position: absolute;
	right: 10px;
	top: 10px;
	color: #fff;
	background-color: #4b3941;
	z-index: 999;
	cursor: pointer;
	padding: 7px;
	font-size: 10px;
	display: none;
}

.has-child li .submenu-toogle {
	opacity: 0.9;
}

.has-child.nav-active>a+.submenu-toogle.glyphicon-plus:before {
	content: "\2212";
}

/*.header-nav .nav > li:last-child{display:none;}*/
@media only screen and (max-width: 991px) {
	header .logo {
		display: block;
		padding: 4px 0;
	}

	.header-nav .nav i {
		margin-top: 6px;
	}

	.header-nav .nav li.has-child.nav-active>a {
		color: #ec5598;
	}
}

@media only screen and (max-width: 991px) {

	/* responsive strat when media screen [991px] css START*/
	.navbar-header {
		float: none;
	}

	.navbar-toggle {
		display: block;
	}

	/* .navbar-collapse.collapse {
	display: none!important;
}
.navbar-collapse.collapse.in {
	display: block!important;  
}
*/

	/* Responsive strat when medai screen [991px] css END*/
	.header-nav {
		clear: both;
		margin: 0px;
		border-bottom: 1px solid #E9E9E9;
	}

	.header-nav .nav {
		float: none;
		margin: 0;
		background: #fff;
	}

	.header-nav .nav li {
		float: none;
	}

	.header-nav .nav>li .sub-menu>li,
	.header-nav .nav>li .mega-menu>li {
		float: none;
		display: block;
		width: auto;
	}

	.header-nav .nav li .sub-menu>li a {
		padding-left: 30px;
	}

	.header-nav .nav>li>a {
		padding: 12px 15px;
		border-top: 1px solid #E9E9E9;
		color: #777;
	}

	.header-nav .nav>li.active>a,
	.header-nav .nav>li.current-menu-item>a {
		color: #ec5598;
	}

	.header-nav .nav i {
		float: right;
	}

	.header-nav .nav>li>a:hover,
	.header-nav .nav>li>a:active,
	.header-nav .nav>li>a:focus {
		background-color: #f0f0f0;
		text-decoration: none;
	}

	.header-nav .nav>li .mega-menu>li:after {
		display: none;
	}

	.header-nav .nav>li ul,
	.header-nav .nav>li .sub-menu,
	.header-nav .nav>li .mega-menu {
		display: none;
		position: static;
		visibility: visible;
		width: auto;
		background: #f9f9f9;
	}

	.header-nav .nav>li ul.mega-menu ul {
		display: block;
	}

	.header-nav .nav>li .mega-menu>li {
		padding: 0px;
	}

	.header-nav .nav>li ul.mega-menu li:hover ul {
		display: block;
	}

	.header-nav .nav li.has-child {
		position: relative;
	}

	.header-nav .nav li .sub-menu,
	.header-nav .nav li .mega-menu {
		opacity: 1;
		margin: 0 !important;
	}

	.has-child .submenu-toogle {
		display: block;
	}

}

/*-------------------------------------
	2. nav dark version
-------------------------------------*/
.nav-dark.header-nav .nav>li .sub-menu,
.nav-dark.header-nav .nav>li .mega-menu {
	background-color: #4b3941;
	border: 1px solid rgba(0, 0, 0, 0.2);
}

.nav-dark.header-nav .nav>li .sub-menu li {
	border-bottom: 1px solid #23282f;
}

.nav-dark.header-nav .nav>li .sub-menu li a,
.nav-dark.header-nav .nav>li .mega-menu>li ul a,
.nav-dark.header-nav .nav>li .mega-menu>li>a {
	color: #CCCCCC;
}

.nav-dark.header-nav .nav>li .sub-menu li a:hover {
	background-color: #23282f;
}

.nav-dark.header-nav .nav>li .mega-menu>li ul a:hover {
	background-color: #23282f;
	color: #ec5598;
}

.nav-dark.header-nav .nav>li .mega-menu>li:after {
	background-color: rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 991px) {
	.nav-dark.header-nav {
		border-bottom: 1px solid rgba(0, 0, 0, 0.8);
	}

	.nav-dark.header-nav .nav {
		background-color: #2D3239;
	}

	.nav-dark.header-nav .nav>li>a {
		border-top: 1px solid rgba(0, 0, 0, 0.2);
	}

	.nav-dark.header-nav .nav>li>a {
		color: #999;
	}

	.nav-dark.header-nav .nav>li>a:hover,
	.nav-dark.header-nav .nav>li>a:active,
	.nav-dark.header-nav .nav>li>a:focus {
		background-color: #23282F;
		color: #ec5598;
	}

	.nav-dark.header-nav .nav>li ul,
	.nav-dark.header-nav .nav>li .sub-menu,
	.nav-dark.header-nav .nav>li .mega-menu {
		background-color: #23282F;
	}
}

/*-------------------------------------
	3. when header is sticky
-------------------------------------*/
.sticky-no .main-bar {
	position: static !important;
}

/*-------------------------------------
	4. when header is sticky
-------------------------------------*/
.is-fixed .main-bar {
	position: fixed;
	top: 0;
	left: 0;
}

.is-fixed .header-nav .nav>li>a {
	padding: 25px 12px;
}

.is-fixed .extra-nav {
	padding: 13px 0;
}

@media only screen and (max-width: 991px) {
	.is-fixed .header-nav .nav>li>a {
		padding: 12px 15px;
	}
}

@media only screen and (max-width: 991px) {
	.is-fixed .header-nav .nav {
		overflow: auto;
		height: 275px;
	}

	.is-fixed .header-nav .nav>li>a {
		padding: 12px 15px;
	}

	.is-fixed#header-part .navbar-toggle {
		margin: 15px 0;
	}
}

/*-------------------------------------
	5. header full width
-------------------------------------*/
#header-part.full-width .top-bar .container,
#header-part.full-width .main-bar .container {
	width: auto;
}

/*-------------------------------------
	6. header with bg primary color 1
-------------------------------------*/
.site-bg-primary .header-nav .nav>li>a {
	color: #fff;
}

.site-bg-primary .header-nav .nav>li:hover>a,
.site-bg-primary .header-nav .nav>li.active>a,
.site-bg-primary .header-nav .nav>li.current-menu-item>a {
	color: #4b3941;
}

.site-bg-primary .wt-cart .woo-cart-total,
.site-bg-primary .wt-cart .woo-cart-count {
	color: #fff;
}

.site-bg-primary .wt-cart .woo-cart-count,
.site-bg-primary .wt-cart .woo-cart-count::before {
	border-color: #fff;
}

.site-bg-primary .navbar-toggle .icon-bar {
	background-color: #fff;
}

@media only screen and (max-width:991px) {

	.site-bg-primary .header-nav .nav>li>a {
		color: #777;
	}

	.site-bg-primary .header-nav .nav>li:hover>a,
	.site-bg-primary .header-nav .nav>li.active>a,
	.site-bg-primary .header-nav .nav>li.current-menu-item>a {
		color: #ec5598;
	}
}

/*-------------------------------------
	7. header style 2 (center content)
-------------------------------------*/
.header-style-2 .wt-topbar-right {
	display: flex;
	justify-content: space-between;
}

.header-style-2 .social-bx li {
	padding-left: 2px;
	padding-right: 2px;
	display: inline-block;
}

.header-style-2 .social-bx li a {
	background-color: rgba(0, 0, 0, 0.08);
}

@media only screen and (max-width:767px) {
	.header-style-2 .wt-topbar-right {
		display: block;
	}

	.header-style-2 .wt-topbar-right .social-bx {
		float: none;
		margin: 10px 0px 0px 0px;
	}
}

.header-style-2 .logo-header {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 0;
	float: none;
}

.header-style-2 .nav-outer {
	display: table;
}

.header-style-2 .cart-dropdown-item-wraper {
	top: 18px !important;
}

.header-style-2 .main-bar-inr {
	display: table;
	margin: auto;
}

.header-style-2 .header-nav {
	display: table-cell !important;
}

.header-style-2 .header-nav .nav {
	float: none;
}

.header-style-2 .header-nav .nav {
	display: flex;
	justify-content: space-between;
	flex-direction: inherit;
}

.header-style-2 .extra-nav {
	display: flex;
	align-items: center;
	position: relative;
	z-index: 9;
	padding: 0px;
	float: none;
}

.header-style-2 .extra-nav .extra-cell {
	display: block;
	margin: 0px 5px;
}

.header-style-2 .extra-nav .extra-cell:last-child {
	margin-right: 0px;
}

.header-style-2 .extra-nav .extra-cell .link-inner {
	display: flex;
}

.header-style-2 .navbar-toggler {
	display: none;
}

.header-style-2 .header-nav .nav>li>a {
	display: block;
}

@media only screen and (max-width:991px) {
	.header-style-2 .main-bar-inr {
		display: block;
		position: relative;
		padding-bottom: 10px;
	}

	.header-style-2 .header-nav {
		display: block;
		float: none;
	}
}

@media only screen and (max-width: 767px) {
	.header-style-2 .navbar-toggle {
		float: left;
	}

	.header-style-2 .nav-outer {
		display: block;
		padding: 22px 0 0;
	}
}

@media only screen and (max-width:480px) {
	.extra-nav {
		clear: none;
		width: auto;
	}
}

.header-style-2 .header-nav .nav>li .sub-menu li .fa {
	background-color: transparent;
	padding: 0px 10px 0px 0px;
	z-index: -1;
}

.header-style-2 .header-nav .nav>li .sub-menu li>a:hover+.fa {
	z-index: -1;
}

.header-style-2 .navbar-toggler .icon-bar {
	background: #2a2a2a;
	height: 3px;
	border-radius: 0;
	display: block;
	width: 22px;
}

.header-style-2 .navbar-toggler .icon-bar+.icon-bar {
	margin-top: 4px;
}

.header-style-2 .header-nav .has-child.nav-active>a+.submenu-toogle.fa.fa-angle-right:before {
	content: "\f107";
}

.header-style-2 .navbar-toggler {
	display: none;
}

.header-style-2 .header-nav .nav>li>a {
	display: block;
}

/*Header style 2 mobile navigation*/
@media only screen and (max-width: 991px) {
	.header-style-2 .navbar-toggler {
		display: block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 12px;
	}

	.header-style-2 .extra-nav {
		margin-right: 40px;
	}

	.header-style-2 .header-nav .nav>li .sub-menu li .fa {
		z-index: 1;
		top: 4px;
		width: 100%;
		padding: 10px;
		text-align: right;
	}

	.header-style-2 .header-nav .nav>li .sub-menu li>a:hover+.fa {
		z-index: 0;
	}

	.header-style-2 .has-child .submenu-toogle {
		background-color: transparent;
		color: #000;
		font-size: 14px;
		width: 100%;
		text-align: right;
	}

	.header-style-2 .is-fixed .header-nav .nav>li>a {
		padding: 12px 15px;
	}

	.header-style-2 .extra-nav .extra-cell {
		padding-left: 0px;
		margin-left: 0px;
	}

	.has-child .submenu-toogle {
		background: none;
	}

	.header-style-2.mobile-sider-drawer-menu .header-nav {
		position: fixed;
		width: 250px !important;
		z-index: 999;
		height: 100vh !important;
		overflow-y: inherit !important;
		left: -250px;
		top: 0px;
		transition: all 0.5s linear;
		background-color: #fff;
	}

	.header-style-2 .header-nav .nav {
		float: none;
		margin: 0px;
		background: #fff;
		background-color: rgb(255, 255, 255);
		width: 100%;
		display: block;
		height: 100%;
	}

	.header-style-2.mobile-sider-drawer-menu .header-nav .nav>li:hover>.sub-menu {
		transition: none;
	}

	.header-style-2.mobile-sider-drawer-menu .header-nav .nav>li .sub-menu li:hover>.sub-menu {
		transition: none;
	}

	.header-style-2.mobile-sider-drawer-menu.active .header-nav {
		left: 0%;
		align-items: normal;
	}

	.header-style-2.mobile-sider-drawer-menu .icon-bar {
		transition: all 0.5s linear;
		position: relative;
	}

	#mobile-side-drawer:focus {
		box-shadow: none;
	}

	.header-style-2.mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
		transform: rotate(45deg) translateX(3px) translateY(2px);
	}

	.header-style-2.mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
		transform: rotate(-45deg) translateX(3px) translateY(-2px);
	}

	.header-style-2.mobile-sider-drawer-menu .icon-bar.icon-bar-two {
		position: relative;
		right: 0%;
	}

	.header-style-2.mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
		position: absolute;
		right: 100px;
		opacity: 0;
	}

	.header-style-2.mobile-sider-drawer-menu .is-fixed .header-nav .nav {
		overflow: auto;
		max-height: 100%;
		display: block;
		width: 100%;
	}

	.header-style-2.mobile-sider-drawer-menu .header-fixed .header-nav .nav {
		overflow: auto;
		max-height: 100%;
	}

	.header-style-2 .header-nav .nav li {
		float: none;
		display: block;
		width: 100%;
	}

	.header-style-2 .header-nav .nav>li>a .fa {
		display: none;
	}


}

/*-------------------------------------
	8. header style 3 (header transperent)
-------------------------------------*/
.header-style-3 .main-bar {
	position: absolute;
	background: none;
}

.header-style-3 .header-nav .nav>li>a {
	color: #fff;
}

@media only screen and (max-width:991px) {
	.header-style-3 .header-nav .nav>li>a {
		color: #777777;
	}
}

.header-style-3 .navbar-toggle .icon-bar {
	background: #FFF;
}

.header-style-3 .site-search-btn,
.header-style-3 .wt-cart .woo-cart-total,
.header-style-3 .wt-cart .woo-cart-count {
	color: #fff;
}

.header-style-3 .wt-cart .woo-cart-count,
.header-style-3 .wt-cart .woo-cart-count::before {
	border-color: #fff;
}

/* header 3 fixed */
.header-style-3 .is-fixed .main-bar {
	position: fixed;
}

.header-style-3 .is-fixed.color-fill .main-bar {
	background: #222222;
}


/*-------------------------------------
	9. header style 4 (header white overlay)
-------------------------------------*/
.header-style-4 .main-bar {
	background-color: rgba(255, 255, 255, 0.6);
	position: absolute;
}

/*	header 4 fixed */
.header-style-4 .is-fixed .main-bar {
	position: fixed;
	background: #fff;
}

/*-------------------------------------
	11. header style 5 (header black overlay)
-------------------------------------*/
.header-style-5 .main-bar {
	position: absolute;
	background-color: rgba(0, 0, 0, 0.6);
}

.header-style-5 .header-nav .nav>li>a {
	color: #fff;
}

@media only screen and (max-width:991px) {
	.header-style-5 .header-nav .nav>li>a {
		color: #777777;
	}
}

.header-style-5 .navbar-toggle .icon-bar {
	background: #FFF;
}

.header-style-5 .site-search-btn,
.header-style-5 .wt-cart .woo-cart-total,
.header-style-5 .wt-cart .woo-cart-count {
	color: #fff;
}

.header-style-5 .wt-cart .woo-cart-count,
.header-style-5 .wt-cart .woo-cart-count::before {
	border-color: #fff;
}

/* header 5 fixed */
.header-style-5 .is-fixed .main-bar {
	position: fixed;
	background-color: rgba(0, 0, 0, 0.9);
}




/*-------------------------------------
	12. header style 6 
-------------------------------------*/
@media only screen and (max-width:767px) {
	.header-style-6 .wt-topbar-right {
		display: block;
	}

	.header-style-6 .wt-topbar-right .social-bx {
		float: none;
		margin: 10px 0px 0px 0px;
	}
}

.header-style-6 .is-fixed .header-nav .nav>li>a {
	padding: 20px 12px;
}

.header-style-6 .is-fixed .navbar-toggle {
	margin-top: 10px;
	margin-bottom: 10px;
}

/*-------------------------------------
	13. header style 7 
-------------------------------------*/
.header-style-7 .top-bar .container {
	display: flex;
	justify-content: space-between;
}

@media only screen and (max-width:575px) {
	.header-style-7 .top-bar .container {
		display: block;
	}

	.header-style-7 .top-bar .container .social-bx {
		float: none;
		margin-left: 0px;
	}
}

.header-style-7 .social-bx li {
	padding-left: 2px;
	padding-right: 2px;
	display: inline-block;
}

.header-style-7 .header-nav .nav>li .sub-menu {
	top: 60px;
}

.header-style-7 .header-nav .nav>li .mega-menu {
	top: 60px;
}

.header-style-7 .header-info>ul li {
	padding: 15px 0px 15px 10px;
}

.header-style-7 .has-child .submenu-toogle {
	background-color: transparent;
}

.header-style-7 .header-nav .nav>li .sub-menu .fa {
	z-index: -1;
	font-size: 14px;
	top: 6px;
}

.header-style-7 .has-child .submenu-toogle {
	background-color: transparent;
}

.header-style-7 .header-middle .container {
	display: flex;
	justify-content: space-between;
}

.header-style-7 .header-nav {
	justify-content: inherit;
	flex-basis: inherit;
}

.header-style-7.header-info>ul li {
	padding-right: 0px;
}

.header-style-7 .wt-cart.dropdown-toggle::after {
	display: none;
}

.header-style-7 .header-botton .header-nav .nav {
	display: flex;
	justify-content: space-between;
	flex-direction: inherit;
}

.header-style-7 .main-bar {
	max-width: 1140px;
	margin: auto;
}

.header-style-7 .is-fixed .header-nav .nav>li>a {
	padding: 20px 12px;
}

.header-style-7 .is-fixed .navbar-toggle {
	margin-top: 10px;
	margin-bottom: 10px;
	flex-direction: row-reverse;

}

.header-style-7 .header-botton .extra-nav {

	padding: 8px 0;
	display: flex;
	align-items: center;

}

.header-style-7 .main-bar .container {
	display: flex;
	justify-content: space-between;
	position: relative;
}

.header-style-7 .is-fixed .main-bar {
	margin: 0px -573px;
	left: 50%;
}

.header-style-7 .cart-dropdown-item-wraper {
	top: 14px !important;
}

.header-style-7 .navbar-toggler .icon-bar {
	background: #fff;
	height: 3px;
	border-radius: 0;
	display: block;
	width: 22px;
}

.header-style-7 .navbar-toggler .icon-bar+.icon-bar {
	margin-top: 4px;
}

.header-style-7 .header-nav .has-child.nav-active>a+.submenu-toogle.fa.fa-angle-right:before {
	content: "\f107";
}

.header-style-7 .navbar-toggler {
	display: none;
}

.header-style-7 .header-nav .nav>li>a {
	display: block;
}

@media only screen and (max-width:1200px) {

	.header-style-7 .main-bar,
	.header-style-7 .is-fixed .main-bar {
		max-width: 100%;
		margin: 0;
		left: 0;
	}
}

/*Header style 7 mobile navigation*/
@media only screen and (max-width: 991px) {
	.header-style-7 .navbar-toggler {
		display: block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 12px;
	}

	.header-style-7 .extra-nav {
		margin-right: 0px;
	}

	.header-style-7 .header-nav .nav>li .sub-menu li .fa {
		z-index: 1;
		top: 4px;
		width: 100%;
		padding: 10px;
		text-align: right;
	}

	.header-style-7 .header-nav .nav>li .sub-menu li>a:hover+.fa {
		z-index: 0;
	}

	.header-style-7 .has-child .submenu-toogle {
		background-color: transparent;
		color: #000;
		font-size: 14px;
		width: 100%;
		text-align: right;
	}

	.header-style-7 .is-fixed .header-nav .nav>li>a {
		padding: 12px 12px;
	}

	.header-style-7 .extra-nav .extra-cell {
		padding-left: 0px;
		margin-left: 0px;
	}

	.header-style-7 .header-botton .extra-nav .site-search-btn {
		color: #fff;
		padding: 7px 15px 7px 0px;
	}

	.has-child .submenu-toogle {
		background: none;
	}

	.header-style-7 .header-botton .header-nav .nav {
		float: none;
		margin: 0px;
		background: #fff;
		background-color: rgb(255, 255, 255);
		width: 100%;
		display: block;
		height: 100%;
	}

	.header-style-7 .header-botton .header-nav .nav>li>a .fa {
		display: none;
	}

	.header-style-7 .header-botton .header-nav .nav>li .sub-menu li .fa {
		top: 1px;
	}

	.header-style-7 .header-botton .header-nav .nav>li .sub-menu li>.sub-menu {
		transition: none !important;
	}

	.header-style-7.mobile-sider-drawer-menu .header-nav {
		position: fixed;
		width: 250px !important;
		z-index: 999;
		height: 100vh !important;
		overflow-y: inherit !important;
		left: -250px;
		top: 0px;
		transition: all 0.5s linear;
		background-color: #fff;
	}

	.header-style-7.mobile-sider-drawer-menu .header-nav .nav>li:hover>.sub-menu {
		transition: none;
	}

	.header-style-7.mobile-sider-drawer-menu .header-nav .nav>li .sub-menu li:hover>.sub-menu {
		transition: none;
	}

	.header-style-7.mobile-sider-drawer-menu.active .header-nav {
		left: 0%;
	}

	.header-style-7.mobile-sider-drawer-menu .icon-bar {
		transition: all 0.5s linear;
		position: relative;
	}

	#mobile-side-drawer:focus {
		box-shadow: none;
	}

	.header-style-7.mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
		transform: rotate(45deg) translateX(3px) translateY(2px);
	}

	.header-style-7.mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
		transform: rotate(-45deg) translateX(3px) translateY(-2px);
	}

	.header-style-7.mobile-sider-drawer-menu .icon-bar.icon-bar-two {
		position: relative;
		right: 0%;
	}

	.header-style-7.mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
		position: absolute;
		right: 100px;
		opacity: 0;
	}

	.header-style-7.mobile-sider-drawer-menu .is-fixed .header-nav .nav {
		overflow: auto;
		max-height: 100%;
	}

	.header-style-7.mobile-sider-drawer-menu .header-fixed .header-nav .nav {
		overflow: auto;
		max-height: 100%;
	}


}

@media only screen and (max-width:480px) {
	.header-style-7 .logo-header {
		width: 100px;
		height: auto;
	}
}




/*-------------------------------------
	13. header style - 8 
-------------------------------------*/
.header-style-8 .top-bar .container {
	display: flex;
	justify-content: space-between;
}

.header-style-8 .cart-dropdown-item-wraper {
	top: 15px !important;
}

@media only screen and (max-width:575px) {
	.header-style-8 .top-bar .container {
		display: block;
	}

	.header-style-8 .top-bar .container .social-bx {
		float: none;
		margin-left: 0px;
	}
}

.header-style-8 .social-bx li {
	padding-left: 2px;
	padding-right: 2px;
	display: inline-block;
}

.header-style-8 .header-nav .nav>li .sub-menu {
	top: 60px;
}

.header-style-8 .header-nav .nav>li .mega-menu {
	top: 60px;
}

.header-style-8 .header-info>ul li {
	padding: 15px 0px 15px 10px;
}

.header-style-8 .has-child .submenu-toogle {
	background-color: transparent;
}

.header-style-8 .header-nav .nav>li .sub-menu .fa {
	z-index: -1;
	font-size: 14px;
	top: 6px;
}

.header-style-8 .has-child .submenu-toogle {
	background-color: transparent;
}

.header-style-8 .header-middle .container {
	display: flex;
	justify-content: space-between;
}

.header-style-8 .header-nav {
	justify-content: inherit;
	flex-basis: inherit;
}

.header-style-8.header-info>ul li {
	padding-right: 0px;
}

.header-style-8 .wt-cart.dropdown-toggle::after {
	display: none;
}

.header-style-8 .header-botton .header-nav .nav {
	display: flex;
	justify-content: space-between;
	flex-direction: inherit;
}

.header-style-8 .main-bar {
	max-width: 1140px;
	margin: 0 auto -60px;
}

.header-style-8 .is-fixed .header-nav .nav>li>a {
	padding: 20px 12px;
}

.header-style-8 .is-fixed .navbar-toggle {
	margin-top: 10px;
	margin-bottom: 10px;
	flex-direction: row-reverse;

}

.header-style-8 .header-botton .extra-nav {

	padding: 8px 0;
	display: flex;
	align-items: center;

}

.header-style-8 .main-bar .container {
	display: flex;
	justify-content: space-between;
	position: relative;
}

.header-style-8 .is-fixed .main-bar {
	margin: 0px -573px;
	left: 50%;
}

.header-style-8 .navbar-toggler .icon-bar {
	background: #fff;
	height: 3px;
	border-radius: 0;
	display: block;
	width: 22px;
}

.header-style-8 .navbar-toggler .icon-bar+.icon-bar {
	margin-top: 4px;
}

.header-style-8 .header-nav .has-child.nav-active>a+.submenu-toogle.fa.fa-angle-right:before {
	content: "\f107";
}

.header-style-8 .navbar-toggler {
	display: none;
}

.header-style-8 .header-nav .nav>li>a {
	display: block;
}

@media only screen and (max-width:1200px) {

	.header-style-8 .main-bar,
	.header-style-8 .is-fixed .main-bar {
		max-width: 100%;
		margin: 0;
		left: 0;
	}
}

/*Header style 8 mobile navigation*/
@media only screen and (max-width: 991px) {
	.header-style-8 .navbar-toggler {
		display: block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 12px;
	}

	.header-style-8 .extra-nav {
		margin-right: 0px;
	}

	.header-style-8 .header-nav .nav>li .sub-menu li .fa {
		z-index: 1;
		top: 4px;
		width: 100%;
		padding: 10px;
		text-align: right;
	}

	.header-style-8 .header-nav .nav>li .sub-menu li>a:hover+.fa {
		z-index: 0;
	}

	.header-style-8 .has-child .submenu-toogle {
		background-color: transparent;
		color: #000;
		font-size: 14px;
		width: 100%;
		text-align: right;
	}

	.header-style-8 .is-fixed .header-nav .nav>li>a {
		padding: 12px 12px;
	}

	.header-style-8 .extra-nav .extra-cell {
		padding-left: 0px;
		margin-left: 0px;
	}

	.header-style-8 .header-botton .extra-nav .site-search-btn {
		color: #fff;
		padding: 7px 15px 7px 0px;
	}

	.has-child .submenu-toogle {
		background: none;
	}

	.header-style-8 .header-botton .header-nav .nav {
		float: none;
		margin: 0px;
		background: #fff;
		background-color: rgb(255, 255, 255);
		width: 100%;
		display: block;
		height: 100%;
	}

	.header-style-8 .header-botton .header-nav .nav>li>a .fa {
		display: none;
	}

	.header-style-8 .header-botton .header-nav .nav>li .sub-menu li .fa {
		top: 1px;
	}

	.header-style-8 .header-botton .header-nav .nav>li .sub-menu li>.sub-menu {
		transition: none !important;
	}

	.header-style-8.mobile-sider-drawer-menu .header-nav {
		position: fixed;
		width: 250px !important;
		z-index: 999;
		height: 100vh !important;
		overflow-y: inherit !important;
		left: -250px;
		top: 0px;
		transition: all 0.5s linear;
		background-color: #fff;
	}

	.header-style-8.mobile-sider-drawer-menu .header-nav .nav>li:hover>.sub-menu {
		transition: none;
	}

	.header-style-8.mobile-sider-drawer-menu .header-nav .nav>li .sub-menu li:hover>.sub-menu {
		transition: none;
	}

	.header-style-8.mobile-sider-drawer-menu.active .header-nav {
		left: 0%;
	}

	.header-style-8.mobile-sider-drawer-menu .icon-bar {
		transition: all 0.5s linear;
		position: relative;
	}

	#mobile-side-drawer:focus {
		box-shadow: none;
	}

	.header-style-8.mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
		transform: rotate(45deg) translateX(3px) translateY(2px);
	}

	.header-style-8.mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
		transform: rotate(-45deg) translateX(3px) translateY(-2px);
	}

	.header-style-8.mobile-sider-drawer-menu .icon-bar.icon-bar-two {
		position: relative;
		right: 0%;
	}

	.header-style-8.mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
		position: absolute;
		right: 100px;
		opacity: 0;
	}

	.header-style-8.mobile-sider-drawer-menu .is-fixed .header-nav .nav {
		overflow: auto;
		max-height: 100%;
	}

	.header-style-8.mobile-sider-drawer-menu .header-fixed .header-nav .nav {
		overflow: auto;
		max-height: 100%;
	}


}

@media only screen and (max-width:480px) {
	.header-style-8 .logo-header {
		width: 100px;
		height: auto;
	}
}

/*======================================
19. BANNER
======================================*/

.wt-banner-row,
.wt-banner-outer {
	position: relative;
}

.wt-bnr-pic {
	width: 100%;
	overflow: hidden;
}

.wt-bnr-pic img {
	width: 100%;
	height: auto;
	display: block;
}

.wt-bnr-text {
	position: absolute;
	left: 0;
	top: 35%;
	width: 100%;
	margin: 0;
	color: #FFFFFF;
}

.wt-bnr-text-has {
	max-width: 600px;
}

.wt-bnr-text strong,
.wt-bnr-text span {
	text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.wt-bnr-text strong {
	display: block;
	font-size: 60px;
	font-weight: 700;
	line-height: 70px;
	margin: 0 0 25px;
	color: #FFFFFF;
}

.wt-bnr-text span {
	font-size: 20px;
	line-height: 30px;
	font-weight: 600;
	display: block;
	margin-bottom: 30px;
}

.wt-bnr-text .btn {
	margin-right: 30px;
}


@media only screen and (max-width:1200px) {
	.wt-bnr-text strong {
		font-size: 60px;
		line-height: 60px;
		margin: 0 0 15px;
	}

	.wt-bnr-text span {
		font-size: 20px;
		line-height: 24px;
	}
}

@media only screen and (max-width:991px) {
	.wt-bnr-text strong {
		font-size: 50px;
		line-height: 50px;
		margin: 0 0 10px;
	}
}


@media only screen and (max-width:767px) {
	.wt-bnr-text strong {
		font-size: 40px;
		line-height: 40px;
		margin: 0 0 0;
	}
}

@media only screen and (max-width:680px) {
	.wt-bnr-text strong {
		font-size: 30px;
		line-height: 30px;
		margin: 0 0 0;
	}

	.wt-bnr-text span {
		font-size: 14px;
		line-height: 18px;
	}
}

@media only screen and (max-width:480px) {
	.wt-bnr-text strong {
		font-size: 20px;
		line-height: 20px;
	}

	.wt-bnr-text span {
		font-size: 12px;
		line-height: 16px;
	}
}

/*-------------------------------------
	1. inner page banner 
-------------------------------------*/
.wt-bnr-inr {
	height: 300px;
	background-size: cover;
	background-position: center center;
	display: table;
	width: 100%;
}

.wt-bnr-inr .container {
	display: table;
	height: 100%;
}

.wt-bnr-inr-entry {
	display: table-cell;
	vertical-align: middle;
}

@media only screen and (max-width:768px) {
	.wt-bnr-inr-entry h1 {
		font-size: 24px;
		line-height: 36px;
	}
}

.banner-inner-row h1,
.banner-inner-row h2,
.banner-inner-row h3,
.banner-inner-row h4,
.banner-inner-row h5,
.banner-inner-row h6 {
	color: #FFF;
}



/*======================================
20. SLIDER
======================================*/

/*-------------------------------------
	1. owl slider 
-------------------------------------*/

/*-------------------------------------
	1.1  next pre btn
-------------------------------------*/
.owl-carousel .owl-nav {
	margin-top: 40px;
	text-align: center;
}

.owl-carousel .owl-nav .disabled {
	opacity: .5;
	cursor: default
}

/*-------------------------------------
	1.2 owl dots button
-------------------------------------*/
.owl-carousel .owl-dots {
	text-align: center;
	margin-top: 20px;
}

.owl-carousel .owl-dots .owl-dot {
	display: inline-block;
}

.owl-carousel .owl-dots .owl-dot span {
	width: 12px;
	height: 12px;
	margin: 5px 7px;
	background: #4b3941;
	border: 1px solid #fff;
	display: block;
	transition: opacity 200ms ease;
}

.owl-carousel .owl-dots .owl-dot.active span,
.owl-carousel .owl-dots .owl-dot:hover span {
	background: #ec5598
}

/*-------------------------------------
	1.3 owl button top to center
-------------------------------------*/
.owl-btn-top-center .owl-nav,
.owl-btn-top-left .owl-nav,
.owl-btn-top-right .owl-nav {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	margin: 0;
}

.owl-btn-top-center .owl-stage-outer,
.owl-btn-top-left .owl-stage-outer,
.owl-btn-top-right .owl-stage-outer {
	padding-top: 60px;
}

.owl-btn-top-center .owl-nav {
	text-align: center;
}

/*-------------------------------------
	1.4 owl button top to left
-------------------------------------*/
.owl-btn-top-left .owl-nav {
	text-align: left;
}

/*-------------------------------------
	1.5 owl button top to right
-------------------------------------*/
.owl-btn-top-right .owl-nav {
	text-align: right;
}

/*-------------------------------------
	1.6 owl button bottom to center [it is default position ]
-------------------------------------*/
.owl-btn-bottom-center .owl-nav {
	text-align: center;
}

/*-------------------------------------
	1.7 owl button bottom to left
-------------------------------------*/
.owl-btn-bottom-left .owl-nav {
	text-align: left;
}

/*-------------------------------------
	1.8 owl button bottom to right
-------------------------------------*/
.owl-btn-bottom-right .owl-nav {
	text-align: right;
}

/*-------------------------------------
	1.9 owl button vertical to center
-------------------------------------*/
.owl-btn-vertical-center .owl-nav {
	margin: 0px;
}

.owl-btn-vertical-center .owl-nav .owl-prev,
.owl-btn-vertical-center .owl-nav .owl-next {
	position: absolute;
	top: 50%;
	margin: -15px 0;
}

.owl-btn-vertical-center .owl-nav .owl-prev {
	left: 0;
}

.owl-btn-vertical-center .owl-nav .owl-next {
	right: 0;
}

/*-------------------------------------
	1.10 owl button show on hover
-------------------------------------*/
.owl-btn-hover .owl-nav {
	opacity: 0;
}

.owl-btn-hover:hover .owl-nav {
	opacity: 1;
}

.owl-carousel .owl-item img {
	transform-style: inherit;
}

/*-------------------------------------
    1.11 owl slider button
-------------------------------------*/
.owl-prev,
.owl-next {
	background-color: #000 !important;
	color: #ffffff !important;
	padding: 8px 10px;
	width: 28px;
	height: 35px;
	text-align: center;
	font-size: 13px;
	display: inline-block;
	cursor: pointer;
}

.owl-carousel .owl-nav {
	margin-top: 30px;
	text-align: center;
}

.owl-carousel .owl-nav .disabled {
	opacity: .5;
	cursor: default
}



.owl-prev,
.owl-next {
	background-color: #212121;
	border-color: #212121;
	color: #ffffff;
	padding: 8px 10px;
	font-size: 13px;
	display: inline-block;
	cursor: pointer;
	border-width: 1px;
	border-style: solid;
	border-radius: 0;
	opacity: 0.5;
}

.owl-prev {
	margin-right: 5px;
}

.owl-next {
	margin-left: 5px;
}

.owl-prev:hover,
.owl-prev:active,
.owl-prev:focus,
.owl-next:hover,
.owl-next:active,
.owl-next:focus {
	background-color: #212121;
	border-color: #212121;
	color: #ffffff;
	opacity: 0.9;
}

/*-------------------------------------
	1.12 owl dots nav position
-------------------------------------*/

.owl-dots-bottom-left .owl-dots,
.owl-dots-bottom-right .owl-dots,
.owl-dots-bottom-center .owl-dots {
	bottom: 0px;
	padding: 15px 20px;
	position: absolute;
	width: 100%;
}

.owl-dots-bottom-left .owl-dots {
	text-align: left;
}

.owl-dots-bottom-right .owl-dots {
	text-align: right;
}


/*======================================
21. PAGINATION 
======================================*/
/*1. pagination style-1 */
.pagination-bx .pagination-1,
.cvf-universal-pagination .pagination-1 {
	margin: 0;
}

.pagination-1 {
	padding: 10px 0;
	display: flex;
}

.pagination-1>li {
	list-style: none;
}

.pagination-1>li:first-child>a,
.pagination-1>li:first-child>span {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	margin-left: 0;
}

.pagination-1>li:last-child>a,
.pagination-1>li:last-child>span {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.pagination-1>li>a,
.pagination-1>li>span {
	background-color: #fff;
	border: 1px solid #e0e0e0;
	color: #767676;
	font-weight: 600;
	font-size: 12px;
	display: block;
	padding: 8px 12px;
	text-align: center;

}

.pagination-1>li>a:hover,
.pagination-1>li>span:hover,
.pagination-1>li>a:focus,
.pagination-1>li>span:focus {
	background-color: #ec5598;
	border-color: transparent;
	color: #fff;
}

.pagination-1>.active>a,
.pagination-1>.active>span,
.pagination-1>.active>a:hover,
.pagination-1>.active>span:hover,
.pagination-1>.active>a:focus,
.pagination-1>.active>span:focus {
	background-color: #ec5598;
	border-color: transparent;
	color: #fff;
}

.pagination-1>.previous>a,
.pagination-1>.next>a {
	font-size: 12px;
}

/*2. pagination style-2 */
.custom-pagination.pagination-1 {
	padding: 10px 0;
}

@media only screen and (max-width:991px) {
	.custom-pagination.pagination-1 {
		margin-bottom: 30px;
		padding: 0px;
	}

}

.custom-pagination.pagination-1>li:first-child>a,
.custom-pagination.pagination-1>li:first-child>span {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	margin-left: 0;
}

.custom-pagination.pagination-1>li:last-child>a,
.custom-pagination.pagination-1>li:last-child>span {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.custom-pagination.pagination-1>li>a,
.custom-pagination.pagination-1>li>span {
	background-color: #4b3941;
	border: 1px solid #0e131b;
	color: #fff;
	font-weight: 600;
	font-size: 12px;
}

.custom-pagination.pagination-1>li>a:hover,
.custom-pagination.pagination-1>li>span:hover,
.custom-pagination.pagination-1>li>a:focus,
.custom-pagination.pagination-1>li>span:focus {
	background-color: #ec5598;
	border-color: transparent;
	color: #fff;
}

.custom-pagination.pagination-1>.active>a,
.custom-pagination.pagination-1>.active>span,
.custom-pagination.pagination-1>.active>a:hover,
.custom-pagination.pagination-1>.active>span:hover,
.custom-pagination.pagination-1>.active>a:focus,
.custom-pagination.pagination-1>.active>span:focus {
	background-color: #ec5598;
	border-color: transparent;
}

.custom-pagination.pagination-1>.previous>a,
.custom-pagination.pagination-1>.next>a {
	padding: 8px 14px;
	font-size: 12px;
}

/*3. Pager */
.pager .disabled>a,
.pager .disabled>a:focus,
.pager .disabled>a:hover,
.pager .disabled>span {
	color: #777;
	cursor: not-allowed;
	background-color: #fff;
}

.pager li>a,
.pager li>span {
	display: inline-block;
	padding: 5px 14px;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 15px;
}

.pager {
	display: block;
	margin: 20px 0px;
}

.pager li {
	list-style: none;
	display: inline-block;
}

.pager.d-flex.justify-content-center li {
	margin: 0px 2px;
}

.custom-page.pager li a {
	background-color: #4b3941;
	border: none;
	color: #fff;
}

.custom-page.pager li>a:focus,
.custom-page.pager li>a:hover {
	background-color: #ffbc13;
	text-decoration: none;
}


.custom-page.pager {
	margin: 20px 0px;
}

.custom-page.pager li {
	list-style: none;
}

.custom-page.pager li a {
	padding: 10px;
}

.pagination-1.pagination-lg>li>a,
.pagination-1.pagination-lg>li>span {
	padding: 10px 15px;
	font-size: 18px;
	line-height: 1.3333333
}

.pagination-1.pagination-sm>li>a,
.pagination-1.pagination-sm>li>span {
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5
}

.pagination-1.pagination-sm>li:first-child>a,
.pagination-1.pagination-sm>li:first-child>span {
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px
}

.pagination-1.pagination-sm>li:last-child>a,
.pagination-1.pagination-sm>li:last-child>span {
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px
}


.pagination-1>.disabled>a,
.pagination-1>.disabled>a:focus,
.pagination-1>.disabled>a:hover,
.pagination-1>.disabled>span,
.pagination-1>.disabled>span:focus,
.pagination-1>.disabled>span:hover {
	color: #777;
	cursor: not-allowed;
	background-color: #fff;
	border-color: #ddd;
}



/*======================================
22. FOOTER 
======================================*/
.ftr-btm {
	display: flex;
	justify-content: space-between;
}

.ftr-btm>* {
	width: auto;
}

/*-------------------------------------
	1. footer fixed on bottom css
-------------------------------------*/
/*1.1 footer fixed with fullscreen view*/
.footer-fixed .site-footer {
	position: fixed;
	width: 100%;
	bottom: 0;
}

.footer-fixed .page-content {
	position: relative;
	z-index: 1;
	background-color: #fff;
}

@media only screen and (max-width:1200px) {
	.footer-fixed .site-footer {
		position: static;
	}

	.footer-fixed .page-wraper {
		padding-bottom: 0 !important;
	}
}


/*1.2 footer fixed with boxed view*/
.footer-fixed.boxed .site-footer {
	margin: 0 -600px;
	left: 50%;
	max-width: 1200px;
}

.footer-fixed.boxed .wt-footer-callout-wrap {
	overflow: hidden;
}

/*1.3 Constrot strip line*/
.constrot-strip {
	position: relative;
	height: 35px;
	width: 100%;
	background-image: url(../images/footer-strip.png);
	background-repeat: repeat-x;
	background-position: center top;
}

.site-footer .widget>.widget-title {
	letter-spacing: 2px;
}

.site-footer p,
.site-footer li {
	font-size: 13px;
	line-height: 20px;
}

.site-footer {
	background-position: center;
	background-size: cover;
}

.site-footer p {
	line-height: 20px;
}

.site-footer .widget ul {
	list-style: none;
	margin-top: 5px;
}

.footer-top {
	padding: 50px 0;
}

.footer-bottom {
	padding: 0px 0px 30px 0px;
	color: #787878;
	font-size: 13px;
}

.footer-bottom .copyrights-text {
	color: #787878;
}

.footer-bottom .copyrights-nav {
	list-style: none;
	display: inline-block;
}

.footer-bottom .copyrights-nav li {
	display: inline-block;
	margin-left: 8px;
	margin-right: 8px;
}

.footer-bottom .copyrights-nav li:first-child {
	margin-left: 0;
}

.footer-bottom .copyrights-nav li:last-child {
	margin-right: 0;
}

.footer-bottom .copyrights-nav li:last-child:after {
	display: none;
}

.footer-bottom .copyrights-nav li:after {
	content: "/";
	position: relative;
	right: -8px;
}

.footer-bottom .copyrights-nav li a {
	color: #787878;
}

.footer-bottom .copyrights-nav li a:hover,
.footer-bottom .copyrights-nav li a:active,
.footer-bottom .copyrights-nav li a:focus,
.footer-bottom .copyrights-nav li.active a {
	color: #ec5598;
}

/*-------------------------------------
	2. Footer dark version css
-------------------------------------*/
.footer-dark .footer-top {
	background-color: #1b1b1b;
}

.footer-dark .footer-bottom {
	background-color: #1B1B1B;
}

.footer-dark .footer-top h1,
.footer-dark .footer-top h2,
.footer-dark .footer-top h3,
.footer-dark .footer-top h4,
.footer-dark .footer-top h5,
.footer-dark .footer-top h6,
.footer-dark .footer-top h1 a,
.footer-dark .footer-top h2 a,
.footer-dark .footer-top h3 a,
.footer-dark .footer-top h4 a,
.footer-dark .footer-top h5 a,
.footer-dark .footer-top h6 a {
	color: #fff;
}

.footer-dark .footer-top .widget-title::before {
	border-color: #fff;
}

.footer-dark .footer-top p,
.footer-dark .footer-top strong,
.footer-dark .footer-top b,
.footer-dark .footer-top,
.footer-dark .widget_categories ul li a,
.footer-dark .widget_archive ul li a,
.footer-dark .widget_meta ul li a,
.footer-dark .widget_pages ul li a,
.footer-dark .widget_recent_comments ul li a,
.footer-dark .widget_nav_menu li a,
.footer-dark .widget_recent_entries ul li a,
.footer-dark .widget_services ul li a {
	color: #999;
}

.footer-dark .footer-top a:active,
.footer-dark .footer-top a:focus,
.footer-dark .footer-top a:hover {
	color: #ec5598;
}

.footer-dark .footer-top p a {
	color: #3396d1;
}

.footer-dark .footer-top .widget_newsletter .form-control {
	background-color: #0e0e0e;
	border-color: #2d2d2d;
}

.footer-light .footer-top {
	background-color: #eee;
}

.footer-light .footer-bottom {
	background-color: #eee;
}

/*-------------------------------------
	3. Footer light version css
-------------------------------------*/





/*-------------------------------------
	4. widget li in footer
-------------------------------------*/
footer .widget_categories ul li,
footer .widget_archive ul li,
footer .widget_meta ul li,
footer .widget_pages ul li,
footer .widget_recent_comments ul li,
footer .widget_nav_menu li,
footer .widget_recent_entries ul li,
footer .widget_services ul li {
	border-bottom: 1px dashed rgba(102, 102, 102, 0.3);
}


footer .widget_categories ul li:last-child,
footer .widget_archive ul li:last-child,
footer .widget_meta ul li:last-child,
footer .widget_pages ul li:last-child,
footer .widget_recent_comments ul li,
footer .widget_nav_menu li:last-child,
footer .widget_recent_entries ul li:last-child,
footer .widget_services ul li:last-child {
	border-bottom: none;
}

/*-------------------------------------
	5. widget Getintuch
-------------------------------------*/
footer .widget_getintuch i {
	background-color: rgba(0, 0, 0, 0.2);
	border: 1px solid rgba(0, 0, 0, 0.30);
}


.footer-bottom ul {
	margin: 0;
}

.wt-footer-bot-left,
.wt-footer-bot-right,
.wt-footer-bot-center {
	padding-left: 15px;
	padding-right: 15px;
}

.wt-footer-bot-left {
	float: left;
}

.wt-footer-bot-right {
	float: right;
}

.wt-footer-bot-center {
	float: right;
}

@media only screen and (max-width:991px) {
	footer .container {
		width: 100%;
	}

	.col-md-3.col-sm-6.footer-col-4:nth-child(3) {
		clear: both;
	}

	.wt-footer-bot-left,
	.wt-footer-bot-right {
		float: none;
		text-align: center;
		padding-top: 5px;
		padding-bottom: 5px;
	}

	.wt-footer-bot-right .copyrights-nav {
		float: none !important;
	}
}

@media only screen and (max-width: 767px) {
	.footer-clear {
		display: block !important;
		clear: both;
	}

	#footer .footer-4-col {
		width: 100%;
	}

	.footer-bottom [class*="clo-"] {
		width: 100%;
	}
}

/*-------------------------------------
	6. scroll top btn css
-------------------------------------*/

/*-------------------------------------
	6. scroll top btn css
-------------------------------------*/
button.scroltop {
	height: 55px;
	width: 55px;
	background: #161616;
	border: 3px solid;
	position: fixed;
	right: 150px;
	bottom: 25px;
	font-size: 11px;
	line-height: 16px;
	font-weight: bold;
	text-transform: uppercase;
	margin: 0;
	padding: 0;
	cursor: pointer;
	display: none;


	text-align: center;
	z-index: 999;
	border-radius: 50%;
}

button.scroltop span {
	display: block;
	font-size: 24px;
	line-height: 24px;
}

.string {

	position: relative;
}

#btn-vibrate {
	animation: vibrate 2s linear 0s infinite;
	-moz-animation: vibrate 2s linear 0s infinite;
	/* Firefox */
	-webkit-animation: vibrate 2s linear 0s infinite;
	/* Safari and Chrome */
	-o-animation: vibrate 2s linear 0s infinite;
	/* Opera */
}

@keyframes vibrate {
	0% {
		top: 0px;
	}

	25% {
		top: -3px;
	}

	50% {
		top: 0px;
	}

	75% {
		top: 3px;
	}

	100% {
		top: 0px;
	}
}

/* Firefox */
@-moz-keyframes vibrate {
	0% {
		top: 0px;
	}

	25% {
		top: -3px;
	}

	50% {
		top: 0px;
	}

	75% {
		top: 3px;
	}

	100% {
		top: 0px;
	}
}

/* Safari and Chrome */
@-webkit-keyframes vibrate {
	0% {
		top: 0px;
	}

	25% {
		top: -3px;
	}

	50% {
		top: 0px;
	}

	75% {
		top: 3px;
	}

	100% {
		top: 0px;
	}
}

/* Opera */
@-o-keyframes vibrate {
	0% {
		top: 0px;
	}

	25% {
		top: -3px;
	}

	50% {
		top: 0px;
	}

	75% {
		top: 3px;
	}

	100% {
		top: 0px;
	}
}

@media only screen and (max-width:480px) {
	button.scroltop {
		font-size: 7px;
		height: 30px;
		width: 30px;
		line-height: 16px;
	}

	button.scroltop span {
		font-size: 10px;
		line-height: 10px;
	}
}



/*======================================
23. PAGE-CONTENT
======================================*/
#bg {
	background-attachment: fixed;
	background-size: cover;
}

.boxed .page-wraper {
	max-width: 1200px;
	margin: 0 auto;
	position: relative;
	overflow: hidden;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.boxed .tp-banner-container,
.boxed .rev_slider_wrapper {
	left: 0 !important;
	width: 100% !important;
}

.boxed .tp-rightarrow {
	right: 0 !important;
}

.boxed .tp-leftarrow {
	left: 0 !important;
}

/*======================================
23. PAGE-CONTENT
======================================*/

.page-wraper {
	background: #fff;
}

.page-content {
	position: relative;
}

.section-full {
	position: relative;
}

/*-------------------------------------
	1. Section Head Part 
-------------------------------------*/
.section-head {
	margin-bottom: 40px;
}

.section-head.no-margin {
	margin-bottom: 0;
}

.section-head h1,
.section-head h2,
.section-head h3 {
	margin-top: 0;
}

@media only screen and (max-width:768px) {

	.section-head h1,
	.section-head h2,
	.section-head h3 {
		font-size: 23px;
	}
}

.title-small {
	display: block;
	color: #6f6f6f;
	margin-bottom: 15px;
}

.section-head p {
	padding-top: 10px;
	font-size: 14px;
}

.section-head p:last-child {
	margin-bottom: 0;
}

.text-center.section-head p {
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;
}

.text-white {
	color: #FFF;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white .title-small {
	color: #FFF;
}

/*-------------------------------------
	2. Section Content Part 
-------------------------------------*/

@media only screen and (max-width:1024px) {
	.section-full {
		background-attachment: scroll !important;
	}

}


/*======================================
24. AFTER-LOGIN-CONTENT
======================================*/

/*-------------------------------------
	1. calender css
-------------------------------------*/
.zabuto_calendar,
.zabuto_calendar th,
.zabuto_calendar td,
.zabuto_calendar .calendar-month-navigation {
	text-align: center;
	cursor: pointer;
}


/*-------------------------------------
	2. Contact us page 
-------------------------------------*/
.banner-contact-row {
	height: 300px;
	background-size: cover;
	background-position: center;
}

.banner-contact-row * {
	color: #FFF;
}

.banner-contact-row .container {
	display: table;
	height: 100%;
}

.banner-contact-row h1 {
	display: table-cell;
	vertical-align: middle;
}

ul.contact-info li {
	padding: 0 0 15px 25px;
	margin-bottom: 15px;
	border-bottom: 1px solid #e1e1e1;
	position: relative;
	line-height: 20px;
}

ul.contact-info li:last-child {
	padding-bottom: 0;
	margin-bottom: 0;
	border-bottom: none;
}

ul.contact-info li h4 ul.contact-info li h5,
ul.contact-info li h6 {
	margin: 0 0 10px;
}

ul.contact-info li p {
	line-height: 20px;
	margin: 0;
}

ul.contact-info li .fa {
	position: absolute;
	left: 0;
	top: 2px;
	color: #3396d1;
	font-size: 16px;
}

/*-------------------------------------
	3.page not found
-------------------------------------*/
.page-notfound-wrap {
	display: flex;
	align-items: center;
}

.page-notfound-left {
	margin: 60px 0px;
}

.page-notfound-left [class^="flaticon-"]::before,
.page-notfound-left [class^="flaticon-"]::after {
	display: block;
	font-size: 200px !important;
}

.page-notfound {
	padding: 60px 0;
}

.page-notfound strong {
	font-size: 70px;
	font-weight: 900;
	line-height: 100px;
	display: block;
}

.page-notfound strong i {
	font-size: 70px;
	margin: 0 10px;
}

.page-notfound .searchform {
	position: relative;
	text-align: left;
	max-width: 420px;
	margin: 30px auto;
}

@media only screen and (max-width: 991px) {

	.page-notfound strong,
	.page-notfound strong i {
		font-size: 32px;
		line-height: 40px;
	}
}

@media only screen and (max-width: 420px) {
	.page-notfound {
		padding: 0px;
	}

	.page-notfound span {
		font-size: 20px !important;

	}

	.page-notfound strong,
	.page-notfound strong i {
		font-size: 32px;
		line-height: 40px;
	}

	.page-notfound strong i {
		margin: 0px 2px;
	}

	.page-notfound-left [class^="flaticon-"]::before,
	.page-notfound-left [class^="flaticon-"]::after {
		font-size: 120px !important;
	}

}


/*======================================
25. THEMEFOREST REQUIRED CLASSES
======================================*/


.sticky {
	clear: both;
}

.gallery-caption {
	clear: both;
}

.bypostauthor {
	clear: both;
}

.page-notfound span {
	font-size: 36px;
	display: block;
	color: #333;
	font-weight: 600;
	text-transform: uppercase;
	margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
	.page-notfound span {
		font-size: 20px;
		text-transform: capitalize;
		margin-bottom: 20px;
		margin-top: 20px;
	}
}

@media only screen and (max-width: 575px) {
	.page-notfound {
		padding: 0px;
	}

	.page-notfound-left {
		margin-bottom: 30px;
	}
}

/*-------------------------------------
	1. Text meant only for screen readers
-------------------------------------*/
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000;
}







.button-xl i {
	padding: 23px;
	margin-left: 50px;
	font-size: 30px;
}

.button-lg i {
	padding: 15px;
	margin-left: 35px;
	font-size: 20px;
}

.button-sm i {
	padding: 5.5px 6px;
	margin-left: 10px;
	font-size: 12px;
}

.skew-btn {
	-webkit-transform: skew(-25deg);
	-moz-transform: skew(-25deg);
	-o-transform: skew(-25deg);
	-ms-transform: skew(-25deg);
	transform: skew(-25deg);
}

.skew-btn>* {
	display: block;
	-webkit-transform: skew(25deg);
	-moz-transform: skew(25deg);
	-o-transform: skew(25deg);
	-ms-transform: skew(25deg);
	transform: skew(25deg);
}



/*======================================
26. HOME PAGE VERSION-1 
======================================*/

/*======================================
27. BOX MAX-WIDTH css (  )
======================================*/

.max-w100 {
	max-width: 100px;
}

.max-w200 {
	max-width: 200px;
}

.max-w300 {
	max-width: 300px;
}

.max-w400 {
	max-width: 400px;
}

.max-w500 {
	max-width: 500px;
}

.max-w600 {
	max-width: 600px;
}

.max-w700 {
	max-width: 700px;
}

.max-w800 {
	max-width: 800px;
}

.max-w900 {
	max-width: 900px;
}

.max-w1000 {
	max-width: 1000px;
}

/*======================================
28. SHOP & PRODUCT DETAIL (Woocommerce)
======================================*/

/*-------------------------------------
	1. Product Box 
-------------------------------------*/

.wt-product-box .price {
	margin-bottom: 10px;
}

.wt-product-box .price del {
	font-size: 16px;
	color: #999999;
	margin: 4px 4px;
	font-weight: 600;
}

.wt-product-box .price ins {
	font-size: 16px;
	color: #111111;
	text-decoration: none;
	margin: 4px 4px;
	font-weight: 600;
}

.wt-product-box .rating-bx {
	padding-top: 10px;
	color: #ffa000;
}

.wt-product-gallery a img {
	width: 100%;

}

/*-------------------------------------
	2. Product Detail 
-------------------------------------*/
.btn-quantity {
	width: 150px;
}

.product-description.dex-tabs.border-top .nav-tabs>li.active>a {
	border-top: 2px solid #efbb20;
}

.product-description.dex-tabs .nav-tabs>li>a i {
	color: #efbb20;
}

.dex-tabs.bg-tabs .nav-tabs>li>a {
	background-color: #fcfcfc;
}

@media only screen and (max-width:480px) {
	.btn-quantity {
		width: 100%;
		margin-bottom: 30px;
	}
}

/*-------------------------------------
	3.  Product Review 
-------------------------------------*/
ol.commentlist {
	list-style: none;
	margin: 0;
}

ol.commentlist li {
	position: relative;
	padding: 0;
	margin-bottom: 20px;
	background: #F7F8FA;
	padding: 20px;
	border: 1px dashed #EEEEEE;
}

ol.commentlist li img {
	float: left;
	border: 5px solid #FFF;
	width: 80px;
	height: auto;
}

ol.commentlist li .comment-text {
	padding-left: 100px;
}

ol.commentlist li .meta {
	margin-bottom: 5px;
}

ol.commentlist li .meta strong {
	font-size: 16px;
}

ol.commentlist li .meta .time {
	color: #999;
	display: block;
	font-size: 14px;
}

ol.commentlist li .description p {
	margin: 0;
}

ol.commentlist li .star-rating {
	position: absolute;
	top: 20px;
	right: 20px;
}

@media only screen and (max-width: 767px) {
	ol.commentlist li .star-rating {
		position: inherit;
	}
}

.comment-reply-title {
	margin-top: 0;
}

.comment-form [class*="comment-form"] {
	width: 50%;
}

@media only screen and (max-width: 767px) {
	.comment-form [class*="comment-form"] {
		width: 100%;
	}
}

.comment-form [class*="-form-author"],
.comment-form [class*="-form-email"] {
	float: left;
}

.comment-form [class*="-form-comment"] {
	width: 100%;
}

.comment-form label {
	display: block;
}

.star-Rating-input i {
	font-size: 18px;
}

.star-Rating-input .fa-star {
	color: #ec5598;
}

.star-Rating-input i:hover {
	color: #ec5598;
}

.comment-form [class*="comment-form"] input,
.comment-form [class*="comment-form"] textarea {
	border: 1px solid #CCC;
	padding: 8px 10px;
	width: 100%;
}

.comment-form {
	margin: 0 -15px;
}

.comment-form [class*="comment-form"],
.comment-form .form-submit {
	margin-bottom: 20px;
	padding: 0 15px;
}

.comment-form .form-submit input:active,
.comment-form .form-submit input:focus,
.comment-form .form-submit input:hover {
	background: #037a85;
}



/*======================================
29. content show on image box () 
======================================*/

/* info box show */
.wt-info-has {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: auto;
	padding: 30px 40px 40px;
	opacity: 0;
	margin-bottom: -100px;
	z-index: 2;
}

.wt-info-has.p-a15 {
	padding: 15px;
}

.wt-info-has.p-a20 {
	padding: 20px;
}

.wt-info-has.site-bg-primary {
	background-color: rgba(27, 188, 232, 0.9);
}

.wt-info-has.bg-green {
	background-color: rgba(106, 179, 62, 0.9);
}

.wt-info-has.bg-black {
	background-color: rgba(0, 0, 0, 0.6);
}

.wt-info-has.bg-white {
	background-color: rgba(255, 255, 255, 0.8);
}

.wt-info-has.bg-white h1,
.wt-info-has.bg-white h2,
.wt-info-has.bg-white h3,
.wt-info-has.bg-white h4,
.wt-info-has.bg-white h5,
.wt-info-has.bg-white h6 {
	color: #3d474a;
}

.wt-info-has.bg-white h1 a,
.wt-info-has.bg-white h2 a,
.wt-info-has.bg-white h3 a,
.wt-info-has.bg-white h4 a,
.wt-info-has.bg-white h5 a,
.wt-info-has.bg-white h6 a {
	color: #3d474a;
}

.wt-info-has.bg-white .wt-info-has-text,
.wt-info-has.bg-white p {
	color: #585858;
}

.wt-info-has h1,
.wt-info-has h2,
.wt-info-has h3,
.wt-info-has h4,
.wt-info-has h5,
.wt-info-has h6,
.wt-info-has p,
.wt-info-has-text {
	color: #fff;
}

.wt-info-has .wt-info-has-text {
	margin-bottom: 20px;
	line-height: 24px;
}

.wt-box:hover .wt-info-has,
.wt-thum-bx:hover .wt-info-has {
	opacity: 1;
	width: 100%;
	margin-bottom: 0;
}

.wt-info-has.no-hover {
	opacity: 1;
	width: 100%;
	margin-bottom: 0;
}


@media only screen and (max-width:768px) {

	.wt-media>img,
	.wt-media a>img {
		width: 100%;
	}
}

@media only screen and (max-width:420px) {
	.wt-info-has {
		opacity: 1;
		position: relative;
		bottom: auto;
		left: auto;
		margin-bottom: 0px;
		border: 1px solid #ddd;
	}
}


/*======================================
30. Customize  Grouping css 
======================================*/

/*-------------------------------------
	1.set-radius 
-------------------------------------*/
.btn,
.panel,
.form-control,
.img-thumbnail,
.panel-head ing,
.dropdown-menu,
.panel-group .panel,
.nav-tabs>li>a,
.modal-content,
.navbar-toggle,
.nav-pills>li>a,
.pager li>a,
.pager li>span,
.well,
.alert,
.list-group-item:first-child,
.list-group-item:last-child,
.input-group-addon,
.btn-group-divides>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle),
.btn-group-divides>.btn:last-child:not(:first-child),
.btn-group>.dropdown-toggle:not(:first-child),
.wt-booking-form .wt-extra-services li .wt-icon-box-lg,
.wt-booking-form .wt-extra-services li.active .wt-icon-box-lg:after,
.searchform input[type="text"],
.searchform input[type="submit"],
ol.comment-list li.comment .comment-body {
	border-radius: 0px;
}

/*------------------------------------- 
	2. transition 
-------------------------------------*/
a,
.btn,
.form-control,
.overlay-bx,
.overlay-icon li a i,
.wt-icon-box-xld,
.wt-icon-box-xl,
.wt-icon-box-lg,
.wt-icon-box-md,
.wt-icon-box-sm,
.wt-icon-box-xs,
.share-social-bx li,
.indicator,
.profile-menu ul li,
.staff-member .member-done,
.how-wt-work .wt-box .shadow-bx img,
.how-wt-work .wt-box .step-no-bx,
.wt-info-has,
.wt-we-find .wt-box,
.navbar-toggle,
.logo-header,
.header-nav .nav>li>a,
.extra-nav,
.acod-head .indicator .fa {
	transition: all 0.2s linear;
}

.animate-slow {
	transition: all 0.3s ease-out;
}

.animate-mid {
	transition: all 0.5s ease-out;
}

.animate-fast {
	transition: all 0.8s ease-out;
}

/*------------------------------------- 
	3. float-clearfix 
-------------------------------------*/
.clearfix::after,
.filter-wrap::after,
.filter-wrap::after,
.wt-icon-box-wraper::after,
ol.commentlist li::after {
	content: "";
	display: table;
	clear: both;
}



/*======================================
32. Inner pages 
======================================*/

/*------------------------------------- 
	1. Contact us 
-------------------------------------*/


.wt-contact-wrap {
	position: relative;
	margin: 0px;
	display: flex;
}

.contact-left-part {
	width: 50%;
	float: left;
	position: relative;
}

.contact-left-part .google-map {
	width: 100%;
	height: 100%;
}

.contact-left-part .gmap-outline {
	padding: 0px;
	height: 100%;
}

.contact-right-part {
	width: 50%;
	float: right;
}

.contact-left-part .google-map iframe {
	height: 100%;
}

@media only screen and (max-width: 1366px) {
	.contact-left-part {
		width: 30%;
		float: left;
	}

	.contact-right-part {
		width: 70%;
		float: right;
	}
}

@media only screen and (max-width: 767px) {

	.contact-left-part,
	.contact-right-part {
		width: 100%;
		float: none;
	}

	.contact-left-part .gmap-outline {
		height: 280px;
	}

	.contact-right-part {
		margin-bottom: 30px;
	}
}

/*------------------------------------- 
	2. Out Team
-------------------------------------*/
/*2.1. Out Team one*/
.wt-team-one {
	position: relative;
}

.wt-team-one .wt-team-info p {
	margin-bottom: 20px;
}

.wt-team-one .social-icons {
	margin-bottom: 0px;
}

/*2.2. Out Team two*/
.wt-team-two {
	position: relative;
	padding: 10px;
}

.wt-team-two .wt-team-media {
	overflow: hidden;
	border: 5px solid #fff;
}

.wt-team-two .wt-team-info {
	margin-bottom: 10px;
}

/*2.3. Out Team three*/
.wt-team-three .wt-team-info p {
	margin-bottom: 10px;
}

/*2.4. Out Team four*/
.wt-team-four {
	position: relative;
	background-color: #f2f2f2;
}

.wt-team-four .wt-team-media a img {
	width: 100%
}

.wt-team-four .wt-team-skew-block {
	background-color: #4b3941;
}

.wt-team-four .wt-team-skew-block {
	top: 0px;
	left: 0px;
}

.wt-team-four .wt-team-skew-block .social-icons-outer {
	margin-right: 70px;
	position: relative;
	z-index: 1;
}

.wt-team-four .wt-team-info {
	position: relative;
}

.wt-team-four .wt-team-info .wt-team-title {
	margin: 0px 0px 5px 0px;
}

.wt-team-four .wt-team-info p {
	margin-bottom: 0px;
}

.wt-team-four:hover .wt-team-media {
	position: relative;
}

.wt-team-four:hover .wt-team-media:after {
	content: "";
	display: block;
	height: 100%;
	position: absolute;
	right: 0px;
	top: 0px;

	width: 100%;
	z-index: 1;
	border: 3px solid #4b3941;
}

/*2.5. Out Team five*/
.wt-team-five-warper {
	margin-left: 60px;
	margin-right: 60px;
	display: table;
	text-align: center;
}

.wt-team-five-warper .wt-col-5 {
	position: relative;
	z-index: 2;
	width: 20%;
	display: table-cell;
	vertical-align: bottom;
}

.wt-team-five-warper .wt-col-5:last-child {
	z-index: 1;
}

.wt-team-five-warper .wt-col-5:last-child:hover {
	z-index: 2;
}

.wt-team-five .wt-team-media img {
	max-width: 160%;
	margin-left: -30%;
	margin-right: -30%;
}

.wt-team-five-warper .wt-team-active {
	z-index: 5;
}

.wt-team-five-warper .wt-team-active .wt-team-media img {
	min-width: 180%;
	margin-left: -40%;
	margin-right: -40%;
}

.wt-team-five .wt-team-info {
	position: absolute;
	width: 100%;
	bottom: 50px;
	left: 0;
	opacity: 0;
	transition: all 0.5s ease;
}

.wt-team-five .wt-arrow-up {
	position: relative;
	z-index: 2;
}

.wt-team-five .wt-arrow-up:before {
	border-bottom: 8px solid #000000;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	content: "";
	height: 0;
	position: absolute;
	right: 50%;
	top: -8px;
	margin: 0 -4px;
	width: 0;
}

.wt-team-five .wt-team-info .wt-team-title,
.wt-team-five .wt-team-info .wt-team-title a {
	color: #fff;
}

.wt-team-five .wt-team-info .wt-team-title a:hover {
	color: #000000;
}

.wt-col-5:hover {
	z-index: 10;
}

.wt-team-five:hover .wt-team-info {
	opacity: 1;
	bottom: 20px;
}

@media only screen and (max-width:767px) {
	.wt-team-five-warper {
		display: block;
	}

	.wt-team-five-warper .wt-col-5 {
		width: auto;
		display: block;
		margin-bottom: 30px;
		padding: 20px 20px 0;
		border: 3px solid #ec5598;
	}

	.wt-team-five .wt-team-media img {
		margin-left: 0;
		margin-right: 0;
		max-width: 100%;
	}

	.wt-team-five-warper .wt-team-active .wt-team-media img {
		margin-left: 0;
		margin-right: 0;
		min-width: inherit;
		max-width: 100%;
	}

	.wt-team-five:hover .wt-team-info {
		opacity: 1;
		bottom: 0;
		left: 0;
		width: 100%;
	}
}

@media only screen and (max-width:460px) {
	.wt-team-five-warper {
		margin: 0;
	}

}


/*------------------------------------- 
	3. Social-new block
-------------------------------------*/

.wt-social-block {
	position: absolute;
	bottom: 0px;
	background: #4b3941;
	padding-top: 10px;
	width: 100%;
}

/*------------------------------------- 
	4. portfolio block
-------------------------------------*/

.product-block p {
	margin-bottom: 0px;
}


/*======================================
33. Box-corner
======================================*/

/* corner-sm */


/*======================================
34. Menu list block
======================================*/
.wt-nav-block {
	border: 1px solid #ddd;
}

.wt-nav {
	list-style: none;
	margin: 0px;
}

.wt-nav-pills li {
	display: block;
	border-bottom: 1px solid #ddd;
}

.wt-nav-pills li:last-child {
	border: 0px;
}

.wt-nav-pills li a {
	color: #777777;
	padding: 10px;
	display: block;
}

.wt-nav-pills li:hover a {
	background-color: #ec5598;
	color: #fff;
}

.wt-nav-title {
	margin: 0px;
	padding: 10px;
	border-bottom: 1px solid #ddd;
	background: #f8f9f9;
}

/*======================================
35. Company Detail skew
======================================*/

.company-detail-skew {
	position: relative;
}

.company-detail-skew:after {
	border-right: 12px solid #1e1c1c;
	content: "";
	display: block;
	height: 115%;
	width: 1920px;
	position: absolute;
	right: 100%;
	top: -20px;
	z-index: 1;
	background-color: #ec5598;
	background-image: url(../images/background/bg-7.png);
	background-repeat: repeat;
	-webkit-transform: skew(30deg);
	-moz-transform: skew(30deg);
	-o-transform: skew(30deg);
	-ms-transform: skew(30deg);
	transform: skew(30deg);
}

@media only screen and (max-width: 991px) {
	.company-detail-skew:after {
		margin-right: -50px;
		-webkit-transform: skew(20deg);
		-moz-transform: skew(20deg);
		-o-transform: skew(20deg);
		-ms-transform: skew(20deg);
		transform: skew(20deg);
	}
}

@media only screen and (max-width: 767px) {
	.company-detail-skew:after {
		margin-right: -70px;
	}
}

@media only screen and (max-width: 600px) {
	.company-detail-skew:after {
		display: none;
	}
}

.company-status {
	padding-left: 190px;
}

@media only screen and (max-width: 1200px) {
	.company-status {
		padding-left: 140px;
	}
}

@media only screen and (max-width:991px) {
	.company-status {
		padding-left: 120px;
	}
}

@media only screen and (max-width:600px) {
	.company-status {
		padding-left: 0;
	}
}

@media only screen and (max-width:470px) {
	.company-status .col-xs-6 {
		width: 100%;
		margin-bottom: 10px;
	}
}

.labour-pic {
	position: relative;
	z-index: 2;
	top: -50px;
}

@media only screen and (max-width: 991px) {
	.labour-pic {
		display: none;
	}
}

.labour-pic img {
	position: absolute;
	top: 0px;
}

.status-sign {
	background: #fff;
	height: 60px;
	width: 96px;
	position: relative;
	float: left;
}

.status-sign i {
	color: #4b3941;
	float: left;
	font-size: 38px;
	padding-top: 10px;
	padding-left: 30px;
}

.status-value span {
	display: inline;
	overflow: hidden;
	font-size: 36px;
	height: 60px;
	height: 60px;
	color: #ec5598;
	font-weight: bold;
}

/*======================================
36. Clients
======================================*/
.client-grid {
	overflow: hidden;
}

.client-grid [class*="col-"] {
	position: relative;
	z-index: 1;
}

.client-grid [class*="col-"] a {
	display: block;
}

.client-grid [class*="col-"]:after {
	content: "";
	position: absolute;
	border-bottom: 1px dashed #dcdcdc;
	border-left: 1px dashed #dcdcdc;
	position: absolute;
	left: -1px;
	bottom: -1px;
	height: 100%;
	width: 100%;
	z-index: -1;
}



.grid-5 [class*="col-"] {
	width: 20%;
}

.grid-4 [class*="col-"] {
	width: 25%;
}

.grid-3 [class*="col-"] {
	width: 33.33%;
}

.grid-2 [class*="col-"] {
	width: 50%;
}

@media only screen and (max-width: 991px) {
	.grid-5 [class*="col-"] {
		width: 25%;
	}
}

@media only screen and (max-width: 767px) {

	.grid-5 [class*="col-"],
	.grid-4 [class*="col-"],
	.grid-3 [class*="col-"] {
		width: 50%;
	}
}

@media only screen and (max-width: 360px) {

	.grid-5 [class*="col-"],
	.grid-4 [class*="col-"],
	.grid-3 [class*="col-"] {
		width: 100%;
	}
}

/*======================================
37. FAQ
======================================*/

.faq-block {
	margin: 0px 0px 30px 0px;
}

.faq-block .faq-que {
	padding: 0px 10px 11px 60px;
	position: relative;
	font-size: 18px;
}

.faq-block .faq-ans {
	padding: 0px 10px 11px 60px;
	position: relative;
}

.faq-block .faq-que:after,
.faq-block .faq-ans:after {
	width: 40px;
	height: 40px;
	line-height: 40px;
	color: #fff;
	background-color: #ec5598;
	position: absolute;
	left: 0px;
	top: 0px;
	display: block;
	font-size: 20px;
	font-weight: 600;
	text-align: center;
}

.faq-block .faq-que:after {
	content: "Q";
}

.faq-block .faq-ans:after {
	content: "A";
}


@media only screen and (max-width: 767px) {
	.faq-block h2 {
		font-size: 18px;
	}
}

@media only screen and (max-width: 320px) {
	.faq-block h2 {
		font-size: 16px;
	}
}

/*======================================
38. Cart
======================================*/
.wt-cart .woo-cart-total {
	display: inline-block;
	font-weight: 600;
	color: #555;
}

.wt-cart .woo-cart-count {
	border: 2px solid #555;
	color: #555;
	display: inline-block;
	font-weight: 800;
	min-width: 40px;
	padding: 9px 5px 10px;
	position: relative;
	text-align: center;
}

.wt-cart .woo-cart-count:before {
	content: "";
	border: 2px solid #555;
	width: 22px;
	height: 10px;
	border-bottom: none;
	position: absolute;
	top: -8px;
	left: 50%;
	margin: 0 -11px;
}


.cart-dropdown-item-wraper {
	top: 110%;
	right: 0px;
	position: absolute;
	z-index: 999;
	background-color: #fff;
	padding: 2px;
	border: 3px solid #eaeaea;
	width: 280px;
	display: none;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
	text-align: left;
}

.nav-cart-items {
	position: relative;
}


.nav-cart-title {
	border-bottom: 1px solid #eee;
}

.nav-cart-title h4 {
	font-size: 15px;
	font-weight: 700;
	letter-spacing: 1px;
	margin: 0px;
	text-transform: uppercase;
}

.nav-cart-item {
	border-top: 1px solid #f5f5f5;
	margin-top: 15px;
	padding-top: 15px;
}

.nav-cart-item:first-child {
	border-top: 0 none;
	margin-top: 0;
	padding-top: 0;
}

.nav-cart-item-image {
	border: 2px solid #eee;
	float: left;
	height: 48px;
	margin-right: 15px;
	transition: border-color 0.2s linear 0s;
	width: 48px;
}

.nav-cart-item-image a,
.nav-cart-item-image img {
	display: block;
	height: 44px;
	width: 44px;
}

.nav-cart-item-image:hover {
	border-color: #243447;
}

.nav-cart-item-desc {
	overflow: hidden;
	position: relative;
}

.nav-cart-item-desc a {
	font-size: 13px;
	font-weight: 600;
	height: auto;
	text-align: left;
	width: auto;

}

.nav-cart-item-desc a:hover {
	color: #243447;
}

.nav-cart-item-desc span.nav-cart-item-price {
	color: #999;
	display: block;
	font-size: 12px;
	line-height: 20px;
}

.nav-cart-item-desc a.nav-cart-item-quantity {
	color: #444;
	display: block;
	font-size: 12px;
	position: absolute;
	right: 0;
	top: 2px;
	padding: 0px 5px 2px 5px;
}

.nav-cart-item-desc a:hover.nav-cart-item-quantity {

	background-color: #000;
	color: #fff;
}

.nav-cart-action {
	border-top: 1px solid #eee;
}


/*======================================
39. Product
======================================*/

.pro-banner {
	position: relative;
	background-color: #fff;
	overflow: hidden;
}

.pro-banner-disc {
	background-color: rgba(0, 0, 0, 0.4);
	width: 100%;
	height: 100%;
}

.pro-banner-disc h2,
.pro-banner-disc h3 {
	font-weight: 700;
}

.pro-banner-disc h3 {
	color: #ec5598;
}

.pro-banner-disc a {
	position: inherit;
	right: 0px;
	bottom: 0px;
}

@media only screen and (max-width: 991px) {
	.pro-banner img {
		width: 100%;
	}
}

@media only screen and (max-width: 420px) {
	.pro-banner-disc {
		padding: 10px !important
	}

	.pro-banner-disc h2 {
		font-size: 15px;
	}

	.pro-banner-disc h3 {
		font-size: 14px;
	}

	.pro-banner-disc h4 {
		font-size: 13px;
	}

	.pro-banner-disc h5 {
		font-size: 12px;
	}

	.pro-banner-disc h2,
	.pro-banner-disc h3,
	.pro-banner-disc h4,
	.pro-banner-disc h5 {
		margin-bottom: 0px !important;
	}
}

.sf-provider-des.sf-provider-descriptions ul.sharebtn-bx,
.sf-provider-des.sf-provider-descriptions ul.share-social-bx {
	width: auto !important;
	float: none !important;
	display: table;
	margin: 5px auto;
}

.sf-provider-des.sf-provider-descriptions .tagline {
	padding-top: 10px;
}

.sf-provider-des.sf-provider-descriptions .sf-provider-cat {
	margin: 10px 0;
}

/*======================================
40. Glass reflection
======================================*/

.img-reflection {
	background-position: left top;
	position: relative;
	overflow: hidden;
	background-size: 100% auto;
	background-repeat: no-repeat;
}

.img-reflection:after {
	content: "";
	background-color: rgba(255, 255, 255, 0.2);
	position: absolute;
	width: 35%;
	height: 200%;
	left: -100%;
	top: -50%;
	transition: all 0.5s linear;
	transform: rotate(40deg);
}

.img-reflection:hover:after {
	left: 150%;
}

/*======================================
41. About company
======================================*/
.about-com-pic {
	position: relative;
	z-index: 1;
	margin-right: 30px;
	margin-left: 10px;
}

.about-com-pic:after {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	top: 10px;
	left: -10px;
	background-color: #ffbc13;
	z-index: -1;
}

.about-com-pic img {
	width: 100%;
}

.about-com-pic-2 img {
	width: 100%;
	height: 540px;
	object-fit: cover;
	object-position: center;
	padding-right: 15px;
}

@media only screen and (max-width:991px) {
	.about-com-pic-2 img {
		height: 400px;
		padding-right: 0px;
	}
}

@media only screen and (max-width:767px) {
	.about-com-pic {
		margin-right: 0px;
	}
}

@media only screen and (max-width:991px) {
	.about-com-pic {
		margin-bottom: 40px;
	}
}

/*======================================
42. Flip
======================================*/

/* simple */
.flip-container {
	perspective: 1000;
	transform: perspective(1000px);
}

/* START: Accommodating for IE */
.flip-container:hover .wt-info {
	transform: rotateY(0deg);
}

.flip-container:hover .wt-thum-bx {
	transform: rotateY(180deg);
}

/* END: Accommodating for IE */

.flip-container,
.flip-container .wt-thum-bx,
.flip-container .wt-info {
	width: 100%;
	height: 100%;
}

.flip-container .wt-box {
	transform: perspective(1000px);
	transition: 0.6s;
	position: relative;
}

.flip-container .wt-thum-bx,
.flip-container .wt-info {
	backface-visibility: hidden;
	transition: 0.6s;
	transform: rotateY(0deg);

	top: 0;
	left: 0;
}

.flip-container .wt-info {
	position: absolute;
}

.flip-container .wt-info .wt-info-text {
	bottom: 0px;
	left: 0px;
	width: 100%;
	position: absolute;
}

.flip-container .wt-info .wt-info-text p {
	margin-bottom: 0px;
}

@media only screen and (max-width:320px) {
	.flip-container .wt-info .wt-info-text {
		padding: 15px;
	}
}

.flip-container .wt-info .wt-info-media-zoom {
	right: 0px;
	top: 0px;
	position: absolute;
	z-index: 10;
}


.flip-container .wt-thum-bx {
	transform: rotateY(0deg);
	z-index: 2;
}

.flip-container .wt-thum-bx img {
	transform-style: flat;
}


.flip-container .wt-info {
	-webkit-transform: rotateY(-180deg);
	-moz-transform: rotateY(-180deg);
	-o-transform: rotateY(-180deg);
	-ms-transform: rotateY(-180deg);
	transform: rotateY(-180deg);
}


/* vertical */
.vertical.flip-container {
	position: relative;
}

.vertical.flip-container .wt-info {
	transform: rotateX(180deg);
}

.vertical.flip-container .wt-box {
	transform-origin: 100% 213.5px;
}

/* START: Accommodating for IE */
.vertical.flip-container:hover .wt-info {
	-webkit-transform: rotateX(0deg);
	-moz-transform: rotateX(0deg);
	-o-transform: rotateX(0deg);
	-ms-transform: rotateX(0deg);
	transform: rotateX(0deg);
}

.vertical.flip-container:hover .wt-thum-bx {
	transform: rotateX(180deg);
}


/*======================================
43. Animate line
======================================*/
.animate_line {
	position: relative;
	z-index: 0;
}

.animate_line .wt-icon-box-wraper {
	margin-top: 10px;
	margin-bottom: 10px;
}

.animate_line:after,
.animate_line:before {
	content: "";
	height: 4px;
	width: 0px;
	position: absolute;
	transition: all 0.5s ease 0s;
	z-index: -1;
}

.animate_line:after {
	left: 10px;
	bottom: 3px;
}

.animate_line:before {
	right: 10px;
	top: 3px;
}

.animate_line:hover:after,
.animate_line:hover:before {
	width: 50%;
	height: 50%;
	background: #ffbc13;
}

@media only screen and (max-width:767px) {
	.animate_line:after {
		bottom: 2px;
	}

	.animate_line:before {
		top: 2px;
	}
}

/*======================================
44. Timeline Section
======================================*/

/*---- timeline 1 ----*/

.timeline {
	position: relative;
}

.timeline::before {
	background-color: #d8d9df;
	bottom: 0;
	content: "";
	left: 50%;
	position: absolute;
	top: 30px;
	width: 1px;
	z-index: 0;
}

.timeline .timeline-item {
	width: 50%;
	position: relative;
}

.timeline .timeline-item:nth-child(odd) {
	text-align: right;
	padding-right: 60px;
}

.timeline .timeline-item:nth-child(even) {
	text-align: left;
	margin-left: 50%;
	padding-left: 60px;
}

.timeline .timeline-icon {
	position: absolute;
}

.timeline .timeline-item:nth-child(odd) .timeline-icon {
	right: -40px;
}

.timeline .timeline-item:nth-child(even) .timeline-icon {
	left: -40px;
}

@media only screen and (max-width:480px) {
	.timeline::before {
		left: 10%;
		right: auto;
	}

	.timeline .timeline-item {
		width: 100%;
	}

	.timeline .timeline-item:nth-child(2n+1) {
		padding-left: 100px;
		padding-right: 0px;
		text-align: left;
	}

	.timeline .timeline-item:nth-child(2n) {
		margin-left: 0%;
		padding-left: 100px;
		text-align: left;
	}

	.timeline .timeline-item:nth-child(2n+1) .timeline-icon {
		left: 5px;
		right: auto;
	}

	.timeline .timeline-item:nth-child(2n) .timeline-icon {
		left: 5px;
	}

}

/*======================================
45. Our value Section
======================================*/
@media only screen and (max-width:768px) {
	.our-value {
		margin-bottom: 40px;
	}

	.our-value .our-value-right {
		padding: 20px 40px;
	}

}


/*======================================
46. Summary counter
======================================*/

.summary-counter .icon-content div span.counter,
.summary-counter .icon-content div b {
	font-size: 36px;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 700;
}

.summary-counter .icon-content span {
	font-weight: 700;
	font-size: 18px;
}

/*======================================
47. Circle Effect
======================================*/

.circle-effect-1 {
	position: relative;
	z-index: 2;
	margin: 10px 17px;
}

.circle-effect-1+.wt-info h2 {
	font-size: 24px;
	line-height: 30px;
	margin-bottom: 20px;
}

.radius-bx.circle-effect-1:after,
.radius-bx.circle-effect-1:before {
	content: "";
	border-radius: 100%;
	position: absolute;
}

.circle-effect-1:after {
	background-color: #ec5598;
	width: 110%;
	height: 110%;
	left: -5%;
	top: -5%;
	z-index: -2;
}

.circle-effect-1:before {
	background-color: #fff;
	width: 105%;
	height: 105%;
	left: -4%;
	top: -4%;
	z-index: -1;
}

.circle-effect-1:after,
.circle-effect-1:before {
	transition: all 0.2s linear;
}

.circle-effect-1:hover:after {
	left: -5%;
	top: -5%;
}

.circle-effect-1:hover:before {
	left: -1%;
	top: -1%;
}


.circle-effect-1+.wt-info p {
	font-size: 16px;
	margin-bottom: 15px;
}

.circle-effect-2 {
	margin: 0 auto;
	position: relative;
	width: 94%;
	z-index: 2;
}

.circle-effect-2+.wt-info p {
	font-size: 16px;
}


.price-tag-3 {
	position: absolute;
	top: 0px;
	left: 30px;
	z-index: 5;
}

.price-tag {
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 5;
}

.price-circle,
.pricing-circle-2 {
	width: 60px;
	height: 60px;
}

.price-circle {
	display: table;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
}

.pricing-circle-2 {
	display: table;
}

.price-circle span,
.pricing-circle-2 span {
	display: table-cell;
	vertical-align: middle;
	font-weight: bold;
	font-family: Arial, Helvetica, sans-serif;
}

/*======================================
48. Special Offer
======================================*/
.special-offer-outer {
	position: relative;
	z-index: 1;
}

.special-offer-outer:after {
	content: "";
	position: absolute;
	right: -25%;
	top: 0px;
	height: 100%;
	width: 50%;
	background-color: #ec5598;
	border-right: 10px solid #fff;
}

.radius.special-offer-outer:after {
	border-radius: 0 50% 50% 0;
}

.special-offer {
	background-color: rgba(255, 255, 255, 0.2);
	position: relative;
	z-index: 1;
	margin-right: 20%;
	padding-right: 3px;
}

.special-offer:after {
	content: "";
	position: absolute;
	right: -45%;
	top: 0px;
	height: 100%;
	width: 45%;
	background-color: rgba(255, 255, 255, 0.2);

}

.radius.special-offer:after {
	border-radius: 0 50% 50% 0;
}

.special-offer-in {
	position: relative;
	margin-right: -24%;
}

.special-offer .wt-left-part h1 {
	font-size: 52px;
	line-height: 60px;
}

.special-offer .wt-left-part p {
	font-size: 16px;
}


.special-offer-outer-square.special-offer-outer:after {
	right: -8%;
}

.special-offer-outer-square.special-offer-outer .special-offer:after {
	width: 0%;
}

.special-offer-outer-square.special-offer-outer .special-offer {
	margin-right: 0%;
}

.special-offer-outer-square.special-offer-outer .special-offer-in {
	margin-right: 0%;
}




@media only screen and (max-width:1200px) {
	.special-offer-in {
		margin-right: 0%;
	}

	.special-offer .special-offer-in .wt-box {
		padding-right: 0px;
	}

	.special-offer::after {
		right: -40%;
		width: 40%;
	}

	.special-offer-outer::after {
		right: -40%;
	}

	.special-offer {
		margin-right: 10%;
	}
}


@media only screen and (max-width:991px) {
	.special-offer-block {
		background-image: none !important;
		text-align: center;
	}

	.special-offer::after,
	.special-offer-outer::after {
		display: none;
	}

	.special-offer {
		border-radius: 50%;
		margin: 0 10%;
		padding: 10%;
	}

	.testimonial-5 {
		margin-left: 13%;
		position: relative;
	}

	.special-offer {
		border-radius: 20% 20%;

	}
}

@media only screen and (max-width:600px) {

	.special-offer .wt-left-part {
		padding-left: 0px;
	}

	.special-offer .wt-left-part h1 {
		font-size: 32px;
		line-height: 36px;
		margin-bottom: 10px;

	}

	.special-offer .wt-left-part h2 {
		font-size: 18px;
		line-height: 26px;
	}

	.special-offer .wt-left-part p {
		font-size: 14px;
		line-height: 20px;
	}


}

@media only screen and (max-width:480px) {
	.testimonial-5 {
		margin-left: 0%;
	}
}


@media only screen and (max-width:420px) {

	.testimonial-5 {
		margin-left: 0%;
		position: relative;
	}

	.testimonial-5 .testimonial-pic-block {
		left: inherit;
		position: inherit;
		text-align: center;
		top: inherit;
		padding-top: 30px;
	}

	.testimonial-5 .testimonial-text {
		padding: 40px;
	}

	.testimonial-5 .testimonial-pic {
		float: none;
	}

	.special-offer {
		padding: 30px;
		margin: 0 3%;
	}

}


/*======================================
49. Special Offer
======================================*/
.wt-subscribe-left .icon-content p {
	font-size: 18px;
}

.wt-subscribe-left .icon-content span {
	font-size: 24px;
	font-weight: 700;
}

/*======================================
50. Opening Hours
======================================*/

.contact-home-left .Opening-hours ul {
	margin-bottom: 0px;
}

.contact-home-left .Opening-hours ul li {
	padding-bottom: 5px;
}

.contact-home-left .Opening-hours h3 {
	margin-bottom: 10px;
}

.contact-home-left .Opening-hours ul li span {
	display: inline-block;
	font-weight: 700;
}

.input-group textarea.form-control {
	height: 44px;
}

@media only screen and (max-width:1024px) {
	.contact-home-left .p-a50 {
		padding: 30px;
	}
}

@media only screen and (max-width:420px) {
	.contact-home-left .wt-icon-box-wraper.left {
		text-align: center;
	}

	.contact-home-left .wt-icon-box-wraper.left .icon-lg {
		float: none;
	}

	.contact-home-left .Opening-hours {
		text-align: center;
	}

	.contact-home-left .Opening-hours ul li span {
		float: none;
	}
}

/*======================================
51. pricing-tab-content-block
======================================*/

.our-pricing-carousel.owl-carousel .owl-stage-outer {
	padding-bottom: 10px;
}

.our-pricing-carousel.nav-tabs {
	border-bottom: none;
}

.our-pricing-carousel .item .wt-icon-box-wraper {
	background-color: #fff;
}

.our-pricing-carousel .item.active-arrow .wt-icon-box-wraper {
	background: none;
	color: #fff;

}

.our-pricing-carousel .item .tab-block {
	cursor: pointer;
}

.our-pricing-carousel .item.active-arrow {
	position: relative;
	background-color: #EC5598;
}

.our-pricing-carousel .item.active-arrow:after {
	position: absolute;
	content: "";
	bottom: -24px;
	margin-left: -15px;
	left: 50%;
	width: 30px;
	height: 30px;
	border-top: 18px solid #EC5598;
	border-left: 18px solid transparent;
	border-right: 18px solid transparent;
}

.our-pricing-carousel .item.active-arrow .wt-icon-box-wraper .icon-cell i:before {
	color: #fff;
}

@media only screen and (max-width:360px) {
	.our-pricing-carousel .item .wt-icon-box-wraper .icon-cell i {
		line-height: 36px;
	}

	.our-pricing-carousel .item .wt-icon-box-wraper .icon-cell i:before {
		font-size: 32px;
	}

	.our-pricing-carousel .item .wt-icon-box-wraper .wt-tilte {
		font-size: 12px;
	}

}

.our-pricing-carousel .item.active-arrow .our-pricing-tab {
	border-style: hidden;
}

.pricing-tab-content-block {
	display: none;
}

.pricing-tab-content-block.active-arrow {
	display: inherit;
}

.pricing-tab-content-block .tab-pane {
	padding: 0px 0px 0px 20px !important;
}

@media only screen and (max-width:991px) {
	.pricing-tab-inner .wt-media img {
		margin-bottom: 20px;
	}
}

.our-services-index .item {
	margin: 0px 20px;
}

.our-services-index .owl-carousel .owl-dots .owl-dot span {
	width: 14px;
	height: 14px;
	border-radius: 50%;
	border: none;
}

.pricing-tab-content-block .wt-tabs.vertical .nav-tabs {
	border-bottom: medium none;
	border-right: 1px solid #ddd;
	float: left;
	width: 275px;
}

.pricing-tab-content-block .wt-tabs.vertical .tab-content {
	border-left: 0px;
	margin-left: 275px;
}

.pricing-tab-content-block .wt-tabs .nav-tabs>li>a {
	color: #4b3941;
	font-size: 15px;
	font-weight: 500;
	padding: 21px 20px 21px 30px;
}


@media only screen and (max-width:767px) {
	.pricing-tab-content-block .wt-tabs.vertical .nav-tabs {
		float: none;
		width: 100%;
		margin-bottom: 20px;
	}

	.pricing-tab-content-block .wt-tabs.vertical .tab-content {
		padding-left: 0px !important;
		margin-left: 0px;
	}

	.pricing-tab-content-block .tab-pane {
		padding-left: 0px !important;
	}
}

@media only screen and (max-width:575px) {
	.pricing-tab-content-block .wt-tabs .nav-tabs>li>a {
		font-size: 13px;
		padding: 14px 10px 14px 10px;
	}
}

@media only screen and (max-width:400px) {
	.pricing-tab-content-block .wt-tabs .nav-tabs>li>a {
		padding: 10px;
		font-size: 12px;
	}
}

.pricing-tab-content-block .wt-tabs.bg-tabs .nav-tabs>li>a {
	background-color: #fff;
	border: 1px solid #ddd;
	margin-right: -1px;
}

.pricing-tab-content-block .wt-tabs.bg-tabs .nav-tabs>li:hover>a {
	background-color: #ec5598;
	color: #fff;
}

.pricing-tab-content-block .wt-tabs.vertical .nav-tabs li a.active {
	background-color: #ec5598;
	color: #fff;
	position: relative;

}

.pricing-tab-content-block .wt-tabs.vertical .nav-tabs li a.active:before {
	content: "";
	font-family: "FontAwesome";
	font-size: 24px;
	position: absolute;
	right: 20px;
	top: 13px;
}

@media only screen and (max-width:575px) {
	.pricing-tab-content-block .wt-tabs.vertical .nav-tabs li a.active:before {
		right: 15px;
		top: 5px;
	}
}

@media only screen and (max-width:400px) {
	.pricing-tab-content-block .wt-tabs.vertical .nav-tabs li a.active:before {
		font-size: 18px;
		right: 15px;
		top: 6px;
		transform: rotate(90deg);
	}
}


@media only screen and (max-width:991px) {
	.pricing-tab-content-block .wt-tabs.vertical .tab-content {
		padding-left: 0px;
	}
}

@media only screen and (max-width:768px) {
	.wt-tabs.vertical .tab-pane {
		padding: 10px 0 10px 20px;
	}
}

@media only screen and (max-width:720px) {
	.pricing-tab-content-block .section-content {
		padding-top: 10px;
	}

	.pricing-tab-content-block .wt-tabs.vertical .tab-pane {
		padding: 0px;
	}

	.pricing-tab-content-block .wt-tabs.vertical .tab-content {
		padding-left: 0px;
	}

	.pricing-tab-content-block .wt-tabs.vertical .nav-tabs {
		float: none;
		width: 100%;
		margin-bottom: 15px;
		display: flex;
		flex-wrap: wrap;
	}

	.pricing-tab-content-block .wt-tabs.vertical .nav-tabs li {
		width: 50%;
	}
}

/*======================================
52.CIRCLE EFFECT
======================================*/
.circle-line-effect {
	position: relative;
	z-index: 1
}

.circle-line-effect:after,
.circle-line-effect:before {
	position: absolute;
	content: "";
	z-index: -1;
	transition: all 1s ease;
}

.radius.circle-line-effect:after,
.radius.circle-line-effect:before {
	border-radius: 100%;
}


.circle-line-effect:after {
	border: 4px solid rgba(0, 0, 0, 0.0);
	width: 130%;
	height: 130%;
	left: -15%;
	top: -15%;
}

.circle-line-effect:before {
	border: 2px solid rgba(0, 0, 0, 0.0);
	width: 150%;
	height: 150%;
	left: -25%;
	top: -25%;

}

.circle-line-effect:after,
.circle-line-effect:before {
	transition: all 0.3s ease;
}

.wt-icon-box-wraper:hover .circle-line-effect:after {
	border: 3px solid #4B3941;
	width: 120%;
	height: 120%;
	left: -10%;
	top: -10%;
	opacity: 0.2;
}

.wt-icon-box-wraper:hover .circle-line-effect:before {
	border: 2px solid #4B3941;
	width: 140%;
	height: 140%;
	left: -20%;
	top: -20%;
	opacity: 0.1;
}

/*======================================
53. Background Effects
======================================*/
.bg-center {
	background-position: center;
}

.bg-top-left {
	background-position: top left;
}

.bg-top-right {
	background-position: top right;
}

.bg-top-center {
	background-position: top center;
}

.bg-bottom-left {
	background-position: bottom left;
}

.bg-bottom-right {
	background-position: bottom right;
}

.bg-bottom-center {
	background-position: bottom center;
}

.bg-left-center {
	background-position: left;
}

.bg-right-center {
	background-position: left;
}

.bg-auto {
	background-size: auto;
}

.bg-contain {
	background-size: contain;
}

.bg-cover {
	background-size: cover;
}

.bg-full-width {
	background-size: 100% auto;
}

.bg-full-height {
	background-size: auto 100%;
}

.bg-repeat {
	background-repeat: repeat;
}

.bg-no-repeat {
	background-repeat: no-repeat;
}

.bg-repeat-x {
	background-repeat: repeat-x;
}

.bg-repeat-y {
	background-repeat: repeat-y;
}

.bg-fixed {
	background-attachment: fixed;
}

.bg-scroll {
	background-attachment: scroll;
}


/*======================================
54. shopping-table
======================================*/
.shopping-table .cf th {
	padding: 15px 10px !important;
	color: #fff;
}

/*======================================
55. shopping-table
======================================*/

.your-order-list ul {
	list-style: none;
}

.your-order-list ul li {
	padding: 25px 10px;
	clear: both;
	border-bottom: 1px solid #eee;
}


/*======================================
47. ON SHOW SLIDER
======================================*/
/*.on-show-slider{
	position:relative;
	cursor:pointer;
}
.on-show-slider #sync2{
	position:absolute;
	bottom:0px;
	background-color:#fff;
	margin:1%;
	width:98%;
}
.on-show-slider #sync2 .owl-item img{
	padding:5px;
}
.on-show-slider #sync1 .owl-nav,
.on-show-slider #sync2{
	opacity:1;
}

.on-show-slider:hover #sync1 .owl-nav,
.on-show-slider:hover #sync2{
	opacity:1;
}*/

.on-show-slider {
	cursor: pointer;
}


.on-show-slider #sync2 .owl-item.current {
	position: relative;
	z-index: 1;
}

.on-show-slider #sync2 .owl-item.current:after {
	position: absolute;
	content: "";
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	border: 2px solid #cccccc;
	z-index: 2;

}

/*======================================
48. SLIDER DOTS
======================================*/
.slider-dots .zeus .tp-bullet {
	border: none;
	background-color: #4B3941;

}

.slider-dots .tp-bullet.selected {
	background-color: #EC5598;
}

.slider-dots .zeus .tp-bullet::after {
	background-color: #EC5598;
}

/*======================================
49. Wt Our Team
======================================*/
.wt-our-team .wt-team-media img {
	width: 100%;
}

/*======================================
50. Gradient
======================================*/
.gradi-green {
	background: -moz-linear-gradient(top, rgba(229, 229, 229, 0) 0%, rgba(129, 211, 222, 0) 30%, rgba(0, 188, 212, 1) 69%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(229, 229, 229, 0) 0%, rgba(129, 211, 222, 0) 30%, rgba(0, 188, 212, 1) 69%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(229, 229, 229, 0) 0%, rgba(129, 211, 222, 0) 30%, rgba(0, 188, 212, 1) 69%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00e5e5e5', endColorstr='#00bcd4', GradientType=0);
	/* IE6-9 */
}

.gradi-purple {
	background: -moz-linear-gradient(top, rgba(229, 229, 229, 0) 0%, rgba(176, 141, 196, 0) 30%, rgba(106, 27, 154, 1) 69%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(229, 229, 229, 0) 0%, rgba(176, 141, 196, 0) 30%, rgba(106, 27, 154, 1) 69%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(229, 229, 229, 0) 0%, rgba(176, 141, 196, 0) 30%, rgba(106, 27, 154, 1) 69%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00e5e5e5', endColorstr='#6a1b9a', GradientType=0);
	/* IE6-9 */
}

.gradi-pink {
	background: -moz-linear-gradient(top, rgba(229, 229, 229, 0) 0%, rgba(232, 166, 196, 0) 30%, rgba(236, 85, 152, 1) 69%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(229, 229, 229, 0) 0%, rgba(232, 166, 196, 0) 30%, rgba(236, 85, 152, 1) 69%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(229, 229, 229, 0) 0%, rgba(232, 166, 196, 0) 30%, rgba(236, 85, 152, 1) 69%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00e5e5e5', endColorstr='#ec5598', GradientType=0);
	/* IE6-9 */
}


.primary-gradi {
	background: -moz-linear-gradient(top, rgba(229, 229, 229, 0) 0%, rgba(176, 141, 196, 0) 30%, rgba(106, 27, 154, 1) 69%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(229, 229, 229, 0) 0%, rgba(176, 141, 196, 0) 30%, rgba(106, 27, 154, 1) 69%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(229, 229, 229, 0) 0%, rgba(176, 141, 196, 0) 30%, rgba(106, 27, 154, 1) 69%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00e5e5e5', endColorstr='#6a1b9a', GradientType=0);
	/* IE6-9 */
}

/*======================================
50. Form BG
======================================*/
.form-transparent .form-control {
	background-color: transparent;
	color: #fff;
	border: 0px;
	border-bottom: 2px solid #fff;
}

.form-transparent .form-control::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #fff;
	font-weight: bold;
}

.form-transparent .form-control::-moz-placeholder {
	/* Firefox 19+ */
	color: #fff;
	font-weight: bold;
}

.form-transparent .form-control:-ms-input-placeholder {
	/* IE 10+ */
	color: #fff;
	font-weight: bold;
}

.form-transparent .form-control:-moz-placeholder {
	/* Firefox 18- */
	color: #fff;
	font-weight: bold;
}

/*======================================
51. Form BG
======================================*/
.circle-block-outer .wt-icon-box-wraper {
	transition: all 0.5s ease-out;
}

.circle-block-outer .wt-icon-box-wraper:hover {
	background-color: #fff;
	cursor: pointer;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
	transition: all 0.5s ease-out;
}

.circle-block-outer .nav-link {
	color: #777;
}

.circle-block-outer .nav-link:hover {
	color: #777;
}

/*======================================
52. Opening Block
======================================*/
.opening-block {
	width: 560px;
	border-left: 0px;
	float: right;
	position: relative;
}

.opening-block:after,
.opening-block:before {
	content: "";
	position: absolute;
	left: 0px;
	width: 5px;
	height: 70px;
	background-color: #A76AAB;
}

.opening-block:after {
	top: 0px;
}

.opening-block:before {
	bottom: 0px;
}

.opening-block p {
	font-size: 18px;
}

.opening-block ul li {
	font-style: italic;
	font-size: 16px;
}

.opening-block ul {
	width: 300px;
	float: right;
	color: #4a424b;
	font-style: italic;
}

.opening-block .opening-date {
	width: 120px;
	display: inline-block;
}

.opening-block .opening-time {
	display: inline-block;
}

a.book-now-btn {
	position: absolute;
	left: -66px;
	top: 45.5%;
	transform: rotate(-90deg);
	color: #fff;
}

a.book-now-btn:hover,
a.book-now-btn:focus,
a.book-now-btn:active {
	background-color: #1a1a1a;
	color: #fff;
}

.left-larg-pic {
	position: absolute;
	left: 0px;
	bottom: 0px;
	width: 35%;
}

@media only screen and (max-width:1200px) {
	.opening-block {
		width: 100%;
	}
}

@media only screen and (max-width:991px) {
	.circle-block-outer .wt-icon-box-wraper.right {
		float: none;
		text-align: center;
	}

	.circle-block-outer .wt-icon-box-wraper.right [class*="icon-"] {
		float: none;
		text-align: center;
	}

	.circle-block-outer .wt-icon-box-wraper.left {
		float: none;
		text-align: center;
	}

	.circle-block-outer .wt-icon-box-wraper.left [class*="icon-"] {
		float: none;
		text-align: center;
	}

	.circle-block-outer .wt-icon-box-wraper.left.m-l30 {
		margin-left: 0px;
	}

	.circle-block-outer .wt-icon-box-wraper.right.m-r30 {
		margin-right: 0px;
	}

	.circle-block-outer .circle-content-pic {
		width: 50%;
		margin: 0px auto;
	}

	.contact-home4-right .text-right {
		text-align: center;
	}

	.opening-block {
		width: 94%;
		margin-bottom: 50px;
	}
}

@media only screen and (max-width:767px) {
	.opening-block ul {
		width: 100%;
	}
}

@media only screen and (max-width:420px) {
	.left-larg-pic {
		width: 60%;
		margin: 0px auto;
	}

	.about4-content {
		text-align: center;
	}

	.about4-content div.text-right {
		text-align: center;
	}

	.special-offer-block2 .awesome-counter.text-right {
		text-align: center;
	}

	.special-offer-block2 .awesome-counter.text-right .font-60 {
		font-size: 32px;
		line-height: normal;
	}

	.special-offer-block2 .pull-right.counter-small {
		float: none !important;
	}

	.special-offer-block2 .list-inline>li .wt-icon-box-wraper.left [class*="icon-"] {
		float: none !important;
	}
}

.counter-small-1 ul {
	padding-left: 0;
	margin-left: -5px;
	list-style: none;
}

.counter-small-1 ul>li {
	display: inline-block;
	padding-right: 5px;
	padding-left: 5px;
}




.header-nav {
	position: relative;
	padding: 0px;
	z-index: 7;
	display: flex !important;
	justify-content: end;
}

/*--------------------------------------
	Header Style 1
--------------------------------------*/
.header-style-1 .wt-topbar-right {
	display: flex;
	justify-content: space-between;
}

.header-style-1.twm-nav-transparent .main-bar.bg-white {
	background-color: rgba(255, 255, 255, 0.6) !important;
	position: absolute;
}

.header-style-1 .header-nav .nav {
	float: right;
	display: flex;
	justify-content: flex-end;
	flex-direction: inherit;
}

.header-style-1 .wt-topbar-right {
	display: flex;
	justify-content: space-between;
}

@media only screen and (max-width:767px) {
	.header-style-1 .wt-topbar-right {
		display: block;
	}

	.header-style-1 .wt-topbar-right .social-bx {
		float: none;
		margin: 10px 0px 0px 0px;
	}
}

.header-style-1 .social-bx li {
	padding-left: 2px;
	padding-right: 2px;
	display: inline-block;
}

.header-style-1 .social-bx li a {
	background-color: rgba(0, 0, 0, 0.08);
}

.header-style-1 .main-bar .container {
	display: flex;
	justify-content: space-between;
	position: relative;
}

.header-style-1 .top-bar .row {
	display: flex;
	justify-content: space-between;
}

.header-style-1 .header-nav .nav {
	float: right;
	display: flex;
	justify-content: flex-end;
	flex-direction: inherit;
}

.header-style-1 .header-nav .nav>li>a {
	display: block;
}

.header-style-1 .header-middle .container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header-style-1 .navbar-toggler .icon-bar {
	background: #010e2a;
	height: 3px;
	border-radius: 0;
	display: block;
	width: 22px;
}

.header-style-1 .navbar-toggler .icon-bar+.icon-bar {
	margin-top: 4px;
}

.header-style-1 .header-nav .has-child.nav-active>a+.submenu-toogle.fa.fa-angle-right:before {
	content: "\f107";
}

.header-style-1 .has-child .submenu-toogle {
	color: #000;
	font-size: 14px;
}

.header-style-1 .extra-nav {
	display: flex;
	align-items: center;
	position: relative;
	z-index: 9;
	padding: 0px;
}

.header-style-1 .extra-nav .extra-cell {
	display: block;
	margin: 0px 5px;
}

.header-style-1 .extra-nav .extra-cell:last-child {
	margin-right: 0px;
}

.header-style-1 .extra-nav .extra-cell .link-inner {
	display: flex;
}

.header-style-1 .navbar-toggler {
	display: none;
}

.header-style-1 .header-nav .nav>li .sub-menu li .fa {
	background-color: transparent;
	padding: 0px 10px 0px 0px;
	z-index: -1;
}

.header-style-1 .header-nav .nav>li .sub-menu li>a:hover+.fa {
	z-index: -1;
}

.header-style-1 .wt-cart.dropdown-toggle::after {
	display: none;
}

.header-style-1 .cart-dropdown-item-wraper {
	top: 17px !important;
}

.header-style-1 .site-search-btn {
	display: block;
}

/*Mobile Navigation*/
/*Header style 1 mobile navigation*/
@media only screen and (max-width: 991px) {
	.header-style-1 .header-nav .nav>li .sub-menu li .fa {
		z-index: 1;
		top: 4px;
		width: 100%;
		padding: 10px;
		text-align: right;
	}

	.header-style-1 .header-nav .nav>li .sub-menu li>a:hover+.fa {
		z-index: 0;
	}

	.header-style-1 .navbar-toggler {
		display: block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 12px;
	}

	.header-style-1.twm-nav-transparent .navbar-toggler {

		right: -33px;
	}

	.header-style-1 .extra-nav {
		margin-right: 40px;
	}

	.has-child .submenu-toogle {
		background: none;
	}

	.header-style-1 .header-nav .nav {
		float: none;
		margin: 0px;
		background: #fff;
		background-color: rgb(255, 255, 255);
		width: 100%;
		display: block;
		height: 100%;
	}

	.header-style-1 .header-nav .nav>li>a .fa {
		display: none;
	}

	.header-nav .nav>li .sub-menu li .fa {
		top: 1px;
	}

	.header-nav .nav>li .sub-menu li>.sub-menu {
		transition: none !important;
	}

	.header-style-1.mobile-sider-drawer-menu .header-nav {
		position: fixed;
		width: 250px !important;
		z-index: 999;
		height: 100vh !important;
		overflow-y: inherit !important;
		left: -250px;
		top: 0px;
		transition: all 0.5s linear;
		background-color: #fff;
	}

	.header-style-1.mobile-sider-drawer-menu .header-nav .nav>li:hover>.sub-menu {
		transition: none;
	}

	.header-style-1.mobile-sider-drawer-menu .header-nav .nav>li .sub-menu li:hover>.sub-menu {
		transition: none;
	}

	.header-style-1.mobile-sider-drawer-menu.active .header-nav {
		left: 0%;
	}

	.header-style-1.mobile-sider-drawer-menu .icon-bar {
		transition: all 0.5s linear;
		position: relative;
	}

	#mobile-side-drawer:focus {
		box-shadow: none;
	}

	.header-style-1.mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
		transform: rotate(45deg) translateX(3px) translateY(2px);
	}

	.header-style-1.mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
		transform: rotate(-45deg) translateX(3px) translateY(-2px);
	}

	.header-style-1.mobile-sider-drawer-menu .icon-bar.icon-bar-two {
		position: relative;
		right: 0%;
	}

	.header-style-1.mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
		position: absolute;
		right: 100px;
		opacity: 0;
	}

	.header-style-1.mobile-sider-drawer-menu .is-fixed .header-nav .nav {
		overflow: auto;
		max-height: 100%;
	}

	.header-style-1.mobile-sider-drawer-menu .header-fixed .header-nav .nav {
		overflow: auto;
		max-height: 100%;
	}


}



/*-------------------------------------
	12. header style 6 
-------------------------------------*/
.header-style-6 .top-bar .container {
	display: flex;
	justify-content: space-between;
}

.header-style-6 .cart-dropdown-item-wraper {
	top: 19px !important;
}

@media only screen and (max-width:575px) {
	.header-style-6 .top-bar .container {
		display: block;
	}

	.header-style-6.top-bar .container .social-bx {
		float: none;
		margin-left: 0px;
	}
}

.header-style-6 .is-fixed .header-nav .nav>li>a {
	padding: 20px 12px 20px 0px;
}

.header-style-6 .social-bx li {
	padding-left: 2px;
	padding-right: 2px;
	display: inline-block;
}

.header-style-6 .is-fixed .navbar-toggle {
	margin-top: 10px;
	margin-bottom: 10px;
}

.header-style-6 .header-middle .container {
	display: flex;
	justify-content: space-between;
}

.header-style-6 .header-middle .container .btn-col-last {
	padding-right: 0px;
}

.header-style-6 .main-bar .container {
	display: flex;
	justify-content: space-between;
	position: relative;
}

.header-style-6 .header-nav .nav>li .sub-menu {
	top: 62px;
}

.header-style-6 .header-nav .nav>li .mega-menu {
	top: 61px;
}

.header-style-6 .header-info>ul li {
	padding: 15px 0px 15px 10px;
}

.header-style-6 .has-child .submenu-toogle {
	background-color: transparent;
}

.header-style-6 .header-nav .nav>li .sub-menu .fa {
	z-index: -1;
	font-size: 14px;
	top: 6px;
}

.header-style-6 .has-child .submenu-toogle {
	background-color: transparent;
}

.header-style-6 .header-nav {
	justify-content: inherit;
	flex-basis: inherit;
}

.header-style-6.header-info>ul li {
	padding-right: 0px;
}

.header-style-6 .wt-cart.dropdown-toggle::after {
	display: none;
}

.header-style-6 .header-botton .header-nav .nav {
	display: flex;
	justify-content: space-between;
	flex-direction: inherit;
}

.header-style-6 .navbar-toggler .icon-bar {
	background: #fff;
	height: 3px;
	border-radius: 0;
	display: block;
	width: 22px;
}

.header-style-6 .navbar-toggler .icon-bar+.icon-bar {
	margin-top: 4px;
}

.header-style-6 .header-nav .has-child.nav-active>a+.submenu-toogle.fa.fa-angle-right:before {
	content: "\f107";
}

.header-style-6 .navbar-toggler {
	display: none;
}

.header-style-6 .header-nav .nav>li>a {
	display: block;
	padding-left: 0px;
}

/*Header style 6 mobile navigation*/
@media only screen and (max-width: 991px) {
	.header-style-6 .navbar-toggler {
		display: block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 12px;
	}

	.header-style-6 .extra-nav {
		margin-right: 0px;
	}

	.header-style-6 .header-nav .nav>li .sub-menu li .fa {
		z-index: 1;
		top: 4px;
		width: 100%;
		padding: 10px;
		text-align: right;
	}

	.header-style-6 .header-nav .nav>li .sub-menu li>a:hover+.fa {
		z-index: 0;
	}

	.header-style-6 .has-child .submenu-toogle {
		background-color: transparent;
		color: #000;
		font-size: 14px;
		width: 100%;
		text-align: right;
	}

	.header-style-6 .is-fixed .header-nav .nav>li>a {
		padding: 12px 12px;
	}

	.header-style-6 .extra-nav .extra-cell {
		padding-left: 0px;
		margin-left: 0px;
	}

	.header-style-6 .header-botton .extra-nav .site-search-btn {
		color: #fff;
		padding: 7px 15px 7px 0px;
	}

	.has-child .submenu-toogle {
		background: none;
	}

	.header-style-6 .header-botton .header-nav .nav {
		float: none;
		margin: 0px;
		background: #fff;
		background-color: rgb(255, 255, 255);
		width: 100%;
		display: block;
		height: 100%;
	}

	.header-style-6 .header-botton .header-nav .nav>li>a .fa {
		display: none;
	}

	.header-style-6 .header-botton .header-nav .nav>li .sub-menu li .fa {
		top: 1px;
	}

	.header-style-6 .header-botton .header-nav .nav>li .sub-menu li>.sub-menu {
		transition: none !important;
	}

	.header-style-6.mobile-sider-drawer-menu .header-nav {
		position: fixed;
		width: 250px !important;
		z-index: 999;
		height: 100vh !important;
		overflow-y: inherit !important;
		left: -250px;
		top: 0px;
		transition: all 0.5s linear;
		background-color: #fff;
	}

	.header-style-6.mobile-sider-drawer-menu .header-nav .nav>li:hover>.sub-menu {
		transition: none;
	}

	.header-style-6.mobile-sider-drawer-menu .header-nav .nav>li .sub-menu li:hover>.sub-menu {
		transition: none;
	}

	.header-style-6.mobile-sider-drawer-menu.active .header-nav {
		left: 0%;
	}

	.header-style-6.mobile-sider-drawer-menu .icon-bar {
		transition: all 0.5s linear;
		position: relative;
	}

	#mobile-side-drawer:focus {
		box-shadow: none;
	}

	.header-style-6.mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
		transform: rotate(45deg) translateX(3px) translateY(2px);
	}

	.header-style-6.mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
		transform: rotate(-45deg) translateX(3px) translateY(-2px);
	}

	.header-style-6.mobile-sider-drawer-menu .icon-bar.icon-bar-two {
		position: relative;
		right: 0%;
	}

	.header-style-6.mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
		position: absolute;
		right: 100px;
		opacity: 0;
	}

	.header-style-6.mobile-sider-drawer-menu .is-fixed .header-nav .nav {
		overflow: auto;
		max-height: 100%;
	}

	.header-style-6.mobile-sider-drawer-menu .header-fixed .header-nav .nav {
		overflow: auto;
		max-height: 100%;
	}


}

@media only screen and (max-width:991px) {
	.header-style-6 .header-nav .nav>li>a {
		padding-left: 15px;
	}
}




/*-------------------------------------
	8. header style 3 (header transperent)
-------------------------------------*/

.header-style-3 .container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
}

.header-style-3 .top-bar .container {
	display: block;
	justify-content: space-between;
	position: relative;
}


.header-style-3 .wt-topbar-right {
	display: flex;
	justify-content: space-between;
}

.header-style-3 .social-bx li {
	padding-left: 2px;
	padding-right: 2px;
	display: inline-block;
}

.header-style-3 .social-bx li a {
	background-color: rgba(0, 0, 0, 0.08);
}

.header-style-3 .extra-nav .extra-cell .link-inner {
	display: flex;
}

.header-style-3 .container .header-nav .nav {
	float: none;
	display: flex;
	flex-direction: inherit;
	justify-content: space-between;
}

.header-style-3 .extra-nav {
	display: flex;
	align-items: center;
	position: relative;
	z-index: 9;
	padding: 0px;
}

.header-style-3 .extra-nav .extra-cell {
	display: block;
	margin: 0px 5px;
}

.header-style-3 .main-bar {
	position: absolute;
	background: none;
}

.header-style-3 .header-nav .nav>li>a {
	color: #fff;
}

@media only screen and (max-width:991px) {
	.header-style-3 .header-nav .nav>li>a {
		color: #777777;
	}

	.header-style-3 .container .header-nav .nav {
		float: none;
		display: block;
	}
}

.header-style-3 .navbar-toggle .icon-bar {
	background: #FFF;
}

.header-style-3 .site-search-btn {
	margin-right: 10px;
	display: block;
}

.header-style-3 .site-search-btn,
.header-style-3 .wt-cart .woo-cart-total,
.header-style-3 .wt-cart .woo-cart-count {
	color: #fff;
}

.header-style-3 .wt-cart .woo-cart-count,
.header-style-3 .wt-cart .woo-cart-count::before {
	border-color: #fff;
}

/* header 3 fixed */
.header-style-3 .is-fixed .main-bar {
	position: fixed;
}

.header-style-3 .is-fixed.color-fill .main-bar {
	background: #1a1a1a;
}

.header-style-3 .header-nav .nav>li .sub-menu {
	top: 70px;
}

.header-style-3 .header-nav .nav>li .mega-menu {
	top: 70px;
	transition: none;
}

.header-style-3 .header-nav .nav>li .sub-menu li .fa {
	top: 4px;
	font-size: 16px;
	background-color: transparent;
	z-index: -1;
}

.wt-cart.dropdown-toggle::after {
	display: none;
}

.header-style-3 .navbar-toggler .icon-bar {
	background: #fff;
	height: 3px;
	border-radius: 0;
	display: block;
	width: 22px;
}

.header-style-3 .navbar-toggler .icon-bar+.icon-bar {
	margin-top: 4px;
}

.header-style-3 .header-nav .has-child.nav-active>a+.submenu-toogle.fa.fa-angle-right:before {
	content: "\f107";
}

.header-style-3 .navbar-toggler {
	display: none;
}

.header-style-3 .header-nav .nav>li>a {
	display: block;
}

/*Header style 3 mobile navigation*/
@media only screen and (max-width: 991px) {
	.header-style-3 .navbar-toggler {
		display: block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 12px;
	}

	.header-style-3 .extra-nav {
		margin-right: 40px;
	}

	.header-style-3 .header-nav .nav>li .sub-menu li .fa {
		z-index: 1;
		top: 4px;
		width: 100%;
		padding: 10px;
		text-align: right;
	}

	.header-style-3 .header-nav .nav>li .sub-menu li>a:hover+.fa {
		z-index: 0;
	}

	.header-style-3 .has-child .submenu-toogle {
		background-color: transparent;
		color: #000;
		font-size: 14px;
		width: 100%;
		text-align: right;
	}

	.header-style-3 .is-fixed .header-nav .nav>li>a {
		padding: 12px 15px;
	}

	.header-style-3 .extra-nav .extra-cell {
		padding-left: 0px;
		margin-left: 0px;
	}

	.header-style-3 .header-botton .extra-nav .site-search-btn {
		color: #fff;
		padding: 7px 15px 7px 0px;
	}

	.has-child .submenu-toogle {
		background: none;
	}

	.header-style-3 .header-botton .header-nav .nav {
		float: none;
		margin: 0px;
		background: #fff;
		background-color: rgb(255, 255, 255);
		width: 100%;
		display: block;
		height: 100%;
	}

	.header-style-3 .header-botton .header-nav .nav>li>a .fa {
		display: none;
	}

	.header-style-3 .header-botton .header-nav .nav>li .sub-menu li .fa {
		top: 1px;
	}

	.header-style-3 .header-botton .header-nav .nav>li .sub-menu li>.sub-menu {
		transition: none !important;
	}

	.header-style-3.mobile-sider-drawer-menu .header-nav {
		position: fixed;
		width: 250px !important;
		z-index: 999;
		height: 100vh !important;
		overflow-y: inherit !important;
		left: -250px;
		top: 0px;
		transition: all 0.5s linear;
		background-color: #fff;
	}

	.header-style-3 .header-nav .nav {
		float: none;
		margin: 0px;
		background: #fff;
		background-color: rgb(255, 255, 255);
		width: 100%;
		display: block;
		height: 100%;
	}

	.header-style-3.mobile-sider-drawer-menu .header-nav .nav>li:hover>.sub-menu {
		transition: none;
	}

	.header-style-3.mobile-sider-drawer-menu .header-nav .nav>li .sub-menu li:hover>.sub-menu {
		transition: none;
	}

	.header-style-3.mobile-sider-drawer-menu.active .header-nav {
		left: 0%;
		align-items: normal;
	}

	.header-style-3.mobile-sider-drawer-menu .icon-bar {
		transition: all 0.5s linear;
		position: relative;
	}

	#mobile-side-drawer:focus {
		box-shadow: none;
	}

	.header-style-3.mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
		transform: rotate(45deg) translateX(3px) translateY(2px);
	}

	.header-style-3.mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
		transform: rotate(-45deg) translateX(3px) translateY(-2px);
	}

	.header-style-3.mobile-sider-drawer-menu .icon-bar.icon-bar-two {
		position: relative;
		right: 0%;
	}

	.header-style-3.mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
		position: absolute;
		right: 100px;
		opacity: 0;
	}

	.header-style-3.mobile-sider-drawer-menu .is-fixed .header-nav .nav {
		overflow: auto;
		max-height: 100%;
		display: block;
		width: 100%;
	}

	.header-style-3.mobile-sider-drawer-menu .header-fixed .header-nav .nav {
		overflow: auto;
		max-height: 100%;
	}

	.header-style-3 .header-nav .nav li {
		float: none;
		display: block;
		width: 100%;
	}

	.header-style-3 .header-nav .nav>li>a .fa {
		display: none;
	}


}

@media only screen and (max-width:575px) {
	.header-style-3 .top-bar .container {
		display: block;
	}

	.header-style-3 .top-bar .container .e-p-bx {
		display: block;
	}

	.header-style-3 .top-bar .container .wt-topbar-right {
		display: block;
	}

	.header-style-3 .top-bar .container .wt-topbar-right .social-bx {
		float: none;
		margin-left: 0px;
	}
}


/*-------------------------------------
	13. header style - 8 
-------------------------------------*/
.header-style-8 .top-bar .container {
	display: flex;
	justify-content: space-between;
}

.header-style-8 .cart-dropdown-item-wraper {
	top: 15px !important;
}

@media only screen and (max-width:575px) {
	.header-style-8 .top-bar .container {
		display: block;
	}

	.header-style-8 .top-bar .container .social-bx {
		float: none;
		margin-left: 0px;
	}
}

.header-style-8 .social-bx li {
	padding-left: 2px;
	padding-right: 2px;
	display: inline-block;
}

.header-style-8 .header-nav .nav>li .sub-menu {
	top: 60px;
}

.header-style-8 .header-nav .nav>li .mega-menu {
	top: 60px;
}

.header-style-8 .header-info>ul li {
	padding: 15px 0px 15px 10px;
}

.header-style-8 .has-child .submenu-toogle {
	background-color: transparent;
}

.header-style-8 .header-nav .nav>li .sub-menu .fa {
	z-index: -1;
	font-size: 14px;
	top: 6px;
}

.header-style-8 .has-child .submenu-toogle {
	background-color: transparent;
}

.header-style-8 .header-middle .container {
	display: flex;
	justify-content: space-between;
}

.header-style-8 .header-nav {
	justify-content: inherit;
	flex-basis: inherit;
}

.header-style-8.header-info>ul li {
	padding-right: 0px;
}

.header-style-8 .wt-cart.dropdown-toggle::after {
	display: none;
}

.header-style-8 .header-botton .header-nav .nav {
	display: flex;
	justify-content: space-between;
	flex-direction: inherit;
}

.header-style-8 .main-bar {
	max-width: 1140px;
	margin: 0 auto -60px;
}

.header-style-8 .is-fixed .header-nav .nav>li>a {
	padding: 20px 12px;
}

.header-style-8 .is-fixed .navbar-toggle {
	margin-top: 10px;
	margin-bottom: 10px;
	flex-direction: row-reverse;

}

.header-style-8 .header-botton .extra-nav {

	padding: 8px 0;
	display: flex;
	align-items: center;

}

.header-style-8 .main-bar .container {
	display: flex;
	justify-content: space-between;
	position: relative;
}

.header-style-8 .is-fixed .main-bar {
	margin: 0px -573px;
	left: 50%;
}

.header-style-8 .navbar-toggler .icon-bar {
	background: #fff;
	height: 3px;
	border-radius: 0;
	display: block;
	width: 22px;
}

.header-style-8 .navbar-toggler .icon-bar+.icon-bar {
	margin-top: 4px;
}

.header-style-8 .header-nav .has-child.nav-active>a+.submenu-toogle.fa.fa-angle-right:before {
	content: "\f107";
}

.header-style-8 .navbar-toggler {
	display: none;
}

.header-style-8 .header-nav .nav>li>a {
	display: block;
}

@media only screen and (max-width:1200px) {

	.header-style-8 .main-bar,
	.header-style-8 .is-fixed .main-bar {
		max-width: 100%;
		margin: 0;
		left: 0;
	}
}

/*Header style 8 mobile navigation*/
@media only screen and (max-width: 991px) {
	.header-style-8 .navbar-toggler {
		display: block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 12px;
	}

	.header-style-8 .extra-nav {
		margin-right: 0px;
	}

	.header-style-8 .header-nav .nav>li .sub-menu li .fa {
		z-index: 1;
		top: 4px;
		width: 100%;
		padding: 10px;
		text-align: right;
	}

	.header-style-8 .header-nav .nav>li .sub-menu li>a:hover+.fa {
		z-index: 0;
	}

	.header-style-8 .has-child .submenu-toogle {
		background-color: transparent;
		color: #000;
		font-size: 14px;
		width: 100%;
		text-align: right;
	}

	.header-style-8 .is-fixed .header-nav .nav>li>a {
		padding: 12px 12px;
	}

	.header-style-8 .extra-nav .extra-cell {
		padding-left: 0px;
		margin-left: 0px;
	}

	.header-style-8 .header-botton .extra-nav .site-search-btn {
		color: #fff;
		padding: 7px 15px 7px 0px;
	}

	.has-child .submenu-toogle {
		background: none;
	}

	.header-style-8 .header-botton .header-nav .nav {
		float: none;
		margin: 0px;
		background: #fff;
		background-color: rgb(255, 255, 255);
		width: 100%;
		display: block;
		height: 100%;
	}

	.header-style-8 .header-botton .header-nav .nav>li>a .fa {
		display: none;
	}

	.header-style-8 .header-botton .header-nav .nav>li .sub-menu li .fa {
		top: 1px;
	}

	.header-style-8 .header-botton .header-nav .nav>li .sub-menu li>.sub-menu {
		transition: none !important;
	}

	.header-style-8.mobile-sider-drawer-menu .header-nav {
		position: fixed;
		width: 250px !important;
		z-index: 999;
		height: 100vh !important;
		overflow-y: inherit !important;
		left: -250px;
		top: 0px;
		transition: all 0.5s linear;
		background-color: #fff;
	}

	.header-style-8.mobile-sider-drawer-menu .header-nav .nav>li:hover>.sub-menu {
		transition: none;
	}

	.header-style-8.mobile-sider-drawer-menu .header-nav .nav>li .sub-menu li:hover>.sub-menu {
		transition: none;
	}

	.header-style-8.mobile-sider-drawer-menu.active .header-nav {
		left: 0%;
	}

	.header-style-8.mobile-sider-drawer-menu .icon-bar {
		transition: all 0.5s linear;
		position: relative;
	}

	#mobile-side-drawer:focus {
		box-shadow: none;
	}

	.header-style-8.mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
		transform: rotate(45deg) translateX(3px) translateY(2px);
	}

	.header-style-8.mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
		transform: rotate(-45deg) translateX(3px) translateY(-2px);
	}

	.header-style-8.mobile-sider-drawer-menu .icon-bar.icon-bar-two {
		position: relative;
		right: 0%;
	}

	.header-style-8.mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
		position: absolute;
		right: 100px;
		opacity: 0;
	}

	.header-style-8.mobile-sider-drawer-menu .is-fixed .header-nav .nav {
		overflow: auto;
		max-height: 100%;
	}

	.header-style-8.mobile-sider-drawer-menu .header-fixed .header-nav .nav {
		overflow: auto;
		max-height: 100%;
	}


}

@media only screen and (max-width:480px) {
	.header-style-8 .logo-header {
		width: 100px;
		height: auto;
	}
}


/*-------------------------------------
	11. header style 5 (header black overlay)
-------------------------------------*/
.header-style-5 .main-bar {
	position: absolute;
	background-color: rgba(0, 0, 0, 0.6);
}

.header-style-5 .header-nav .nav>li>a {
	color: #fff;
}

@media only screen and (max-width:991px) {
	.header-style-5 .header-nav .nav>li>a {
		color: #777777;
	}
}

.header-style-5 .navbar-toggle .icon-bar {
	background: #FFF;
}

.header-style-5 .site-search-btn,
.header-style-5 .wt-cart .woo-cart-total,
.header-style-5 .wt-cart .woo-cart-count {
	color: #fff;
}

.header-style-5 .wt-cart .woo-cart-count,
.header-style-5 .wt-cart .woo-cart-count::before {
	border-color: #fff;
}

/* header 5 fixed */
.header-style-5 .is-fixed .main-bar {
	position: fixed;
	background-color: rgba(0, 0, 0, 0.9);
}


.header-style-5 .header-nav .nav {
	float: right;
	display: flex;
	justify-content: flex-end;
	flex-direction: inherit;
}

.header-style-5 .wt-topbar-right {
	display: flex;
	justify-content: end;
}

@media only screen and (max-width:575px) {
	.header-style-5 .wt-topbar-right {
		display: block;
	}

	.header-style-5 .wt-topbar-right .social-bx {
		float: none;
		margin-left: 0px;
	}
}

.header-style-5 .social-bx li {
	padding-left: 2px;
	padding-right: 2px;
	display: inline-block;
}

.header-style-5 .social-bx li a {
	background-color: rgba(0, 0, 0, 0.08);
}

.header-style-5 .main-bar .container {
	display: flex;
	justify-content: space-between;
	position: relative;
}

.header-style-5 .top-bar .row {
	display: flex;
	justify-content: space-between;
}

.header-style-5 .header-nav .nav {
	float: right;
	display: flex;
	justify-content: flex-end;
	flex-direction: inherit;
}

.header-style-5 .header-nav .nav>li>a {
	display: block;
}

.header-style-5 .header-middle .container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header-style-5 .navbar-toggler .icon-bar {
	background: #fff;
	height: 3px;
	border-radius: 0;
	display: block;
	width: 22px;
}

.header-style-5 .navbar-toggler .icon-bar+.icon-bar {
	margin-top: 4px;
}

.header-style-5 .header-nav .has-child.nav-active>a+.submenu-toogle.fa.fa-angle-right:before {
	content: "\f107";
}

.header-style-5 .has-child .submenu-toogle {
	color: #000;
	font-size: 14px;
}

.header-style-5 .extra-nav {
	display: flex;
	align-items: center;
	position: relative;
	z-index: 9;
	padding: 0px;
}

.header-style-5 .extra-nav .extra-cell {
	display: block;
	margin: 0px 5px;
}

.header-style-5 .extra-nav .extra-cell:last-child {
	margin-right: 0px;
}

.header-style-5 .extra-nav .extra-cell .link-inner {
	display: flex;
}

.header-style-5 .navbar-toggler {
	display: none;
}

.header-style-5 .header-nav .nav>li .sub-menu li .fa {
	background-color: transparent;
	padding: 0px 10px 0px 0px;
	z-index: -1;
}

.header-style-5 .header-nav .nav>li .sub-menu li>a:hover+.fa {
	z-index: -1;
}

.header-style-5 .wt-cart.dropdown-toggle::after {
	display: none;
}

.header-style-5 .cart-dropdown-item-wraper {
	top: 17px !important;
}

.header-style-5 .site-search-btn {
	display: block;
}

/*Mobile Navigation*/
/*Header style 1 mobile navigation*/
@media only screen and (max-width: 991px) {
	.header-style-5 .navbar-toggler {
		display: block;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 12px;
	}

	.header-style-5 .extra-nav {
		margin-right: 40px;
	}

	.header-style-5 .header-nav .nav>li .sub-menu li .fa {
		z-index: 1;
		top: 4px;
		width: 100%;
		padding: 10px;
		text-align: right;
	}

	.header-style-5 .header-nav .nav>li .sub-menu li>a:hover+.fa {
		z-index: 0;
	}

	.has-child .submenu-toogle {
		background: none;
	}

	.header-style-5 .header-nav .nav {
		float: none;
		margin: 0px;
		background: #fff;
		background-color: rgb(255, 255, 255);
		width: 100%;
		display: block;
		height: 100%;
	}

	.header-style-5 .header-nav .nav>li>a .fa {
		display: none;
	}

	.header-nav .nav>li .sub-menu li .fa {
		top: 1px;
	}

	.header-nav .nav>li .sub-menu li>.sub-menu {
		transition: none !important;
	}

	.header-style-5.mobile-sider-drawer-menu .header-nav {
		position: fixed;
		width: 250px !important;
		z-index: 999;
		height: 100vh !important;
		overflow-y: inherit !important;
		left: -250px;
		top: 0px;
		transition: all 0.5s linear;
		background-color: #fff;
	}

	.header-style-5.mobile-sider-drawer-menu .header-nav .nav>li:hover>.sub-menu {
		transition: none;
	}

	.header-style-5.mobile-sider-drawer-menu .header-nav .nav>li .sub-menu li:hover>.sub-menu {
		transition: none;
	}

	.header-style-5.mobile-sider-drawer-menu.active .header-nav {
		left: 0%;
	}

	.header-style-5.mobile-sider-drawer-menu .icon-bar {
		transition: all 0.5s linear;
		position: relative;
	}

	#mobile-side-drawer:focus {
		box-shadow: none;
	}

	.header-style-5.mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
		transform: rotate(45deg) translateX(3px) translateY(2px);
	}

	.header-style-5.mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
		transform: rotate(-45deg) translateX(3px) translateY(-2px);
	}

	.header-style-5.mobile-sider-drawer-menu .icon-bar.icon-bar-two {
		position: relative;
		right: 0%;
	}

	.header-style-5.mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
		position: absolute;
		right: 100px;
		opacity: 0;
	}

	.header-style-5.mobile-sider-drawer-menu .is-fixed .header-nav .nav {
		overflow: auto;
		max-height: 100%;
	}

	.header-style-5.mobile-sider-drawer-menu .header-fixed .header-nav .nav {
		overflow: auto;
		max-height: 100%;
	}


}







/*New Css*/
.embed-responsive {
	position: relative;
	display: block;
	height: 0;
	padding: 0;
	padding-bottom: 0px;
	overflow: hidden;
}

.embed-responsive-16by9 {
	padding-bottom: 56.25%;
}

.embed-responsive .ytp-impression-link {
	opacity: 0;
}

.ytp-impression-link {
	opacity: 0;
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

/*Model Popup Close Btn*/
.button-model button.close {
	min-width: inherit;
	background: transparent;
	border: none;
	font-size: 26px;
	line-height: 26px;
	color: #fff;
}

.button-model .modal-header {
	border-radius: 0px;
}

.btn-default {
	color: #333;
	background-color: #fff;
	border-color: #ccc;
}


.alert-dismissible .btn-close {
	background: none;
	font-size: 24px;
	line-height: 24px;
	padding: 15px;
	text-align: center;
}




.wt-mid-page {
	position: relative;
	max-width: 770px;
	margin: 0px auto;
}

.twitterSummary a {
	word-break: break-all;
}


@media only screen and (max-width:1199px) {
	.pricingtable-5 [class*="col-"] {
		width: 33.33%;
	}
}

@media only screen and (max-width:991px) {
	.pricingtable-5 [class*="col-"] {
		width: 50%;
	}
}

@media only screen and (max-width:991px) {
	.section-full.p-t80 {
		padding-top: 50px;
	}

	.section-full.p-b50 {
		padding-bottom: 20px;
	}

	.section-full.p-t100 {
		padding-top: 50px;
	}

	.section-full.p-b70 {
		padding-bottom: 20px;
	}

	.pricingtable-highlight {
		margin: 0px;
	}

	.pricingtable-highlight .pricingtable-price {
		padding: 10px 10px;
	}

	.pricingtable-highlight .pricingtable-footer {
		padding: 20px 20px;
	}

	.pricing-table-style-4 .pricingtable-highlight .pricingtable-price {
		padding: 20px 10px;
	}

	.pricingtable-5 [class*="col-"] {
		width: 100%;
	}


	.block-reverse {
		display: flex;
		flex-direction: row-reverse;
	}

	.block-reverse-wrap .row.m-b50 {
		margin-bottom: 0px;
	}

	.counter.font-70 {
		font-size: 44px;
	}
}

@media only screen and (max-width:767px) {
	.block-reverse {
		display: flex;
		flex-direction: column-reverse;
	}

}

@media only screen and (max-width: 720px) {
	.font-icon-tabs-wrap.wt-tabs.border .tab-pane {
		border: 1px solid #ddd;
	}

	.font-icon-tabs-wrap .row-list.row span,
	.font-icon-tabs-wrap .row-list.row span.fa {
		text-align: left;
		margin: inherit;
	}

	.font-icon-tabs-wrap [class*="col-"] {
		padding: 10px 20px;
	}

	h1.font-60 {
		font-size: 32px;
		line-height: 32px;
	}

	h3.font-24 {
		font-size: 18px;
		line-height: 24px;
	}
}


@media only screen and (max-width: 575px) {
	.animated-block-w-scroll .wt-icon-box-md {
		float: none !important;
		margin-right: 0px;
		margin-bottom: 30px;
	}
}

@media only screen and (max-width: 420px) {
	.b-detail-social {
		display: block !important;
	}

	.b-detail-social .pull-left {
		float: none;
	}
}


.portfolio-wrap .wt-box img {
	width: 100%;
}

.conntact-home {
	display: flex;
	align-items: center;
	justify-content: space-between;
}



@media only screen and (max-width:1136px) {
	.tp-caption.sld-btn .button-lg {
		font-size: 12px !important;
		padding: 10px 16px;
	}
}

@media only screen and (max-width:991px) {
	.conntact-home .opening-block h2 {
		font-size: 24px;
	}

	.conntact-home .opening-block .font-60 {
		font-size: 28px;
	}
}

@media only screen and (max-width:767px) {
	.conntact-home .contact-home4-left {
		margin-top: 50px;
	}

	.conntact-home .contact-home4-right .opening-block {
		margin-bottom: 0px;
		width: 97%;
		padding: 25px;
	}
}

@media only screen and (max-width:575px) {
	.tp-caption.sld-btn .button-lg {
		font-size: 10px !important;
		padding: 8px 10px;
	}
}


.wt-team-full-media img {
	width: 100%;
}