@media only screen and (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1170px;
  }
}

body {
  color: #767676;
  background-color: #fff;
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  overflow-x: hidden;
}

a {
  color: #ec5598;
  outline: 0;
  text-decoration: none;
}

a:hover, a:focus {
  outline: 0;
  text-decoration: none;
}

a:active, a:hover, p a {
  color: #333;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  border-style: none;
}

hr {
  clear: both;
}

section, article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, summary {
  display: block;
}

.inline-block {
  display: inline-block;
}

.display-block {
  display: block;
}

address {
  font-style: italic;
}

table {
  width: 100%;
  background-color: #0000;
}

table thead th {
  color: #333;
}

table td {
  padding: 15px 10px;
}

@media only screen and (max-width: 360px) {
  table td {
    word-break: break-all;
  }
}

p, address, pre, hr, ul, ol, dl, dd, table {
  margin-bottom: 24px;
}

.overflow-hide {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.clear {
  clear: both;
}

.pull-none {
  float: none;
}

::-moz-selection {
  color: #fff;
  background: #ec5598;
}

::selection {
  color: #fff;
  background: #ec5598;
}

::-webkit-selection {
  color: #fff;
  background: #ec5598;
}

@media only screen and (max-width: 600px) {
  .col-xs-100pc {
    width: 100%;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 24px;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: #3d474a;
}

h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
  color: #ec5598;
}

h1 {
  color: #3f3e3e;
  font-size: 42px;
  font-weight: normal;
  line-height: 48px;
}

h2 {
  color: #3f3e3e;
  font-size: 36px;
  font-weight: normal;
  line-height: 42px;
}

h3 {
  color: #3f3e3e;
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: normal;
  line-height: 32px;
}

h4 {
  color: #3f3e3e;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: normal;
  line-height: 24px;
}

h5 {
  color: #3f3e3e;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
}

h6 {
  color: #3f3e3e;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
}

p {
  margin-bottom: 24px;
  line-height: 24px;
}

strong {
  font-weight: 600;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.v-align-t {
  vertical-align: top;
}

.v-align-m {
  vertical-align: middle;
}

.v-align-b {
  vertical-align: bottom;
}

.text-nowrap {
  white-space: nowrap;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.font-14 {
  font-size: 14px;
  line-height: 20px;
}

.font-16 {
  font-size: 16px;
  line-height: 22px;
}

.font-18 {
  font-size: 18px;
  line-height: 24px;
}

.font-20 {
  font-size: 20px;
  line-height: 26px;
}

.font-22 {
  font-size: 22px;
  line-height: 28px;
}

.font-24 {
  font-size: 24px;
  line-height: 30px;
}

.font-26 {
  font-size: 26px;
  line-height: 32px;
}

.font-50 {
  font-size: 50px;
  line-height: 56px;
}

.font-60 {
  font-size: 60px;
  line-height: 66px;
}

.font-70 {
  font-size: 70px;
  line-height: 76px;
}

.font-80 {
  font-size: 80 px;
  line-height: 86px;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}

dl, ul, ol {
  padding: 0;
  list-style-position: outside;
}

ul, ol {
  margin-bottom: 24px;
}

ul li, ol li {
  padding: 0;
}

dl {
  margin-bottom: 30px;
  margin-left: 0;
}

dl dd, .list-simple li {
  margin-bottom: 10px;
}

.list-simple li ul {
  margin-top: 10px;
  margin-left: 15px;
}

.list-simple.list-unstyled li ul {
  margin-top: 10px;
  margin-left: 30px;
}

.list-circle, .list-angle-right, .list-arrow, .list-check, .list-checked, .list-check-circle, .list-chevron-circle, .list-arrow-circle, .list-times-circle {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
}

.list-circle li, .list-angle-right li, .list-arrow li, .list-check li, .list-checked li, .list-check-circle li, .list-chevron-circle li, .list-arrow-circle li, .list-times-circle li {
  padding: 5px 5px 5px 20px;
  position: relative;
}

.list-circle li:before, .list-angle-right li:before, .list-arrow li:before, .list-check li:before, .list-checked li:before, .list-check-circle li:before, .list-chevron-circle li:before, .list-arrow-circle li:before, .list-times-circle li:before {
  color: #777;
  font-family: FontAwesome;
  font-size: 15px;
  display: block;
  position: absolute;
  top: 6px;
  left: 0;
}

.list-circle li:before {
  content: "";
  font-size: 10px;
  top: 10px;
}

.list-angle-right li:before {
  content: "";
}

.list-arrow li:before {
  content: "";
}

.list-check li:before {
  content: "";
}

.list-checked li:before {
  content: "";
}

.list-check-circle li:before {
  content: "";
}

.list-chevron-circle li:before {
  content: "";
}

.list-arrow-circle li:before {
  content: "";
}

.list-times-circle li:before {
  content: "";
}

ul.primary li:before {
  color: #ffbc13;
}

ul.secondry li:before {
  color: #77c04b;
}

ul.black li:before {
  color: #000;
}

ul.white li:before {
  color: #fff;
}

ul.orange li:before {
  color: #f60;
}

ul.green li:before {
  color: #0c0;
}

ul.red li:before {
  color: #ff3737;
}

dl.no-margin, ul.no-margin, ol.no-margin {
  list-style: none;
  margin: 0 !important;
}

ol.list-num-count {
  counter-reset: li;
  padding-left: 0;
}

ol.list-num-count > li {
  margin: 0 0 6px 30px;
  padding: 4px 8px;
  list-style: none;
  position: relative;
}

ol.list-num-count > li:before {
  content: counter(li, decimal);
  counter-increment: li;
  width: 28px;
  height: 28px;
  color: #fff;
  text-align: center;
  background: #ffbc13;
  border-radius: 100px;
  padding: 5px;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: -28px;
}

ol.list-num-count.lower-alpha > li:before {
  content: counter(li, lower-alpha);
}

ol.list-num-count.upper-alpha > li:before {
  content: counter(li, upper-alpha);
}

ol.list-num-count.list-outline > li:before {
  color: #787878;
  background-color: #0000;
  border: 1px solid #ec5598;
}

ol.list-num-count.list-outline-none > li:before {
  color: #787878;
  background-color: #0000;
}

.p-a0 {
  padding: 0;
}

.p-a5 {
  padding: 5px;
}

.p-a10 {
  padding: 10px;
}

.p-a15 {
  padding: 15px;
}

.p-a20 {
  padding: 20px;
}

.p-a25 {
  padding: 25px;
}

.p-a30 {
  padding: 30px;
}

.p-a40 {
  padding: 40px;
}

.p-a50 {
  padding: 50px;
}

.p-a60 {
  padding: 60px;
}

.p-a70 {
  padding: 70px;
}

.p-a80 {
  padding: 80px;
}

.p-a90 {
  padding: 90px;
}

.p-a100 {
  padding: 100px;
}

.p-a110 {
  padding: 110px;
}

.p-a120 {
  padding: 120px;
}

.p-a130 {
  padding: 130px;
}

.p-a140 {
  padding: 140px;
}

.p-a150 {
  padding: 150px;
}

.p-t0 {
  padding-top: 0;
}

.p-t5 {
  padding-top: 5px;
}

.p-t10 {
  padding-top: 10px;
}

.p-t15 {
  padding-top: 15px;
}

.p-t20 {
  padding-top: 20px;
}

.p-t30 {
  padding-top: 30px;
}

.p-t40 {
  padding-top: 40px;
}

.p-t50 {
  padding-top: 50px;
}

.p-t60 {
  padding-top: 60px;
}

.p-t70 {
  padding-top: 70px;
}

.p-t80 {
  padding-top: 80px;
}

.p-t90 {
  padding-top: 90px;
}

.p-t100 {
  padding-top: 100px;
}

.p-t110 {
  padding-top: 110px;
}

.p-t120 {
  padding-top: 120px;
}

.p-t130 {
  padding-top: 130px;
}

.p-t140 {
  padding-top: 140px;
}

.p-t150 {
  padding-top: 150px;
}

.p-b0 {
  padding-bottom: 0;
}

.p-b5 {
  padding-bottom: 5px;
}

.p-b10 {
  padding-bottom: 10px;
}

.p-b15 {
  padding-bottom: 15px;
}

.p-b20 {
  padding-bottom: 20px;
}

.p-b30 {
  padding-bottom: 30px;
}

.p-b40 {
  padding-bottom: 40px;
}

.p-b50 {
  padding-bottom: 50px;
}

.p-b60 {
  padding-bottom: 60px;
}

.p-b70 {
  padding-bottom: 70px;
}

.p-b80 {
  padding-bottom: 80px;
}

.p-b90 {
  padding-bottom: 90px;
}

.p-b100 {
  padding-bottom: 100px;
}

.p-b110 {
  padding-bottom: 110px;
}

.p-b120 {
  padding-bottom: 120px;
}

.p-b130 {
  padding-bottom: 130px;
}

.p-b140 {
  padding-bottom: 140px;
}

.p-b150 {
  padding-bottom: 150px;
}

.p-l0 {
  padding-left: 0;
}

.p-l5 {
  padding-left: 5px;
}

.p-l10 {
  padding-left: 10px;
}

.p-l15 {
  padding-left: 15px;
}

.p-l20 {
  padding-left: 20px;
}

.p-l30 {
  padding-left: 30px;
}

.p-l40 {
  padding-left: 40px;
}

.p-l50 {
  padding-left: 50px;
}

.p-l60 {
  padding-left: 60px;
}

.p-l70 {
  padding-left: 70px;
}

.p-l80 {
  padding-left: 80px;
}

.p-l90 {
  padding-left: 90px;
}

.p-l100 {
  padding-left: 100px;
}

.p-l110 {
  padding-left: 110px;
}

.p-l120 {
  padding-left: 120px;
}

.p-l130 {
  padding-left: 130px;
}

.p-l140 {
  padding-left: 140px;
}

.p-l150 {
  padding-left: 150px;
}

.p-r0 {
  padding-right: 0;
}

.p-r5 {
  padding-right: 5px;
}

.p-r10 {
  padding-right: 10px;
}

.p-r15 {
  padding-right: 15px;
}

.p-r20 {
  padding-right: 20px;
}

.p-r30 {
  padding-right: 30px;
}

.p-r40 {
  padding-right: 40px;
}

.p-r50 {
  padding-right: 50px;
}

.p-r60 {
  padding-right: 60px;
}

.p-r70 {
  padding-right: 70px;
}

.p-r80 {
  padding-right: 80px;
}

.p-r90 {
  padding-right: 90px;
}

.p-r100 {
  padding-right: 100px;
}

.p-r110 {
  padding-right: 110px;
}

.p-r120 {
  padding-right: 120px;
}

.p-r130 {
  padding-right: 130px;
}

.p-r140 {
  padding-right: 140px;
}

.p-r150 {
  padding-right: 150px;
}

.p-lr0 {
  padding-left: 0;
  padding-right: 0;
}

.p-lr5 {
  padding-left: 5px;
  padding-right: 5px;
}

.p-lr10 {
  padding-left: 10px;
  padding-right: 10px;
}

.p-lr15 {
  padding-left: 15px;
  padding-right: 15px;
}

.p-lr20 {
  padding-left: 20px;
  padding-right: 20px;
}

.p-lr30 {
  padding-left: 30px;
  padding-right: 30px;
}

.p-lr40 {
  padding-left: 40px;
  padding-right: 40px;
}

.p-lr50 {
  padding-left: 50px;
  padding-right: 50px;
}

.p-lr60 {
  padding-left: 60px;
  padding-right: 60px;
}

.p-lr70 {
  padding-left: 70px;
  padding-right: 70px;
}

.p-lr80 {
  padding-left: 80px;
  padding-right: 80px;
}

.p-lr90 {
  padding-left: 90px;
  padding-right: 90px;
}

.p-lr100 {
  padding-left: 100px;
  padding-right: 100px;
}

.p-lr120 {
  padding-left: 120px;
  padding-right: 120px;
}

.p-lr150 {
  padding-left: 150px;
  padding-right: 150px;
}

.p-tb0 {
  padding-top: 0;
  padding-bottom: 0;
}

.p-tb5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.p-tb10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.p-tb15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.p-tb20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.p-tb30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.p-tb40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.p-tb50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.p-tb60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.p-tb70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.p-tb80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.p-tb90 {
  padding-top: 90px;
  padding-bottom: 90px;
}

.p-tb100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.p-tb120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.p-tb150 {
  padding-top: 150px;
  padding-bottom: 150px;
}

.m-a-1 {
  margin: -1px;
}

.m-a0 {
  margin: 0;
}

.m-a5 {
  margin: 5px;
}

.m-a10 {
  margin: 10px;
}

.m-a15 {
  margin: 15px;
}

.m-a20 {
  margin: 20px;
}

.m-a30 {
  margin: 30px;
}

.m-a40 {
  margin: 40px;
}

.m-a50 {
  margin: 50px;
}

.m-a60 {
  margin: 60px;
}

.m-a70 {
  margin: 70px;
}

.m-a80 {
  margin: 80px;
}

.m-a90 {
  margin: 90px;
}

.m-a100 {
  margin: 100px;
}

.m-a110 {
  margin: 110px;
}

.m-a120 {
  margin: 120px;
}

.m-a130 {
  margin: 130px;
}

.m-a140 {
  margin: 140px;
}

.m-a150 {
  margin: 150px;
}

.m-t0 {
  margin-top: 0;
}

.m-t5 {
  margin-top: 5px;
}

.m-t10 {
  margin-top: 10px;
}

.m-t15 {
  margin-top: 15px;
}

.m-t20 {
  margin-top: 20px;
}

.m-t30 {
  margin-top: 30px;
}

.m-t40 {
  margin-top: 40px;
}

.m-t50 {
  margin-top: 50px;
}

.m-t60 {
  margin-top: 60px;
}

.m-t70 {
  margin-top: 70px;
}

.m-t80 {
  margin-top: 80px;
}

.m-t90 {
  margin-top: 90px;
}

.m-t100 {
  margin-top: 100px;
}

.m-t110 {
  margin-top: 110px;
}

.m-t120 {
  margin-top: 120px;
}

.m-t130 {
  margin-top: 130px;
}

.m-t140 {
  margin-top: 140px;
}

.m-t150 {
  margin-top: 150px;
}

.m-b0 {
  margin-bottom: 0;
}

.m-b5 {
  margin-bottom: 5px;
}

.m-b10 {
  margin-bottom: 10px;
}

.m-b15 {
  margin-bottom: 15px;
}

.m-b20 {
  margin-bottom: 20px;
}

.m-b30 {
  margin-bottom: 30px;
}

.m-b40 {
  margin-bottom: 40px;
}

.m-b50 {
  margin-bottom: 50px;
}

.m-b60 {
  margin-bottom: 60px;
}

.m-b70 {
  margin-bottom: 70px;
}

.m-b80 {
  margin-bottom: 80px;
}

.m-b90 {
  margin-bottom: 90px;
}

.m-b100 {
  margin-bottom: 100px;
}

.m-b110 {
  margin-bottom: 110px;
}

.m-b120 {
  margin-bottom: 120px;
}

.m-b130 {
  margin-bottom: 130px;
}

.m-b140 {
  margin-bottom: 140px;
}

.m-b150 {
  margin-bottom: 150px;
}

.m-l0 {
  margin-left: 0;
}

.m-l5 {
  margin-left: 5px;
}

.m-l10 {
  margin-left: 10px;
}

.m-l15 {
  margin-left: 15px;
}

.m-l20 {
  margin-left: 20px;
}

.m-l30 {
  margin-left: 30px;
}

.m-l40 {
  margin-left: 40px;
}

.m-l50 {
  margin-left: 50px;
}

.m-l60 {
  margin-left: 60px;
}

.m-l70 {
  margin-left: 70px;
}

.m-l80 {
  margin-left: 80px;
}

.m-l90 {
  margin-left: 90px;
}

.m-l100 {
  margin-left: 100px;
}

.m-l110 {
  margin-left: 110px;
}

.m-l120 {
  margin-left: 120px;
}

.m-l130 {
  margin-left: 130px;
}

.m-l140 {
  margin-left: 140px;
}

.m-l150 {
  margin-left: 150px;
}

.m-r0 {
  margin-right: 0;
}

.m-r5 {
  margin-right: 5px;
}

.m-r10 {
  margin-right: 10px;
}

.m-r15 {
  margin-right: 15px;
}

.m-r20 {
  margin-right: 20px;
}

.m-r30 {
  margin-right: 30px;
}

.m-r40 {
  margin-right: 40px;
}

.m-r50 {
  margin-right: 50px;
}

.m-r60 {
  margin-right: 60px;
}

.m-r70 {
  margin-right: 70px;
}

.m-r80 {
  margin-right: 80px;
}

.m-r90 {
  margin-right: 90px;
}

.m-r100 {
  margin-right: 100px;
}

.m-r110 {
  margin-right: 110px;
}

.m-r120 {
  margin-right: 120px;
}

.m-r130 {
  margin-right: 130px;
}

.m-r140 {
  margin-right: 140px;
}

.m-r150 {
  margin-right: 150px;
}

.m-lr0 {
  margin-left: 0;
  margin-right: 0;
}

.m-lr5 {
  margin-left: 5px;
  margin-right: 5px;
}

.m-lr10 {
  margin-left: 10px;
  margin-right: 10px;
}

.m-lr15 {
  margin-left: 15px;
  margin-right: 15px;
}

.m-lr20 {
  margin-left: 20px;
  margin-right: 20px;
}

.m-lr30 {
  margin-left: 30px;
  margin-right: 30px;
}

.m-lr40 {
  margin-left: 40px;
  margin-right: 40px;
}

.m-lr50 {
  margin-left: 50px;
  margin-right: 50px;
}

.m-lr60 {
  margin-left: 60px;
  margin-right: 60px;
}

.m-lr70 {
  margin-left: 70px;
  margin-right: 70px;
}

.m-lr80 {
  margin-left: 80px;
  margin-right: 80px;
}

.m-lr90 {
  margin-left: 90px;
  margin-right: 90px;
}

.m-lr100 {
  margin-left: 100px;
  margin-right: 100px;
}

.m-lr120 {
  margin-left: 120px;
  margin-right: 120px;
}

.m-lr150 {
  margin-left: 150px;
  margin-right: 150px;
}

.m-tb0 {
  margin-top: 0;
  margin-bottom: 0;
}

.m-tb5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.m-tb10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.m-tb15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.m-tb20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.m-tb30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.m-tb40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.m-tb50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.m-tb60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.m-tb70 {
  margin-top: 70px;
  margin-bottom: 70px;
}

.m-tb80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.m-tb90 {
  margin-top: 90px;
  margin-bottom: 90px;
}

.m-tb100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.m-tb120 {
  margin-top: 120px;
  margin-bottom: 120px;
}

.m-tb150 {
  margin-top: 150px;
  margin-bottom: 150px;
}

.no-col-gap [class*="col-xs-"], .no-col-gap [class*="col-sm-"], .no-col-gap [class*="col-md-"], .no-col-gap [class*="col-lg-"] {
  padding-left: 0;
  padding-right: 0;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.static {
  position: static;
}

.z-index1 {
  z-index: 1;
}

.z-index2 {
  z-index: 2;
}

.z-index3 {
  z-index: 3;
}

.z-index4 {
  z-index: 4;
}

.z-index5 {
  z-index: 5;
}

.z-index6 {
  z-index: 6;
}

.z-index7 {
  z-index: 7;
}

.z-index8 {
  z-index: 8;
}

.z-index9 {
  z-index: 9;
}

.z-index10 {
  z-index: 10;
}

.z-index100 {
  z-index: 100;
}

.z-index999 {
  z-index: 999;
}

.text-white {
  color: #fff;
}

.site-text-primary {
  color: #ffbc13;
}

.text-secondry {
  color: #1a1a1a;
}

.text-black {
  color: #000;
}

.text-gray {
  color: #f5f6f6;
}

.text-gray-dark {
  color: #d3d3d3;
}

.text-red {
  color: #99090a;
}

.text-green {
  color: #557131;
}

.text-yellow {
  color: #99910a;
}

.text-light-blue {
  color: #004f83;
}

.bg-transparent {
  background-color: #0000;
}

.site-site-bg-primary {
  background-color: #ec5598;
}

.bg-secondry {
  background-color: #4b3941;
}

.bg-white {
  background-color: #fff;
}

.bg-black {
  background-color: #000;
}

.bg-black-light {
  background-color: #212121;
}

.bg-gray {
  background-color: #f5f6f6;
}

.bg-gray-light {
  background-color: #fafafa;
}

.bg-gray-dark {
  background-color: #d3d3d3;
}

.bg-red {
  background-color: #fb5455;
}

.bg-green {
  background-color: #a5de5c;
}

.bg-yellow {
  background-color: #fff000;
}

.bg-dark-blue {
  background-color: #161b23;
}

.bg-light-blue {
  background-color: #3097db;
}

.bg-parallax {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

@media only screen and (max-width: 768px) {
  .bg-parallax {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center !important;
    background-attachment: fixed !important;
  }
}

.overlay-wraper {
  position: relative;
}

.overlay-main {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.opacity-01 {
  opacity: .1;
}

.opacity-02 {
  opacity: .2;
}

.opacity-03 {
  opacity: .3;
}

.opacity-04 {
  opacity: .4;
}

.opacity-05 {
  opacity: .5;
}

.opacity-06 {
  opacity: .6;
}

.opacity-07 {
  opacity: .7;
}

.opacity-08 {
  opacity: .8;
}

.opacity-09 {
  opacity: .9;
}

.overlay-light .overlay-main {
  opacity: .3;
}

.overlay-dark .overlay-main {
  opacity: .9;
}

.overlay-wraper > .container, .overlay-wraper > .container-fluid, .overlay-wraper > .wt-icon-box-wraper, .overlay-wraper > .wt-left-part, .overlay-wraper > .wt-right-part {
  z-index: 1;
  position: relative;
}

.wt-left-part, .wt-right-part {
  width: 560px;
}

.wt-left-part {
  float: right;
}

@media only screen and (max-width: 1200px) {
  .wt-left-part, .wt-right-part {
    width: 100%;
  }

  .wt-left-part {
    clear: both;
    float: none;
    padding-left: 30px;
  }

  .wt-right-part {
    padding-right: 30px;
  }
}

.overlay-bx {
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
}

.overlay-icon {
  width: 160px;
  height: 40px;
  margin: -20px -75px;
  list-style: none;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1 !important;
}

.overlay-icon a {
  color: #fff;
  border: 1px solid #fff;
  margin: 0 2px;
  padding: 0;
  display: inline-block;
}

.overlay-icon .wt-icon-box-xs {
  line-height: 40px;
}

.overlay-icon a:hover {
  color: #fff;
  background-color: #ffbc13;
  border: 1px solid #0000;
}

.overlay-bx:hover a > i, .wt-thum-bx:hover .overlay-bx a > i, .wt-box:hover .overlay-bx a > i {
  opacity: 1;
  visibility: visible;
  transition: all .5s ease-in-out;
}

.overlay-bx:hover, .wt-thum-bx:hover .overlay-bx, .wt-box:hover .overlay-bx, .ow-img:hover .overlay-bx, .ow-portfolio-img:hover .overlay-bx {
  opacity: 1;
  visibility: visible;
}

.overlay-text {
  width: 100%;
  color: #fff;
  padding: 20px;
  position: absolute;
  bottom: 20px;
  left: 0;
}

.wt-address-bx {
  text-align: left;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  padding: 20px 20px 20px 25px;
  font-size: 14px;
  font-weight: 600;
  display: table;
  position: relative;
}

.wt-address-bx .fa {
  font-size: 22px;
  position: absolute;
  top: 22px;
  left: 0;
}

.col-md-3 .overlay-text {
  padding: 5px;
}

.col-md-4 .overlay-text {
  padding: 20px;
}

.wt-address2-bx {
  background: #eaeaea;
  margin-bottom: 10px;
  padding: 5px 10px;
  display: table;
}

.rounded-bx, .wt-box, .wt-icon-box, .wt-icon-box-small, .wt-thum-bx, .wt-post-thum, .wt-box {
  position: relative;
}

.wt-box.no-margin {
  margin-bottom: 0;
}

.wt-icon-box-xld, .wt-icon-box-xl, .wt-icon-box-lg, .wt-icon-box-md, .wt-icon-box-sm, .wt-icon-box-xs {
  text-align: center;
  display: inline-block;
}

.wt-icon-box-xld {
  width: 180px;
  height: 180px;
}

.wt-icon-box-xl {
  width: 150px;
  height: 150px;
}

.wt-icon-box-lg {
  width: 120px;
  height: 120px;
}

.wt-icon-box-md {
  width: 100px;
  height: 100px;
}

.wt-icon-box-sm {
  width: 80px;
  height: 80px;
}

.wt-icon-box-xs {
  width: 40px;
  height: 40px;
}

.wt-icon-box-xld.radius, .wt-icon-box-xl.radius, .wt-icon-box-lg.radius, .wt-icon-box-md.radius, .wt-icon-box-sm.radius, .wt-icon-box-xs.radius {
  border-radius: 100%;
}

.wt-icon-box-xld i, .wt-icon-box-xl i, .wt-icon-box-lg i, .wt-icon-box-md i, .wt-icon-box-sm i, .wt-icon-box-xs i {
  vertical-align: middle;
}

.wt-icon-box-xld i {
  font-size: 100px;
}

.wt-icon-box-xl i {
  font-size: 80px;
}

.wt-icon-box-lg i {
  font-size: 60px;
}

.wt-icon-box-md i {
  font-size: 45px;
}

.wt-icon-box-sm i {
  font-size: 30px;
}

.wt-icon-box-xs i {
  font-size: 20px;
}

.wt-icon-box-xld img, .wt-icon-box-xl img, .wt-icon-box-lg img, .wt-icon-box-md img, .wt-icon-box-sm img, .wt-icon-box-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
}

.wt-icon-box-xld img {
  height: 90px;
}

.wt-icon-box-xl img {
  height: 80px;
}

.wt-icon-box-lg img {
  height: 55px;
}

.wt-icon-box-md img {
  height: 40px;
}

.wt-icon-box-sm img {
  height: 30px;
}

.wt-icon-box-xs img {
  height: 20px;
}

.bdr-white {
  border-color: #fff;
}

.bdr-black {
  border-color: #000;
}

.bdr-primary {
  border-color: #ec5598;
}

.bdr-gray {
  border-color: #ddd;
}

.bdr-gray-light {
  border-color: #eee;
}

.bdr-gray-dark {
  border-color: #333;
}

.bdr-1, .bdr-2, .bdr-3, .bdr-4, .bdr-5 {
  border-style: solid;
}

.bdr-dot-1, .bdr-dot-2, .bdr-dot-3, .bdr-dot-4, .bdr-dot-5 {
  border-style: dotted;
}

.bdr-dash-1, .bdr-dash-2, .bdr-dash-3, .bdr-dash-4, .bdr-dash-5 {
  border-style: dashed;
}

.bdr-1 {
  border-width: 1px;
}

.bdr-2 {
  border-width: 2px;
}

.bdr-3 {
  border-width: 3px;
}

.bdr-4 {
  border-width: 4px;
}

.bdr-5 {
  border-width: 5px;
}

.icon-xld, .icon-xl, .icon-lg, .icon-md, .icon-sm, .icon-xs {
  text-align: center;
  display: inline-block;
}

.icon-xld i, .icon-xld [class*="flaticon-"]:before, .icon-xld [class*="flaticon-"]:after {
  font-size: 100px;
}

.icon-xl i, .icon-xl [class*="flaticon-"]:before, .icon-xl [class*="flaticon-"]:after {
  font-size: 80px;
}

.icon-lg i, .icon-lg [class*="flaticon-"]:before, .icon-lg [class*="flaticon-"]:after {
  font-size: 60px;
}

.icon-md i, .icon-md [class*="flaticon-"]:before, .icon-md [class*="flaticon-"]:after {
  font-size: 45px;
}

.icon-sm i, .icon-sm [class*="flaticon-"]:before, .icon-sm [class*="flaticon-"]:after {
  font-size: 30px;
}

.icon-xs i, .icon-xs [class*="flaticon-"]:before, .icon-xs [class*="flaticon-"]:after {
  font-size: 20px;
}

.icon-xld i, .icon-xl i, .icon-lg i, .icon-md i, .icon-sm i, .icon-xs i {
  vertical-align: middle;
}

.icon-xld {
  width: 120px;
}

.icon-xld i {
  font-size: 100px;
}

.icon-xl {
  width: 100px;
}

.icon-xl i {
  font-size: 80px;
}

.icon-lg {
  width: 80px;
}

.icon-lg i {
  font-size: 60px;
}

.icon-md {
  width: 60px;
}

.icon-md i {
  font-size: 45px;
}

.icon-sm {
  width: 40px;
}

.icon-sm i {
  font-size: 30px;
}

.icon-xs {
  width: 30px;
}

.icon-xs i {
  font-size: 20px;
}

.icon-xld img, .icon-xl img, .icon-lg img, .icon-md img, .icon-sm img, .icon-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
}

.icon-xld img.img-full, .icon-xl img.img-full {
  width: 100%;
  height: 100%;
}

.icon-xld img {
  height: 90px;
}

.icon-xld img.img-full {
  width: 100%;
  height: 100%;
}

.icon-xl img {
  height: 80px;
}

.icon-lg img {
  height: 70px;
}

.icon-md img {
  height: 50px;
}

.icon-sm img {
  height: 30px;
}

.icon-xs img {
  height: 20px;
}

.about-types .wt-icon-box-wraper {
  border-width: 0 0 0 3px;
}

.wt-icon-box-wraper {
  position: relative;
}

.wt-icon-box-wraper .wt-tilte {
  margin-top: 0;
}

.wt-icon-box-wraper .after-titile-line {
  margin-bottom: 10px;
}

.wt-icon-box-wraper p:last-child {
  margin: 0;
}

.icon-content {
  overflow: hidden;
}

.wt-icon-box-xld, .wt-icon-box-xl, .wt-icon-box-lg, .wt-icon-box-md, .wt-icon-box-sm, .wt-icon-box-xs {
  display: table;
}

.wt-icon-box-xld .icon-cell, .wt-icon-box-xl .icon-cell, .wt-icon-box-lg .icon-cell, .wt-icon-box-md .icon-cell, .wt-icon-box-sm .icon-cell, .wt-icon-box-xs .icon-cell {
  vertical-align: middle;
  display: table-cell;
}

.wt-icon-box-wraper.left .wt-icon-box-xld, .wt-icon-box-wraper.left .wt-icon-box-xl, .wt-icon-box-wraper.left .wt-icon-box-lg, .wt-icon-box-wraper.left .wt-icon-box-md, .wt-icon-box-wraper.left .wt-icon-box-sm, .wt-icon-box-wraper.left .wt-icon-box-xs {
  float: left;
  margin-right: 20px;
}

.wt-icon-box-wraper.left .icon-xld, .wt-icon-box-wraper.left .icon-xl, .wt-icon-box-wraper.left .icon-lg, .wt-icon-box-wraper.left .icon-md, .wt-icon-box-wraper.left .icon-sm, .wt-icon-box-wraper.left .icon-xs {
  float: left;
  margin-right: 10px;
}

.wt-icon-box-wraper.right {
  text-align: right;
}

.wt-icon-box-wraper.right .wt-icon-box-xld, .wt-icon-box-wraper.right .wt-icon-box-xl, .wt-icon-box-wraper.right .wt-icon-box-lg, .wt-icon-box-wraper.right .wt-icon-box-md, .wt-icon-box-wraper.right .wt-icon-box-sm, .wt-icon-box-wraper.right .wt-icon-box-xs {
  float: right;
  margin-left: 20px;
  display: table;
}

.wt-icon-box-wraper.right .icon-xld, .wt-icon-box-wraper.right .icon-xl, .wt-icon-box-wraper.right .icon-lg, .wt-icon-box-wraper.right .icon-md, .wt-icon-box-wraper.right .icon-sm, .wt-icon-box-wraper.right .icon-xs {
  float: right;
  margin-left: 10px;
}

.wt-icon-box-wraper.center {
  text-align: center;
}

.wt-icon-box-wraper.center .wt-icon-box-xld, .wt-icon-box-wraper.center .wt-icon-box-xl, .wt-icon-box-wraper.center .wt-icon-box-lg, .wt-icon-box-wraper.center .wt-icon-box-md, .wt-icon-box-wraper.center .wt-icon-box-sm, .wt-icon-box-wraper.center .wt-icon-box-xs {
  margin-left: auto;
  margin-right: auto;
}

.wt-icon-box-wraper.bx-style-1, .wt-icon-box-wraper.bx-style-2 {
  border: 1px solid #ddd;
}

.wt-icon-box-wraper.bx-style-2.center [class*="wt-icon-box-"], .wt-icon-box-wraper.bx-style-2.left [class*="wt-icon-box-"], .wt-icon-box-wraper.bx-style-2.right [class*="wt-icon-box-"] {
  position: absolute;
}

.wt-icon-box-wraper.bx-style-2.center [class*="wt-icon-box-"] {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-50%);
}

.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-xl {
  margin-left: -75px;
}

.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-lg {
  margin-left: -60px;
}

.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-md {
  margin-left: -50px;
}

.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-sm {
  margin-left: -40px;
}

.wt-icon-box-wraper.bx-style-2.center .wt-icon-box-xs {
  margin-left: -20px;
}

.wt-icon-box-wraper.bx-style-2.left [class*="wt-icon-box-"] {
  position: absolute;
  top: auto;
  left: 0;
  transform: translateX(-50%);
}

.wt-icon-box-wraper.bx-style-2.right [class*="wt-icon-box-"] {
  position: absolute;
  top: auto;
  right: 0;
  transform: translateX(50%);
}

.wt-box, .wt-info, .wt-tilte, .wt-tilte-inner {
  position: relative;
}

.wt-tilte-inner {
  display: inline-block;
}

.wt-tilte-inner.skew-title:after {
  content: "";
  width: 50px;
  height: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  right: -15px;
  transform: skewX(20deg);
}

.wt-box[class*="border-"], .wt-info[class*="border-"], .wt-icon-box-wraper[class*="border-"] {
  border-color: #eee;
}

.wt-info.border-1, .wt-info.border-2, .wt-info.border-3, .wt-info.border-4, .wt-info.border-5 {
  border-top: none;
}

.left-border, .right-border {
  position: relative;
}

.left-border:before, .right-border:before {
  content: "";
  width: 1px;
  height: 90%;
  background: #ccc;
  position: absolute;
  top: 5px;
}

.right-border:before {
  right: 0;
}

.wt-thum-bx, .wt-post-thum {
  background-color: #000;
}

.wt-thum-bx img, .wt-post-thum img {
  width: 100%;
  height: auto;
}

.wt-img-overlay1, .wt-img-overlay2, .wt-img-overlay3, .wt-img-overlay4, .wt-img-overlay5, .wt-img-overlay6, .wt-img-overlay7, .wt-img-overlay8, .wt-img-overlay9 .wt-img-overlay10 {
  position: relative;
}

.wt-img-overlay1:before, .wt-img-overlay1:after, .wt-img-overlay2:before, .wt-img-overlay2:after, .wt-img-overlay3:before, .wt-img-overlay3:after, .wt-img-overlay4:before, .wt-img-overlay4:after, .wt-img-overlay5:before, .wt-img-overlay5:after, .wt-img-overlay6:before, .wt-img-overlay6:after, .wt-img-overlay7:before, .wt-img-overlay7:after, .wt-img-overlay8:before, .wt-img-overlay8:after, .wt-img-overlay9:before, .wt-img-overlay9:after {
  content: "";
  width: 0;
  height: 0;
  opacity: .4;
  z-index: 1;
  background: #000;
  transition: all .3s linear;
  position: absolute;
  top: 0;
  left: 0;
}

.wt-img-overlay1:before {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.wt-img-overlay1:hover:before, .wt-box:hover .wt-img-overlay1:before {
  opacity: .5;
}

.wt-img-overlay2:before {
  width: 100%;
  height: 100%;
  opacity: 0;
  background: linear-gradient(#0000 0%, #000000a6 100%);
}

.wt-img-overlay2:hover:before, .wt-box:hover .wt-img-overlay2:before {
  opacity: .9;
}

.wt-img-overlay3:before {
  top: 50%;
  left: 50%;
}

.wt-img-overlay3:hover:before, .wt-box:hover .wt-img-overlay3:before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.wt-img-overlay4:before {
  height: 100%;
  top: 0;
  left: 50%;
}

.wt-img-overlay4:hover:before, .wt-box:hover .wt-img-overlay4:before {
  width: 100%;
  left: 0;
}

.wt-img-overlay5:before {
  width: 100%;
  top: 50%;
  left: 0;
}

.wt-img-overlay5:hover:before, .wt-box:hover .wt-img-overlay5:before {
  height: 100%;
  top: 0;
}

.wt-img-overlay6:before {
  width: 100%;
  top: auto;
  bottom: 0;
  left: 0;
}

.wt-img-overlay6:hover:before, .wt-box:hover .wt-img-overlay6:before, .wt-img-overlay7:before, .wt-img-overlay7:after {
  height: 100%;
}

.wt-img-overlay7:after {
  z-index: 0;
  left: auto;
  right: 0;
}

.wt-img-overlay7:hover:before, .wt-img-overlay7:hover:after, .wt-box:hover .wt-img-overlay7:before, .wt-box:hover .wt-img-overlay7:after {
  width: 50%;
}

.wt-img-overlay8:before, .wt-img-overlay8:after {
  height: 100%;
}

.wt-img-overlay8:after {
  left: auto;
  right: 0;
}

.wt-img-overlay8:hover:before, .wt-img-overlay8:hover:after, .wt-box:hover .wt-img-overlay8:before, .wt-box:hover .wt-img-overlay8:after {
  width: 100%;
  opacity: .3;
}

.wt-img-overlay9:after {
  inset: auto 0 0 auto;
}

.wt-img-overlay9:hover:before, .wt-img-overlay9:hover:after, .wt-box:hover .wt-img-overlay9:before, .wt-box:hover .wt-img-overlay9:after {
  width: 100%;
  height: 100%;
  opacity: .3;
}

.wt-img-overlay10:before, .wt-img-overlay10:after {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.wt-img-overlay10:hover:before, .wt-box:hover .wt-img-overlay10:before {
  opacity: .5;
}

.wt-img-overlay10:hover:after, .wt-box:hover .wt-img-overlay10:after {
  width: 90%;
  height: 80%;
  opacity: .5;
  background: none;
  border: 1px dashed #fff;
  top: 10%;
  left: 5%;
}

.wt-img-overlay11:hover .wt-img-overlay11-content {
  transform: scale(1);
}

.wt-img-overlay11-content {
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all .3s linear;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0);
}

.wt-img-overlay11-content .overlay-11-detail {
  height: 100%;
  display: table;
}

.wt-img-overlay11-content .overlay-11-detail .overlay-11-info {
  vertical-align: middle;
  text-align: center;
  display: table-cell;
}

.wt-img-overlay11-content .overlay-11-detail .overlay-11-info h4 a {
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .wt-img-overlay11-content .overlay-11-detail p {
    font-size: 13px;
    line-height: 18px;
  }
}

.wt-img-overlay11-content .bg-color {
  width: 100%;
  height: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

.btn {
  padding-top: 9px;
  padding-bottom: 9px;
}

.site-button, .site-button-secondry {
  color: #fff;
  cursor: pointer;
  border: 0 solid #0000;
  outline: none;
  margin-left: -1px;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 1.42857;
  display: inline-block;
  text-decoration: none !important;
}

.site-button {
  background-color: #ec5598;
}

.site-button:active, .site-button:hover, .site-button:focus, .active > .site-button {
  color: #fff;
  background-color: #e4a101;
}

.site-button-secondry {
  background-color: #4b3941;
}

.site-button-secondry:active, .site-button-secondry:hover, .site-button-secondry:focus, .active > .site-button-secondry {
  color: #fff;
  background-color: #192639;
}

.site-button.text-uppercase {
  text-transform: uppercase;
}

.button-sm {
  padding: 5px 10px;
  font-size: 12px;
}

.button-lg {
  padding: 20px 35px;
  font-size: 16px;
}

.button-xl {
  padding: 30px 50px;
  font-size: 24px;
}

@media only screen and (max-width: 575px) {
  .button-lg {
    padding: 10px 20px;
    font-size: 12px;
  }

  .button-xl {
    padding: 10px 20px;
    font-size: 16px;
  }
}

.radius-no {
  border-radius: 0;
}

.radius-sm {
  border-radius: 3px;
}

.radius-xl {
  border-radius: 100px;
}

.radius-bx {
  border-radius: 100%;
}

.site-button.white {
  color: #777;
  background-color: #fff;
}

.site-button.white:hover, .site-button.white:active, .site-button.white:focus {
  color: #555;
  background-color: #f4f4f4;
}

.site-button.black {
  color: #fff;
  background-color: #171717;
}

.site-button.black:hover, .site-button.black:active, .site-button.black:focus {
  color: #fff;
  background-color: #000;
}

.site-button.gray {
  color: #fff;
  background-color: #666;
}

.site-button.gray:hover, .site-button.gray:active, .site-button.gray:focus {
  color: #fff;
  background-color: #555;
}

.site-button.pink {
  color: #fff;
  background-color: #e63f75;
}

.site-button.pink:hover, .site-button.pink:active, .site-button.pink:focus {
  color: #fff;
  background-color: #d22b61;
}

.site-button.blue {
  color: #fff;
  background-color: #42b8d4;
}

.site-button.blue:hover, .site-button.blue:active, .site-button.blue:focus {
  color: #fff;
  background-color: #2ca2be;
}

.site-button.green {
  color: #fff;
  background-color: #35b494;
}

.site-button.green:hover, .site-button.green:active, .site-button.green:focus {
  color: #fff;
  background-color: #26a585;
}

.site-button.orange {
  color: #fff;
  background-color: #e56713;
}

.site-button.orange:hover, .site-button.orange:active, .site-button.orange:focus {
  color: #fff;
  background-color: #d55703;
}

.site-button.red {
  color: #fff;
  background-color: #d93223;
}

.site-button.red:hover, .site-button.red:active, .site-button.red:focus {
  color: #fff;
  background-color: #c51e0f;
}

.site-button.brown {
  color: #fff;
  background-color: #69441f;
}

.site-button.brown:hover, .site-button.brown:active, .site-button.brown:focus {
  color: #fff;
  background-color: #5f3a15;
}

.site-button.yellow {
  color: #fff;
  background-color: #ecc731;
}

.site-button.yellow:hover, .site-button.yellow:active, .site-button.yellow:focus {
  color: #fff;
  background-color: #d4af19;
}

.site-button.purple {
  color: #fff;
  background-color: #ae1af7;
}

.site-button.purple:hover, .site-button.purple:active, .site-button.purple:focus {
  color: #fff;
  background-color: #9804e1;
}

.site-button.graphical {
  color: #fff;
  text-shadow: 0 -1px #0006;
  border: 1px solid #00000026;
  box-shadow: inset 0 1px 1px #ffffff4d, 0 1px 3px -1px #2d3c4880;
}

.site-button.graphical:active {
  box-shadow: inset 0 2px 2px #00000040;
}

.site-button.button-3d {
  color: #fff;
  border: none;
  border-bottom: 4px solid #0003;
}

.site-button.outline {
  color: #888;
  background: none;
  border: 2px solid #eee;
}

.site-button.outline.white {
  color: #e7e7e7;
  border-color: #e7e7e7;
}

.site-button.outline.white:hover {
  color: #666;
}

.site-button.outline.black {
  color: #171717;
  border-color: #171717;
}

.site-button.outline.gray {
  color: #666;
  border-color: #666;
}

.site-button.outline.pink {
  color: #e63f75;
  border-color: #e63f75;
}

.site-button.outline.blue {
  color: #42b8d4;
  border-color: #42b8d4;
}

.site-button.outline.green {
  color: #35b494;
  border-color: #35b494;
}

.site-button.outline.orange {
  color: #e56713;
  border-color: #e56713;
}

.site-button.outline.red {
  color: #d93223;
  border-color: #d93223;
}

.site-button.outline.brown {
  color: #69441f;
  border-color: #69441f;
}

.site-button.outline.yellow {
  color: #ec5598;
  border-color: #ec5598;
}

.site-button.outline.purple {
  color: #ae1af7;
  border-color: #ae1af7;
}

.site-button.outline:hover {
  color: #fff;
  border-color: #0000;
}

.site-button-link {
  font-family: Roboto, sans-serif;
  font-weight: 600;
  display: inline-block;
}

.site-button-link.white {
  color: #e7e7e7;
}

.site-button-link.white:hover, .site-button-link.white:active, .site-button-link.white:focus {
  color: #ccc;
}

.site-button-link.black {
  color: #171717;
}

.site-button-link.black:hover, .site-button-link.black:active, .site-button-link.black:focus {
  color: #000;
}

.site-button-link.gray {
  color: #666;
}

.site-button-link.gray:hover, .site-button-link.gray:active, .site-button-link.gray:focus {
  color: #555;
}

.site-button-link.pink {
  color: #e63f75;
}

.site-button-link.pink:hover, .site-button-link.pink:active, .site-button-link.pink:focus {
  color: #2ca2be;
}

.site-button-link.blue {
  color: #42b8d4;
}

.site-button-link.blue:hover, .site-button-link.blue:active, .site-button-link.blue:focus {
  color: #2ca2be;
}

.site-button-link.green {
  color: #35b494;
}

.site-button-link.green:hover, .site-button-link.green:active, .site-button-link.green:focus {
  color: #26a585;
}

.site-button-link.orange {
  color: #e56713;
}

.site-button-link.orange:hover, .site-button-link.orange:active, .site-button-link.orange:focus {
  color: #d55703;
}

.site-button-link.red {
  color: #d93223;
}

.site-button-link.red:hover, .site-button-link.red:active, .site-button-link.red:focus {
  color: #c51e0f;
}

.site-button-link.brown {
  color: #69441f;
}

.site-button-link.brown:hover, .site-button-link.brown:active, .site-button-link.brown:focus {
  color: #5f3a15;
}

.site-button-link.yellow {
  color: #ecc731;
}

.site-button-link.yellow:hover, .site-button-link.yellow:active, .site-button-link.yellow:focus {
  color: #d4af19;
}

.site-button-link.purple {
  color: #ae1af7;
}

.site-button-link.purple:hover, .site-button-link.purple:active, .site-button-link.purple:focus {
  color: #9804e1;
}

.site-button.button-app {
  text-align: left;
  padding: 15px 25px;
}

.site-button.button-app i {
  margin-right: 15px;
  font-size: 45px;
  display: inline-block;
}

.site-button.button-app strong {
  font-size: 16px;
  display: block;
}

.masonry-filter {
  margin-bottom: 0;
  display: table;
}

.masonry-filter > li {
  margin-right: 30px;
  display: inline-block;
  position: relative;
}

.masonry-filter > li a {
  color: #666;
  font-size: 14px;
}

.masonry-filter > li.active a, .masonry-filter > li a:hover, .masonry-filter > li a:active, .masonry-filter > li a:focus {
  color: #f7c20a;
}

@media only screen and (max-width: 600px) {
  .masonry-filter > li {
    margin-bottom: 10px;
  }

  .masonry-filter > li a {
    font-size: 14px !important;
  }
}

.filter-wrap.right > .masonry-filter {
  float: right;
}

@media only screen and (max-width: 991px) {
  .filter-wrap.right > .masonry-filter {
    float: none;
  }
}

.filter-wrap.center > .masonry-filter {
  margin-left: auto;
  margin-right: auto;
}

.masonry-filter.link-style > li a {
  font-size: 15px;
  font-weight: 600;
}

.masonry-filter.link-style > li:after {
  content: "/";
  opacity: .3;
  position: relative;
  right: -18px;
}

.masonry-filter.link-style.white > li:after {
  color: #fff;
}

.masonry-filter.link-style > li:last-child:after {
  display: none;
}

.masonry-filter.link-style.white > li.active a, .masonry-filter.link-style.white > li a:hover, .masonry-filter.link-style.white > li a:active, .masonry-filter.link-style.white > li a:focus {
  color: #f7c20a;
}

.masonry-filter.has-bg > li a {
  color: #fff;
}

.masonry-filter.link-style.has-bg > li.active a, .masonry-filter.link-style.has-bg > li:hover a, .masonry-filter.link-style.has-bg > li:active a, .masonry-filter.link-style.has-bg > li:focus a {
  color: #ec5598;
}

.masonry-filter.button-style > li {
  margin-bottom: 10px;
  margin-right: 10px;
}

@media only screen and (max-width: 767px) {
  .masonry-filter.button-style > li {
    margin-bottom: 10px;
  }
}

.masonry-filter.button-style > li a {
  color: #fff;
  background-color: #666;
  padding: 10px 20px;
  font-weight: 600;
  display: inline-block;
}

.masonry-filter.button-style > li.active a, .masonry-filter.button-style > li a:hover, .masonry-filter.button-style > li a:active, .masonry-filter.button-style > li a:focus {
  color: #fff;
  background-color: #ec5598;
}

.masonry-filter.button-style.has-bg > li a {
  color: #666;
  background-color: #fff;
}

.masonry-filter.button-style.has-bg > li.active a, .masonry-filter.button-style.has-bg > li a:hover, .masonry-filter.button-style.has-bg > li a:active, .masonry-filter.button-style.has-bg > li a:focus {
  color: #fff;
  background-color: #ec5598;
}

.masonry-filter.outline-style > li {
  margin-right: 10px;
}

.masonry-filter.outline-style > li a {
  color: #666;
  border: 2px solid #666;
  margin-bottom: 10px;
  padding: 10px 20px;
  font-weight: 600;
  display: inline-block;
}

.masonry-filter.outline-style.rounded-corner > li a {
  border-radius: 100px;
}

.masonry-filter.outline-style > li.active a, .masonry-filter.outline-style > li a:hover, .masonry-filter.outline-style > li a:active, .masonry-filter.outline-style > li a:focus {
  color: #ec5598;
  border-color: #ec5598;
}

.masonry-filter.outline-style.has-bg > li a {
  color: #fff;
  border-color: #fff;
}

.masonry-filter.outline-style.has-bg > li.active a, .masonry-filter.outline-style.has-bg > li a:hover, .masonry-filter.outline-style.has-bg > li a:active, .masonry-filter.outline-style.has-bg > li a:focus {
  color: #ec5598;
  border-color: #ec5598;
}

@media only screen and (max-width: 767px) {
  .masonry-item {
    width: 100%;
  }
}

.wt-separator-outer {
  overflow: hidden;
}

.wt-separator {
  height: 3px;
  width: 50px;
  display: inline-block;
  position: relative;
}

.wt-separator .separator-left, .wt-separator .separator-right {
  width: 70px;
  height: 2px;
  margin-top: -1px;
  position: absolute;
  top: 50%;
}

.wt-separator .separator-left {
  left: -80px;
}

.wt-separator .separator-right {
  right: -80px;
}

.wt-separator.style-liner {
  width: 20px;
  height: 4px;
}

.wt-separator.style-square {
  width: 10px;
  height: 10px;
  background-color: #0000;
  border: 3px solid #2d3239;
}

.wt-separator.style-square .separator-left, .wt-separator.style-square .separator-right {
  height: 3px;
}

.wt-separator.style-square.has-bg {
  border-color: #fff;
}

.wt-separator.style-icon {
  width: 30px;
  height: 30px;
  text-align: center;
  color: #dedede;
  font-size: 18px;
  line-height: 30px;
}

.wt-divider {
  height: 1px;
  margin: 30px 0;
  position: relative;
}

.wt-divider.divider-2px {
  height: 2px;
}

.wt-divider.divider-3px {
  height: 3px;
}

.wt-divider.divider-4px {
  height: 4px;
}

.wt-divider i {
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 100%;
  line-height: 30px;
  display: block;
  position: absolute;
  transform: translateY(-50%);
}

.wt-divider.icon-left {
  margin-left: 40px;
}

.wt-divider.icon-left i {
  left: -40px;
}

.wt-divider.icon-right {
  margin-right: 40px;
}

.wt-divider.icon-right i {
  left: auto;
  right: -40px;
}

.wt-divider.icon-center i {
  margin-left: -5px;
  left: 50%;
}

.wt-tabs:after {
  visibility: hidden;
  content: " ";
  clear: both;
  height: 0;
  font-size: 0;
  display: block;
}

@media only screen and (max-width: 767px) {
  .wt-tabs .nav-tabs > li {
    float: none;
  }
}

.wt-tabs p:last-child {
  margin-bottom: 0;
}

.wt-tabs .tab-pane {
  padding: 20px 0;
}

.wt-tabs .nav-tabs > li > a {
  color: #1a1a1a;
  border-radius: 0;
  padding: 15px;
  font-size: 13px;
  font-weight: 600;
}

.wt-tabs .nav-tabs > li > a.active, .wt-tabs .nav-tabs > li > a.active:focus, .wt-tabs .nav-tabs > li > a.active:hover {
  color: #1a1a1a;
  background-color: #fff;
  border-color: #ddd #ddd #fff;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.wt-tabs .nav > li > a:focus, .wt-tabs .nav > li > a:hover {
  background-color: #0000;
}

.wt-tabs .nav li a:hover {
  border: 1px solid #0000;
}

.wt-tabs .nav-tabs > li > a i {
  margin-right: 5px;
}

.wt-tabs.tabs-default.has-bg .nav-tabs > li > a {
  color: #fff;
}

.wt-tabs.tabs-default.has-bg .nav-tabs > li > a.active, .wt-tabs.tabs-default.has-bg .nav-tabs > li > a.active:focus, .wt-tabs.tabs-default.has-bg .nav-tabs > li > a.active:hover {
  color: #1a1a1a;
  border-color: #ddd #ddd #fff;
}

.wt-tabs.tabs-default.has-bg .tab-pane {
  color: #fff;
}

.wt-tabs.nav-center > .nav-tabs {
  margin-left: auto;
  margin-right: auto;
  display: table;
}

.wt-tabs.bg-tabs .nav-tabs > li > a {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  margin-right: -1px;
}

.wt-tabs.bg-tabs .nav-tabs > li > a.active {
  background-color: #fff;
  border-bottom: 1px solid #0000;
}

.wt-tabs.bg-tabs.has-bg .tab-pane {
  color: #fff;
}

.wt-tabs.vertical.bg-tabs .nav-tabs > li > a {
  border: 1px solid #ddd;
}

.wt-tabs.vertical.bg-tabs .nav-tabs > li > a.active {
  border-right: 1px solid #0000;
}

.wt-tabs.vertical.right.bg-tabs .nav-tabs > li > a {
  border: 1px solid #ddd;
}

.wt-tabs.vertical.right.bg-tabs .nav-tabs > li > a.active {
  border-left: 1px solid #0000;
}

.wt-tabs.border-top {
  border-top: 0 !important;
}

.wt-tabs.border-top .nav-tabs > li > a.active {
  color: #1a1a1a;
  position: relative;
}

.wt-tabs.border-top.border.bg-tabs .nav-tabs {
  border: 0;
}

.wt-tabs.border-top .nav-tabs > li > a.active:after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: #ffbc13;
  position: absolute;
  top: -1px;
  left: 0;
}

.wt-tabs.border-top.vertical .nav-tabs > li > a.active:after {
  width: 3px;
  height: 100%;
  top: 0;
  left: -1px;
}

.wt-tabs.border {
  border: none !important;
}

.wt-tabs.border .tab-pane {
  border: 1px solid #ddd;
  margin-top: -1px;
  padding: 10px;
}

.wt-tabs.vertical.tabs-default.border .nav-tabs {
  border-right: 0;
}

.wt-tabs.vertical .nav-tabs {
  float: left;
  width: 170px;
  border-bottom: none;
  border-right: 1px solid #ddd;
}

.wt-tabs.border-top.vertical.bg-tabs .nav-tabs {
  border-right: 0;
}

@media only screen and (max-width: 720px) {
  .wt-tabs.vertical .nav-tabs {
    width: 100%;
    float: none;
    border-right: 0;
  }

  .wt-tabs.vertical .tab-content {
    width: 100%;
    margin-left: 0 !important;
  }
}

.wt-tabs.vertical .tab-pane {
  padding: 10px 0 10px 20px;
}

.wt-tabs.vertical .nav-tabs li {
  float: none;
  width: 100%;
  margin-right: 0;
}

.wt-tabs.vertical .nav-tabs li a {
  color: #1a1a1a;
  border-right: none;
  border-radius: 0;
  margin-right: -1px;
}

.wt-tabs.border-top.vertical.border.bg-tabs .nav-tabs li a {
  margin-right: -2px;
}

.wt-tabs.vertical .nav-tabs li a.active {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

.wt-tabs.vertical .tab-content {
  border-left: 1px solid #ddd;
  margin-left: 169px;
}

.wt-tabs.vertical.border .tab-pane {
  margin-left: -1px;
  padding: 20px;
}

.wt-tabs.vertical.right .nav-tabs {
  float: right;
  border-right: none;
}

.wt-tabs.vertical.right .nav-tabs li {
  margin-left: -1px;
  margin-right: 0;
}

.wt-tabs.vertical.right .nav-tabs li a {
  border-left: none;
  border-right: 1px solid #0000;
}

.wt-tabs.vertical.right .nav-tabs li a.active {
  border-left: none;
  border-right: 1px solid #ddd;
}

.wt-tabs.vertical.right .tab-content {
  border-left: none;
  border-right: 1px solid #ddd;
  margin-left: 0;
  margin-right: 169px;
}

.wt-tabs.vertical.right .tab-pane {
  padding: 10px 20px 10px 0;
}

.wt-tabs.vertical.right.border .tab-pane {
  margin-right: -1px;
  padding: 20px;
}

@media only screen and (max-width: 720px) {
  .wt-tabs.tabs-default .nav.nav-tabs {
    display: block;
  }

  .wt-tabs.tabs-default .nav.nav-tabs li a.active {
    border: 1px solid #ddd;
    margin-right: 0;
  }

  .pricing-tab-content-block .wt-tabs.vertical .tab-content {
    border: 0 solid #ddd;
  }

  .wt-tabs.vertical .tab-content {
    border: 1px solid #ddd;
  }

  .wt-tabs.vertical.right .nav-tabs {
    float: none;
    border-left: 0;
  }

  .wt-tabs.vertical.right .tab-content {
    border: 1px solid #ddd;
  }

  .wt-tabs.vertical.right .tab-pane {
    padding: 10px;
  }

  .wt-tabs.vertical.tabs-default.border .tab-content {
    border: 0;
  }

  .wt-tabs.bg-tabs ul {
    display: block;
  }

  .wt-tabs.vertical.bg-tabs .nav-tabs > li > a {
    margin-right: 0;
  }

  .wt-tabs.vertical.bg-tabs .nav-tabs > li > a.active {
    border-right: 1px solid #ddd;
  }

  .wt-tabs.vertical.right.tabs-default.has-bg .nav-link.active, .wt-tabs.vertical.right.bg-tabs.has-bg .nav-link {
    margin-right: -1px;
  }
}

.wt-accordion .wt-panel {
  box-shadow: none;
  background-color: #0000;
  border: none;
  border-radius: 0;
  margin-bottom: 10px;
}

.acod-head {
  position: relative;
}

.acod-title {
  margin-top: 0;
  margin-bottom: 0;
}

.acod-title a {
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.acod-head .fa {
  margin-right: 5px;
}

.acod-head a {
  padding: 15px 40px 15px 15px;
  display: block;
}

.acod-head a, .acod-head a:hover, .acod-head a:active, .acod-head a:focus {
  color: #3d474a;
}

.acod-body {
  color: #777;
}

.acod-head .indicator {
  color: #ababab;
  padding: 15px;
  position: absolute;
  top: 0;
  right: 0;
}

.acod-head .indicator .fa, .acod-head.acc-actives .indicator .fa {
  transform: rotate(0);
}

.acod-head.acc-actives .indicator .fa, .acod-head [aria-expanded="true"] .indicator .fa {
  transform: rotate(45deg);
}

.acc-default .acod-title a {
  padding: 5px 0;
}

.acc-default .acod-head .indicator {
  padding: 0;
}

.acc-default.acc-has-bg .acod-head a, .acc-default.acc-has-bg .acod-head a:hover, .acc-default.acc-has-bg .acod-head a:active, .acc-default.acc-has-bg .acod-head a:focus, .acc-default.acc-has-bg .acod-head .indicator, .acc-default.acc-has-bg .acod-body {
  color: #fff;
}

.acc-outline .acod-title a {
  border: 1px solid #ddd;
}

.acc-outline.acc-has-bg .acod-head a, .acc-outline.acc-has-bg .acod-head a:hover, .acc-outline.acc-has-bg .acod-head a:active, .acc-outline.acc-has-bg .acod-head a:focus, .acc-outline.acc-has-bg .acod-head .indicator, .acc-outline.acc-has-bg .acod-body {
  color: #fff;
}

.acc-bg-gray a {
  background-color: #f5f5f5;
}

.acc-bg-gray .acod-head .indicator {
  color: #777;
}

.acc-bg-gray.acc-has-bg .acod-body {
  color: #fff;
}

.acc-site-bg-primary a {
  background-color: #ec5598;
}

.acc-site-bg-primary .acod-head a, .acc-site-bg-primary .acod-head a:hover, .acc-site-bg-primary .acod-head a:active, .acc-site-bg-primary .acod-head a:focus, .acc-site-bg-primary .acod-head .indicator, .acc-site-bg-primary.acc-has-bg .acod-body {
  color: #fff;
}

.acc-bg-dark a {
  background-color: #212427;
}

.acc-bg-dark .acod-head a, .acc-bg-dark .acod-head a:hover, .acc-bg-dark .acod-head a:active, .acc-bg-dark .acod-head a:focus, .acc-bg-dark .acod-head .indicator, .acc-bg-dark.acc-has-bg .acod-body {
  color: #fff;
}

.owl-imgbx, .ow-portfolio-img {
  position: relative;
}

.ow-entry-content {
  background-color: #fff;
  border: 1px solid #eee;
  border-top-width: 0;
  margin-bottom: 5px;
  padding: 20px;
}

.ow-entry-title {
  padding: 5px 0;
  font-size: 16px;
  font-weight: 600;
}

.ow-entry-title, .ow-entry-title a {
  color: #3d474a;
}

.ow-entry-text p:last-child {
  margin: 0;
}

.ow-post-title .post-title {
  margin: 0 0 10px;
}

.ow-post-text {
  margin-bottom: 10px;
}

.ow-post-text p:last-child {
  margin: 0;
}

.ow-post-readmore {
  margin-bottom: 10px;
}

.ow-post-readmore a {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  text-decoration: underline;
}

.ow-post-meta {
  margin-bottom: 10px;
  font-family: Roboto, sans-serif;
}

.ow-post-meta ul {
  margin: 0 -4px;
  list-style: none;
}

.ow-post-meta ul li {
  padding: 0;
  display: inline-block;
}

.ow-post-meta li:after {
  content: "/";
  opacity: .5;
  margin-left: 5px;
  font-weight: normal;
  display: inline-block;
}

.ow-post-meta li:last-child:after {
  display: none;
}

.ow-post-meta a {
  color: #a9a9a9;
}

.ow-post-meta li i {
  color: #7b7b7b;
  margin: 0 5px;
}

.ow-post-tags {
  border-top: 1px solid #e9e9e9;
  padding-top: 10px;
}

.ow-post-tags .post-comment {
  float: left;
  text-transform: uppercase;
  font-weight: bold;
}

.ow-post-tags .post-comment a {
  color: #a9a9a9;
}

.ow-post-tags .post-tags {
  margin: 0 -3px;
  list-style: none;
}

.ow-post-tags .post-tags a {
  color: #777;
  text-transform: uppercase;
  border: 1px solid #ebebeb;
  margin: 0 3px;
  padding: 2px 8px 1px;
  font-size: 11px;
  display: inline-block;
}

.ow-post-tags .post-tags a:hover, .ow-post-tags .post-tags a:active, .ow-post-tags .post-tags a:focus {
  color: #fff;
  background-color: #ec5598;
}

.date-style-3 .ow-post-info {
  position: relative;
}

.date-style-3 .ow-post-media {
  border-bottom: 3px solid #ec5598;
}

.no-image-blog.date-style-2 .ow-post-info {
  padding-top: 70px;
}

.ow-event-title .event-title {
  margin: 0 0 10px;
}

.ow-event-text {
  margin-bottom: 10px;
}

.ow-event-text p:last-child {
  margin: 0;
}

.ow-event-readmore {
  margin-bottom: 10px;
}

.ow-event-readmore a {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: italic;
  text-decoration: underline;
}

.ow-event-meta ul {
  margin: 0;
}

.ow-event-meta ul li {
  padding: 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: italic;
  display: inline-block;
}

.ow-event-meta ul li i {
  color: #7b7b7b;
  margin-right: 3px;
}

.no-image-event.date-style-2 .ow-post-info {
  padding-top: 70px;
}

.ow-client-logo {
  width: 100%;
  background-color: #fff;
  display: table;
}

.client-logo {
  text-align: center;
  vertical-align: middle;
  padding: 10px;
  display: table-cell;
}

.owl-carousel .ow-client-logo img {
  max-height: 100%;
  width: auto;
  display: inline-block;
}

.client-logo-media img {
  max-width: 100% !important;
  width: auto !important;
  margin: 0 auto !important;
}

.testimonial-pic {
  width: 100px;
  height: 100px;
  background: #fff;
  border: 5px solid #fff;
  display: inline-block;
  position: relative;
}

.testimonial-pic.radius {
  border-radius: 100%;
}

.testimonial-pic.radius img {
  width: 100%;
  border-radius: 100%;
}

.testimonial-pic.shadow {
  box-shadow: 2px 3px 6px -3px #00000059;
}

.quote-left, .quote-right {
  position: relative;
}

.quote-left:before, .quote-right:after {
  z-index: 10;
  font-family: FontAwesome;
  position: absolute;
  top: 15px;
}

.quote-left:before {
  content: "";
  left: 0;
}

.quote-right:after {
  content: "";
  left: auto;
  right: 0;
}

.testimonial-text {
  padding: 15px;
  position: relative;
}

.testimonial-text p:last-child {
  margin: 0;
}

.testimonial-detail {
  padding: 5px;
}

.testimonial-name, .testimonial-position {
  display: block;
}

.testimonial-position {
  font-style: italic;
}

.testimonial-text p {
  margin: 0;
}

.testimonial-bg {
  color: #fff;
}

.testimonial-1, .testimonial-grid-1 {
  text-align: center;
}

.testimonial-1 .testimonial-position, .testimonial-grid-1 .testimonial-position {
  color: #ec5598;
}

.testimonial-1 .testimonial-name, .testimonial-1 .testimonial-position, .testimonial-grid-1 .testimonial-name, .testimonial-grid-1 .testimonial-position {
  display: block;
}

.testimonial-1 .quote-left:before, .testimonial-grid-1 .quote-left:before {
  width: 36px;
  height: 36px;
  color: #fff;
  text-align: center;
  background-color: #ec5598;
  border-radius: 100%;
  font-size: 16px;
  line-height: 36px;
  top: 65px;
  left: 0;
}

.testimonial-2 .testimonial-text, .testimonial-grid-2 .testimonial-text {
  background-color: #f4f5f7;
  padding: 25px 30px 25px 50px;
}

.testimonial-2 .testimonial-text:after, .testimonial-grid-2 .testimonial-text:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 15px solid #f4f5f7;
  border-left: 15px solid #0000;
  border-right: 15px solid #0000;
  position: absolute;
  bottom: -15px;
  left: 50px;
}

.testimonial-2 .testimonial-text .fa-quote-left, .testimonial-grid-2 .testimonial-text .fa-quote-left {
  color: #ec5598;
  font-size: 24px;
  position: absolute;
  top: 30px;
  left: 15px;
}

.testimonial-2 .testimonial-detail, .testimonial-grid-2 .testimonial-detail {
  background-color: #ec5598;
  padding: 25px 0 20px 20px;
}

.testimonial-2 .testimonial-pic, .testimonial-grid-2 .testimonial-pic {
  float: left;
  width: 80px;
  height: 80px;
  margin-right: 15px;
}

.testimonial-2 .testimonial-name, .testimonial-2 .testimonial-position, .testimonial-grid-2 .testimonial-name, .testimonial-grid-2 .testimonial-position {
  padding: 2px 0;
}

.testimonial-2 .testimonial-name, .testimonial-grid-2 .testimonial-name {
  color: #000;
  padding-top: 20px;
}

.testimonial-2 .testimonial-position, .testimonial-grid-2 .testimonial-position {
  color: #fff;
}

.testimonial-2 .quote-left:before, .testimonial-grid-2 .quote-left:before {
  top: 50px;
}

.testimonial-2.testimonial-bg .testimonial-text, .testimonial-grid-2.testimonial-bg .testimonial-text {
  color: #777;
  background-color: #fff;
}

.testimonial-2.testimonial-bg .testimonial-text:after, .testimonial-grid-2.testimonial-bg .testimonial-text:after {
  border-top-color: #fff;
}

.testimonial-3, .testimonial-grid-3 {
  text-align: center;
}

.testimonial-3 .quote-left:before, .testimonial-grid-3 .quote-left:before {
  color: #ec5598;
  font-size: 40px;
  position: static;
}

.testimonial-3 .testimonial-position, .testimonial-grid-3 .testimonial-position {
  color: #ec5598;
}

.testimonial-3 .testimonial-name, .testimonial-3 .testimonial-position, .testimonial-grid-3 .testimonial-name, .testimonial-grid-3 .testimonial-position {
  display: inline-block;
}

.testimonial-4 .testimonial-text, .testimonial-grid-4 .testimonial-text {
  color: #fff;
  background-color: #4b3941;
  padding: 40px 30px;
}

.testimonial-4 .testimonial-pic, .testimonial-grid-4 .testimonial-pic {
  float: left;
  width: 90px;
  height: 90px;
  z-index: 2;
  border: 3px solid #ec5598;
  position: relative;
}

.testimonial-4 .testimonial-pic:after, .testimonial-grid-4 .testimonial-pic:after {
  height: 150%;
  content: "";
  z-index: -1;
  border-right: 3px solid #ec5598;
  position: absolute;
  top: -21px;
  left: 36px;
  transform: skew(25deg);
}

@media only screen and (max-width: 400px) {
  .testimonial-4 .testimonial-pic, .testimonial-grid-4 .testimonial-pic {
    width: 60px;
    height: 60px;
  }

  .testimonial-4 .testimonial-pic:after, .testimonial-grid-4 .testimonial-pic:after {
    height: 140%;
    position: absolute;
    top: -11px;
    left: 24px;
    transform: skew(30deg);
  }

  .testimonial-grid-4 .testimonial-pic {
    float: none;
    margin-bottom: 30px;
  }

  .testimonial-grid-4 .testimonial-paragraph {
    padding-left: 0;
    overflow: hidden;
  }
}

.testimonial-4 .testimonial-paragraph {
  padding-left: 20px;
  overflow: hidden;
}

.testimonial-4 .testimonial-detail, .testimonial-grid-4 .testimonial-detail {
  color: #fff;
  z-index: 0;
  background-color: #ec5598;
  padding: 15px 20px 15px 70px;
  display: inline-block;
  position: relative;
}

@media only screen and (max-width: 400px) {
  .testimonial-4 .testimonial-detail, .testimonial-grid-4 .testimonial-detail {
    min-width: 220px;
  }
}

.testimonial-4 .testimonial-detail .testimonial-name, .testimonial-grid-4 .testimonial-detail .testimonial-name, .testimonial-4 .testimonial-detail .testimonial-position, .testimonial-grid-4 .testimonial-detail .testimonial-position {
  margin: 0 5px;
  display: inline-block;
  position: relative;
}

.testimonial-4 .testimonial-detail .testimonial-position, .testimonial-grid-4 .testimonial-detail .testimonial-position {
  color: #000;
  margin: 0 10px;
}

@media only screen and (max-width: 400px) {
  .testimonial-4 .testimonial-detail .testimonial-name, .testimonial-grid-4 .testimonial-detail .testimonial-name, .testimonial-4 .testimonial-detail .testimonial-position, .testimonial-grid-4 .testimonial-detail .testimonial-position {
    margin: 0;
    display: block;
  }
}

.testimonial-4 .testimonial-detail .testimonial-position:after, .testimonial-grid-4 .testimonial-detail .testimonial-position:after {
  height: 50%;
  content: "";
  border: 1px solid #000;
  position: absolute;
  top: 5px;
  left: -10px;
  transform: skew(-25deg);
}

@media only screen and (max-width: 400px) {
  .testimonial-4 .testimonial-detail .testimonial-position:after, .testimonial-grid-4 .testimonial-detail .testimonial-position:after {
    display: none;
  }
}

.testimonial-4 .testimonial-detail .fa-quote-left, .testimonial-grid-4 .testimonial-detail .fa-quote-left {
  color: #ec5598;
  width: 55px;
  height: 100%;
  text-align: center;
  background-color: #4b3941;
  font-size: 30px;
  line-height: 55px;
  position: absolute;
  top: 0;
  left: 0;
}

.testimonial-5, .testimonial-grid-5 {
  margin-left: 70px;
  position: relative;
}

.testimonial-5 .testimonial-pic-block, .testimonial-grid-5 .testimonial-pic-block {
  position: absolute;
  top: 20%;
  left: -13%;
}

.testimonial-5 .testimonial-text, .testimonial-grid-5 .testimonial-text {
  padding: 40px 30px 40px 90px;
}

.testimonial-5 .testimonial-pic, .testimonial-grid-5 .testimonial-pic {
  float: left;
  width: 130px;
  height: 130px;
  z-index: 2;
  border: none;
  position: relative;
}

.testimonial-5 .testimonial-pic-block.radius-bx .testimonial-pic:after, .testimonial-grid-5 .testimonial-pic-block.radius-bx .testimonial-pic:after {
  content: "";
  width: 110%;
  height: 110%;
  z-index: -1;
  background-color: #ffbc13;
  border-radius: 100%;
  position: absolute;
  top: -2%;
  left: -2%;
}

.testimonial-5 .testimonial-name, .testimonial-grid-5 .testimonial-name, .testimonial-5 .testimonial-position, .testimonial-grid-5 .testimonial-position {
  display: block;
}

.testimonial-5 .fa-quote-left, .testimonial-grid-5 .fa-quote-left {
  font-size: 36px;
}

.testimonial-5 .testimonial-paragraph p, .testimonial-grid-5 .testimonial-paragraph p {
  padding: 5px 0;
}

.testimonial-5 .testimonial-name, .testimonial-grid-5 .testimonial-name {
  color: #2a2a2a;
  text-transform: uppercase;
  font-size: 15px;
}

.testimonial-5 .testimonial-position, .testimonial-grid-5 .testimonial-position {
  font-style: normal;
}

.testimonial-5 .testimonial-detail, .testimonial-grid-5 .testimonial-detail {
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (max-width: 480px) {
  .testimonial-5, .testimonial-grid-5 {
    margin-left: 0%;
    position: relative;
  }

  .testimonial-5 .testimonial-pic-block, .testimonial-grid-5 .testimonial-pic-block {
    left: inherit;
    position: inherit;
    text-align: center;
    top: inherit;
    padding-top: 30px;
  }

  .testimonial-5 .testimonial-text, .testimonial-grid-5 .testimonial-text {
    padding: 40px;
  }

  .testimonial-5 .testimonial-pic, .testimonial-grid-5 .testimonial-pic {
    float: none;
  }
}

.pricingtable-5 [class*="col-"] {
  width: 20%;
}

@media only screen and (max-width: 991px) {
  .pricingtable-5 [class*="col-"] {
    width: 100%;
  }
}

.pricingtable-inner {
  text-align: center;
}

.pricingtable-price {
  background-color: #ec5598;
  padding: 10px;
}

.pricingtable-bx {
  color: #666;
  color: #4b3941;
  font-family: Arial;
  font-size: 44px;
}

.pricingtable-type {
  text-transform: uppercase;
  color: #fff;
  font-size: 24px;
}

.pricingtable-type:before {
  content: "/";
  margin-right: 3px;
}

.pricingtable-title {
  text-transform: uppercase;
  background-color: #4b3941;
  padding: 20px;
}

.pricingtable-title * {
  color: #fff;
  margin: 0;
  font-size: 20px;
}

.pricingtable-features {
  border: 1px solid #e9e9e9;
  margin: 0;
  padding: 0;
  list-style: none;
}

.pricingtable-features li {
  border-bottom: 1px solid #e9e9e9;
  padding: 10px;
}

.pricingtable-features li i {
  margin: 0 3px;
}

.pricingtable-features li:nth-child(2n) {
  background-color: #f4f7f8;
}

.pricingtable-features li:last-child {
  border-bottom: none;
}

.pricingtable-footer {
  background-color: #4b3941;
  margin-top: -1px;
  padding: 20px;
}

.pricingtable-highlight {
  z-index: 99;
  margin: -20px 0;
  position: relative;
  box-shadow: 0 0 10px 5px #0003;
}

.pricingtable-highlight .pricingtable-price {
  padding: 20px 10px;
}

.pricingtable-highlight .pricingtable-footer {
  padding: 30px 20px;
}

.no-col-gap .pricingtable-wrapper {
  margin-left: -1px;
}

.pricing-table-style-2 .pricingtable-price {
  z-index: 0;
  position: relative;
  overflow: hidden;
}

.pricing-table-style-2 .pricingtable-price:after {
  height: 90%;
  width: 96%;
  content: "";
  z-index: -1;
  background-color: #4b3941;
  position: absolute;
  top: 0;
  left: -40px;
}

.pricing-table-style-2 .pricingtable-price .pricingtable-bx {
  color: #fff;
  font-size: 48px;
}

.pricing-table-style-2 .pricingtable-title {
  background-color: #ec5598;
}

.pricing-table-style-2 .pricingtable-title * {
  color: #000;
}

@media only screen and (max-width: 1200px) {
  .pricing-table-style-2 .pricingtable-price .pricingtable-bx {
    font-size: 36px;
  }
}

@media only screen and (max-width: 991px) {
  .pricing-table-style-2 .pricingtable-price .pricingtable-bx {
    font-size: 20px;
  }

  .pricing-table-style-2 .pricingtable-price .pricingtable-type {
    font-size: 14px;
  }
}

@media only screen and (max-width: 766px) {
  .pricing-table-style-2 .pricingtable-price .pricingtable-bx {
    font-size: 70px;
  }

  .pricing-table-style-2 .pricingtable-price .pricingtable-type {
    font-size: 24px;
  }
}

@media only screen and (max-width: 420px) {
  .pricing-table-style-2 .pricingtable-price .pricingtable-bx {
    font-size: 40px;
  }

  .pricing-table-style-2 .pricingtable-price .pricingtable-type {
    font-size: 18px;
  }

  .pricingtable-bx {
    font-size: 40px;
  }

  .pricingtable-type {
    font-size: 18px;
  }
}

.pricing-table-style-3 .pricingtable-inner {
  z-index: 1;
  position: relative;
  overflow: hidden;
}

.pricing-table-style-3 .pricingtable-inner.radius {
  border-radius: 40px;
}

.pricing-table-style-3 .pricingtable-inner.pricingtable-highlight {
  z-index: 2;
}

.pricing-table-style-3 .pricingtable-type {
  color: #4b3941;
}

.pricing-table-style-3 .pricingtable-title {
  background-color: inherit;
  width: 190px;
  margin: 0 auto;
  padding: 10px 0;
}

.pricing-table-style-3 .pricingtable-title * {
  color: #4b3941;
  font-size: 18px;
  font-weight: 600;
}

.pricing-table-style-3 .pricingtable-price, .pricing-table-style-3 .pricingtable-features li:nth-child(2n), .pricing-table-style-3 .pricingtable-footer {
  background-color: inherit;
}

.pricing-table-style-3 .pricingtable-features {
  border: none;
}

.pricing-table-style-3 .pricingtable-features li {
  border-bottom: 0;
  font-size: 18px;
  font-weight: 600;
}

.pricing-table-style-3 .overlay-main {
  z-index: -1;
}

.pricing-table-style-4 .pricingtable-inner {
  border-bottom: 4px solid #ec5598;
  position: relative;
  overflow: hidden;
}

.pricing-table-style-4 .pricingtable-inner.radius {
  border-radius: 40px;
}

.pricing-table-style-4 .pricingtable-type {
  color: #fff;
}

.pricing-table-style-4 .pricingtable-inner-overlay {
  z-index: 1;
  position: relative;
}

.pricing-table-style-4 .pricingtable-inner-overlay .overlay-main {
  z-index: -1;
}

.pricing-table-style-4 .pricingtable-title {
  background-color: inherit;
  color: #fff;
  padding: 60px 0 0;
}

.pricing-table-style-4 .pricingtable-title *, .pricing-table-style-4 .pricingtable-bx {
  color: #fff;
}

.pricing-table-style-4 .pricingtable-price {
  padding: 20px 10px;
}

.pricing-table-style-4 .pricingtable-highlight .pricingtable-price {
  padding: 30px 10px;
}

.pricing-table-style-4 .pricingtable-price, .pricing-table-style-4 .pricingtable-features li:nth-child(2n), .pricing-table-style-4 .pricingtable-footer {
  background-color: inherit;
}

.pricing-table-style-4 .pricingtable-features {
  border: none;
  padding-top: 20px;
}

.pricing-table-style-4 .pricingtable-features li {
  border-bottom: 0;
}

.alert.alert-sm {
  padding: 5px 15px;
  font-size: 12px;
}

.alert.alert-lg {
  padding: 25px 15px;
  font-size: 16px;
}

.alert.alert-xl {
  padding: 35px 15px;
  font-size: 18px;
}

.alert[class*="alert-"] i {
  margin-right: 8px;
}

.alert.no-radius {
  border-radius: 0;
}

.alert.no-bg {
  background-color: #0000;
  border-width: 2px;
}

.alert[class*="alert-"] ul {
  margin-top: 10px;
  padding-left: 25px;
}

.alert[class*="alert-"] ul li:before {
  color: #a94442;
}

.wt-img-effect {
  display: block;
  position: relative;
  overflow: hidden;
}

.wt-img-effect img {
  width: 100%;
  height: auto;
  -o-transition: all .25s;
  margin: 0;
  transition: all .25s;
  display: block;
  box-shadow: 0 0 #0000;
}

.wt-img-effect.opacity img:hover {
  opacity: .8;
}

.wt-img-effect.zoom-slow img {
  -o-transition: all 10s;
  transition: all 10s;
}

.wt-img-effect.zoom-slow:hover img {
  transform: scale(2);
}

.wt-img-effect.zoom:hover img {
  transform: scale(1.5);
}

@media only screen and (max-width: 420px) {
  .wt-img-effect.zoom:hover img {
    transform: none;
  }
}

.wt-img-effect.shrink:hover img {
  transform: scale(.8);
}

.wt-img-effect.side-pan:hover img {
  margin-left: -9%;
  transform: scale(1.2);
}

.wt-img-effect.vertical-pan:hover img {
  margin-top: -10%;
  transform: scale(1.2);
}

.wt-img-effect.fade-in {
  background: #000;
}

.wt-img-effect.fade-in img {
  opacity: .65;
}

.wt-img-effect.fade-in:hover img {
  opacity: 1;
}

.wt-img-effect.fade-out {
  background: #000;
}

.wt-img-effect.fade-out:hover img {
  opacity: .7;
}

.wt-img-effect.rotate:hover img {
  transform: scale(1.5)rotate(-20deg);
}

.wt-img-effect.sepia img {
  -webkit-filter: sepia();
  filter: sepia();
}

.algo-image-hover.sepia:hover img {
  -webkit-filter: sepia(0);
  filter: sepia(0);
}

.wt-img-effect.blurr img {
  -o-transition: all .2s;
  transition: all .2s;
}

.wt-img-effect.blurr:hover img {
  filter: blur(3px);
}

.wt-img-effect.blurr-invert img {
  -o-transition: all .2s;
  filter: blur(3px);
  transition: all .2s;
}

.wt-img-effect.blurr-invert:hover img {
  filter: blur();
}

.wt-img-effect.off-color img {
  filter: grayscale(0);
}

.wt-img-effect.off-color:hover img, .wt-img-effect.on-color img {
  filter: grayscale();
}

.wt-img-effect.on-color:hover img {
  filter: grayscale(0);
}

.modal-xlg {
  width: 1000px;
}

.provider_registration .bootstrap-select div.dropdown-menu ul li a {
  font-weight: 600;
}

.provider_registration .bootstrap-select div.dropdown-menu ul li a span.childcat {
  color: #7c7c7c;
  font-weight: 400;
}

@media only screen and (max-width: 1024px) {
  .modal-xlg {
    width: auto;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.social-icons {
  margin-left: -3px;
  margin-right: -3px;
  font-size: 12px;
  list-style: none;
}

.social-icons li {
  text-align: center;
  display: inline-block;
}

.social-icons li a {
  padding: 3px 5px;
  display: block;
}

.social-icons.social-md {
  font-size: 18px;
}

.social-icons.social-lg li a {
  font-size: 24px;
}

.social-icons.has-bg li a:hover, .social-icons.social-light a {
  color: #fff;
}

.social-icons.social-light.has-bg li a:hover {
  color: #ec5598;
}

.social-icons.social-dark a {
  color: #777;
}

.social-icons.social-dark.has-bg li a:hover {
  color: #ec5598;
}

.social-icons.social-square a, .social-icons.social-radius a {
  width: 24px;
  height: 24px;
  border: 1px solid #0003;
  padding: 0;
  line-height: 24px;
}

.social-icons.social-square.social-md a, .social-icons.social-radius.social-md a {
  width: 36px;
  height: 36px;
  padding: 0;
  line-height: 36px;
}

.social-icons.social-square.social-lg a, .social-icons.social-radius.social-lg a {
  width: 40px;
  height: 40px;
  padding: 0;
  line-height: 40px;
}

.social-icons.social-square.social-primary a, .social-icons.social-radius.social-primary a {
  color: #fff;
  background-color: #ec5598;
  border: 1px solid #0000001a;
}

.social-icons.social-square.social-primary a:hover, .social-icons.social-radius.social-primary a:hover {
  background-color: #4b3941;
}

.social-icons.social-square.social-primary.has-bg a:hover, .social-icons.social-radius.social-primary.has-bg a:hover {
  border: 1px solid #fff;
}

.social-icons.social-square.social-dark a, .social-icons.social-radius.social-dark a {
  background-color: #fff;
}

.social-icons.social-square.social-dark a:hover, .social-icons.social-radius.social-dark a:hover {
  color: #fff;
  background-color: #4b3941;
}

.social-icons.social-square.social-dark.has-bg a:hover, .social-icons.social-radius.social-dark.has-bg a:hover {
  border: 1px solid #ec5598;
}

.social-icons.social-square.social-darkest a, .social-icons.social-radius.social-darkest a {
  border: 1px solid #4a4c4c;
}

.social-icons.social-darkest a {
  color: #454747;
}

.social-icons.social-square.social-darkest a:hover, .social-icons.social-radius.social-darkest a:hover {
  color: #4a4c4c;
  background-color: #ec5598;
}

.social-icons.social-radius a {
  border-radius: 100px;
}

.social-icons.social-square.social-dark.white-border a, .social-icons.social-radius.social-dark.white-border a {
  color: #fff;
  background: none;
  border: 1px solid #fff;
}

.wt-social-icon {
  margin: 0 -3px;
  padding: 0;
  display: inline-block;
}

.wt-social-icon li {
  padding: 0;
  font-size: 12px;
  display: inline-block;
}

.wt-social-icon li a {
  width: 24px;
  height: 24px;
  padding: 4px;
  display: inline-block;
}

.wt-social-icon li .fa {
  vertical-align: middle;
}

.wt-social-icon.border li {
  padding: 0 3px;
}

.wt-social-icon.border li a {
  border: 1px solid #efeded;
  border-radius: 2px;
}

.wt-social-links.dark li {
  padding: 0 3px;
}

.wt-social-icon.dark li a {
  color: #777;
  border: 1px solid #777;
  border-radius: 2px;
}

.wt-share-icon li {
  width: 36px;
  float: left;
  white-space: nowrap;
  border: none;
  margin-right: 0;
  display: block;
  overflow: hidden;
}

.wt-share-icon li a {
  color: #fff;
}

.wt-share-icon li i {
  color: #fff;
  width: 36px;
  height: 34px;
  text-align: center;
  border-right: none;
  padding: 0;
  font-size: 14px;
  line-height: 34px;
}

.wt-share-icon li.fb {
  background: #354d89;
}

.wt-share-icon li.fb i {
  background: #3a5799;
}

.wt-share-icon li.gp {
  background: #d34b2b;
}

.wt-share-icon li.gp i {
  background: #e35736;
}

.wt-share-icon li.tw {
  background: #029fdf;
}

.wt-share-icon li.tw i {
  background: #00abf0;
}

.wt-share-icon li.dig {
  background: #1d61aa;
}

.wt-share-icon li.dig i {
  background: #2b6fb8;
}

.wt-share-icon li.lin {
  background: #0176be;
}

.wt-share-icon li.lin i {
  background: #0082ca;
}

.wt-share-icon li.pin {
  background: #ac0104;
}

.wt-share-icon li.pin i {
  background: #bd0e15;
}

.wt-share-icon li:hover {
  width: 90px;
}

.wt-breadcrumb {
  margin: 0;
  list-style: none;
}

.wt-breadcrumb li {
  color: #333;
  margin-right: 3px;
  padding: 0;
  display: inline-block;
  position: relative;
}

.wt-breadcrumb li a {
  color: #ec5598;
}

.wt-breadcrumb li:last-child {
  color: #676767;
}

.wt-breadcrumb li:last-child:after {
  display: none;
}

.breadcrumb-style-1 li:after {
  content: "/";
  margin-left: 7px;
}

.breadcrumb-style-2 li:after {
  content: "";
  margin-left: 7px;
  font-family: FontAwesome;
}

.breadcrumb-style-3 li {
  padding: 20px 15px 20px 20px;
}

.breadcrumb-style-3 li:after, .breadcrumb-style-3 li:before {
  content: "";
  height: 50%;
  width: 1px;
  background-color: #e8e9e9;
  border-right: 1px solid #ccc;
  position: absolute;
  right: 0;
}

.breadcrumb-style-3 li:after {
  top: 0;
  transform: skew(30deg);
}

.breadcrumb-style-3 li:before {
  bottom: 0;
  transform: skew(-30deg);
}

.breadcrumb-style-3 li:last-child:before {
  display: none;
}

.google-map {
  width: 100%;
}

.google-map iframe {
  width: 100%;
  height: 300px;
}

.google-map-gray iframe {
  width: 100%;
  filter: grayscale();
  border: 0;
}

.progress .tooltip {
  float: right;
  margin-top: 5px;
  position: relative;
}

.progress .tooltip > .tooltip-inner {
  color: #fff;
  background-color: #212121;
  border-radius: 0;
  padding: 3px 10px;
  font-size: 12px;
  font-weight: bold;
}

.progress .popOver + .tooltip > .tooltip-arrow {
  border-top: 5px solid #212121;
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
}

.progress {
  border-radius: 0;
  overflow: visible;
}

.progress-bar {
  transition: width 1.5s ease-in-out;
}

.wt-probar-1 {
  height: 4px;
}

.with-overlay.progress .tooltip > .tooltip-inner {
  color: #000;
  background-color: #fff;
}

.with-overlay.progress .popOver + .tooltip > .tooltip-arrow {
  border-top: 5px solid #fff;
}

.count-row {
  background: #333;
}

#countdown-clock {
  text-align: center;
  width: 80%;
  border: 1px solid #3f3f3f;
  margin: 17px 0;
  display: table;
}

#countdown-clock span {
  width: 1%;
  color: #fff;
  text-transform: uppercase;
  color: #999;
  border-right: 1px solid #3f3f3f;
  padding: 5px 15px;
  font-weight: 600;
  display: table-cell;
}

#countdown-clock span:last-child {
  border-right: none;
}

#countdown-clock span b {
  color: #fff;
  font-size: 25px;
  font-weight: 900;
  display: block;
}

@media only screen and (max-width: 991px) {
  #countdown-clock {
    width: 100%;
  }
}

label {
  margin-bottom: 10px;
  font-weight: 600;
}

.panel-default, .panel-default > .panel-heading {
  color: inherit;
  border-color: #e7ecf1;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #e7ecf1;
}

.form-group {
  margin-bottom: 25px;
}

.form-control {
  box-shadow: none;
  height: 40px;
  border-color: #e1e6eb;
  padding: 9px 12px;
  font-size: 13px;
  line-height: 20px;
}

.form-control:focus {
  border-color: #e1e1e1;
  outline: 0;
}

.form-control:focus, .has-error .form-control:focus {
  box-shadow: none;
}

.form-control.kv-fileinput-caption {
  height: 39px;
}

.form-group.form-inline .radio, .form-group.form-inline .checkbox {
  margin-right: 15px;
}

.input-group {
  width: 100%;
}

.input-group .form-control, .input-group-addon, .input-group-btn {
  display: table-cell;
}

.input-group-addon {
  background: #fff;
  border-color: #e1e1e1;
  padding: 6px 14px;
  font-size: 16px;
}

.input-group-addon.font-size-20 {
  font-size: 20px;
}

.input-group-addon.fixed-w {
  text-align: center;
  width: 40px;
  padding: 6px 0;
}

.input-group-addon.v-align-t {
  line-height: 30px;
}

.input-group-addon {
  color: #545454;
  background: #fff;
  border: 1px solid #e1e1e1;
  padding: 6px 14px;
  font-size: 16px;
}

.dropdown-menu {
  font-size: 13px;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

.bootstrap-select .dropdown-toggle {
  height: 40px;
  color: #999;
  font-size: 13px;
  background-color: #fff !important;
  border: 1px solid #e7ecf1 !important;
}

.bootstrap-select .dropdown-toggle:after {
  display: none;
}

.bootstrap-select .dropdown-toggle:active, .bootstrap-select .dropdown-toggle:focus, .bootstrap-select .dropdown-toggle:hover {
  box-shadow: none !important;
  background-color: #fff !important;
  border: 1px solid #e9e9e9 !important;
  outline: 0 !important;
}

.bootstrap-select:active, .bootstrap-select:focus {
  box-shadow: none !important;
  border: 0 !important;
  outline: 0 !important;
}

.bootstrap-select.open, .bootstrap-select.open .dropdown-toggle {
  box-shadow: none !important;
  outline: 0 !important;
}

.bootstrap-select div.dropdown-menu {
  box-shadow: none;
  border: 1px solid #e9e9e9;
  border-radius: 0;
  margin-top: -1px;
  padding: 0;
  font-size: 13px;
}

.bootstrap-select div.dropdown-menu ul li {
  padding: 0;
}

.bootstrap-select div.dropdown-menu ul li a {
  padding: 5px 15px;
}

.bootstrap-select div.dropdown-menu ul li a img {
  border-radius: 100%;
  margin-right: 5px;
}

.bootstrap-select .bs-searchbox .form-control {
  height: 30px;
  padding: 5px 10px;
}

input[type="checkbox"], input[type="radio"] {
  opacity: 0;
  margin-left: 1px !important;
}

input[type="checkbox"] + label, input[type="radio"] + label {
  display: block;
  position: relative;
  padding-left: 20px !important;
}

input[type="checkbox"] + label:hover:before, input[type="radio"] + label:hover:before {
  border-color: #3396d1;
}

input[type="checkbox"] + label:before, input[type="radio"] + label:before {
  content: "";
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 2px solid #b6b7b8;
  border-radius: 0;
  margin-left: -20px;
  line-height: 1;
  transition: all .1s linear;
  display: inline-block;
  position: absolute;
  top: 2px;
}

input[type="checkbox"] + label:after {
  color: #fff;
  content: "";
  font-family: FontAwesome;
  font-size: 0;
  transition: all .1s linear;
  position: absolute;
  top: 7px;
  left: 6px;
}

input[type="checkbox"]:checked + label:before {
  border-width: 7px;
  border-color: #3396d1;
}

input[type="checkbox"]:checked + label:after {
  font-size: 10px;
  top: 2px;
  left: 2px;
}

input[type="radio"] + label:before {
  content: "";
  border-radius: 50%;
}

input[type="radio"]:checked + label:before {
  border-width: 5px;
  border-color: #3396d1;
}

.form-inline .checkbox input[type="checkbox"], .form-inline .radio input[type="radio"] {
  position: absolute;
  bottom: 0;
  left: 0;
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type="file"] {
  cursor: inherit;
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  text-align: right;
  background: none;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.bootstrap-touchspin .input-group-btn-vertical {
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
  position: relative;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn {
  float: none;
  width: 100%;
  max-width: 100%;
  height: 21px;
  background: #151414;
  border: 1px solid #ddd;
  margin-left: -1px;
  padding: 0 10px;
  line-height: 20px;
  display: block;
  position: relative;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  border-radius: 0;
  margin-top: -2px;
}

.bootstrap-touchspin .input-group-btn-vertical i {
  color: #9fa0a1;
  font-size: 9px;
  font-weight: normal;
  position: absolute;
  top: 4px;
  left: 5px;
}

.input-rating input[type="radio"] {
  display: none;
}

.input-rating input[type="radio"] + label {
  width: 24px;
  margin-left: -4px;
  padding-right: 0;
  display: inline-block;
  padding-left: 0 !important;
}

.input-rating input[type="radio"] + label:first-of-type {
  margin-left: 0;
}

.input-rating input[type="radio"] + label:before {
  color: #ffd925;
  content: "";
  height: 14px;
  text-align: center;
  width: 14px;
  background-color: #0000;
  border: none;
  margin-left: 0;
  font-family: FontAwesome;
  font-size: 20px;
  line-height: 1;
  transition: none;
  display: inline-block;
  position: static;
  top: 2px;
}

.input-rating input[type="radio"]:checked + label:before, .input-rating input[type="radio"] + label.marked:before, .input-rating input[type="radio"] + label.filled:before, .input-rating input[type="radio"] + label.hovered.filled:before {
  content: "";
}

.input-rating input[type="radio"] + label.hovered:before {
  content: "";
}

.rating-bx {
  color: #ffd925;
  font-size: 14px;
}

.rating-bx i {
  margin: 0 2px;
}

.rating-container .rating-stars:before {
  text-shadow: none;
}

.rating-container {
  color: #c8c8c8;
}

.rating-container .rating-stars {
  color: #3396d1;
}

.has-feedback label ~ .form-control-feedback {
  top: 31px;
}

.has-error .bootstrap-select .dropdown-toggle, .has-error .bootstrap-select .dropdown-toggle:hover, .has-error .bootstrap-select .dropdown-toggle:focus {
  border-color: #a94442 !important;
}

.has-success .bootstrap-select .dropdown-toggle, .has-success .bootstrap-select .dropdown-toggle:hover, .has-success .bootstrap-select .dropdown-toggle:focus {
  border-color: #3c763d !important;
}

.resize-vertical {
  resize: vertical;
}

.resize-horizontal {
  resize: horizontal;
}

.resize-none {
  resize: none;
}

.loading-area {
  width: 100%;
  height: 100%;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.loading-box {
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 9999;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
}

.loading-pic {
  width: 100%;
  z-index: 99999;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.loading-cover, .loading-srh-bar, .loading-map {
  width: 100%;
  height: 100%;
  z-index: 2;
  background: #fffc;
  position: absolute;
  top: 0;
  left: 0;
}

.loading-cover .fa-spinner, .loading-srh-bar .fa-spinner, .loading-map .fa-spinner {
  color: #333;
  margin: -10px;
  font-size: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.wt-banner-outer, .wt-success-top, .wt-error-top {
  margin-bottom: 0;
}

.alert-bx.alert-info {
  border: 1px solid #bce8f1;
  padding: 15px;
}

.widget {
  margin-bottom: 40px;
}

.widget-title {
  margin: 0 0 25px;
}

.widget-title, .widget-title-two, .widget-title-three {
  margin-bottom: 30px;
  padding-bottom: 15px;
  position: relative;
}

.widget-title-two:after, .widget-title-two:before, .widget-title-three:after, .widget-title-three:before {
  content: "";
  margin: 10px 0;
  position: absolute;
}

.widget-title:after {
  content: "";
  height: 3px;
  width: 70px;
  background-color: #ec5598;
  margin: 10px 0;
  position: absolute;
  bottom: -10px;
  left: 30px;
}

.widget-title:before {
  content: "";
  width: 10px;
  height: 10px;
  margin-bottom: 10px;
  font-family: FontAwesome;
  font-size: 18px;
  display: inline-block;
  position: absolute;
  bottom: -8px;
  left: 0;
}

.widget .widget-title, .widget .widget-title-two, .widget .post-title {
  text-transform: none;
}

.recent-posts-entry ul, .category-entry ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.widget_categories ul, .widget_archive ul, .widget_meta ul, .widget_pages ul, .widget_recent_comments ul, .widget_nav_menu ul, .widget_rss ul, .widget_recent_entries ul, .widget_getintuch ul, .widget_services ul {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.widget_categories ul li, .widget_archive ul li, .widget_meta ul li, .widget_pages ul li, .widget_recent_comments ul li, .widget_nav_menu li, .widget_recent_entries ul li, .widget_services ul li {
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 0;
  padding: 10px 10px 10px 15px;
  line-height: 20px;
  position: relative;
}

.widget_archive ul li span.badge {
  background-color: #ffbc13;
  margin: 0 5px;
  padding: 4px 7px;
}

.widget_categories ul li a, .widget_archive ul li a, .widget_meta ul li a, .widget_pages ul li a, .widget_recent_comments ul li a, .widget_nav_menu li a, .widget_recent_entries ul li a, .widget_services ul li a {
  color: #767676;
}

.widget_categories ul li a:hover, .widget_archive ul li a:hover, .widget_meta ul li a:hover, .widget_pages ul li a:hover, .widget_recent_comments ul li a:hover, .widget_nav_menu li a:hover, .widget_recent_entries ul li a:hover, .widget_services ul li a:hover {
  color: #ec5598;
}

.widget_categories ul li:before, .widget_archive ul li:before, .widget_meta ul li:before, .widget_pages ul li:before, .widget_recent_comments ul li:before, .widget_nav_menu ul li:before, .widget_useful_links ul li:before, .widget_recent_entries ul li:before, .widget_services ul li:before {
  content: "";
  font-family: FontAwesome;
  display: block;
  position: absolute;
  top: 10px;
  left: 0;
}

.widget_categories ul li li, .widget_archive ul li li, .widget_meta ul li li, .widget_pages ul li li, .widget_recent_comments ul li li, .widget_nav_menu li li, .widget_services li li {
  border-bottom: none;
  padding: 0 5px 0 10px;
  line-height: 28px;
}

.widget_categories ul li li:before, .widget_archive ul li li:before, .widget_meta ul li li:before, .widget_pages ul li li:before, .widget_recent_comments ul li li:before, .widget_nav_menu li li:before, .widget_services li li:before {
  top: 0;
  left: -8px;
}

.search-bx .btn {
  color: #3396d1;
  border-left-color: #fff;
  padding: 9px 10px;
}

.widget_search .screen-reader-text {
  display: block;
}

.searchform {
  position: relative;
}

.searchform input[type="text"] {
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  padding: 10px 90px 10px 15px;
}

.searchform input[type="submit"] {
  height: 40px;
  color: #fff;
  background-color: #ec5598;
  border: none;
  padding: 10px 15px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.searchform input[type="submit"]:hover, .searchform input[type="submit"]:focus, .searchform input[type="submit"]:active {
  background-color: #6ab33e;
  border-bottom-color: #5a9e2f;
  color: #fff !important;
}

.recent-posts-entry .post-date, .tweets-feed-entry .tweet-date {
  color: #3396d1;
  font-style: normal;
}

.widget .post-title {
  margin-bottom: 8px;
  font-size: 13px;
  line-height: 16px;
}

.recent-posts-entry .widget-post {
  margin-bottom: 5px;
  padding: 5px;
}

.recent-posts-entry .widget-post-bx:last-child {
  border-bottom: none;
}

.recent-posts-entry .wt-post-media {
  float: left;
  width: 65px;
}

.recent-posts-entry .wt-post-info {
  background: none;
  border: none;
  margin-left: 80px;
  padding: 0;
}

.recent-posts-entry .post-meta span {
  margin-right: 10px;
}

.widget_recent_comments ul li:before {
  content: "";
}

.widget_recent_comments ul li {
  color: #999;
  padding-left: 20px;
}

.widget_meta ul li a abbr[title] {
  color: #333;
  border-bottom: none;
}

.widget_calendar caption:after {
  color: #707070;
  content: ">";
  margin: 0 0 0 5px;
  font-family: FontAwesome;
}

.widget_calendar table {
  border-collapse: separate;
  border-spacing: 2px;
  width: 100%;
}

.widget_calendar caption {
  color: #1a1a1a;
  text-transform: uppercase;
  background-color: #e0e0e0;
  padding: 8px 10px;
  font-weight: 600;
}

.widget_calendar thead {
  background-color: #e63f75;
}

.widget_calendar tfoot tr td {
  border: none;
  padding: 0;
}

.widget_calendar tfoot tr td a {
  background-color: #fff;
  padding: 4px 10px;
}

.widget_calendar table thead tr th {
  text-align: center;
  color: #fff;
  border: none;
  padding: 5px;
  font-size: 11px;
}

.widget_calendar table tbody td {
  text-align: center;
  color: #444;
  background-color: #f9f9f9;
  border: none;
  padding: 6px 5px;
  font-size: 13px;
}

.widget_calendar table tbody td#today {
  color: #fff;
  background-color: #ffbc13;
}

.widget_calendar table tbody td#today a {
  color: #fff;
}

.widget_tag_cloud a {
  color: #333;
  background-color: #f6f7f8;
  border-radius: 20px;
  margin: 0 0 5px;
  padding: 12px 14px;
  font-size: 14px;
  display: inline-block;
}

.side-bar .widget_tag_cloud a:hover {
  background-color: #ccc;
}

.widget_archive select, .widget_text select {
  width: 100%;
  border: 1px solid #ccc;
  padding: 5px;
}

.widget_text select option {
  width: 100%;
}

.widget_categories li {
  text-align: right;
}

.widget_categories li a {
  float: left;
}

.widget_categories li span.badge {
  background-color: #ffbc13;
  padding: 4px 7px;
}

.widget_categories .dropdown-menu li:before {
  top: 5px;
  left: 5px;
}

.widget_categories li a:hover {
  color: #ec5598;
}

.widget_rss ul {
  margin: 0;
  line-height: 20px;
}

.widget_rss ul li {
  margin-bottom: 15px;
  line-height: 20px;
}

.widget_rss ul .rsswidget {
  color: #333;
}

.widget_rss ul .rss-date {
  color: #999;
  font-style: italic;
}

.widget_rss ul .rssSummary {
  padding: 5px 0;
}

.widget_rss ul cite {
  color: #333;
  font-weight: 600;
}

.widget_getintuch {
  padding-top: 10px;
}

.widget_getintuch li {
  margin-bottom: 20px;
  padding-left: 40px;
  position: relative;
}

.widget_getintuch b, .widget_getintuch strong {
  text-transform: uppercase;
  display: block;
}

.widget_getintuch i {
  color: #fff;
  text-align: center;
  width: 30px;
  height: 30px;
  background-color: #ec5598;
  font-size: 14px;
  line-height: 30px;
  position: absolute;
  top: 5px;
  left: 0;
}

.widget_gallery ul {
  flex-wrap: wrap;
  padding-left: 0;
  display: flex;
}

.widget_gallery li {
  width: 25%;
  background-color: #000;
  margin-bottom: 0;
  display: inline-block;
}

.widget_gallery li a {
  position: relative;
}

.widget_gallery li a:after {
  content: "";
  color: #fff;
  opacity: 0;
  margin: -10px;
  font-family: FontAwesome;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.widget_gallery li:hover img {
  opacity: .6;
  margin-bottom: 0;
}

.widget_gallery li:hover a:after {
  opacity: 1;
}

.widget_gallery a {
  display: block;
}

@media only screen and (max-width: 991px) {
  .widget_gallery li {
    width: 25%;
  }
}

@media only screen and (max-width: 480px) {
  .widget_gallery li {
    width: 33%;
  }
}

.widget_categories .dropdown-menu ul li:before {
  display: none;
}

.widget_categories .dropdown-menu ul li {
  text-align: left;
  border: none;
}

.widget_categories .dropdown-menu ul li a {
  float: none;
}

.widget-quick-form .form-group {
  margin-bottom: 10px;
}

.widget-quick-form input, .widget-quick-form button, .widget-quick-form textarea {
  resize: none;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
}

.widget-quick-form input, .widget-quick-form textarea {
  background-color: #f6f7f8;
}

.widget-quick-form .form-control::-webkit-input-placeholder {
  color: #1a1a1a;
  font-weight: bold;
}

.widget-quick-form .form-control::-moz-placeholder {
  color: #1a1a1a;
  font-weight: bold;
}

.widget-quick-form .form-control:-ms-input-placeholder {
  color: #1a1a1a;
  font-weight: bold;
}

.widget-quick-form .form-control:-moz-placeholder {
  color: #1a1a1a;
  font-weight: bold;
}

.widget_newsletter-2 .newsletter-bx {
  z-index: 9;
  background-color: #f6f7f8;
  position: relative;
  overflow: hidden;
}

.widget_newsletter-2 .newsletter-bx .newsletter-icon {
  position: absolute;
  top: -5px;
  right: 5px;
}

.widget_newsletter-2 .newsletter-bx .newsletter-icon i {
  z-index: 0;
  color: #0000000d;
  font-size: 75px;
}

.widget-client, .widget-client .ow-client-logo {
  background-color: #f6f7f8;
}

.widget_twitter ul {
  margin-bottom: 0;
  margin-left: 15px;
  list-style: none;
}

.widget_twitter ul li {
  padding-bottom: 15px;
  position: relative;
}

.widget_twitter ul li .twitter-w-icon {
  font-size: 18px;
  position: absolute;
  top: 2px;
  left: -24px;
}

.widget_twitter a.site-button.outline.black {
  border-color: #e6e6e6;
  padding: 6px 14px;
}

table.table-bordered {
  border-color: #ddd !important;
}

.table-behavior {
  display: table;
}

.table-behavior .table-cell-behavior {
  float: none;
  display: table-cell;
}

.wt-responsive-table td, .wt-responsive-table th {
  padding: 8px !important;
}

@media only screen and (max-width: 800px) {
  #no-more-tables table, #no-more-tables thead, #no-more-tables tbody, #no-more-tables th, #no-more-tables td, #no-more-tables tr {
    display: block;
  }

  #no-more-tables thead tr {
    display: none;
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  #no-more-tables tr {
    border: 1px solid #ccc;
  }

  #no-more-tables td {
    white-space: normal;
    text-align: left;
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
  }

  #no-more-tables td:before {
    width: 45%;
    white-space: nowrap;
    text-align: left;
    content: attr(data-title);
    padding-right: 10px;
    font-weight: bold;
    position: absolute;
    top: 6px;
    left: 6px;
  }
}

.yt-thum-box img {
  margin: -9.5% 0%;
}

.bs-glyphicons-wrap [class*="col-"], .fa-icon-wrap [class*="col-"], .outline-fonts-wrap [class*="col-"] {
  min-height: 100px;
  border: 1px solid #fff;
  padding: 10px;
  display: block;
}

.outline-fonts-wrap [class*="col-"] {
  min-height: 120px;
}

.bs-glyphicons-wrap [class*="col-"] a, .fa-icon-wrap [class*="col-"] a, .outline-fonts-wrap [class*="col-"] a {
  display: grid;
}

.bs-glyphicons-wrap [class*="col-"] span, .fa-icon-wrap [class*="col-"] span, .outline-fonts-wrap [class*="col-"] span {
  text-align: center;
  color: #767676;
  display: block;
}

.bs-glyphicons-wrap [class*="col-"] span.glyphicon, .fa-icon-wrap [class*="col-"] span.fa {
  margin: 0 auto;
  padding: 5px;
  font-size: 24px;
  display: table;
}

.bs-glyphicons-wrap [class*="col-"] span.glyphicon-class, .fa-icon-wrap [class*="col-"] span.fa-icon-info, .outline-fonts-wrap [class*="col-"] span.flaticon-info {
  font-size: 13px;
}

.bs-glyphicons-wrap [class*="col-"]:hover, .fa-icon-wrap [class*="col-"]:hover, .outline-fonts-wrap [class*="col-"]:hover {
  color: #fff;
  background-color: #4b3941;
}

.bs-glyphicons-wrap [class*="col-"]:hover span, .fa-icon-wrap [class*="col-"]:hover span, .outline-fonts-wrap [class*="col-"]:hover span, .call-to-action-left, .call-to-action-left h1, .call-to-action-left h2, .call-to-action-left h3, .call-to-action-left h4, .call-to-action-left h5, .call-to-action-left h6, .call-to-action-left p {
  color: #fff;
}

.call-to-action-left p:last-child {
  margin-bottom: 0;
}

.call-to-action-right {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .call-to-action-right {
    text-align: left;
    padding-top: 0;
    padding-bottom: 20px;
  }
}

.call-to-action-skew .call-to-action-left {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .call-to-action-skew .call-to-action-left:after {
    border-right: none;
    right: -15px;
  }

  .call-to-action-skew .call-to-action-left.p-r50 {
    padding-right: 0;
  }

  .call-to-action-skew .call-to-action-right {
    padding-top: 10px;
  }
}

.call-to-action-skew .call-to-action-left > * {
  z-index: 2;
  position: relative;
}

.alignnone {
  margin: 0;
}

.aligncenter, div.aligncenter {
  margin: 5px auto 15px;
  display: block;
}

.alignright {
  float: right;
  margin: 5px 0 25px 25px;
}

.alignleft {
  float: left;
  margin: 5px 25px 25px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 25px 25px;
}

a img.alignnone {
  margin: 5px 0 25px;
}

a img.alignleft {
  float: left;
  margin: 5px 25px 25px 0;
}

a img.aligncenter {
  margin: 5px auto 15px;
  display: block;
}

.wp-caption {
  max-width: 100%;
  text-align: center;
}

.wp-caption img[class*="wp-image-"] {
  margin: 0;
  display: block;
}

.wp-caption.alignnone {
  margin: 5px 0 25px;
}

.wp-caption.alignleft {
  margin: 5px 25px 25px 0;
}

.wp-caption.alignright {
  margin: 5px 0 25px 25px;
}

.wp-caption img {
  height: auto;
  max-width: 100%;
  width: auto;
  border: 0;
  margin: 0;
  padding: 0;
}

.wp-caption p.wp-caption-text {
  color: #9d9d9d;
  text-align: left;
  margin: 0;
  padding: 10px 0;
  font-size: 13px;
  line-height: 18px;
}

blockquote {
  color: #555;
  clear: both;
  background: #f6f6f6;
  border: none;
  margin: 40px 0;
  padding: 30px 20px 30px 80px;
  font-family: Crete Round, serif;
  font-size: 18px;
  font-style: italic;
  line-height: 30px;
  position: relative;
}

blockquote p {
  color: #555;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 30px;
}

blockquote:before {
  content: "";
  color: #555;
  font-family: FontAwesome;
  font-size: 35px;
  font-style: normal;
  position: absolute;
  top: 38px;
  left: 20px;
}

.size-auto, .size-full, .size-large, .size-medium, .size-thumbnail {
  max-width: 100%;
  height: auto;
}

.date-style-3 .wt-post-media {
  border-bottom: 3px solid #ec5598;
}

.blog-post {
  margin-bottom: 40px;
  position: relative;
}

.wt-post-title {
  margin-bottom: 15px;
}

.wt-post-title .post-title {
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  line-height: 28px;
}

.wt-post-meta {
  margin-bottom: 10px;
}

.wt-post-meta ul {
  margin: 0 -4px;
  list-style: none;
}

.wt-post-meta li {
  padding: 0;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  display: inline-block;
}

.wt-post-meta a {
  color: #a9a9a9;
}

.wt-post-meta i {
  color: #7b7b7b;
  margin: 0 5px;
}

.wt-post-meta li:after {
  content: "/";
  opacity: .5;
  margin-left: 5px;
  font-weight: normal;
  display: inline-block;
}

.wt-post-meta li:last-child:after {
  display: none;
}

.wt-post-text {
  margin-bottom: 15px;
}

.wt-post-text p:last-child {
  margin: 0;
}

.wt-post-readmore {
  margin-bottom: 15px;
}

.wt-post-readmore a {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: italic;
  text-decoration: underline;
}

.wt-post-tags {
  padding-top: 10px;
}

.wt-post-tags .post-tags {
  margin: 0 -3px;
  list-style: none;
}

.wt-post-tags .post-tags a {
  color: #a9a9a9;
  text-transform: uppercase;
  border: 1px solid #ddd;
  margin: 0 3px 5px;
  padding: 2px 8px;
  font-size: 10px;
  font-weight: 600;
  display: inline-block;
}

.wt-post-tags .post-tags a:hover {
  color: #fff;
  background-color: #ec5598;
}

.date-style-2 .post-date {
  color: #fff;
  width: 50px;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
  background-color: #ec5598;
  position: absolute;
  top: 0;
  left: 20px;
}

.date-style-2 .post-date strong, .date-style-2 .post-date span {
  padding: 10px 5px;
  display: block;
}

.date-style-2 .post-date strong {
  background-color: #4b3941;
  padding: 10px 5px 5px;
  font-size: 10px;
  position: relative;
}

.date-style-2 .post-date span {
  padding: 5px 5px 10px;
  font-size: 16px;
}

.date-style-2 .post-date:after, .date-style-2 .post-date .fa, .date-style-3 .post-date:after {
  display: none;
}

.date-style-3 .wt-post-info {
  position: relative;
}

.date-style-3 .post-date {
  text-transform: uppercase;
  color: #fff;
  height: 36px;
  z-index: 1;
  background-color: #4b3941;
  padding: 8px 15px;
  position: absolute;
  top: -39px;
  left: 0;
}

.date-style-3 .post-date i {
  color: #fff;
  text-align: center;
  width: 40px;
  height: 36px;
  background: #ec5598;
  margin-top: -8px;
  margin-left: -15px;
  margin-right: 15px;
  line-height: 36px;
  display: inline-block;
  position: relative;
}

.date-style-3 .post-date strong {
  color: #ec5598;
  margin-right: 2px;
}

.date-style-3.date-skew .post-date i {
  margin-right: 35px;
}

.date-style-3.date-skew .post-date i:after {
  content: "";
  height: 100%;
  width: 25px;
  background-color: #ec5598;
  position: absolute;
  right: -12px;
  transform: skew(30deg);
}

.date-style-3.date-skew .post-date:before {
  content: "";
  height: 100%;
  width: 25px;
  z-index: 1;
  background-color: #4b3941;
  display: block;
  position: absolute;
  top: 0;
  right: -12px;
  transform: skew(30deg);
}

.recent-posts-entry-date .widget-post:last-child {
  border: none;
}

.recent-posts-entry-date .wt-post-date {
  width: 50px;
  height: 60px;
  float: left;
  background-color: #0e0e0e;
  padding: 10px 0;
}

.recent-posts-entry-date .wt-post-date strong {
  font-size: 24px;
  line-height: 24px;
  display: block;
}

.recent-posts-entry-date .wt-post-date span {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 600;
  display: block;
}

.recent-posts-entry-date .wt-post-info {
  margin-left: 70px;
}

.recent-posts-entry-date .wt-post-info .wt-post-meta ul li {
  font-size: 12px;
  font-weight: normal;
}

.recent-posts-entry-date .wt-post-info .post-title {
  margin-top: 0;
  font-weight: bold;
}

.no-image-blog.date-style-2 .algo-post-info {
  padding-top: 70px;
}

.no-image-blog.date-style-2 .post-date {
  top: 0;
  left: 20px;
}

.blog-post.no-img-post .post-date {
  float: left;
  position: static;
}

.blog-md .wt-post-media {
  width: 350px;
  float: left;
  margin-right: 30px;
}

.blog-md .wt-post-info {
  border: none;
}

.blog-md .wt-post-tags {
  border: none;
  padding: 0;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .blog-md.blog-post .wt-post-media {
    float: none;
    width: auto;
    margin: 0 0 20px;
  }
}

.nav-links:after {
  clear: both;
  content: "";
  display: table;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  overflow: hidden;
  position: absolute !important;
}

.pagination .page-numbers {
  color: #767676;
  float: left;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  margin-left: -1px;
  padding: 9px 15px;
  font-size: 12px;
  font-weight: 600;
  display: block;
}

.pagination .page-numbers.current, .pagination a.page-numbers:hover {
  color: #fff;
  background: #3396d1;
}

.nav-links .nav-previous a, .nav-links .nav-next a {
  color: #3396d1;
  margin-bottom: 20px;
  font-weight: 600;
  display: inline-block;
  outline: none !important;
}

.nav-links .nav-previous a:hover, .nav-links .nav-next a:hover {
  color: #09c;
}

.nav-links .nav-previous a:after, .nav-links .nav-next a:after {
  content: "";
  vertical-align: middle;
  color: #333;
  margin-left: 2px;
  font-family: FontAwesome;
}

.post-navigation {
  background-color: #fff;
  border-bottom: 2px solid #e3e3e3;
  padding: 30px;
}

.post-navigation .screen-reader-text {
  margin-top: 0;
}

.post-navigation .nav-previous, .post-navigation .nav-next {
  width: 50%;
  display: inline-block;
}

.post-navigation .nav-next {
  text-align: right;
}

.post-navigation .nav-previous a:after, .post-navigation .nav-next a:after {
  display: none;
}

.post-navigation .nav-previous .post-title, .post-navigation .nav-next .post-title {
  color: #777;
  margin-top: 10px;
  font-size: 15px;
  display: block;
}

.wt-link-pages {
  padding: 20px 0 30px;
}

.wt-link-pages a {
  color: #fff;
  background-color: #ccc;
  margin: 0 2px;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: 600;
}

.sticky-post {
  color: #fff;
  text-transform: uppercase;
  background: #77c04b;
  padding: 10px 15px;
  font-weight: 600;
  position: absolute;
  top: 0;
  right: 0;
}

.post-password-form {
  clear: both;
  position: relative;
}

.post-password-form input[type="password"] {
  width: 100%;
  height: 40px;
  border: 1px solid #ddd;
  padding: 10px 100px 10px 15px;
}

.post-password-form label {
  display: block;
}

.post-password-form input[type="submit"] {
  color: #fff;
  height: 40px;
  text-transform: uppercase;
  background-color: #333;
  border: none;
  padding: 10px 20px;
  font-weight: 600;
  position: absolute;
  bottom: 0;
  right: 0;
}

.blog [class*="galleryid-"] {
  clear: both;
  margin: 0 auto;
  overflow: hidden;
}

.gallery .gallery-item {
  float: left;
  text-align: center;
  margin: 0;
  padding: 0 0 5px 15px;
  list-style: none;
  overflow: hidden;
}

.gallery .gallery-caption {
  padding-top: 10px;
  font-size: 13px;
}

.gallery .gallery-item img {
  float: left;
  width: 100%;
  padding: 0;
  border: none !important;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-icon img {
  margin: 0 auto;
}

.gallery-caption {
  color: #707070;
  color: #333333b3;
  padding: .5em 0;
  font-size: 1.2rem;
  line-height: 1.5;
  display: block;
}

.gallery-columns-6 .gallery-caption, .gallery-columns-7 .gallery-caption, .gallery-columns-8 .gallery-caption, .gallery-columns-9 .gallery-caption {
  display: none;
}

.side-bar .widget {
  margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .side-bar {
    margin-bottom: 40px;
  }
}

.blog-detail .wt-post-media {
  margin-bottom: 30px;
}

.comments-area {
  padding: 0;
}

.comments-area .comments-title {
  text-transform: uppercase;
  font-size: 20px;
}

ol.comment-list {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

ol.comment-list li.comment {
  padding: 0;
  position: relative;
}

ol.comment-list li.comment .comment-body {
  border: 1px solid #f2f2f2;
  border-left-width: 5px;
  margin-bottom: 40px;
  margin-left: 40px;
  padding: 20px 30px 20px 60px;
  position: relative;
}

ol.comment-list li.comment .comment-author {
  margin-bottom: 10px;
  display: block;
}

ol.comment-list li.comment .comment-author .avatar {
  width: 90px;
  height: 90px;
  background-color: #fff;
  border: 2px solid #f2f2f2;
  border-radius: 100%;
  padding: 5px;
  position: absolute;
  top: 20px;
  left: -50px;
}

ol.comment-list li.comment .comment-author .fn {
  color: #555;
  text-transform: uppercase;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  display: inline-block;
}

ol.comment-list li.comment .comment-author .says {
  color: #999;
  font-weight: 600;
  display: none;
}

ol.comment-list li.comment .comment-meta {
  color: #8d8d8d;
  margin-bottom: 15px;
  font-family: Crete Round, serif;
  font-size: 16px;
}

ol.comment-list li.comment .comment-meta a {
  color: #8d8d8d;
  color: #8d8d8d;
}

ol.comment-list li.comment .comment-meta:before, ol.comment-list li.comment .reply a:before {
  vertical-align: top;
  font-family: FontAwesome;
  font-size: 16px;
}

ol.comment-list li.comment .comment-meta:before {
  content: "";
}

ol.comment-list li.comment .reply a:before {
  content: "";
  color: #555;
  vertical-align: middle;
  margin-right: 5px;
  font-weight: normal;
}

ol.comment-list li.comment p {
  margin: 0 0 5px;
  line-height: 18px;
}

ol.comment-list li.comment .reply a {
  color: #ffbc13;
  margin-top: -5px;
  font-weight: 600;
  position: absolute;
  top: 50px;
  right: 30px;
}

ol.comment-list li .children {
  margin-left: 80px;
  list-style: none;
}

ol.comment-list li .children li {
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .comments-area .padding-30 {
    padding: 15px;
  }

  ol.comment-list li.comment .comment-body {
    margin-bottom: 30px;
    margin-left: 30px;
  }

  ol.comment-list li.comment .comment-author .avatar {
    height: 70px;
    width: 70px;
    left: -35px;
  }

  ol.comment-list li .children {
    margin-left: 20px;
  }

  ol.comment-list li.comment .reply a {
    position: static;
  }
}

@media only screen and (max-width: 480px) {
  ol.comment-list li.comment .comment-body {
    margin-left: 26px;
  }

  ol.comment-list li.comment .comment-author .avatar {
    width: 60px;
    height: 60px;
    top: 12px;
    left: -30px;
  }

  ol.comment-list li.comment .comment-body {
    padding-left: 40px;
  }
}

.comment-respond .comment-reply-title {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}

.comments-area .comment-form {
  margin: 0 -15px;
}

.comments-area .comment-form .comment-notes {
  display: none;
}

.comments-area .comment-form p {
  width: 33.333%;
  float: left;
  margin-bottom: 30px;
  padding: 0 15px;
  font-weight: 600;
  position: relative;
}

.comments-area .comment-form p.form-allowed-tags {
  width: 100%;
}

ol.comment-list li.comment .comment-respond .comment-form p {
  padding: 0 15px !important;
}

.comments-area .comment-form p label {
  margin-bottom: 10px;
  line-height: 18px;
  display: none;
}

.comments-area p:before {
  color: #8f8f8f;
  width: 40px;
  height: 40px;
  text-align: center;
  border: none;
  font-family: FontAwesome;
  font-size: 16px;
  line-height: 40px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 15px;
}

.comments-area p.comment-form-author:before {
  content: "";
}

.comments-area p.comment-form-email:before {
  content: "";
}

.comments-area p.comment-form-url:before {
  content: "";
}

.comments-area p.comment-form-comment:before {
  content: "";
}

.comments-area .comment-form p input[type="text"], .comments-area .comment-form p textarea {
  width: 100%;
  height: 40px;
  background-color: #f3f3f3;
  border: none;
  padding: 10px 10px 10px 50px;
}

.comments-area .comment-form p.comment-form-comment {
  width: 100%;
  clear: both;
  display: block;
}

.comments-area .comment-form p textarea {
  height: 120px;
  resize: none;
}

.comments-area .comment-form p.form-submit {
  clear: both;
  float: none;
  width: 100%;
  margin: 0;
}

.comments-area .comment-form p input[type="submit"] {
  color: #fff;
  text-transform: uppercase;
  background-color: #77c04b;
  border-width: 0;
  border-radius: 3px;
  padding: 10px 20px;
  font-weight: 600;
  display: inline-block;
}

.comments-area .comment-form p input[type="submit"]:hover, .comments-area .comment-form p input[type="submit"]:focus, .comments-area .comment-form p input[type="submit"]:active {
  color: #fff;
  background-color: #6ab33e;
  border-color: #6ab33e;
}

.comment-respond .form-submit .site-button {
  width: 100%;
  padding: 15px 20px;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .comments-area .comment-form p {
    width: 100%;
    float: none;
    margin-bottom: 20px;
  }

  .comment-respond {
    padding: 20px;
  }
}

.blog-post-demo-one [class*="col-"]:nth-child(2n) .wt-post-media {
  display: table-footer-group;
}

.blog-post-demo-one [class*="col-"]:nth-child(2n) .wt-post-media a {
  display: block;
  overflow: hidden;
}

.blog-post-demo-one [class*="col-"]:nth-child(2n) .wt-post-info {
  display: table-header-group;
}

.blog-post-demo-one [class*="col-"]:nth-child(2n) .date-style-3 .wt-post-info, .blog-post-demo-one [class*="col-"]:nth-child(2n) .date-style-2 .wt-post-info {
  position: static;
}

.blog-post-demo-one [class*="col-"]:nth-child(2n) .date-style-3 .post-date, .blog-post-demo-one [class*="col-"]:nth-child(2n) .date-style-2 .post-date {
  top: auto;
  bottom: 0;
}

.blog-block-img {
  height: 250px;
  width: 100%;
  background-position: center;
  background-size: cover;
  display: block;
}

.wt-post-info-inner {
  height: 250px;
  padding: 50px;
}

.blog-post-demo-one .wt-post-media {
  position: relative;
}

.wt-blog-post-direction {
  text-align: center;
  width: 100%;
  color: #f5f6f6;
  position: absolute;
  bottom: -20px;
}

.wt-blog-post-direction span {
  font-size: 40px;
  display: inline-block;
}

.blog-post-demo-one .wt-post-media:after {
  content: "";
  width: 0;
  height: 0;
  border-bottom: 14px solid #f5f6f6;
  border-left: 14px solid #0000;
  border-right: 14px solid #0000;
  margin-left: -8px;
  position: absolute;
  bottom: 0;
  left: 50%;
}

.blog-post-demo-one [class*="col-"]:nth-child(2n) .wt-post-media:after {
  border: 14px solid #0000;
  border-top-color: #f5f6f6;
  border-bottom: none;
  top: 0;
  bottom: auto;
}

@media only screen and (max-width: 1280px) {
  .wt-post-info-inner {
    padding: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .wt-post-info-inner {
    height: auto;
  }
}

.blog-post .grid-post li {
  float: left;
  width: 50%;
  border-bottom: 4px solid #fff;
  border-right: 4px solid #fff;
  list-style: none;
}

.blog-post .grid-post li .portfolio-item {
  width: 100%;
}

.portfolio-item {
  width: 100%;
  color: #fff;
  width: 100%;
  border: 0;
  position: relative;
  overflow: hidden;
}

.blog-post .grid-post li img {
  width: 100%;
}

.latest-blog-3.post-overlay .wt-post-info {
  width: 100%;
  height: 100%;
  display: table;
  position: absolute;
  top: 0;
  left: 0;
}

.latest-blog-3.post-overlay {
  height: 526px;
}

.latest-blog-3.post-overlay .wt-post-info .post-overlay-position {
  vertical-align: bottom;
  display: table-cell;
}

.latest-blog-3.post-overlay .wt-post-meta a, .latest-blog-3.post-overlay .wt-post-meta i {
  color: #fff;
}

.latest-blog-3.post-overlay.date-style-3 .post-date {
  top: 0;
}

.latest-blog-3.post-overlay.date-style-3 .wt-post-meta .post-date i {
  color: #fff;
}

.latest-blog-3.blog-md .wt-post-media {
  width: 230px;
  float: left;
  margin-right: 30px;
}

.latest-blog-3.blog-md .wt-post-info {
  border: none;
}

.latest-blog-3.blog-md .wt-post-tags {
  border: none;
  padding: 0;
  display: inline-block;
}

.latest-blog-3.blog-md .wt-post-meta i {
  color: #222;
}

.latest-blog-3.blog-md.date-style-3 .post-date {
  top: auto;
  bottom: -10px;
}

.latest-blog-3.blog-md.date-style-3 .wt-post-meta .post-date i {
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .latest-blog-3.post-overlay {
    height: 360px;
  }
}

@media only screen and (max-width: 640px) {
  .latest-blog-3.blog-md .wt-post-media {
    width: 100%;
  }
}

.site-header {
  z-index: 996;
  position: relative;
}

@media only screen and (max-width: 1200px) {
  .site-header .container {
    width: auto;
  }
}

.site-header ul, .site-header ol {
  margin-bottom: 0;
}

.nav-wide .container {
  width: 100%;
}

.extra-nav {
  float: right;
  z-index: 9;
  padding: 23px 0;
  position: relative;
}

.extra-nav .extra-cell {
  margin-left: 10px;
  display: inline-block;
}

@media only screen and (max-width: 480px) {
  .extra-nav {
    width: 100%;
    clear: both;
    text-align: right;
    margin: 0;
  }
}

#header-part.fix-map-header {
  height: 90px;
}

#header-part.fix-map-header .main-bar {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 480px) {
  #header-part.fix-map-header {
    height: 160px;
  }
}

.top-bar {
  color: #fff;
  background-color: #ec5598;
  padding: 10px 0;
}

.top-bar .fa {
  vertical-align: middle;
  width: 22px;
  height: 22px;
  text-align: center;
  background-color: #00000014;
  border: 1px solid #00000003;
  border-radius: 1px;
  padding: 3px;
}

.e-p-bx li {
  padding-right: 10px;
  font-size: 12px;
  display: inline;
}

.e-p-bx li a {
  color: #fff;
}

.e-p-bx li i {
  vertical-align: middle;
  margin-right: 5px;
}

.social-bx, .login-bx {
  float: right;
  margin: 0 0 0 15px;
}

@media only screen and (max-width: 480px) {
  .social-bx, .e-p-bx, .login-bx {
    margin-top: 5px;
    margin-bottom: 5px !important;
  }
}

.social-bx li, .login-bx li {
  margin: 0;
  font-size: 12px;
  position: relative;
}

.social-bx li {
  padding-left: 2px;
  padding-right: 2px;
}

.social-bx li a, .login-bx li a {
  color: #fff;
}

.social-bx li i, .login-bx li i {
  vertical-align: baseline;
  margin: 0 5px 0 0;
}

.social-bx li span, .login-bx li span {
  vertical-align: baseline;
  margin: 0 2px;
  font-size: 8px;
}

.login-bx li ul {
  border: none;
  padding: 0;
  top: 25px;
  left: auto;
  right: 0;
}

.login-bx li ul li {
  border-bottom: 1px solid #f7f7f7;
}

.login-bx li ul li a {
  color: #767676;
  padding: 9px 15px;
}

.arrow-up-border:before, .arrow-up:after {
  content: "";
  display: inline-block;
  position: absolute;
}

.arrow-up-border:before {
  border-bottom: 7px solid #0003;
  border-left: 7px solid #0000;
  border-right: 7px solid #0000;
  top: -7px;
  right: 19px;
}

.arrow-up:after {
  border-bottom: 6px solid #fff;
  border-left: 6px solid #0000;
  border-right: 6px solid #0000;
  top: -6px;
  right: 20px;
}

.arrow-left:before {
  content: "";
  border-bottom: 7px solid #0003;
  border-left: 7px solid #0000;
  border-right: 7px solid #0000;
  display: inline-block;
  position: absolute;
  top: -7px;
  left: 9px;
}

.arrow-left:after {
  content: "";
  border-bottom: 6px solid #fff;
  border-left: 6px solid #0000;
  border-right: 6px solid #0000;
  display: inline-block;
  position: absolute;
  top: -6px;
  left: 10px;
}

.language-bx li {
  margin: 0 10px;
  display: inline;
}

.logo-header {
  float: left;
  vertical-align: middle;
  color: #1bbce8;
  width: 320px;
  height: 90px;
  z-index: 9;
  margin: 0;
  padding: 0;
  font-size: 36px;
  display: table;
  position: relative;
}

@media only screen and (max-width: 991px) {
  .logo-header {
    width: 280px;
  }

  .header-skew:before, .header-skew:after {
    height: 127%;
    bottom: -10px;
  }
}

@media only screen and (max-width: 480px) {
  .logo-header {
    width: 320px;
  }

  .header-skew:before, .header-skew:after {
    height: 120%;
    bottom: -9px;
  }
}

.logo-footer {
  vertical-align: middle;
  color: #1bbce8;
  max-width: 200px;
  height: 60px;
  margin: 15px 0;
  padding: 0;
  font-size: 36px;
  display: table;
}

.logo-header > a, .logo-footer > a {
  vertical-align: middle;
  display: table-cell;
}

.logo-header.center-block {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.logo-header img, .logo-footer img {
  max-width: 100%;
  width: auto;
  height: auto;
  vertical-align: middle;
}

.logo-header span, .logo-footer span {
  color: #1bbce8;
  letter-spacing: 20px;
  font-size: 20px;
}

.is-fixed .logo-header {
  height: 70px;
}

.main-bar {
  width: 100%;
  position: relative;
}

.main-bar:after, .main-bar:after {
  clear: both;
  content: "";
  display: table;
}

.boxed .is-fixed .main-bar {
  max-width: 1200px;
  margin: 0 -600px;
  left: 50%;
}

@media only screen and (max-width: 1200px) {
  .boxed .is-fixed .main-bar {
    margin: 0;
    left: 0;
  }
}

.boxed .site-header .container {
  width: auto;
}

.header-info {
  float: right;
  padding: 10px 0;
}

.header-info > ul {
  list-style: none;
  display: table;
}

.header-info > ul li {
  vertical-align: middle;
  padding: 15px 10px;
  display: table-cell;
}

.header-info > ul li .icon-sm {
  float: left;
  margin-right: 15px;
}

.header-info > ul li strong {
  text-transform: uppercase;
  color: #222;
  font-size: 16px;
  display: block;
}

@media only screen and (max-width: 991px) {
  .header-info > ul li {
    display: none;
  }

  .header-info > ul li.btn-col-last {
    padding-left: 0;
    padding-right: 0;
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .header-info > ul li.btn-col-last, .header-info {
    display: none;
  }
}

.header-botton {
  background-color: #4b3941;
}

.header-botton .header-nav .nav {
  float: none;
}

.header-botton .header-nav .nav > li > a {
  color: #fff;
  padding: 20px 12px;
}

.header-botton .extra-nav {
  padding: 13px 0;
}

.header-botton .extra-nav .site-search-btn {
  color: #fff;
  padding: 7px 15px;
}

.header-botton .extra-nav .wt-cart .woo-cart-count {
  color: #fff;
  min-width: 36px;
  border: 2px solid #fff;
  padding: 5px;
}

.header-botton .extra-nav .wt-cart .woo-cart-count:before {
  border-color: #fff;
}

@media only screen and (max-width: 991px) {
  .header-botton .navbar-toggle {
    float: left;
    margin: 9px 0;
  }

  .header-botton .navbar-toggle span {
    background-color: #fff;
  }

  .header-botton .header-nav .nav > li > a {
    color: #777;
    padding: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .header-middle .logo-header {
    float: none;
    margin: 9px auto;
  }
}

.site-search-btn {
  color: #555;
  background: none;
  border: none;
  outline: none;
  padding: 10px 15px;
}

#search {
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: #000000e6;
  transition: all .5s ease-in-out;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(0, -100%)scale(0);
}

#search.open {
  opacity: 1;
  z-index: 999;
  display: block;
  transform: translate(0)scale(1);
}

#search form {
  width: 60%;
  color: #ffffff80;
  text-align: left;
  background: none;
  border: 1px solid #ffffff1a;
  outline: none;
  margin-top: -51px;
  margin-left: 20%;
  padding: 10px;
  font-family: Roboto;
  font-size: 40px;
  font-weight: 300;
  position: absolute;
  top: 50%;
  left: 0;
}

#search input[type="search"] {
  width: 100%;
  background: none;
  border: none;
  padding: 0 30px;
}

#search .search-btn {
  background: none;
  border: none;
  padding: 0 30px;
}

#search .search-btn i {
  font-size: 36px;
}

#search .close {
  opacity: 1;
  color: #fff;
  width: 30px;
  height: 30px;
  font-size: 27px;
  position: fixed;
  top: 15px;
  right: 15px;
}

#search .close:after, #search .close:before {
  content: "";
  width: 2px;
  height: 32px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 20px;
}

#search .close:after {
  transform: rotate(45deg);
}

#search .close:before {
  transform: rotate(-45deg);
}

#search .close:hover:after, #search .close:hover:before {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  #search form {
    width: 90%;
    margin-left: 5%;
    font-size: 26px;
  }

  #search input[type="search"] {
    padding: 0 0 0 30px;
  }

  #search .search-btn {
    padding: 0 15px;
  }

  #search .search-btn i {
    font-size: 24px;
  }
}

@media only screen and (max-width: 400px) {
  #search form {
    font-size: 16px;
  }

  #search input[type="search"] {
    padding: 0 0 0 10px;
  }

  #search .search-btn {
    padding: 0 15px;
  }

  #search .search-btn i {
    font-size: 18px;
  }
}

.wt-quik-search .form-control::-moz-placeholder {
  color: #fff;
}

.wt-quik-search .form-control:-moz-placeholder {
  color: #fff;
}

.wt-quik-search .form-control:-ms-input-placeholder {
  color: #fff;
}

.wt-quik-search .form-control::-webkit-input-placeholder {
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .site-search {
    width: 100%;
  }

  .site-search .site-button {
    padding: 0;
  }

  .site-search form {
    padding-left: 0;
  }
}

.navbar-toggle {
  border: none;
  margin: 24px -10px 24px 15px;
  padding: 12px 10px;
}

.navbar-toggle .icon-bar {
  height: 3px;
  background: #555;
  border-radius: 0;
}

@media only screen and (max-width: 480px) {
  .navbar-toggle {
    margin-left: 10px;
  }

  .extra-nav .extra-cell {
    margin-left: 0;
  }
}

.is-fixed .navbar-toggle {
  margin-top: 14px;
  margin-bottom: 14px;
}

.header-nav {
  padding: 0;
  position: relative;
}

.header-nav .nav {
  float: right;
}

.header-nav .nav i {
  vertical-align: middle;
  opacity: .7;
  margin-top: -3px;
  margin-left: 3px;
  font-size: 9px;
}

.header-nav .nav > li {
  text-transform: uppercase;
  margin: 0;
  font-weight: 400;
  position: relative;
}

.header-nav .nav > li > a {
  color: #555;
  cursor: pointer;
  border-radius: 0;
  padding: 35px 12px;
  font-size: 14px;
  font-weight: 600;
}

.header-nav .nav > li > a:hover {
  color: #212427;
  background-color: #0000;
}

.header-nav .nav > li > a:active, .header-nav .nav > li > a:focus {
  background-color: #0000;
}

.header-nav .nav > li.active > a, .header-nav .nav > li.current-menu-item > a {
  color: #ec5598;
  background-color: #0000;
}

.header-nav .nav > li:hover > a {
  color: #ec5598;
}

.header-nav .nav > li:hover > .sub-menu, .header-nav .nav > li:hover > .mega-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  transition: all .3s;
}

.header-nav .nav > li .sub-menu {
  opacity: 0;
  visibility: hidden;
  width: 220px;
  z-index: 10;
  background-color: #fff;
  border: 1px solid #f4f4f4;
  margin: 20px 0 0;
  padding: 0;
  list-style: none;
  display: block;
  position: absolute;
  left: 0;
}

.header-nav .nav > li .sub-menu li {
  border-bottom: 1px solid #f4f4f4;
  position: relative;
}

.header-nav .nav > li .sub-menu li a {
  color: #474747;
  text-transform: none;
  padding: 10px 20px;
  font-size: 13px;
  font-weight: 600;
  display: block;
}

.header-nav .nav > li .sub-menu li a:hover {
  color: #1bbce8;
  background-color: #f2f2f2;
  text-decoration: none;
}

.header-nav .nav > li .sub-menu li:hover > a {
  color: #1bbce8;
}

.header-nav .nav > li .sub-menu li:last-child {
  border-bottom: 0;
}

.header-nav .nav > li .sub-menu li .fa {
  color: #363636;
  float: right;
  display: block;
  position: absolute;
  top: 12px;
  right: 10px;
}

.header-nav .nav > li .sub-menu li > .sub-menu {
  margin: 0 0 0 20px;
  transition: all .3s;
  left: 220px;
}

.header-nav .nav > li .sub-menu li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  margin: 0;
  top: -1px;
  left: 220px;
}

.header-nav .nav > li .sub-menu li:hover > .sub-menu:before {
  content: "";
  height: 100%;
  width: 6px;
  background-color: #0000;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -6px;
}

.header-nav .nav > li.has-mega-menu {
  position: inherit;
}

.header-nav .nav > li .mega-menu li a > i {
  min-width: 20px;
  margin-right: 5px;
  font-size: 13px;
}

.header-nav .nav > li .mega-menu {
  opacity: 0;
  visibility: hidden;
  width: 100%;
  z-index: 10;
  background-color: #fff;
  border: 1px solid #e9e9e9;
  margin-top: 20px;
  list-style: none;
  display: table;
  position: absolute;
  left: 0;
  right: 0;
}

.header-nav .nav > li .mega-menu > li {
  vertical-align: top;
  width: 25%;
  padding: 10px 0;
  display: table-cell;
  position: relative;
}

.header-nav .nav > li .mega-menu > li:after {
  content: "";
  width: 1px;
  height: 100%;
  background-color: #ccc6;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.header-nav .nav > li .mega-menu > li:last-child:after {
  display: none;
}

.header-nav .nav > li .mega-menu > li > a {
  color: #555;
  padding: 0 20px;
  font-size: 14px;
  display: block;
}

.header-nav .nav > li .mega-menu > li ul {
  margin: 10px 0 0;
  padding: 0;
  list-style: none;
}

.header-nav .nav > li .mega-menu > li ul a {
  color: #474747;
  text-transform: none;
  padding: 2px 20px;
  font-size: 13px;
  font-weight: 600;
  line-height: 34px;
  display: block;
}

.header-nav .nav > li .mega-menu > li ul a:hover {
  color: #1bbce8;
  background-color: #f2f2f2;
}

.header-nav .nav > li.menu-item-has-children:before {
  content: "";
  color: #999;
  margin-top: -8px;
  font-family: FontAwesome;
  font-size: 8px;
  display: block;
  position: absolute;
  top: 50%;
  right: 4px;
}

.header-nav .nav > li.submenu-direction .sub-menu {
  left: auto;
  right: 0;
}

.header-nav .nav > li.submenu-direction .sub-menu li > .sub-menu {
  margin: 0 20px 0 0;
  left: auto;
  right: 220px;
}

.header-nav .nav > li.submenu-direction .sub-menu li:hover > .sub-menu {
  margin: 0;
  left: auto;
  right: 219px;
}

.has-child .submenu-toogle {
  color: #fff;
  z-index: 999;
  cursor: pointer;
  background-color: #4b3941;
  padding: 7px;
  font-size: 10px;
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
}

.has-child li .submenu-toogle {
  opacity: .9;
}

.has-child.nav-active > a + .submenu-toogle.glyphicon-plus:before {
  content: "−";
}

@media only screen and (max-width: 991px) {
  header .logo {
    padding: 4px 0;
    display: block;
  }

  .header-nav .nav i {
    margin-top: 6px;
  }

  .header-nav .nav li.has-child.nav-active > a {
    color: #ec5598;
  }

  .navbar-header {
    float: none;
  }

  .navbar-toggle {
    display: block;
  }

  .header-nav {
    clear: both;
    border-bottom: 1px solid #e9e9e9;
    margin: 0;
  }

  .header-nav .nav {
    float: none;
    background: #fff;
    margin: 0;
  }

  .header-nav .nav li {
    float: none;
  }

  .header-nav .nav > li .sub-menu > li, .header-nav .nav > li .mega-menu > li {
    float: none;
    width: auto;
    display: block;
  }

  .header-nav .nav li .sub-menu > li a {
    padding-left: 30px;
  }

  .header-nav .nav > li > a {
    color: #777;
    border-top: 1px solid #e9e9e9;
    padding: 12px 15px;
  }

  .header-nav .nav > li.active > a, .header-nav .nav > li.current-menu-item > a {
    color: #ec5598;
  }

  .header-nav .nav i {
    float: right;
  }

  .header-nav .nav > li > a:hover, .header-nav .nav > li > a:active, .header-nav .nav > li > a:focus {
    background-color: #f0f0f0;
    text-decoration: none;
  }

  .header-nav .nav > li .mega-menu > li:after {
    display: none;
  }

  .header-nav .nav > li ul, .header-nav .nav > li .sub-menu, .header-nav .nav > li .mega-menu {
    visibility: visible;
    width: auto;
    background: #f9f9f9;
    display: none;
    position: static;
  }

  .header-nav .nav > li ul.mega-menu ul {
    display: block;
  }

  .header-nav .nav > li .mega-menu > li {
    padding: 0;
  }

  .header-nav .nav > li ul.mega-menu li:hover ul {
    display: block;
  }

  .header-nav .nav li.has-child {
    position: relative;
  }

  .header-nav .nav li .sub-menu, .header-nav .nav li .mega-menu {
    opacity: 1;
    margin: 0 !important;
  }

  .has-child .submenu-toogle {
    display: block;
  }
}

.nav-dark.header-nav .nav > li .sub-menu, .nav-dark.header-nav .nav > li .mega-menu {
  background-color: #4b3941;
  border: 1px solid #0003;
}

.nav-dark.header-nav .nav > li .sub-menu li {
  border-bottom: 1px solid #23282f;
}

.nav-dark.header-nav .nav > li .sub-menu li a, .nav-dark.header-nav .nav > li .mega-menu > li ul a, .nav-dark.header-nav .nav > li .mega-menu > li > a {
  color: #ccc;
}

.nav-dark.header-nav .nav > li .sub-menu li a:hover {
  background-color: #23282f;
}

.nav-dark.header-nav .nav > li .mega-menu > li ul a:hover {
  color: #ec5598;
  background-color: #23282f;
}

.nav-dark.header-nav .nav > li .mega-menu > li:after {
  background-color: #0003;
}

@media only screen and (max-width: 991px) {
  .nav-dark.header-nav {
    border-bottom: 1px solid #000c;
  }

  .nav-dark.header-nav .nav {
    background-color: #2d3239;
  }

  .nav-dark.header-nav .nav > li > a {
    color: #999;
    border-top: 1px solid #0003;
  }

  .nav-dark.header-nav .nav > li > a:hover, .nav-dark.header-nav .nav > li > a:active, .nav-dark.header-nav .nav > li > a:focus {
    color: #ec5598;
    background-color: #23282f;
  }

  .nav-dark.header-nav .nav > li ul, .nav-dark.header-nav .nav > li .sub-menu, .nav-dark.header-nav .nav > li .mega-menu {
    background-color: #23282f;
  }
}

.sticky-no .main-bar {
  position: static !important;
}

.is-fixed .main-bar {
  position: fixed;
  top: 0;
  left: 0;
}

.is-fixed .header-nav .nav > li > a {
  padding: 25px 12px;
}

.is-fixed .extra-nav {
  padding: 13px 0;
}

@media only screen and (max-width: 991px) {
  .is-fixed .header-nav .nav > li > a {
    padding: 12px 15px;
  }

  .is-fixed .header-nav .nav {
    height: 275px;
    overflow: auto;
  }

  .is-fixed .header-nav .nav > li > a {
    padding: 12px 15px;
  }

  .is-fixed#header-part .navbar-toggle {
    margin: 15px 0;
  }
}

#header-part.full-width .top-bar .container, #header-part.full-width .main-bar .container {
  width: auto;
}

.site-bg-primary .header-nav .nav > li > a {
  color: #fff;
}

.site-bg-primary .header-nav .nav > li:hover > a, .site-bg-primary .header-nav .nav > li.active > a, .site-bg-primary .header-nav .nav > li.current-menu-item > a {
  color: #4b3941;
}

.site-bg-primary .wt-cart .woo-cart-total, .site-bg-primary .wt-cart .woo-cart-count {
  color: #fff;
}

.site-bg-primary .wt-cart .woo-cart-count, .site-bg-primary .wt-cart .woo-cart-count:before {
  border-color: #fff;
}

.site-bg-primary .navbar-toggle .icon-bar {
  background-color: #fff;
}

@media only screen and (max-width: 991px) {
  .site-bg-primary .header-nav .nav > li > a {
    color: #777;
  }

  .site-bg-primary .header-nav .nav > li:hover > a, .site-bg-primary .header-nav .nav > li.active > a, .site-bg-primary .header-nav .nav > li.current-menu-item > a {
    color: #ec5598;
  }
}

.header-style-2 .wt-topbar-right {
  justify-content: space-between;
  display: flex;
}

.header-style-2 .social-bx li {
  padding-left: 2px;
  padding-right: 2px;
  display: inline-block;
}

.header-style-2 .social-bx li a {
  background-color: #00000014;
}

@media only screen and (max-width: 767px) {
  .header-style-2 .wt-topbar-right {
    display: block;
  }

  .header-style-2 .wt-topbar-right .social-bx {
    float: none;
    margin: 10px 0 0;
  }
}

.header-style-2 .logo-header {
  float: none;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}

.header-style-2 .nav-outer {
  display: table;
}

.header-style-2 .cart-dropdown-item-wraper {
  top: 18px !important;
}

.header-style-2 .main-bar-inr {
  margin: auto;
  display: table;
}

.header-style-2 .header-nav {
  display: table-cell !important;
}

.header-style-2 .header-nav .nav {
  float: none;
  flex-direction: inherit;
  justify-content: space-between;
  display: flex;
}

.header-style-2 .extra-nav {
  z-index: 9;
  float: none;
  align-items: center;
  padding: 0;
  display: flex;
  position: relative;
}

.header-style-2 .extra-nav .extra-cell {
  margin: 0 5px;
  display: block;
}

.header-style-2 .extra-nav .extra-cell:last-child {
  margin-right: 0;
}

.header-style-2 .extra-nav .extra-cell .link-inner {
  display: flex;
}

.header-style-2 .navbar-toggler {
  display: none;
}

.header-style-2 .header-nav .nav > li > a {
  display: block;
}

@media only screen and (max-width: 991px) {
  .header-style-2 .main-bar-inr {
    padding-bottom: 10px;
    display: block;
    position: relative;
  }

  .header-style-2 .header-nav {
    float: none;
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .header-style-2 .navbar-toggle {
    float: left;
  }

  .header-style-2 .nav-outer {
    padding: 22px 0 0;
    display: block;
  }
}

@media only screen and (max-width: 480px) {
  .extra-nav {
    clear: none;
    width: auto;
  }
}

.header-style-2 .header-nav .nav > li .sub-menu li .fa {
  z-index: -1;
  background-color: #0000;
  padding: 0 10px 0 0;
}

.header-style-2 .header-nav .nav > li .sub-menu li > a:hover + .fa {
  z-index: -1;
}

.header-style-2 .navbar-toggler .icon-bar {
  height: 3px;
  width: 22px;
  background: #2a2a2a;
  border-radius: 0;
  display: block;
}

.header-style-2 .navbar-toggler .icon-bar + .icon-bar {
  margin-top: 4px;
}

.header-style-2 .header-nav .has-child.nav-active > a + .submenu-toogle.fa.fa-angle-right:before {
  content: "";
}

.header-style-2 .navbar-toggler {
  display: none;
}

.header-style-2 .header-nav .nav > li > a {
  display: block;
}

@media only screen and (max-width: 991px) {
  .header-style-2 .navbar-toggler {
    display: block;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }

  .header-style-2 .extra-nav {
    margin-right: 40px;
  }

  .header-style-2 .header-nav .nav > li .sub-menu li .fa {
    z-index: 1;
    width: 100%;
    text-align: right;
    padding: 10px;
    top: 4px;
  }

  .header-style-2 .header-nav .nav > li .sub-menu li > a:hover + .fa {
    z-index: 0;
  }

  .header-style-2 .has-child .submenu-toogle {
    color: #000;
    width: 100%;
    text-align: right;
    background-color: #0000;
    font-size: 14px;
  }

  .header-style-2 .is-fixed .header-nav .nav > li > a {
    padding: 12px 15px;
  }

  .header-style-2 .extra-nav .extra-cell {
    margin-left: 0;
    padding-left: 0;
  }

  .has-child .submenu-toogle {
    background: none;
  }

  .header-style-2.mobile-sider-drawer-menu .header-nav {
    z-index: 999;
    background-color: #fff;
    transition: all .5s linear;
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px !important;
    height: 100vh !important;
    overflow-y: inherit !important;
  }

  .header-style-2 .header-nav .nav {
    float: none;
    width: 100%;
    height: 100%;
    background: #fff;
    margin: 0;
    display: block;
  }

  .header-style-2.mobile-sider-drawer-menu .header-nav .nav > li:hover > .sub-menu, .header-style-2.mobile-sider-drawer-menu .header-nav .nav > li .sub-menu li:hover > .sub-menu {
    transition: none;
  }

  .header-style-2.mobile-sider-drawer-menu.active .header-nav {
    align-items: normal;
    left: 0%;
  }

  .header-style-2.mobile-sider-drawer-menu .icon-bar {
    transition: all .5s linear;
    position: relative;
  }

  #mobile-side-drawer:focus {
    box-shadow: none;
  }

  .header-style-2.mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
    transform: rotate(45deg)translateX(3px)translateY(2px);
  }

  .header-style-2.mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
    transform: rotate(-45deg)translateX(3px)translateY(-2px);
  }

  .header-style-2.mobile-sider-drawer-menu .icon-bar.icon-bar-two {
    position: relative;
    right: 0%;
  }

  .header-style-2.mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
    opacity: 0;
    position: absolute;
    right: 100px;
  }

  .header-style-2.mobile-sider-drawer-menu .is-fixed .header-nav .nav {
    max-height: 100%;
    width: 100%;
    display: block;
    overflow: auto;
  }

  .header-style-2.mobile-sider-drawer-menu .header-fixed .header-nav .nav {
    max-height: 100%;
    overflow: auto;
  }

  .header-style-2 .header-nav .nav li {
    float: none;
    width: 100%;
    display: block;
  }

  .header-style-2 .header-nav .nav > li > a .fa {
    display: none;
  }
}

.header-style-3 .main-bar {
  background: none;
  position: absolute;
}

.header-style-3 .header-nav .nav > li > a {
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .header-style-3 .header-nav .nav > li > a {
    color: #777;
  }
}

.header-style-3 .navbar-toggle .icon-bar {
  background: #fff;
}

.header-style-3 .site-search-btn, .header-style-3 .wt-cart .woo-cart-total, .header-style-3 .wt-cart .woo-cart-count {
  color: #fff;
}

.header-style-3 .wt-cart .woo-cart-count, .header-style-3 .wt-cart .woo-cart-count:before {
  border-color: #fff;
}

.header-style-3 .is-fixed .main-bar {
  position: fixed;
}

.header-style-3 .is-fixed.color-fill .main-bar {
  background: #222;
}

.header-style-4 .main-bar {
  background-color: #fff9;
  position: absolute;
}

.header-style-4 .is-fixed .main-bar {
  background: #fff;
  position: fixed;
}

.header-style-5 .main-bar {
  background-color: #0009;
  position: absolute;
}

.header-style-5 .header-nav .nav > li > a {
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .header-style-5 .header-nav .nav > li > a {
    color: #777;
  }
}

.header-style-5 .navbar-toggle .icon-bar {
  background: #fff;
}

.header-style-5 .site-search-btn, .header-style-5 .wt-cart .woo-cart-total, .header-style-5 .wt-cart .woo-cart-count {
  color: #fff;
}

.header-style-5 .wt-cart .woo-cart-count, .header-style-5 .wt-cart .woo-cart-count:before {
  border-color: #fff;
}

.header-style-5 .is-fixed .main-bar {
  background-color: #000000e6;
  position: fixed;
}

@media only screen and (max-width: 767px) {
  .header-style-6 .wt-topbar-right {
    display: block;
  }

  .header-style-6 .wt-topbar-right .social-bx {
    float: none;
    margin: 10px 0 0;
  }
}

.header-style-6 .is-fixed .header-nav .nav > li > a {
  padding: 20px 12px;
}

.header-style-6 .is-fixed .navbar-toggle {
  margin-top: 10px;
  margin-bottom: 10px;
}

.header-style-7 .top-bar .container {
  justify-content: space-between;
  display: flex;
}

@media only screen and (max-width: 575px) {
  .header-style-7 .top-bar .container {
    display: block;
  }

  .header-style-7 .top-bar .container .social-bx {
    float: none;
    margin-left: 0;
  }
}

.header-style-7 .social-bx li {
  padding-left: 2px;
  padding-right: 2px;
  display: inline-block;
}

.header-style-7 .header-nav .nav > li .sub-menu, .header-style-7 .header-nav .nav > li .mega-menu {
  top: 60px;
}

.header-style-7 .header-info > ul li {
  padding: 15px 0 15px 10px;
}

.header-style-7 .has-child .submenu-toogle {
  background-color: #0000;
}

.header-style-7 .header-nav .nav > li .sub-menu .fa {
  z-index: -1;
  font-size: 14px;
  top: 6px;
}

.header-style-7 .has-child .submenu-toogle {
  background-color: #0000;
}

.header-style-7 .header-middle .container {
  justify-content: space-between;
  display: flex;
}

.header-style-7 .header-nav {
  justify-content: inherit;
  flex-basis: inherit;
}

.header-style-7.header-info > ul li {
  padding-right: 0;
}

.header-style-7 .wt-cart.dropdown-toggle:after {
  display: none;
}

.header-style-7 .header-botton .header-nav .nav {
  flex-direction: inherit;
  justify-content: space-between;
  display: flex;
}

.header-style-7 .main-bar {
  max-width: 1140px;
  margin: auto;
}

.header-style-7 .is-fixed .header-nav .nav > li > a {
  padding: 20px 12px;
}

.header-style-7 .is-fixed .navbar-toggle {
  flex-direction: row-reverse;
  margin-top: 10px;
  margin-bottom: 10px;
}

.header-style-7 .header-botton .extra-nav {
  align-items: center;
  padding: 8px 0;
  display: flex;
}

.header-style-7 .main-bar .container {
  justify-content: space-between;
  display: flex;
  position: relative;
}

.header-style-7 .is-fixed .main-bar {
  margin: 0 -573px;
  left: 50%;
}

.header-style-7 .cart-dropdown-item-wraper {
  top: 14px !important;
}

.header-style-7 .navbar-toggler .icon-bar {
  height: 3px;
  width: 22px;
  background: #fff;
  border-radius: 0;
  display: block;
}

.header-style-7 .navbar-toggler .icon-bar + .icon-bar {
  margin-top: 4px;
}

.header-style-7 .header-nav .has-child.nav-active > a + .submenu-toogle.fa.fa-angle-right:before {
  content: "";
}

.header-style-7 .navbar-toggler {
  display: none;
}

.header-style-7 .header-nav .nav > li > a {
  display: block;
}

@media only screen and (max-width: 1200px) {
  .header-style-7 .main-bar, .header-style-7 .is-fixed .main-bar {
    max-width: 100%;
    margin: 0;
    left: 0;
  }
}

@media only screen and (max-width: 991px) {
  .header-style-7 .navbar-toggler {
    display: block;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }

  .header-style-7 .extra-nav {
    margin-right: 0;
  }

  .header-style-7 .header-nav .nav > li .sub-menu li .fa {
    z-index: 1;
    width: 100%;
    text-align: right;
    padding: 10px;
    top: 4px;
  }

  .header-style-7 .header-nav .nav > li .sub-menu li > a:hover + .fa {
    z-index: 0;
  }

  .header-style-7 .has-child .submenu-toogle {
    color: #000;
    width: 100%;
    text-align: right;
    background-color: #0000;
    font-size: 14px;
  }

  .header-style-7 .is-fixed .header-nav .nav > li > a {
    padding: 12px;
  }

  .header-style-7 .extra-nav .extra-cell {
    margin-left: 0;
    padding-left: 0;
  }

  .header-style-7 .header-botton .extra-nav .site-search-btn {
    color: #fff;
    padding: 7px 15px 7px 0;
  }

  .has-child .submenu-toogle {
    background: none;
  }

  .header-style-7 .header-botton .header-nav .nav {
    float: none;
    width: 100%;
    height: 100%;
    background: #fff;
    margin: 0;
    display: block;
  }

  .header-style-7 .header-botton .header-nav .nav > li > a .fa {
    display: none;
  }

  .header-style-7 .header-botton .header-nav .nav > li .sub-menu li .fa {
    top: 1px;
  }

  .header-style-7 .header-botton .header-nav .nav > li .sub-menu li > .sub-menu {
    transition: none !important;
  }

  .header-style-7.mobile-sider-drawer-menu .header-nav {
    z-index: 999;
    background-color: #fff;
    transition: all .5s linear;
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px !important;
    height: 100vh !important;
    overflow-y: inherit !important;
  }

  .header-style-7.mobile-sider-drawer-menu .header-nav .nav > li:hover > .sub-menu, .header-style-7.mobile-sider-drawer-menu .header-nav .nav > li .sub-menu li:hover > .sub-menu {
    transition: none;
  }

  .header-style-7.mobile-sider-drawer-menu.active .header-nav {
    left: 0%;
  }

  .header-style-7.mobile-sider-drawer-menu .icon-bar {
    transition: all .5s linear;
    position: relative;
  }

  #mobile-side-drawer:focus {
    box-shadow: none;
  }

  .header-style-7.mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
    transform: rotate(45deg)translateX(3px)translateY(2px);
  }

  .header-style-7.mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
    transform: rotate(-45deg)translateX(3px)translateY(-2px);
  }

  .header-style-7.mobile-sider-drawer-menu .icon-bar.icon-bar-two {
    position: relative;
    right: 0%;
  }

  .header-style-7.mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
    opacity: 0;
    position: absolute;
    right: 100px;
  }

  .header-style-7.mobile-sider-drawer-menu .is-fixed .header-nav .nav, .header-style-7.mobile-sider-drawer-menu .header-fixed .header-nav .nav {
    max-height: 100%;
    overflow: auto;
  }
}

@media only screen and (max-width: 480px) {
  .header-style-7 .logo-header {
    width: 100px;
    height: auto;
  }
}

.header-style-8 .top-bar .container {
  justify-content: space-between;
  display: flex;
}

.header-style-8 .cart-dropdown-item-wraper {
  top: 15px !important;
}

@media only screen and (max-width: 575px) {
  .header-style-8 .top-bar .container {
    display: block;
  }

  .header-style-8 .top-bar .container .social-bx {
    float: none;
    margin-left: 0;
  }
}

.header-style-8 .social-bx li {
  padding-left: 2px;
  padding-right: 2px;
  display: inline-block;
}

.header-style-8 .header-nav .nav > li .sub-menu, .header-style-8 .header-nav .nav > li .mega-menu {
  top: 60px;
}

.header-style-8 .header-info > ul li {
  padding: 15px 0 15px 10px;
}

.header-style-8 .has-child .submenu-toogle {
  background-color: #0000;
}

.header-style-8 .header-nav .nav > li .sub-menu .fa {
  z-index: -1;
  font-size: 14px;
  top: 6px;
}

.header-style-8 .has-child .submenu-toogle {
  background-color: #0000;
}

.header-style-8 .header-middle .container {
  justify-content: space-between;
  display: flex;
}

.header-style-8 .header-nav {
  justify-content: inherit;
  flex-basis: inherit;
}

.header-style-8.header-info > ul li {
  padding-right: 0;
}

.header-style-8 .wt-cart.dropdown-toggle:after {
  display: none;
}

.header-style-8 .header-botton .header-nav .nav {
  flex-direction: inherit;
  justify-content: space-between;
  display: flex;
}

.header-style-8 .main-bar {
  max-width: 1140px;
  margin: 0 auto -60px;
}

.header-style-8 .is-fixed .header-nav .nav > li > a {
  padding: 20px 12px;
}

.header-style-8 .is-fixed .navbar-toggle {
  flex-direction: row-reverse;
  margin-top: 10px;
  margin-bottom: 10px;
}

.header-style-8 .header-botton .extra-nav {
  align-items: center;
  padding: 8px 0;
  display: flex;
}

.header-style-8 .main-bar .container {
  justify-content: space-between;
  display: flex;
  position: relative;
}

.header-style-8 .is-fixed .main-bar {
  margin: 0 -573px;
  left: 50%;
}

.header-style-8 .navbar-toggler .icon-bar {
  height: 3px;
  width: 22px;
  background: #fff;
  border-radius: 0;
  display: block;
}

.header-style-8 .navbar-toggler .icon-bar + .icon-bar {
  margin-top: 4px;
}

.header-style-8 .header-nav .has-child.nav-active > a + .submenu-toogle.fa.fa-angle-right:before {
  content: "";
}

.header-style-8 .navbar-toggler {
  display: none;
}

.header-style-8 .header-nav .nav > li > a {
  display: block;
}

@media only screen and (max-width: 1200px) {
  .header-style-8 .main-bar, .header-style-8 .is-fixed .main-bar {
    max-width: 100%;
    margin: 0;
    left: 0;
  }
}

@media only screen and (max-width: 991px) {
  .header-style-8 .navbar-toggler {
    display: block;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }

  .header-style-8 .extra-nav {
    margin-right: 0;
  }

  .header-style-8 .header-nav .nav > li .sub-menu li .fa {
    z-index: 1;
    width: 100%;
    text-align: right;
    padding: 10px;
    top: 4px;
  }

  .header-style-8 .header-nav .nav > li .sub-menu li > a:hover + .fa {
    z-index: 0;
  }

  .header-style-8 .has-child .submenu-toogle {
    color: #000;
    width: 100%;
    text-align: right;
    background-color: #0000;
    font-size: 14px;
  }

  .header-style-8 .is-fixed .header-nav .nav > li > a {
    padding: 12px;
  }

  .header-style-8 .extra-nav .extra-cell {
    margin-left: 0;
    padding-left: 0;
  }

  .header-style-8 .header-botton .extra-nav .site-search-btn {
    color: #fff;
    padding: 7px 15px 7px 0;
  }

  .has-child .submenu-toogle {
    background: none;
  }

  .header-style-8 .header-botton .header-nav .nav {
    float: none;
    width: 100%;
    height: 100%;
    background: #fff;
    margin: 0;
    display: block;
  }

  .header-style-8 .header-botton .header-nav .nav > li > a .fa {
    display: none;
  }

  .header-style-8 .header-botton .header-nav .nav > li .sub-menu li .fa {
    top: 1px;
  }

  .header-style-8 .header-botton .header-nav .nav > li .sub-menu li > .sub-menu {
    transition: none !important;
  }

  .header-style-8.mobile-sider-drawer-menu .header-nav {
    z-index: 999;
    background-color: #fff;
    transition: all .5s linear;
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px !important;
    height: 100vh !important;
    overflow-y: inherit !important;
  }

  .header-style-8.mobile-sider-drawer-menu .header-nav .nav > li:hover > .sub-menu, .header-style-8.mobile-sider-drawer-menu .header-nav .nav > li .sub-menu li:hover > .sub-menu {
    transition: none;
  }

  .header-style-8.mobile-sider-drawer-menu.active .header-nav {
    left: 0%;
  }

  .header-style-8.mobile-sider-drawer-menu .icon-bar {
    transition: all .5s linear;
    position: relative;
  }

  #mobile-side-drawer:focus {
    box-shadow: none;
  }

  .header-style-8.mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
    transform: rotate(45deg)translateX(3px)translateY(2px);
  }

  .header-style-8.mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
    transform: rotate(-45deg)translateX(3px)translateY(-2px);
  }

  .header-style-8.mobile-sider-drawer-menu .icon-bar.icon-bar-two {
    position: relative;
    right: 0%;
  }

  .header-style-8.mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
    opacity: 0;
    position: absolute;
    right: 100px;
  }

  .header-style-8.mobile-sider-drawer-menu .is-fixed .header-nav .nav, .header-style-8.mobile-sider-drawer-menu .header-fixed .header-nav .nav {
    max-height: 100%;
    overflow: auto;
  }
}

@media only screen and (max-width: 480px) {
  .header-style-8 .logo-header {
    width: 100px;
    height: auto;
  }
}

.wt-banner-row, .wt-banner-outer {
  position: relative;
}

.wt-bnr-pic {
  width: 100%;
  overflow: hidden;
}

.wt-bnr-pic img {
  width: 100%;
  height: auto;
  display: block;
}

.wt-bnr-text {
  width: 100%;
  color: #fff;
  margin: 0;
  position: absolute;
  top: 35%;
  left: 0;
}

.wt-bnr-text-has {
  max-width: 600px;
}

.wt-bnr-text strong, .wt-bnr-text span {
  text-shadow: 0 1px 2px #0000004d;
}

.wt-bnr-text strong {
  color: #fff;
  margin: 0 0 25px;
  font-size: 60px;
  font-weight: 700;
  line-height: 70px;
  display: block;
}

.wt-bnr-text span {
  margin-bottom: 30px;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  display: block;
}

.wt-bnr-text .btn {
  margin-right: 30px;
}

@media only screen and (max-width: 1200px) {
  .wt-bnr-text strong {
    margin: 0 0 15px;
    font-size: 60px;
    line-height: 60px;
  }

  .wt-bnr-text span {
    font-size: 20px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 991px) {
  .wt-bnr-text strong {
    margin: 0 0 10px;
    font-size: 50px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .wt-bnr-text strong {
    margin: 0;
    font-size: 40px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 680px) {
  .wt-bnr-text strong {
    margin: 0;
    font-size: 30px;
    line-height: 30px;
  }

  .wt-bnr-text span {
    font-size: 14px;
    line-height: 18px;
  }
}

@media only screen and (max-width: 480px) {
  .wt-bnr-text strong {
    font-size: 20px;
    line-height: 20px;
  }

  .wt-bnr-text span {
    font-size: 12px;
    line-height: 16px;
  }
}

.wt-bnr-inr {
  height: 300px;
  width: 100%;
  background-position: center;
  background-size: cover;
  display: table;
}

.wt-bnr-inr .container {
  height: 100%;
  display: table;
}

.wt-bnr-inr-entry {
  vertical-align: middle;
  display: table-cell;
}

@media only screen and (max-width: 768px) {
  .wt-bnr-inr-entry h1 {
    font-size: 24px;
    line-height: 36px;
  }
}

.banner-inner-row h1, .banner-inner-row h2, .banner-inner-row h3, .banner-inner-row h4, .banner-inner-row h5, .banner-inner-row h6 {
  color: #fff;
}

.owl-carousel .owl-nav {
  text-align: center;
  margin-top: 40px;
}

.owl-carousel .owl-nav .disabled {
  opacity: .5;
  cursor: default;
}

.owl-carousel .owl-dots {
  text-align: center;
  margin-top: 20px;
}

.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
}

.owl-carousel .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  background: #4b3941;
  border: 1px solid #fff;
  margin: 5px 7px;
  transition: opacity .2s;
  display: block;
}

.owl-carousel .owl-dots .owl-dot.active span, .owl-carousel .owl-dots .owl-dot:hover span {
  background: #ec5598;
}

.owl-btn-top-center .owl-nav, .owl-btn-top-left .owl-nav, .owl-btn-top-right .owl-nav {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.owl-btn-top-center .owl-stage-outer, .owl-btn-top-left .owl-stage-outer, .owl-btn-top-right .owl-stage-outer {
  padding-top: 60px;
}

.owl-btn-top-center .owl-nav {
  text-align: center;
}

.owl-btn-top-left .owl-nav {
  text-align: left;
}

.owl-btn-top-right .owl-nav {
  text-align: right;
}

.owl-btn-bottom-center .owl-nav {
  text-align: center;
}

.owl-btn-bottom-left .owl-nav {
  text-align: left;
}

.owl-btn-bottom-right .owl-nav {
  text-align: right;
}

.owl-btn-vertical-center .owl-nav {
  margin: 0;
}

.owl-btn-vertical-center .owl-nav .owl-prev, .owl-btn-vertical-center .owl-nav .owl-next {
  margin: -15px 0;
  position: absolute;
  top: 50%;
}

.owl-btn-vertical-center .owl-nav .owl-prev {
  left: 0;
}

.owl-btn-vertical-center .owl-nav .owl-next {
  right: 0;
}

.owl-btn-hover .owl-nav {
  opacity: 0;
}

.owl-btn-hover:hover .owl-nav {
  opacity: 1;
}

.owl-carousel .owl-item img {
  transform-style: inherit;
}

.owl-prev, .owl-next {
  width: 28px;
  height: 35px;
  text-align: center;
  cursor: pointer;
  padding: 8px 10px;
  font-size: 13px;
  display: inline-block;
  color: #fff !important;
  background-color: #000 !important;
}

.owl-carousel .owl-nav {
  text-align: center;
  margin-top: 30px;
}

.owl-carousel .owl-nav .disabled {
  opacity: .5;
  cursor: default;
}

.owl-prev, .owl-next {
  color: #fff;
  cursor: pointer;
  opacity: .5;
  background-color: #212121;
  border: 1px solid #212121;
  border-radius: 0;
  padding: 8px 10px;
  font-size: 13px;
  display: inline-block;
}

.owl-prev {
  margin-right: 5px;
}

.owl-next {
  margin-left: 5px;
}

.owl-prev:hover, .owl-prev:active, .owl-prev:focus, .owl-next:hover, .owl-next:active, .owl-next:focus {
  color: #fff;
  opacity: .9;
  background-color: #212121;
  border-color: #212121;
}

.owl-dots-bottom-left .owl-dots, .owl-dots-bottom-right .owl-dots, .owl-dots-bottom-center .owl-dots {
  width: 100%;
  padding: 15px 20px;
  position: absolute;
  bottom: 0;
}

.owl-dots-bottom-left .owl-dots {
  text-align: left;
}

.owl-dots-bottom-right .owl-dots {
  text-align: right;
}

.pagination-bx .pagination-1, .cvf-universal-pagination .pagination-1 {
  margin: 0;
}

.pagination-1 {
  padding: 10px 0;
  display: flex;
}

.pagination-1 > li {
  list-style: none;
}

.pagination-1 > li:first-child > a, .pagination-1 > li:first-child > span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
}

.pagination-1 > li:last-child > a, .pagination-1 > li:last-child > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-1 > li > a, .pagination-1 > li > span {
  color: #767676;
  text-align: center;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  padding: 8px 12px;
  font-size: 12px;
  font-weight: 600;
  display: block;
}

.pagination-1 > li > a:hover, .pagination-1 > li > span:hover, .pagination-1 > li > a:focus, .pagination-1 > li > span:focus, .pagination-1 > .active > a, .pagination-1 > .active > span, .pagination-1 > .active > a:hover, .pagination-1 > .active > span:hover, .pagination-1 > .active > a:focus, .pagination-1 > .active > span:focus {
  color: #fff;
  background-color: #ec5598;
  border-color: #0000;
}

.pagination-1 > .previous > a, .pagination-1 > .next > a {
  font-size: 12px;
}

.custom-pagination.pagination-1 {
  padding: 10px 0;
}

@media only screen and (max-width: 991px) {
  .custom-pagination.pagination-1 {
    margin-bottom: 30px;
    padding: 0;
  }
}

.custom-pagination.pagination-1 > li:first-child > a, .custom-pagination.pagination-1 > li:first-child > span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: 0;
}

.custom-pagination.pagination-1 > li:last-child > a, .custom-pagination.pagination-1 > li:last-child > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.custom-pagination.pagination-1 > li > a, .custom-pagination.pagination-1 > li > span {
  color: #fff;
  background-color: #4b3941;
  border: 1px solid #0e131b;
  font-size: 12px;
  font-weight: 600;
}

.custom-pagination.pagination-1 > li > a:hover, .custom-pagination.pagination-1 > li > span:hover, .custom-pagination.pagination-1 > li > a:focus, .custom-pagination.pagination-1 > li > span:focus {
  color: #fff;
  background-color: #ec5598;
  border-color: #0000;
}

.custom-pagination.pagination-1 > .active > a, .custom-pagination.pagination-1 > .active > span, .custom-pagination.pagination-1 > .active > a:hover, .custom-pagination.pagination-1 > .active > span:hover, .custom-pagination.pagination-1 > .active > a:focus, .custom-pagination.pagination-1 > .active > span:focus {
  background-color: #ec5598;
  border-color: #0000;
}

.custom-pagination.pagination-1 > .previous > a, .custom-pagination.pagination-1 > .next > a {
  padding: 8px 14px;
  font-size: 12px;
}

.pager .disabled > a, .pager .disabled > a:focus, .pager .disabled > a:hover, .pager .disabled > span {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
}

.pager li > a, .pager li > span {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 5px 14px;
  display: inline-block;
}

.pager {
  margin: 20px 0;
  display: block;
}

.pager li {
  list-style: none;
  display: inline-block;
}

.pager.d-flex.justify-content-center li {
  margin: 0 2px;
}

.custom-page.pager li a {
  color: #fff;
  background-color: #4b3941;
  border: none;
}

.custom-page.pager li > a:focus, .custom-page.pager li > a:hover {
  background-color: #ffbc13;
  text-decoration: none;
}

.custom-page.pager {
  margin: 20px 0;
}

.custom-page.pager li {
  list-style: none;
}

.custom-page.pager li a {
  padding: 10px;
}

.pagination-1.pagination-lg > li > a, .pagination-1.pagination-lg > li > span {
  padding: 10px 15px;
  font-size: 18px;
  line-height: 1.33333;
}

.pagination-1.pagination-sm > li > a, .pagination-1.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.pagination-1.pagination-sm > li:first-child > a, .pagination-1.pagination-sm > li:first-child > span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.pagination-1.pagination-sm > li:last-child > a, .pagination-1.pagination-sm > li:last-child > span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.pagination-1 > .disabled > a, .pagination-1 > .disabled > a:focus, .pagination-1 > .disabled > a:hover, .pagination-1 > .disabled > span, .pagination-1 > .disabled > span:focus, .pagination-1 > .disabled > span:hover {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

.ftr-btm {
  justify-content: space-between;
  display: flex;
}

.ftr-btm > * {
  width: auto;
}

.footer-fixed .site-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
}

.footer-fixed .page-content {
  z-index: 1;
  background-color: #fff;
  position: relative;
}

@media only screen and (max-width: 1200px) {
  .footer-fixed .site-footer {
    position: static;
  }

  .footer-fixed .page-wraper {
    padding-bottom: 0 !important;
  }
}

.footer-fixed.boxed .site-footer {
  max-width: 1200px;
  margin: 0 -600px;
  left: 50%;
}

.footer-fixed.boxed .wt-footer-callout-wrap {
  overflow: hidden;
}

.constrot-strip {
  height: 35px;
  width: 100%;
  background-image: url("footer-strip.e4f20ba4.png");
  background-position: top;
  background-repeat: repeat-x;
  position: relative;
}

.site-footer .widget > .widget-title {
  letter-spacing: 2px;
}

.site-footer p, .site-footer li {
  font-size: 13px;
  line-height: 20px;
}

.site-footer {
  background-position: center;
  background-size: cover;
}

.site-footer p {
  line-height: 20px;
}

.site-footer .widget ul {
  margin-top: 5px;
  list-style: none;
}

.footer-top {
  padding: 50px 0;
}

.footer-bottom {
  color: #787878;
  padding: 0 0 30px;
  font-size: 13px;
}

.footer-bottom .copyrights-text {
  color: #787878;
}

.footer-bottom .copyrights-nav {
  list-style: none;
  display: inline-block;
}

.footer-bottom .copyrights-nav li {
  margin-left: 8px;
  margin-right: 8px;
  display: inline-block;
}

.footer-bottom .copyrights-nav li:first-child {
  margin-left: 0;
}

.footer-bottom .copyrights-nav li:last-child {
  margin-right: 0;
}

.footer-bottom .copyrights-nav li:last-child:after {
  display: none;
}

.footer-bottom .copyrights-nav li:after {
  content: "/";
  position: relative;
  right: -8px;
}

.footer-bottom .copyrights-nav li a {
  color: #787878;
}

.footer-bottom .copyrights-nav li a:hover, .footer-bottom .copyrights-nav li a:active, .footer-bottom .copyrights-nav li a:focus, .footer-bottom .copyrights-nav li.active a {
  color: #ec5598;
}

.footer-dark .footer-top, .footer-dark .footer-bottom {
  background-color: #1b1b1b;
}

.footer-dark .footer-top h1, .footer-dark .footer-top h2, .footer-dark .footer-top h3, .footer-dark .footer-top h4, .footer-dark .footer-top h5, .footer-dark .footer-top h6, .footer-dark .footer-top h1 a, .footer-dark .footer-top h2 a, .footer-dark .footer-top h3 a, .footer-dark .footer-top h4 a, .footer-dark .footer-top h5 a, .footer-dark .footer-top h6 a {
  color: #fff;
}

.footer-dark .footer-top .widget-title:before {
  border-color: #fff;
}

.footer-dark .footer-top p, .footer-dark .footer-top strong, .footer-dark .footer-top b, .footer-dark .footer-top, .footer-dark .widget_categories ul li a, .footer-dark .widget_archive ul li a, .footer-dark .widget_meta ul li a, .footer-dark .widget_pages ul li a, .footer-dark .widget_recent_comments ul li a, .footer-dark .widget_nav_menu li a, .footer-dark .widget_recent_entries ul li a, .footer-dark .widget_services ul li a {
  color: #999;
}

.footer-dark .footer-top a:active, .footer-dark .footer-top a:focus, .footer-dark .footer-top a:hover {
  color: #ec5598;
}

.footer-dark .footer-top p a {
  color: #3396d1;
}

.footer-dark .footer-top .widget_newsletter .form-control {
  background-color: #0e0e0e;
  border-color: #2d2d2d;
}

.footer-light .footer-top, .footer-light .footer-bottom {
  background-color: #eee;
}

footer .widget_categories ul li, footer .widget_archive ul li, footer .widget_meta ul li, footer .widget_pages ul li, footer .widget_recent_comments ul li, footer .widget_nav_menu li, footer .widget_recent_entries ul li, footer .widget_services ul li {
  border-bottom: 1px dashed #6666664d;
}

footer .widget_categories ul li:last-child, footer .widget_archive ul li:last-child, footer .widget_meta ul li:last-child, footer .widget_pages ul li:last-child, footer .widget_recent_comments ul li, footer .widget_nav_menu li:last-child, footer .widget_recent_entries ul li:last-child, footer .widget_services ul li:last-child {
  border-bottom: none;
}

footer .widget_getintuch i {
  background-color: #0003;
  border: 1px solid #0000004d;
}

.footer-bottom ul {
  margin: 0;
}

.wt-footer-bot-left, .wt-footer-bot-right, .wt-footer-bot-center {
  padding-left: 15px;
  padding-right: 15px;
}

.wt-footer-bot-left {
  float: left;
}

.wt-footer-bot-right, .wt-footer-bot-center {
  float: right;
}

@media only screen and (max-width: 991px) {
  footer .container {
    width: 100%;
  }

  .col-md-3.col-sm-6.footer-col-4:nth-child(3) {
    clear: both;
  }

  .wt-footer-bot-left, .wt-footer-bot-right {
    float: none;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .wt-footer-bot-right .copyrights-nav {
    float: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .footer-clear {
    clear: both;
    display: block !important;
  }

  #footer .footer-4-col, .footer-bottom [class*="clo-"] {
    width: 100%;
  }
}

button.scroltop {
  height: 55px;
  width: 55px;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  z-index: 999;
  background: #161616;
  border: 3px solid;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  font-size: 11px;
  font-weight: bold;
  line-height: 16px;
  display: none;
  position: fixed;
  bottom: 25px;
  right: 150px;
}

button.scroltop span {
  font-size: 24px;
  line-height: 24px;
  display: block;
}

.string {
  position: relative;
}

#btn-vibrate {
  animation: 2s linear infinite vibrate;
}

@keyframes vibrate {
  0% {
    top: 0;
  }

  25% {
    top: -3px;
  }

  50% {
    top: 0;
  }

  75% {
    top: 3px;
  }

  100% {
    top: 0;
  }
}

@media only screen and (max-width: 480px) {
  button.scroltop {
    height: 30px;
    width: 30px;
    font-size: 7px;
    line-height: 16px;
  }

  button.scroltop span {
    font-size: 10px;
    line-height: 10px;
  }
}

#bg {
  background-size: cover;
  background-attachment: fixed;
}

.boxed .page-wraper {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 10px #00000080;
}

.boxed .tp-banner-container, .boxed .rev_slider_wrapper {
  width: 100% !important;
  left: 0 !important;
}

.boxed .tp-rightarrow {
  right: 0 !important;
}

.boxed .tp-leftarrow {
  left: 0 !important;
}

.page-wraper {
  background: #fff;
}

.page-content, .section-full {
  position: relative;
}

.section-head {
  margin-bottom: 40px;
}

.section-head.no-margin {
  margin-bottom: 0;
}

.section-head h1, .section-head h2, .section-head h3 {
  margin-top: 0;
}

@media only screen and (max-width: 768px) {
  .section-head h1, .section-head h2, .section-head h3 {
    font-size: 23px;
  }
}

.title-small {
  color: #6f6f6f;
  margin-bottom: 15px;
  display: block;
}

.section-head p {
  padding-top: 10px;
  font-size: 14px;
}

.section-head p:last-child {
  margin-bottom: 0;
}

.text-center.section-head p {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.text-white, .text-white h1, .text-white h2, .text-white h3, .text-white h4, .text-white h5, .text-white h6, .text-white p, .text-white .title-small {
  color: #fff;
}

@media only screen and (max-width: 1024px) {
  .section-full {
    background-attachment: scroll !important;
  }
}

.zabuto_calendar, .zabuto_calendar th, .zabuto_calendar td, .zabuto_calendar .calendar-month-navigation {
  text-align: center;
  cursor: pointer;
}

.banner-contact-row {
  height: 300px;
  background-position: center;
  background-size: cover;
}

.banner-contact-row * {
  color: #fff;
}

.banner-contact-row .container {
  height: 100%;
  display: table;
}

.banner-contact-row h1 {
  vertical-align: middle;
  display: table-cell;
}

ul.contact-info li {
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 15px;
  padding: 0 0 15px 25px;
  line-height: 20px;
  position: relative;
}

ul.contact-info li:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

ul.contact-info li h4 ul.contact-info li h5, ul.contact-info li h6 {
  margin: 0 0 10px;
}

ul.contact-info li p {
  margin: 0;
  line-height: 20px;
}

ul.contact-info li .fa {
  color: #3396d1;
  font-size: 16px;
  position: absolute;
  top: 2px;
  left: 0;
}

.page-notfound-wrap {
  align-items: center;
  display: flex;
}

.page-notfound-left {
  margin: 60px 0;
}

.page-notfound-left [class^="flaticon-"]:before, .page-notfound-left [class^="flaticon-"]:after {
  display: block;
  font-size: 200px !important;
}

.page-notfound {
  padding: 60px 0;
}

.page-notfound strong {
  font-size: 70px;
  font-weight: 900;
  line-height: 100px;
  display: block;
}

.page-notfound strong i {
  margin: 0 10px;
  font-size: 70px;
}

.page-notfound .searchform {
  text-align: left;
  max-width: 420px;
  margin: 30px auto;
  position: relative;
}

@media only screen and (max-width: 991px) {
  .page-notfound strong, .page-notfound strong i {
    font-size: 32px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 420px) {
  .page-notfound {
    padding: 0;
  }

  .page-notfound span {
    font-size: 20px !important;
  }

  .page-notfound strong, .page-notfound strong i {
    font-size: 32px;
    line-height: 40px;
  }

  .page-notfound strong i {
    margin: 0 2px;
  }

  .page-notfound-left [class^="flaticon-"]:before, .page-notfound-left [class^="flaticon-"]:after {
    font-size: 120px !important;
  }
}

.sticky, .gallery-caption, .bypostauthor {
  clear: both;
}

.page-notfound span {
  color: #333;
  text-transform: uppercase;
  margin-bottom: 30px;
  font-size: 36px;
  font-weight: 600;
  display: block;
}

@media only screen and (max-width: 991px) {
  .page-notfound span {
    text-transform: capitalize;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .page-notfound {
    padding: 0;
  }

  .page-notfound-left {
    margin-bottom: 30px;
  }
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  width: 1px;
  overflow: hidden;
  position: absolute !important;
}

.screen-reader-text:focus {
  color: #21759b;
  height: auto;
  width: auto;
  z-index: 100000;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 15px 23px 14px;
  font-size: .875rem;
  font-weight: bold;
  line-height: normal;
  text-decoration: none;
  display: block;
  top: 5px;
  left: 5px;
  box-shadow: 0 0 2px 2px #0009;
  clip: auto !important;
}

.button-xl i {
  margin-left: 50px;
  padding: 23px;
  font-size: 30px;
}

.button-lg i {
  margin-left: 35px;
  padding: 15px;
  font-size: 20px;
}

.button-sm i {
  margin-left: 10px;
  padding: 5.5px 6px;
  font-size: 12px;
}

.skew-btn {
  transform: skew(-25deg);
}

.skew-btn > * {
  display: block;
  transform: skew(25deg);
}

.max-w100 {
  max-width: 100px;
}

.max-w200 {
  max-width: 200px;
}

.max-w300 {
  max-width: 300px;
}

.max-w400 {
  max-width: 400px;
}

.max-w500 {
  max-width: 500px;
}

.max-w600 {
  max-width: 600px;
}

.max-w700 {
  max-width: 700px;
}

.max-w800 {
  max-width: 800px;
}

.max-w900 {
  max-width: 900px;
}

.max-w1000 {
  max-width: 1000px;
}

.wt-product-box .price {
  margin-bottom: 10px;
}

.wt-product-box .price del {
  color: #999;
  margin: 4px;
  font-size: 16px;
  font-weight: 600;
}

.wt-product-box .price ins {
  color: #111;
  margin: 4px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
}

.wt-product-box .rating-bx {
  color: #ffa000;
  padding-top: 10px;
}

.wt-product-gallery a img {
  width: 100%;
}

.btn-quantity {
  width: 150px;
}

.product-description.dex-tabs.border-top .nav-tabs > li.active > a {
  border-top: 2px solid #efbb20;
}

.product-description.dex-tabs .nav-tabs > li > a i {
  color: #efbb20;
}

.dex-tabs.bg-tabs .nav-tabs > li > a {
  background-color: #fcfcfc;
}

@media only screen and (max-width: 480px) {
  .btn-quantity {
    width: 100%;
    margin-bottom: 30px;
  }
}

ol.commentlist {
  margin: 0;
  list-style: none;
}

ol.commentlist li {
  background: #f7f8fa;
  border: 1px dashed #eee;
  margin-bottom: 20px;
  padding: 20px;
  position: relative;
}

ol.commentlist li img {
  float: left;
  width: 80px;
  height: auto;
  border: 5px solid #fff;
}

ol.commentlist li .comment-text {
  padding-left: 100px;
}

ol.commentlist li .meta {
  margin-bottom: 5px;
}

ol.commentlist li .meta strong {
  font-size: 16px;
}

ol.commentlist li .meta .time {
  color: #999;
  font-size: 14px;
  display: block;
}

ol.commentlist li .description p {
  margin: 0;
}

ol.commentlist li .star-rating {
  position: absolute;
  top: 20px;
  right: 20px;
}

@media only screen and (max-width: 767px) {
  ol.commentlist li .star-rating {
    position: inherit;
  }
}

.comment-reply-title {
  margin-top: 0;
}

.comment-form [class*="comment-form"] {
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .comment-form [class*="comment-form"] {
    width: 100%;
  }
}

.comment-form [class*="-form-author"], .comment-form [class*="-form-email"] {
  float: left;
}

.comment-form [class*="-form-comment"] {
  width: 100%;
}

.comment-form label {
  display: block;
}

.star-Rating-input i {
  font-size: 18px;
}

.star-Rating-input .fa-star, .star-Rating-input i:hover {
  color: #ec5598;
}

.comment-form [class*="comment-form"] input, .comment-form [class*="comment-form"] textarea {
  width: 100%;
  border: 1px solid #ccc;
  padding: 8px 10px;
}

.comment-form {
  margin: 0 -15px;
}

.comment-form [class*="comment-form"], .comment-form .form-submit {
  margin-bottom: 20px;
  padding: 0 15px;
}

.comment-form .form-submit input:active, .comment-form .form-submit input:focus, .comment-form .form-submit input:hover {
  background: #037a85;
}

.wt-info-has {
  width: 100%;
  height: auto;
  opacity: 0;
  z-index: 2;
  margin-bottom: -100px;
  padding: 30px 40px 40px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.wt-info-has.p-a15 {
  padding: 15px;
}

.wt-info-has.p-a20 {
  padding: 20px;
}

.wt-info-has.site-bg-primary {
  background-color: #1bbce8e6;
}

.wt-info-has.bg-green {
  background-color: #6ab33ee6;
}

.wt-info-has.bg-black {
  background-color: #0009;
}

.wt-info-has.bg-white {
  background-color: #fffc;
}

.wt-info-has.bg-white h1, .wt-info-has.bg-white h2, .wt-info-has.bg-white h3, .wt-info-has.bg-white h4, .wt-info-has.bg-white h5, .wt-info-has.bg-white h6, .wt-info-has.bg-white h1 a, .wt-info-has.bg-white h2 a, .wt-info-has.bg-white h3 a, .wt-info-has.bg-white h4 a, .wt-info-has.bg-white h5 a, .wt-info-has.bg-white h6 a {
  color: #3d474a;
}

.wt-info-has.bg-white .wt-info-has-text, .wt-info-has.bg-white p {
  color: #585858;
}

.wt-info-has h1, .wt-info-has h2, .wt-info-has h3, .wt-info-has h4, .wt-info-has h5, .wt-info-has h6, .wt-info-has p, .wt-info-has-text {
  color: #fff;
}

.wt-info-has .wt-info-has-text {
  margin-bottom: 20px;
  line-height: 24px;
}

.wt-box:hover .wt-info-has, .wt-thum-bx:hover .wt-info-has, .wt-info-has.no-hover {
  opacity: 1;
  width: 100%;
  margin-bottom: 0;
}

@media only screen and (max-width: 768px) {
  .wt-media > img, .wt-media a > img {
    width: 100%;
  }
}

@media only screen and (max-width: 420px) {
  .wt-info-has {
    opacity: 1;
    border: 1px solid #ddd;
    margin-bottom: 0;
    position: relative;
    bottom: auto;
    left: auto;
  }
}

.btn, .panel, .form-control, .img-thumbnail, .panel-head ing, .dropdown-menu, .panel-group .panel, .nav-tabs > li > a, .modal-content, .navbar-toggle, .nav-pills > li > a, .pager li > a, .pager li > span, .well, .alert, .list-group-item:first-child, .list-group-item:last-child, .input-group-addon, .btn-group-divides > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle), .btn-group-divides > .btn:last-child:not(:first-child), .btn-group > .dropdown-toggle:not(:first-child), .wt-booking-form .wt-extra-services li .wt-icon-box-lg, .wt-booking-form .wt-extra-services li.active .wt-icon-box-lg:after, .searchform input[type="text"], .searchform input[type="submit"], ol.comment-list li.comment .comment-body {
  border-radius: 0;
}

a, .btn, .form-control, .overlay-bx, .overlay-icon li a i, .wt-icon-box-xld, .wt-icon-box-xl, .wt-icon-box-lg, .wt-icon-box-md, .wt-icon-box-sm, .wt-icon-box-xs, .share-social-bx li, .indicator, .profile-menu ul li, .staff-member .member-done, .how-wt-work .wt-box .shadow-bx img, .how-wt-work .wt-box .step-no-bx, .wt-info-has, .wt-we-find .wt-box, .navbar-toggle, .logo-header, .header-nav .nav > li > a, .extra-nav, .acod-head .indicator .fa {
  transition: all .2s linear;
}

.animate-slow {
  transition: all .3s ease-out;
}

.animate-mid {
  transition: all .5s ease-out;
}

.animate-fast {
  transition: all .8s ease-out;
}

.clearfix:after, .filter-wrap:after, .filter-wrap:after, .wt-icon-box-wraper:after, ol.commentlist li:after {
  content: "";
  clear: both;
  display: table;
}

.wt-contact-wrap {
  margin: 0;
  display: flex;
  position: relative;
}

.contact-left-part {
  width: 50%;
  float: left;
  position: relative;
}

.contact-left-part .google-map {
  width: 100%;
  height: 100%;
}

.contact-left-part .gmap-outline {
  height: 100%;
  padding: 0;
}

.contact-right-part {
  width: 50%;
  float: right;
}

.contact-left-part .google-map iframe {
  height: 100%;
}

@media only screen and (max-width: 1366px) {
  .contact-left-part {
    width: 30%;
    float: left;
  }

  .contact-right-part {
    width: 70%;
    float: right;
  }
}

@media only screen and (max-width: 767px) {
  .contact-left-part, .contact-right-part {
    width: 100%;
    float: none;
  }

  .contact-left-part .gmap-outline {
    height: 280px;
  }

  .contact-right-part {
    margin-bottom: 30px;
  }
}

.wt-team-one {
  position: relative;
}

.wt-team-one .wt-team-info p {
  margin-bottom: 20px;
}

.wt-team-one .social-icons {
  margin-bottom: 0;
}

.wt-team-two {
  padding: 10px;
  position: relative;
}

.wt-team-two .wt-team-media {
  border: 5px solid #fff;
  overflow: hidden;
}

.wt-team-two .wt-team-info, .wt-team-three .wt-team-info p {
  margin-bottom: 10px;
}

.wt-team-four {
  background-color: #f2f2f2;
  position: relative;
}

.wt-team-four .wt-team-media a img {
  width: 100%;
}

.wt-team-four .wt-team-skew-block {
  background-color: #4b3941;
  top: 0;
  left: 0;
}

.wt-team-four .wt-team-skew-block .social-icons-outer {
  z-index: 1;
  margin-right: 70px;
  position: relative;
}

.wt-team-four .wt-team-info {
  position: relative;
}

.wt-team-four .wt-team-info .wt-team-title {
  margin: 0 0 5px;
}

.wt-team-four .wt-team-info p {
  margin-bottom: 0;
}

.wt-team-four:hover .wt-team-media {
  position: relative;
}

.wt-team-four:hover .wt-team-media:after {
  content: "";
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 3px solid #4b3941;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.wt-team-five-warper {
  text-align: center;
  margin-left: 60px;
  margin-right: 60px;
  display: table;
}

.wt-team-five-warper .wt-col-5 {
  z-index: 2;
  width: 20%;
  vertical-align: bottom;
  display: table-cell;
  position: relative;
}

.wt-team-five-warper .wt-col-5:last-child {
  z-index: 1;
}

.wt-team-five-warper .wt-col-5:last-child:hover {
  z-index: 2;
}

.wt-team-five .wt-team-media img {
  max-width: 160%;
  margin-left: -30%;
  margin-right: -30%;
}

.wt-team-five-warper .wt-team-active {
  z-index: 5;
}

.wt-team-five-warper .wt-team-active .wt-team-media img {
  min-width: 180%;
  margin-left: -40%;
  margin-right: -40%;
}

.wt-team-five .wt-team-info {
  width: 100%;
  opacity: 0;
  transition: all .5s;
  position: absolute;
  bottom: 50px;
  left: 0;
}

.wt-team-five .wt-arrow-up {
  z-index: 2;
  position: relative;
}

.wt-team-five .wt-arrow-up:before {
  content: "";
  height: 0;
  width: 0;
  border-bottom: 8px solid #000;
  border-left: 8px solid #0000;
  border-right: 8px solid #0000;
  margin: 0 -4px;
  position: absolute;
  top: -8px;
  right: 50%;
}

.wt-team-five .wt-team-info .wt-team-title, .wt-team-five .wt-team-info .wt-team-title a {
  color: #fff;
}

.wt-team-five .wt-team-info .wt-team-title a:hover {
  color: #000;
}

.wt-col-5:hover {
  z-index: 10;
}

.wt-team-five:hover .wt-team-info {
  opacity: 1;
  bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .wt-team-five-warper {
    display: block;
  }

  .wt-team-five-warper .wt-col-5 {
    width: auto;
    border: 3px solid #ec5598;
    margin-bottom: 30px;
    padding: 20px 20px 0;
    display: block;
  }

  .wt-team-five .wt-team-media img {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .wt-team-five-warper .wt-team-active .wt-team-media img {
    min-width: inherit;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .wt-team-five:hover .wt-team-info {
    opacity: 1;
    width: 100%;
    bottom: 0;
    left: 0;
  }
}

@media only screen and (max-width: 460px) {
  .wt-team-five-warper {
    margin: 0;
  }
}

.wt-social-block {
  width: 100%;
  background: #4b3941;
  padding-top: 10px;
  position: absolute;
  bottom: 0;
}

.product-block p {
  margin-bottom: 0;
}

.wt-nav-block {
  border: 1px solid #ddd;
}

.wt-nav {
  margin: 0;
  list-style: none;
}

.wt-nav-pills li {
  border-bottom: 1px solid #ddd;
  display: block;
}

.wt-nav-pills li:last-child {
  border: 0;
}

.wt-nav-pills li a {
  color: #777;
  padding: 10px;
  display: block;
}

.wt-nav-pills li:hover a {
  color: #fff;
  background-color: #ec5598;
}

.wt-nav-title {
  background: #f8f9f9;
  border-bottom: 1px solid #ddd;
  margin: 0;
  padding: 10px;
}

.company-detail-skew {
  position: relative;
}

.company-detail-skew:after {
  content: "";
  height: 115%;
  width: 1920px;
  z-index: 1;
  background-color: #ec5598;
  background-image: url("bg-7.b94ef382.png");
  background-repeat: repeat;
  border-right: 12px solid #1e1c1c;
  display: block;
  position: absolute;
  top: -20px;
  right: 100%;
  transform: skew(30deg);
}

@media only screen and (max-width: 991px) {
  .company-detail-skew:after {
    margin-right: -50px;
    transform: skew(20deg);
  }
}

@media only screen and (max-width: 767px) {
  .company-detail-skew:after {
    margin-right: -70px;
  }
}

@media only screen and (max-width: 600px) {
  .company-detail-skew:after {
    display: none;
  }
}

.company-status {
  padding-left: 190px;
}

@media only screen and (max-width: 1200px) {
  .company-status {
    padding-left: 140px;
  }
}

@media only screen and (max-width: 991px) {
  .company-status {
    padding-left: 120px;
  }
}

@media only screen and (max-width: 600px) {
  .company-status {
    padding-left: 0;
  }
}

@media only screen and (max-width: 470px) {
  .company-status .col-xs-6 {
    width: 100%;
    margin-bottom: 10px;
  }
}

.labour-pic {
  z-index: 2;
  position: relative;
  top: -50px;
}

@media only screen and (max-width: 991px) {
  .labour-pic {
    display: none;
  }
}

.labour-pic img {
  position: absolute;
  top: 0;
}

.status-sign {
  height: 60px;
  width: 96px;
  float: left;
  background: #fff;
  position: relative;
}

.status-sign i {
  color: #4b3941;
  float: left;
  padding-top: 10px;
  padding-left: 30px;
  font-size: 38px;
}

.status-value span {
  height: 60px;
  height: 60px;
  color: #ec5598;
  font-size: 36px;
  font-weight: bold;
  display: inline;
  overflow: hidden;
}

.client-grid {
  overflow: hidden;
}

.client-grid [class*="col-"] {
  z-index: 1;
  position: relative;
}

.client-grid [class*="col-"] a {
  display: block;
}

.client-grid [class*="col-"]:after {
  content: "";
  height: 100%;
  width: 100%;
  z-index: -1;
  border-bottom: 1px dashed #dcdcdc;
  border-left: 1px dashed #dcdcdc;
  position: absolute;
  bottom: -1px;
  left: -1px;
}

.grid-5 [class*="col-"] {
  width: 20%;
}

.grid-4 [class*="col-"] {
  width: 25%;
}

.grid-3 [class*="col-"] {
  width: 33.33%;
}

.grid-2 [class*="col-"] {
  width: 50%;
}

@media only screen and (max-width: 991px) {
  .grid-5 [class*="col-"] {
    width: 25%;
  }
}

@media only screen and (max-width: 767px) {
  .grid-5 [class*="col-"], .grid-4 [class*="col-"], .grid-3 [class*="col-"] {
    width: 50%;
  }
}

@media only screen and (max-width: 360px) {
  .grid-5 [class*="col-"], .grid-4 [class*="col-"], .grid-3 [class*="col-"] {
    width: 100%;
  }
}

.faq-block {
  margin: 0 0 30px;
}

.faq-block .faq-que {
  padding: 0 10px 11px 60px;
  font-size: 18px;
  position: relative;
}

.faq-block .faq-ans {
  padding: 0 10px 11px 60px;
  position: relative;
}

.faq-block .faq-que:after, .faq-block .faq-ans:after {
  width: 40px;
  height: 40px;
  color: #fff;
  text-align: center;
  background-color: #ec5598;
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.faq-block .faq-que:after {
  content: "Q";
}

.faq-block .faq-ans:after {
  content: "A";
}

@media only screen and (max-width: 767px) {
  .faq-block h2 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 320px) {
  .faq-block h2 {
    font-size: 16px;
  }
}

.wt-cart .woo-cart-total {
  color: #555;
  font-weight: 600;
  display: inline-block;
}

.wt-cart .woo-cart-count {
  color: #555;
  min-width: 40px;
  text-align: center;
  border: 2px solid #555;
  padding: 9px 5px 10px;
  font-weight: 800;
  display: inline-block;
  position: relative;
}

.wt-cart .woo-cart-count:before {
  content: "";
  width: 22px;
  height: 10px;
  border: 2px solid #555;
  border-bottom: none;
  margin: 0 -11px;
  position: absolute;
  top: -8px;
  left: 50%;
}

.cart-dropdown-item-wraper {
  z-index: 999;
  width: 280px;
  text-align: left;
  background-color: #fff;
  border: 3px solid #eaeaea;
  padding: 2px;
  display: none;
  position: absolute;
  top: 110%;
  right: 0;
  box-shadow: 0 0 10px #00000029;
}

.nav-cart-items {
  position: relative;
}

.nav-cart-title {
  border-bottom: 1px solid #eee;
}

.nav-cart-title h4 {
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0;
  font-size: 15px;
  font-weight: 700;
}

.nav-cart-item {
  border-top: 1px solid #f5f5f5;
  margin-top: 15px;
  padding-top: 15px;
}

.nav-cart-item:first-child {
  border-top: 0;
  margin-top: 0;
  padding-top: 0;
}

.nav-cart-item-image {
  float: left;
  height: 48px;
  width: 48px;
  border: 2px solid #eee;
  margin-right: 15px;
  transition: border-color .2s linear;
}

.nav-cart-item-image a, .nav-cart-item-image img {
  height: 44px;
  width: 44px;
  display: block;
}

.nav-cart-item-image:hover {
  border-color: #243447;
}

.nav-cart-item-desc {
  position: relative;
  overflow: hidden;
}

.nav-cart-item-desc a {
  height: auto;
  text-align: left;
  width: auto;
  font-size: 13px;
  font-weight: 600;
}

.nav-cart-item-desc a:hover {
  color: #243447;
}

.nav-cart-item-desc span.nav-cart-item-price {
  color: #999;
  font-size: 12px;
  line-height: 20px;
  display: block;
}

.nav-cart-item-desc a.nav-cart-item-quantity {
  color: #444;
  padding: 0 5px 2px;
  font-size: 12px;
  display: block;
  position: absolute;
  top: 2px;
  right: 0;
}

.nav-cart-item-desc a:hover.nav-cart-item-quantity {
  color: #fff;
  background-color: #000;
}

.nav-cart-action {
  border-top: 1px solid #eee;
}

.pro-banner {
  background-color: #fff;
  position: relative;
  overflow: hidden;
}

.pro-banner-disc {
  width: 100%;
  height: 100%;
  background-color: #0006;
}

.pro-banner-disc h2, .pro-banner-disc h3 {
  font-weight: 700;
}

.pro-banner-disc h3 {
  color: #ec5598;
}

.pro-banner-disc a {
  position: inherit;
  bottom: 0;
  right: 0;
}

@media only screen and (max-width: 991px) {
  .pro-banner img {
    width: 100%;
  }
}

@media only screen and (max-width: 420px) {
  .pro-banner-disc {
    padding: 10px !important;
  }

  .pro-banner-disc h2 {
    font-size: 15px;
  }

  .pro-banner-disc h3 {
    font-size: 14px;
  }

  .pro-banner-disc h4 {
    font-size: 13px;
  }

  .pro-banner-disc h5 {
    font-size: 12px;
  }

  .pro-banner-disc h2, .pro-banner-disc h3, .pro-banner-disc h4, .pro-banner-disc h5 {
    margin-bottom: 0 !important;
  }
}

.sf-provider-des.sf-provider-descriptions ul.sharebtn-bx, .sf-provider-des.sf-provider-descriptions ul.share-social-bx {
  margin: 5px auto;
  display: table;
  width: auto !important;
  float: none !important;
}

.sf-provider-des.sf-provider-descriptions .tagline {
  padding-top: 10px;
}

.sf-provider-des.sf-provider-descriptions .sf-provider-cat {
  margin: 10px 0;
}

.img-reflection {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  overflow: hidden;
}

.img-reflection:after {
  content: "";
  width: 35%;
  height: 200%;
  background-color: #fff3;
  transition: all .5s linear;
  position: absolute;
  top: -50%;
  left: -100%;
  transform: rotate(40deg);
}

.img-reflection:hover:after {
  left: 150%;
}

.about-com-pic {
  z-index: 1;
  margin-left: 10px;
  margin-right: 30px;
  position: relative;
}

.about-com-pic:after {
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #ffbc13;
  position: absolute;
  top: 10px;
  left: -10px;
}

.about-com-pic img {
  width: 100%;
}

.about-com-pic-2 img {
  width: 100%;
  height: 540px;
  object-fit: cover;
  object-position: center;
  padding-right: 15px;
}

@media only screen and (max-width: 991px) {
  .about-com-pic-2 img {
    height: 400px;
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .about-com-pic {
    margin-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .about-com-pic {
    margin-bottom: 40px;
  }
}

.flip-container {
  perspective: 1000px;
  transform: perspective(1000px);
}

.flip-container:hover .wt-info {
  transform: rotateY(0);
}

.flip-container:hover .wt-thum-bx {
  transform: rotateY(180deg);
}

.flip-container, .flip-container .wt-thum-bx, .flip-container .wt-info {
  width: 100%;
  height: 100%;
}

.flip-container .wt-box {
  transition: all .6s;
  position: relative;
  transform: perspective(1000px);
}

.flip-container .wt-thum-bx, .flip-container .wt-info {
  backface-visibility: hidden;
  transition: all .6s;
  top: 0;
  left: 0;
  transform: rotateY(0);
}

.flip-container .wt-info {
  position: absolute;
}

.flip-container .wt-info .wt-info-text {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.flip-container .wt-info .wt-info-text p {
  margin-bottom: 0;
}

@media only screen and (max-width: 320px) {
  .flip-container .wt-info .wt-info-text {
    padding: 15px;
  }
}

.flip-container .wt-info .wt-info-media-zoom {
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
}

.flip-container .wt-thum-bx {
  z-index: 2;
  transform: rotateY(0);
}

.flip-container .wt-thum-bx img {
  transform-style: flat;
}

.flip-container .wt-info {
  transform: rotateY(-180deg);
}

.vertical.flip-container {
  position: relative;
}

.vertical.flip-container .wt-info {
  transform: rotateX(180deg);
}

.vertical.flip-container .wt-box {
  transform-origin: 100% 213.5px;
}

.vertical.flip-container:hover .wt-info {
  transform: rotateX(0);
}

.vertical.flip-container:hover .wt-thum-bx {
  transform: rotateX(180deg);
}

.animate_line {
  z-index: 0;
  position: relative;
}

.animate_line .wt-icon-box-wraper {
  margin-top: 10px;
  margin-bottom: 10px;
}

.animate_line:after, .animate_line:before {
  content: "";
  height: 4px;
  width: 0;
  z-index: -1;
  transition: all .5s;
  position: absolute;
}

.animate_line:after {
  bottom: 3px;
  left: 10px;
}

.animate_line:before {
  top: 3px;
  right: 10px;
}

.animate_line:hover:after, .animate_line:hover:before {
  width: 50%;
  height: 50%;
  background: #ffbc13;
}

@media only screen and (max-width: 767px) {
  .animate_line:after {
    bottom: 2px;
  }

  .animate_line:before {
    top: 2px;
  }
}

.timeline {
  position: relative;
}

.timeline:before {
  content: "";
  width: 1px;
  z-index: 0;
  background-color: #d8d9df;
  position: absolute;
  top: 30px;
  bottom: 0;
  left: 50%;
}

.timeline .timeline-item {
  width: 50%;
  position: relative;
}

.timeline .timeline-item:nth-child(2n+1) {
  text-align: right;
  padding-right: 60px;
}

.timeline .timeline-item:nth-child(2n) {
  text-align: left;
  margin-left: 50%;
  padding-left: 60px;
}

.timeline .timeline-icon {
  position: absolute;
}

.timeline .timeline-item:nth-child(2n+1) .timeline-icon {
  right: -40px;
}

.timeline .timeline-item:nth-child(2n) .timeline-icon {
  left: -40px;
}

@media only screen and (max-width: 480px) {
  .timeline:before {
    left: 10%;
    right: auto;
  }

  .timeline .timeline-item {
    width: 100%;
  }

  .timeline .timeline-item:nth-child(2n+1) {
    text-align: left;
    padding-left: 100px;
    padding-right: 0;
  }

  .timeline .timeline-item:nth-child(2n) {
    text-align: left;
    margin-left: 0%;
    padding-left: 100px;
  }

  .timeline .timeline-item:nth-child(2n+1) .timeline-icon {
    left: 5px;
    right: auto;
  }

  .timeline .timeline-item:nth-child(2n) .timeline-icon {
    left: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .our-value {
    margin-bottom: 40px;
  }

  .our-value .our-value-right {
    padding: 20px 40px;
  }
}

.summary-counter .icon-content div span.counter, .summary-counter .icon-content div b {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 36px;
  font-weight: 700;
}

.summary-counter .icon-content span {
  font-size: 18px;
  font-weight: 700;
}

.circle-effect-1 {
  z-index: 2;
  margin: 10px 17px;
  position: relative;
}

.circle-effect-1 + .wt-info h2 {
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 30px;
}

.radius-bx.circle-effect-1:after, .radius-bx.circle-effect-1:before {
  content: "";
  border-radius: 100%;
  position: absolute;
}

.circle-effect-1:after {
  width: 110%;
  height: 110%;
  z-index: -2;
  background-color: #ec5598;
  top: -5%;
  left: -5%;
}

.circle-effect-1:before {
  width: 105%;
  height: 105%;
  z-index: -1;
  background-color: #fff;
  top: -4%;
  left: -4%;
}

.circle-effect-1:after, .circle-effect-1:before {
  transition: all .2s linear;
}

.circle-effect-1:hover:after {
  top: -5%;
  left: -5%;
}

.circle-effect-1:hover:before {
  top: -1%;
  left: -1%;
}

.circle-effect-1 + .wt-info p {
  margin-bottom: 15px;
  font-size: 16px;
}

.circle-effect-2 {
  width: 94%;
  z-index: 2;
  margin: 0 auto;
  position: relative;
}

.circle-effect-2 + .wt-info p {
  font-size: 16px;
}

.price-tag-3 {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 30px;
}

.price-tag {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
}

.price-circle, .pricing-circle-2 {
  width: 60px;
  height: 60px;
}

.price-circle {
  display: table;
  box-shadow: 0 0 10px #0009;
}

.pricing-circle-2 {
  display: table;
}

.price-circle span, .pricing-circle-2 span {
  vertical-align: middle;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  display: table-cell;
}

.special-offer-outer {
  z-index: 1;
  position: relative;
}

.special-offer-outer:after {
  content: "";
  height: 100%;
  width: 50%;
  background-color: #ec5598;
  border-right: 10px solid #fff;
  position: absolute;
  top: 0;
  right: -25%;
}

.radius.special-offer-outer:after {
  border-radius: 0 50% 50% 0;
}

.special-offer {
  z-index: 1;
  background-color: #fff3;
  margin-right: 20%;
  padding-right: 3px;
  position: relative;
}

.special-offer:after {
  content: "";
  height: 100%;
  width: 45%;
  background-color: #fff3;
  position: absolute;
  top: 0;
  right: -45%;
}

.radius.special-offer:after {
  border-radius: 0 50% 50% 0;
}

.special-offer-in {
  margin-right: -24%;
  position: relative;
}

.special-offer .wt-left-part h1 {
  font-size: 52px;
  line-height: 60px;
}

.special-offer .wt-left-part p {
  font-size: 16px;
}

.special-offer-outer-square.special-offer-outer:after {
  right: -8%;
}

.special-offer-outer-square.special-offer-outer .special-offer:after {
  width: 0%;
}

.special-offer-outer-square.special-offer-outer .special-offer, .special-offer-outer-square.special-offer-outer .special-offer-in {
  margin-right: 0%;
}

@media only screen and (max-width: 1200px) {
  .special-offer-in {
    margin-right: 0%;
  }

  .special-offer .special-offer-in .wt-box {
    padding-right: 0;
  }

  .special-offer:after {
    width: 40%;
    right: -40%;
  }

  .special-offer-outer:after {
    right: -40%;
  }

  .special-offer {
    margin-right: 10%;
  }
}

@media only screen and (max-width: 991px) {
  .special-offer-block {
    text-align: center;
    background-image: none !important;
  }

  .special-offer:after, .special-offer-outer:after {
    display: none;
  }

  .special-offer {
    border-radius: 50%;
    margin: 0 10%;
    padding: 10%;
  }

  .testimonial-5 {
    margin-left: 13%;
    position: relative;
  }

  .special-offer {
    border-radius: 20%;
  }
}

@media only screen and (max-width: 600px) {
  .special-offer .wt-left-part {
    padding-left: 0;
  }

  .special-offer .wt-left-part h1 {
    margin-bottom: 10px;
    font-size: 32px;
    line-height: 36px;
  }

  .special-offer .wt-left-part h2 {
    font-size: 18px;
    line-height: 26px;
  }

  .special-offer .wt-left-part p {
    font-size: 14px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .testimonial-5 {
    margin-left: 0%;
  }
}

@media only screen and (max-width: 420px) {
  .testimonial-5 {
    margin-left: 0%;
    position: relative;
  }

  .testimonial-5 .testimonial-pic-block {
    left: inherit;
    position: inherit;
    text-align: center;
    top: inherit;
    padding-top: 30px;
  }

  .testimonial-5 .testimonial-text {
    padding: 40px;
  }

  .testimonial-5 .testimonial-pic {
    float: none;
  }

  .special-offer {
    margin: 0 3%;
    padding: 30px;
  }
}

.wt-subscribe-left .icon-content p {
  font-size: 18px;
}

.wt-subscribe-left .icon-content span {
  font-size: 24px;
  font-weight: 700;
}

.contact-home-left .Opening-hours ul {
  margin-bottom: 0;
}

.contact-home-left .Opening-hours ul li {
  padding-bottom: 5px;
}

.contact-home-left .Opening-hours h3 {
  margin-bottom: 10px;
}

.contact-home-left .Opening-hours ul li span {
  font-weight: 700;
  display: inline-block;
}

.input-group textarea.form-control {
  height: 44px;
}

@media only screen and (max-width: 1024px) {
  .contact-home-left .p-a50 {
    padding: 30px;
  }
}

@media only screen and (max-width: 420px) {
  .contact-home-left .wt-icon-box-wraper.left {
    text-align: center;
  }

  .contact-home-left .wt-icon-box-wraper.left .icon-lg {
    float: none;
  }

  .contact-home-left .Opening-hours {
    text-align: center;
  }

  .contact-home-left .Opening-hours ul li span {
    float: none;
  }
}

.our-pricing-carousel.owl-carousel .owl-stage-outer {
  padding-bottom: 10px;
}

.our-pricing-carousel.nav-tabs {
  border-bottom: none;
}

.our-pricing-carousel .item .wt-icon-box-wraper {
  background-color: #fff;
}

.our-pricing-carousel .item.active-arrow .wt-icon-box-wraper {
  color: #fff;
  background: none;
}

.our-pricing-carousel .item .tab-block {
  cursor: pointer;
}

.our-pricing-carousel .item.active-arrow {
  background-color: #ec5598;
  position: relative;
}

.our-pricing-carousel .item.active-arrow:after {
  content: "";
  width: 30px;
  height: 30px;
  border-top: 18px solid #ec5598;
  border-left: 18px solid #0000;
  border-right: 18px solid #0000;
  margin-left: -15px;
  position: absolute;
  bottom: -24px;
  left: 50%;
}

.our-pricing-carousel .item.active-arrow .wt-icon-box-wraper .icon-cell i:before {
  color: #fff;
}

@media only screen and (max-width: 360px) {
  .our-pricing-carousel .item .wt-icon-box-wraper .icon-cell i {
    line-height: 36px;
  }

  .our-pricing-carousel .item .wt-icon-box-wraper .icon-cell i:before {
    font-size: 32px;
  }

  .our-pricing-carousel .item .wt-icon-box-wraper .wt-tilte {
    font-size: 12px;
  }
}

.our-pricing-carousel .item.active-arrow .our-pricing-tab {
  border-style: hidden;
}

.pricing-tab-content-block {
  display: none;
}

.pricing-tab-content-block.active-arrow {
  display: inherit;
}

.pricing-tab-content-block .tab-pane {
  padding: 0 0 0 20px !important;
}

@media only screen and (max-width: 991px) {
  .pricing-tab-inner .wt-media img {
    margin-bottom: 20px;
  }
}

.our-services-index .item {
  margin: 0 20px;
}

.our-services-index .owl-carousel .owl-dots .owl-dot span {
  width: 14px;
  height: 14px;
  border: none;
  border-radius: 50%;
}

.pricing-tab-content-block .wt-tabs.vertical .nav-tabs {
  float: left;
  width: 275px;
  border-bottom: none;
  border-right: 1px solid #ddd;
}

.pricing-tab-content-block .wt-tabs.vertical .tab-content {
  border-left: 0;
  margin-left: 275px;
}

.pricing-tab-content-block .wt-tabs .nav-tabs > li > a {
  color: #4b3941;
  padding: 21px 20px 21px 30px;
  font-size: 15px;
  font-weight: 500;
}

@media only screen and (max-width: 767px) {
  .pricing-tab-content-block .wt-tabs.vertical .nav-tabs {
    float: none;
    width: 100%;
    margin-bottom: 20px;
  }

  .pricing-tab-content-block .wt-tabs.vertical .tab-content {
    margin-left: 0;
    padding-left: 0 !important;
  }

  .pricing-tab-content-block .tab-pane {
    padding-left: 0 !important;
  }
}

@media only screen and (max-width: 575px) {
  .pricing-tab-content-block .wt-tabs .nav-tabs > li > a {
    padding: 14px 10px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 400px) {
  .pricing-tab-content-block .wt-tabs .nav-tabs > li > a {
    padding: 10px;
    font-size: 12px;
  }
}

.pricing-tab-content-block .wt-tabs.bg-tabs .nav-tabs > li > a {
  background-color: #fff;
  border: 1px solid #ddd;
  margin-right: -1px;
}

.pricing-tab-content-block .wt-tabs.bg-tabs .nav-tabs > li:hover > a {
  color: #fff;
  background-color: #ec5598;
}

.pricing-tab-content-block .wt-tabs.vertical .nav-tabs li a.active {
  color: #fff;
  background-color: #ec5598;
  position: relative;
}

.pricing-tab-content-block .wt-tabs.vertical .nav-tabs li a.active:before {
  content: "";
  font-family: FontAwesome;
  font-size: 24px;
  position: absolute;
  top: 13px;
  right: 20px;
}

@media only screen and (max-width: 575px) {
  .pricing-tab-content-block .wt-tabs.vertical .nav-tabs li a.active:before {
    top: 5px;
    right: 15px;
  }
}

@media only screen and (max-width: 400px) {
  .pricing-tab-content-block .wt-tabs.vertical .nav-tabs li a.active:before {
    font-size: 18px;
    top: 6px;
    right: 15px;
    transform: rotate(90deg);
  }
}

@media only screen and (max-width: 991px) {
  .pricing-tab-content-block .wt-tabs.vertical .tab-content {
    padding-left: 0;
  }
}

@media only screen and (max-width: 768px) {
  .wt-tabs.vertical .tab-pane {
    padding: 10px 0 10px 20px;
  }
}

@media only screen and (max-width: 720px) {
  .pricing-tab-content-block .section-content {
    padding-top: 10px;
  }

  .pricing-tab-content-block .wt-tabs.vertical .tab-pane {
    padding: 0;
  }

  .pricing-tab-content-block .wt-tabs.vertical .tab-content {
    padding-left: 0;
  }

  .pricing-tab-content-block .wt-tabs.vertical .nav-tabs {
    float: none;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 15px;
    display: flex;
  }

  .pricing-tab-content-block .wt-tabs.vertical .nav-tabs li {
    width: 50%;
  }
}

.circle-line-effect {
  z-index: 1;
  position: relative;
}

.circle-line-effect:after, .circle-line-effect:before {
  content: "";
  z-index: -1;
  transition: all 1s;
  position: absolute;
}

.radius.circle-line-effect:after, .radius.circle-line-effect:before {
  border-radius: 100%;
}

.circle-line-effect:after {
  width: 130%;
  height: 130%;
  border: 4px solid #0000;
  top: -15%;
  left: -15%;
}

.circle-line-effect:before {
  width: 150%;
  height: 150%;
  border: 2px solid #0000;
  top: -25%;
  left: -25%;
}

.circle-line-effect:after, .circle-line-effect:before {
  transition: all .3s;
}

.wt-icon-box-wraper:hover .circle-line-effect:after {
  width: 120%;
  height: 120%;
  opacity: .2;
  border: 3px solid #4b3941;
  top: -10%;
  left: -10%;
}

.wt-icon-box-wraper:hover .circle-line-effect:before {
  width: 140%;
  height: 140%;
  opacity: .1;
  border: 2px solid #4b3941;
  top: -20%;
  left: -20%;
}

.bg-center {
  background-position: center;
}

.bg-top-left {
  background-position: 0 0;
}

.bg-top-right {
  background-position: 100% 0;
}

.bg-top-center {
  background-position: top;
}

.bg-bottom-left {
  background-position: 0 100%;
}

.bg-bottom-right {
  background-position: 100% 100%;
}

.bg-bottom-center {
  background-position: bottom;
}

.bg-left-center, .bg-right-center {
  background-position: 0;
}

.bg-auto {
  background-size: auto;
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-full-width {
  background-size: 100%;
}

.bg-full-height {
  background-size: auto 100%;
}

.bg-repeat {
  background-repeat: repeat;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-repeat-x {
  background-repeat: repeat-x;
}

.bg-repeat-y {
  background-repeat: repeat-y;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-scroll {
  background-attachment: scroll;
}

.shopping-table .cf th {
  color: #fff;
  padding: 15px 10px !important;
}

.your-order-list ul {
  list-style: none;
}

.your-order-list ul li {
  clear: both;
  border-bottom: 1px solid #eee;
  padding: 25px 10px;
}

.on-show-slider {
  cursor: pointer;
}

.on-show-slider #sync2 .owl-item.current {
  z-index: 1;
  position: relative;
}

.on-show-slider #sync2 .owl-item.current:after {
  content: "";
  width: 100%;
  height: 100%;
  z-index: 2;
  border: 2px solid #ccc;
  position: absolute;
  top: 0;
  left: 0;
}

.slider-dots .zeus .tp-bullet {
  background-color: #4b3941;
  border: none;
}

.slider-dots .tp-bullet.selected, .slider-dots .zeus .tp-bullet:after {
  background-color: #ec5598;
}

.wt-our-team .wt-team-media img {
  width: 100%;
}

.gradi-green {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#00e5e5e5", endColorstr= "#00bcd4", GradientType= 0);
  background: linear-gradient(#e5e5e500 0%, #81d3de00 30%, #00bcd4 69%);
}

.gradi-purple {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#00e5e5e5", endColorstr= "#6a1b9a", GradientType= 0);
  background: linear-gradient(#e5e5e500 0%, #b08dc400 30%, #6a1b9a 69%);
}

.gradi-pink {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#00e5e5e5", endColorstr= "#ec5598", GradientType= 0);
  background: linear-gradient(#e5e5e500 0%, #e8a6c400 30%, #ec5598 69%);
}

.primary-gradi {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#00e5e5e5", endColorstr= "#6a1b9a", GradientType= 0);
  background: linear-gradient(#e5e5e500 0%, #b08dc400 30%, #6a1b9a 69%);
}

.form-transparent .form-control {
  color: #fff;
  background-color: #0000;
  border: 0;
  border-bottom: 2px solid #fff;
}

.form-transparent .form-control::-webkit-input-placeholder {
  color: #fff;
  font-weight: bold;
}

.form-transparent .form-control::-moz-placeholder {
  color: #fff;
  font-weight: bold;
}

.form-transparent .form-control:-ms-input-placeholder {
  color: #fff;
  font-weight: bold;
}

.form-transparent .form-control:-moz-placeholder {
  color: #fff;
  font-weight: bold;
}

.circle-block-outer .wt-icon-box-wraper {
  transition: all .5s ease-out;
}

.circle-block-outer .wt-icon-box-wraper:hover {
  cursor: pointer;
  background-color: #fff;
  transition: all .5s ease-out;
  box-shadow: 0 0 20px #0000001a;
}

.circle-block-outer .nav-link, .circle-block-outer .nav-link:hover {
  color: #777;
}

.opening-block {
  width: 560px;
  float: right;
  border-left: 0;
  position: relative;
}

.opening-block:after, .opening-block:before {
  content: "";
  width: 5px;
  height: 70px;
  background-color: #a76aab;
  position: absolute;
  left: 0;
}

.opening-block:after {
  top: 0;
}

.opening-block:before {
  bottom: 0;
}

.opening-block p {
  font-size: 18px;
}

.opening-block ul li {
  font-size: 16px;
  font-style: italic;
}

.opening-block ul {
  width: 300px;
  float: right;
  color: #4a424b;
  font-style: italic;
}

.opening-block .opening-date {
  width: 120px;
  display: inline-block;
}

.opening-block .opening-time {
  display: inline-block;
}

a.book-now-btn {
  color: #fff;
  position: absolute;
  top: 45.5%;
  left: -66px;
  transform: rotate(-90deg);
}

a.book-now-btn:hover, a.book-now-btn:focus, a.book-now-btn:active {
  color: #fff;
  background-color: #1a1a1a;
}

.left-larg-pic {
  width: 35%;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media only screen and (max-width: 1200px) {
  .opening-block {
    width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .circle-block-outer .wt-icon-box-wraper.right, .circle-block-outer .wt-icon-box-wraper.right [class*="icon-"], .circle-block-outer .wt-icon-box-wraper.left, .circle-block-outer .wt-icon-box-wraper.left [class*="icon-"] {
    float: none;
    text-align: center;
  }

  .circle-block-outer .wt-icon-box-wraper.left.m-l30 {
    margin-left: 0;
  }

  .circle-block-outer .wt-icon-box-wraper.right.m-r30 {
    margin-right: 0;
  }

  .circle-block-outer .circle-content-pic {
    width: 50%;
    margin: 0 auto;
  }

  .contact-home4-right .text-right {
    text-align: center;
  }

  .opening-block {
    width: 94%;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .opening-block ul {
    width: 100%;
  }
}

@media only screen and (max-width: 420px) {
  .left-larg-pic {
    width: 60%;
    margin: 0 auto;
  }

  .about4-content, .about4-content div.text-right, .special-offer-block2 .awesome-counter.text-right {
    text-align: center;
  }

  .special-offer-block2 .awesome-counter.text-right .font-60 {
    font-size: 32px;
    line-height: normal;
  }

  .special-offer-block2 .pull-right.counter-small, .special-offer-block2 .list-inline > li .wt-icon-box-wraper.left [class*="icon-"] {
    float: none !important;
  }
}

.counter-small-1 ul {
  margin-left: -5px;
  padding-left: 0;
  list-style: none;
}

.counter-small-1 ul > li {
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
}

.header-nav {
  z-index: 7;
  justify-content: end;
  padding: 0;
  position: relative;
  display: flex !important;
}

.header-style-1 .wt-topbar-right {
  justify-content: space-between;
  display: flex;
}

.header-style-1.twm-nav-transparent .main-bar.bg-white {
  position: absolute;
  background-color: #fff9 !important;
}

.header-style-1 .header-nav .nav {
  float: right;
  flex-direction: inherit;
  justify-content: flex-end;
  display: flex;
}

.header-style-1 .wt-topbar-right {
  justify-content: space-between;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .header-style-1 .wt-topbar-right {
    display: block;
  }

  .header-style-1 .wt-topbar-right .social-bx {
    float: none;
    margin: 10px 0 0;
  }
}

.header-style-1 .social-bx li {
  padding-left: 2px;
  padding-right: 2px;
  display: inline-block;
}

.header-style-1 .social-bx li a {
  background-color: #00000014;
}

.header-style-1 .main-bar .container {
  justify-content: space-between;
  display: flex;
  position: relative;
}

.header-style-1 .top-bar .row {
  justify-content: space-between;
  display: flex;
}

.header-style-1 .header-nav .nav {
  float: right;
  flex-direction: inherit;
  justify-content: flex-end;
  display: flex;
}

.header-style-1 .header-nav .nav > li > a {
  display: block;
}

.header-style-1 .header-middle .container {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header-style-1 .navbar-toggler .icon-bar {
  height: 3px;
  width: 22px;
  background: #010e2a;
  border-radius: 0;
  display: block;
}

.header-style-1 .navbar-toggler .icon-bar + .icon-bar {
  margin-top: 4px;
}

.header-style-1 .header-nav .has-child.nav-active > a + .submenu-toogle.fa.fa-angle-right:before {
  content: "";
}

.header-style-1 .has-child .submenu-toogle {
  color: #000;
  font-size: 14px;
}

.header-style-1 .extra-nav {
  z-index: 9;
  align-items: center;
  padding: 0;
  display: flex;
  position: relative;
}

.header-style-1 .extra-nav .extra-cell {
  margin: 0 5px;
  display: block;
}

.header-style-1 .extra-nav .extra-cell:last-child {
  margin-right: 0;
}

.header-style-1 .extra-nav .extra-cell .link-inner {
  display: flex;
}

.header-style-1 .navbar-toggler {
  display: none;
}

.header-style-1 .header-nav .nav > li .sub-menu li .fa {
  z-index: -1;
  background-color: #0000;
  padding: 0 10px 0 0;
}

.header-style-1 .header-nav .nav > li .sub-menu li > a:hover + .fa {
  z-index: -1;
}

.header-style-1 .wt-cart.dropdown-toggle:after {
  display: none;
}

.header-style-1 .cart-dropdown-item-wraper {
  top: 17px !important;
}

.header-style-1 .site-search-btn {
  display: block;
}

@media only screen and (max-width: 991px) {
  .header-style-1 .header-nav .nav > li .sub-menu li .fa {
    z-index: 1;
    width: 100%;
    text-align: right;
    padding: 10px;
    top: 4px;
  }

  .header-style-1 .header-nav .nav > li .sub-menu li > a:hover + .fa {
    z-index: 0;
  }

  .header-style-1 .navbar-toggler {
    display: block;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }

  .header-style-1.twm-nav-transparent .navbar-toggler {
    right: -33px;
  }

  .header-style-1 .extra-nav {
    margin-right: 40px;
  }

  .has-child .submenu-toogle {
    background: none;
  }

  .header-style-1 .header-nav .nav {
    float: none;
    width: 100%;
    height: 100%;
    background: #fff;
    margin: 0;
    display: block;
  }

  .header-style-1 .header-nav .nav > li > a .fa {
    display: none;
  }

  .header-nav .nav > li .sub-menu li .fa {
    top: 1px;
  }

  .header-nav .nav > li .sub-menu li > .sub-menu {
    transition: none !important;
  }

  .header-style-1.mobile-sider-drawer-menu .header-nav {
    z-index: 999;
    background-color: #fff;
    transition: all .5s linear;
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px !important;
    height: 100vh !important;
    overflow-y: inherit !important;
  }

  .header-style-1.mobile-sider-drawer-menu .header-nav .nav > li:hover > .sub-menu, .header-style-1.mobile-sider-drawer-menu .header-nav .nav > li .sub-menu li:hover > .sub-menu {
    transition: none;
  }

  .header-style-1.mobile-sider-drawer-menu.active .header-nav {
    left: 0%;
  }

  .header-style-1.mobile-sider-drawer-menu .icon-bar {
    transition: all .5s linear;
    position: relative;
  }

  #mobile-side-drawer:focus {
    box-shadow: none;
  }

  .header-style-1.mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
    transform: rotate(45deg)translateX(3px)translateY(2px);
  }

  .header-style-1.mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
    transform: rotate(-45deg)translateX(3px)translateY(-2px);
  }

  .header-style-1.mobile-sider-drawer-menu .icon-bar.icon-bar-two {
    position: relative;
    right: 0%;
  }

  .header-style-1.mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
    opacity: 0;
    position: absolute;
    right: 100px;
  }

  .header-style-1.mobile-sider-drawer-menu .is-fixed .header-nav .nav, .header-style-1.mobile-sider-drawer-menu .header-fixed .header-nav .nav {
    max-height: 100%;
    overflow: auto;
  }
}

.header-style-6 .top-bar .container {
  justify-content: space-between;
  display: flex;
}

.header-style-6 .cart-dropdown-item-wraper {
  top: 19px !important;
}

@media only screen and (max-width: 575px) {
  .header-style-6 .top-bar .container {
    display: block;
  }

  .header-style-6.top-bar .container .social-bx {
    float: none;
    margin-left: 0;
  }
}

.header-style-6 .is-fixed .header-nav .nav > li > a {
  padding: 20px 12px 20px 0;
}

.header-style-6 .social-bx li {
  padding-left: 2px;
  padding-right: 2px;
  display: inline-block;
}

.header-style-6 .is-fixed .navbar-toggle {
  margin-top: 10px;
  margin-bottom: 10px;
}

.header-style-6 .header-middle .container {
  justify-content: space-between;
  display: flex;
}

.header-style-6 .header-middle .container .btn-col-last {
  padding-right: 0;
}

.header-style-6 .main-bar .container {
  justify-content: space-between;
  display: flex;
  position: relative;
}

.header-style-6 .header-nav .nav > li .sub-menu {
  top: 62px;
}

.header-style-6 .header-nav .nav > li .mega-menu {
  top: 61px;
}

.header-style-6 .header-info > ul li {
  padding: 15px 0 15px 10px;
}

.header-style-6 .has-child .submenu-toogle {
  background-color: #0000;
}

.header-style-6 .header-nav .nav > li .sub-menu .fa {
  z-index: -1;
  font-size: 14px;
  top: 6px;
}

.header-style-6 .has-child .submenu-toogle {
  background-color: #0000;
}

.header-style-6 .header-nav {
  justify-content: inherit;
  flex-basis: inherit;
}

.header-style-6.header-info > ul li {
  padding-right: 0;
}

.header-style-6 .wt-cart.dropdown-toggle:after {
  display: none;
}

.header-style-6 .header-botton .header-nav .nav {
  flex-direction: inherit;
  justify-content: space-between;
  display: flex;
}

.header-style-6 .navbar-toggler .icon-bar {
  height: 3px;
  width: 22px;
  background: #fff;
  border-radius: 0;
  display: block;
}

.header-style-6 .navbar-toggler .icon-bar + .icon-bar {
  margin-top: 4px;
}

.header-style-6 .header-nav .has-child.nav-active > a + .submenu-toogle.fa.fa-angle-right:before {
  content: "";
}

.header-style-6 .navbar-toggler {
  display: none;
}

.header-style-6 .header-nav .nav > li > a {
  padding-left: 0;
  display: block;
}

@media only screen and (max-width: 991px) {
  .header-style-6 .navbar-toggler {
    display: block;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }

  .header-style-6 .extra-nav {
    margin-right: 0;
  }

  .header-style-6 .header-nav .nav > li .sub-menu li .fa {
    z-index: 1;
    width: 100%;
    text-align: right;
    padding: 10px;
    top: 4px;
  }

  .header-style-6 .header-nav .nav > li .sub-menu li > a:hover + .fa {
    z-index: 0;
  }

  .header-style-6 .has-child .submenu-toogle {
    color: #000;
    width: 100%;
    text-align: right;
    background-color: #0000;
    font-size: 14px;
  }

  .header-style-6 .is-fixed .header-nav .nav > li > a {
    padding: 12px;
  }

  .header-style-6 .extra-nav .extra-cell {
    margin-left: 0;
    padding-left: 0;
  }

  .header-style-6 .header-botton .extra-nav .site-search-btn {
    color: #fff;
    padding: 7px 15px 7px 0;
  }

  .has-child .submenu-toogle {
    background: none;
  }

  .header-style-6 .header-botton .header-nav .nav {
    float: none;
    width: 100%;
    height: 100%;
    background: #fff;
    margin: 0;
    display: block;
  }

  .header-style-6 .header-botton .header-nav .nav > li > a .fa {
    display: none;
  }

  .header-style-6 .header-botton .header-nav .nav > li .sub-menu li .fa {
    top: 1px;
  }

  .header-style-6 .header-botton .header-nav .nav > li .sub-menu li > .sub-menu {
    transition: none !important;
  }

  .header-style-6.mobile-sider-drawer-menu .header-nav {
    z-index: 999;
    background-color: #fff;
    transition: all .5s linear;
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px !important;
    height: 100vh !important;
    overflow-y: inherit !important;
  }

  .header-style-6.mobile-sider-drawer-menu .header-nav .nav > li:hover > .sub-menu, .header-style-6.mobile-sider-drawer-menu .header-nav .nav > li .sub-menu li:hover > .sub-menu {
    transition: none;
  }

  .header-style-6.mobile-sider-drawer-menu.active .header-nav {
    left: 0%;
  }

  .header-style-6.mobile-sider-drawer-menu .icon-bar {
    transition: all .5s linear;
    position: relative;
  }

  #mobile-side-drawer:focus {
    box-shadow: none;
  }

  .header-style-6.mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
    transform: rotate(45deg)translateX(3px)translateY(2px);
  }

  .header-style-6.mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
    transform: rotate(-45deg)translateX(3px)translateY(-2px);
  }

  .header-style-6.mobile-sider-drawer-menu .icon-bar.icon-bar-two {
    position: relative;
    right: 0%;
  }

  .header-style-6.mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
    opacity: 0;
    position: absolute;
    right: 100px;
  }

  .header-style-6.mobile-sider-drawer-menu .is-fixed .header-nav .nav, .header-style-6.mobile-sider-drawer-menu .header-fixed .header-nav .nav {
    max-height: 100%;
    overflow: auto;
  }

  .header-style-6 .header-nav .nav > li > a {
    padding-left: 15px;
  }
}

.header-style-3 .container {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.header-style-3 .top-bar .container {
  justify-content: space-between;
  display: block;
  position: relative;
}

.header-style-3 .wt-topbar-right {
  justify-content: space-between;
  display: flex;
}

.header-style-3 .social-bx li {
  padding-left: 2px;
  padding-right: 2px;
  display: inline-block;
}

.header-style-3 .social-bx li a {
  background-color: #00000014;
}

.header-style-3 .extra-nav .extra-cell .link-inner {
  display: flex;
}

.header-style-3 .container .header-nav .nav {
  float: none;
  flex-direction: inherit;
  justify-content: space-between;
  display: flex;
}

.header-style-3 .extra-nav {
  z-index: 9;
  align-items: center;
  padding: 0;
  display: flex;
  position: relative;
}

.header-style-3 .extra-nav .extra-cell {
  margin: 0 5px;
  display: block;
}

.header-style-3 .main-bar {
  background: none;
  position: absolute;
}

.header-style-3 .header-nav .nav > li > a {
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .header-style-3 .header-nav .nav > li > a {
    color: #777;
  }

  .header-style-3 .container .header-nav .nav {
    float: none;
    display: block;
  }
}

.header-style-3 .navbar-toggle .icon-bar {
  background: #fff;
}

.header-style-3 .site-search-btn {
  margin-right: 10px;
  display: block;
}

.header-style-3 .site-search-btn, .header-style-3 .wt-cart .woo-cart-total, .header-style-3 .wt-cart .woo-cart-count {
  color: #fff;
}

.header-style-3 .wt-cart .woo-cart-count, .header-style-3 .wt-cart .woo-cart-count:before {
  border-color: #fff;
}

.header-style-3 .is-fixed .main-bar {
  position: fixed;
}

.header-style-3 .is-fixed.color-fill .main-bar {
  background: #1a1a1a;
}

.header-style-3 .header-nav .nav > li .sub-menu {
  top: 70px;
}

.header-style-3 .header-nav .nav > li .mega-menu {
  transition: none;
  top: 70px;
}

.header-style-3 .header-nav .nav > li .sub-menu li .fa {
  z-index: -1;
  background-color: #0000;
  font-size: 16px;
  top: 4px;
}

.wt-cart.dropdown-toggle:after {
  display: none;
}

.header-style-3 .navbar-toggler .icon-bar {
  height: 3px;
  width: 22px;
  background: #fff;
  border-radius: 0;
  display: block;
}

.header-style-3 .navbar-toggler .icon-bar + .icon-bar {
  margin-top: 4px;
}

.header-style-3 .header-nav .has-child.nav-active > a + .submenu-toogle.fa.fa-angle-right:before {
  content: "";
}

.header-style-3 .navbar-toggler {
  display: none;
}

.header-style-3 .header-nav .nav > li > a {
  display: block;
}

@media only screen and (max-width: 991px) {
  .header-style-3 .navbar-toggler {
    display: block;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }

  .header-style-3 .extra-nav {
    margin-right: 40px;
  }

  .header-style-3 .header-nav .nav > li .sub-menu li .fa {
    z-index: 1;
    width: 100%;
    text-align: right;
    padding: 10px;
    top: 4px;
  }

  .header-style-3 .header-nav .nav > li .sub-menu li > a:hover + .fa {
    z-index: 0;
  }

  .header-style-3 .has-child .submenu-toogle {
    color: #000;
    width: 100%;
    text-align: right;
    background-color: #0000;
    font-size: 14px;
  }

  .header-style-3 .is-fixed .header-nav .nav > li > a {
    padding: 12px 15px;
  }

  .header-style-3 .extra-nav .extra-cell {
    margin-left: 0;
    padding-left: 0;
  }

  .header-style-3 .header-botton .extra-nav .site-search-btn {
    color: #fff;
    padding: 7px 15px 7px 0;
  }

  .has-child .submenu-toogle {
    background: none;
  }

  .header-style-3 .header-botton .header-nav .nav {
    float: none;
    width: 100%;
    height: 100%;
    background: #fff;
    margin: 0;
    display: block;
  }

  .header-style-3 .header-botton .header-nav .nav > li > a .fa {
    display: none;
  }

  .header-style-3 .header-botton .header-nav .nav > li .sub-menu li .fa {
    top: 1px;
  }

  .header-style-3 .header-botton .header-nav .nav > li .sub-menu li > .sub-menu {
    transition: none !important;
  }

  .header-style-3.mobile-sider-drawer-menu .header-nav {
    z-index: 999;
    background-color: #fff;
    transition: all .5s linear;
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px !important;
    height: 100vh !important;
    overflow-y: inherit !important;
  }

  .header-style-3 .header-nav .nav {
    float: none;
    width: 100%;
    height: 100%;
    background: #fff;
    margin: 0;
    display: block;
  }

  .header-style-3.mobile-sider-drawer-menu .header-nav .nav > li:hover > .sub-menu, .header-style-3.mobile-sider-drawer-menu .header-nav .nav > li .sub-menu li:hover > .sub-menu {
    transition: none;
  }

  .header-style-3.mobile-sider-drawer-menu.active .header-nav {
    align-items: normal;
    left: 0%;
  }

  .header-style-3.mobile-sider-drawer-menu .icon-bar {
    transition: all .5s linear;
    position: relative;
  }

  #mobile-side-drawer:focus {
    box-shadow: none;
  }

  .header-style-3.mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
    transform: rotate(45deg)translateX(3px)translateY(2px);
  }

  .header-style-3.mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
    transform: rotate(-45deg)translateX(3px)translateY(-2px);
  }

  .header-style-3.mobile-sider-drawer-menu .icon-bar.icon-bar-two {
    position: relative;
    right: 0%;
  }

  .header-style-3.mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
    opacity: 0;
    position: absolute;
    right: 100px;
  }

  .header-style-3.mobile-sider-drawer-menu .is-fixed .header-nav .nav {
    max-height: 100%;
    width: 100%;
    display: block;
    overflow: auto;
  }

  .header-style-3.mobile-sider-drawer-menu .header-fixed .header-nav .nav {
    max-height: 100%;
    overflow: auto;
  }

  .header-style-3 .header-nav .nav li {
    float: none;
    width: 100%;
    display: block;
  }

  .header-style-3 .header-nav .nav > li > a .fa {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .header-style-3 .top-bar .container, .header-style-3 .top-bar .container .e-p-bx, .header-style-3 .top-bar .container .wt-topbar-right {
    display: block;
  }

  .header-style-3 .top-bar .container .wt-topbar-right .social-bx {
    float: none;
    margin-left: 0;
  }
}

.header-style-8 .top-bar .container {
  justify-content: space-between;
  display: flex;
}

.header-style-8 .cart-dropdown-item-wraper {
  top: 15px !important;
}

@media only screen and (max-width: 575px) {
  .header-style-8 .top-bar .container {
    display: block;
  }

  .header-style-8 .top-bar .container .social-bx {
    float: none;
    margin-left: 0;
  }
}

.header-style-8 .social-bx li {
  padding-left: 2px;
  padding-right: 2px;
  display: inline-block;
}

.header-style-8 .header-nav .nav > li .sub-menu, .header-style-8 .header-nav .nav > li .mega-menu {
  top: 60px;
}

.header-style-8 .header-info > ul li {
  padding: 15px 0 15px 10px;
}

.header-style-8 .has-child .submenu-toogle {
  background-color: #0000;
}

.header-style-8 .header-nav .nav > li .sub-menu .fa {
  z-index: -1;
  font-size: 14px;
  top: 6px;
}

.header-style-8 .has-child .submenu-toogle {
  background-color: #0000;
}

.header-style-8 .header-middle .container {
  justify-content: space-between;
  display: flex;
}

.header-style-8 .header-nav {
  justify-content: inherit;
  flex-basis: inherit;
}

.header-style-8.header-info > ul li {
  padding-right: 0;
}

.header-style-8 .wt-cart.dropdown-toggle:after {
  display: none;
}

.header-style-8 .header-botton .header-nav .nav {
  flex-direction: inherit;
  justify-content: space-between;
  display: flex;
}

.header-style-8 .main-bar {
  max-width: 1140px;
  margin: 0 auto -60px;
}

.header-style-8 .is-fixed .header-nav .nav > li > a {
  padding: 20px 12px;
}

.header-style-8 .is-fixed .navbar-toggle {
  flex-direction: row-reverse;
  margin-top: 10px;
  margin-bottom: 10px;
}

.header-style-8 .header-botton .extra-nav {
  align-items: center;
  padding: 8px 0;
  display: flex;
}

.header-style-8 .main-bar .container {
  justify-content: space-between;
  display: flex;
  position: relative;
}

.header-style-8 .is-fixed .main-bar {
  margin: 0 -573px;
  left: 50%;
}

.header-style-8 .navbar-toggler .icon-bar {
  height: 3px;
  width: 22px;
  background: #fff;
  border-radius: 0;
  display: block;
}

.header-style-8 .navbar-toggler .icon-bar + .icon-bar {
  margin-top: 4px;
}

.header-style-8 .header-nav .has-child.nav-active > a + .submenu-toogle.fa.fa-angle-right:before {
  content: "";
}

.header-style-8 .navbar-toggler {
  display: none;
}

.header-style-8 .header-nav .nav > li > a {
  display: block;
}

@media only screen and (max-width: 1200px) {
  .header-style-8 .main-bar, .header-style-8 .is-fixed .main-bar {
    max-width: 100%;
    margin: 0;
    left: 0;
  }
}

@media only screen and (max-width: 991px) {
  .header-style-8 .navbar-toggler {
    display: block;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }

  .header-style-8 .extra-nav {
    margin-right: 0;
  }

  .header-style-8 .header-nav .nav > li .sub-menu li .fa {
    z-index: 1;
    width: 100%;
    text-align: right;
    padding: 10px;
    top: 4px;
  }

  .header-style-8 .header-nav .nav > li .sub-menu li > a:hover + .fa {
    z-index: 0;
  }

  .header-style-8 .has-child .submenu-toogle {
    color: #000;
    width: 100%;
    text-align: right;
    background-color: #0000;
    font-size: 14px;
  }

  .header-style-8 .is-fixed .header-nav .nav > li > a {
    padding: 12px;
  }

  .header-style-8 .extra-nav .extra-cell {
    margin-left: 0;
    padding-left: 0;
  }

  .header-style-8 .header-botton .extra-nav .site-search-btn {
    color: #fff;
    padding: 7px 15px 7px 0;
  }

  .has-child .submenu-toogle {
    background: none;
  }

  .header-style-8 .header-botton .header-nav .nav {
    float: none;
    width: 100%;
    height: 100%;
    background: #fff;
    margin: 0;
    display: block;
  }

  .header-style-8 .header-botton .header-nav .nav > li > a .fa {
    display: none;
  }

  .header-style-8 .header-botton .header-nav .nav > li .sub-menu li .fa {
    top: 1px;
  }

  .header-style-8 .header-botton .header-nav .nav > li .sub-menu li > .sub-menu {
    transition: none !important;
  }

  .header-style-8.mobile-sider-drawer-menu .header-nav {
    z-index: 999;
    background-color: #fff;
    transition: all .5s linear;
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px !important;
    height: 100vh !important;
    overflow-y: inherit !important;
  }

  .header-style-8.mobile-sider-drawer-menu .header-nav .nav > li:hover > .sub-menu, .header-style-8.mobile-sider-drawer-menu .header-nav .nav > li .sub-menu li:hover > .sub-menu {
    transition: none;
  }

  .header-style-8.mobile-sider-drawer-menu.active .header-nav {
    left: 0%;
  }

  .header-style-8.mobile-sider-drawer-menu .icon-bar {
    transition: all .5s linear;
    position: relative;
  }

  #mobile-side-drawer:focus {
    box-shadow: none;
  }

  .header-style-8.mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
    transform: rotate(45deg)translateX(3px)translateY(2px);
  }

  .header-style-8.mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
    transform: rotate(-45deg)translateX(3px)translateY(-2px);
  }

  .header-style-8.mobile-sider-drawer-menu .icon-bar.icon-bar-two {
    position: relative;
    right: 0%;
  }

  .header-style-8.mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
    opacity: 0;
    position: absolute;
    right: 100px;
  }

  .header-style-8.mobile-sider-drawer-menu .is-fixed .header-nav .nav, .header-style-8.mobile-sider-drawer-menu .header-fixed .header-nav .nav {
    max-height: 100%;
    overflow: auto;
  }
}

@media only screen and (max-width: 480px) {
  .header-style-8 .logo-header {
    width: 100px;
    height: auto;
  }
}

.header-style-5 .main-bar {
  background-color: #0009;
  position: absolute;
}

.header-style-5 .header-nav .nav > li > a {
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .header-style-5 .header-nav .nav > li > a {
    color: #777;
  }
}

.header-style-5 .navbar-toggle .icon-bar {
  background: #fff;
}

.header-style-5 .site-search-btn, .header-style-5 .wt-cart .woo-cart-total, .header-style-5 .wt-cart .woo-cart-count {
  color: #fff;
}

.header-style-5 .wt-cart .woo-cart-count, .header-style-5 .wt-cart .woo-cart-count:before {
  border-color: #fff;
}

.header-style-5 .is-fixed .main-bar {
  background-color: #000000e6;
  position: fixed;
}

.header-style-5 .header-nav .nav {
  float: right;
  flex-direction: inherit;
  justify-content: flex-end;
  display: flex;
}

.header-style-5 .wt-topbar-right {
  justify-content: end;
  display: flex;
}

@media only screen and (max-width: 575px) {
  .header-style-5 .wt-topbar-right {
    display: block;
  }

  .header-style-5 .wt-topbar-right .social-bx {
    float: none;
    margin-left: 0;
  }
}

.header-style-5 .social-bx li {
  padding-left: 2px;
  padding-right: 2px;
  display: inline-block;
}

.header-style-5 .social-bx li a {
  background-color: #00000014;
}

.header-style-5 .main-bar .container {
  justify-content: space-between;
  display: flex;
  position: relative;
}

.header-style-5 .top-bar .row {
  justify-content: space-between;
  display: flex;
}

.header-style-5 .header-nav .nav {
  float: right;
  flex-direction: inherit;
  justify-content: flex-end;
  display: flex;
}

.header-style-5 .header-nav .nav > li > a {
  display: block;
}

.header-style-5 .header-middle .container {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header-style-5 .navbar-toggler .icon-bar {
  height: 3px;
  width: 22px;
  background: #fff;
  border-radius: 0;
  display: block;
}

.header-style-5 .navbar-toggler .icon-bar + .icon-bar {
  margin-top: 4px;
}

.header-style-5 .header-nav .has-child.nav-active > a + .submenu-toogle.fa.fa-angle-right:before {
  content: "";
}

.header-style-5 .has-child .submenu-toogle {
  color: #000;
  font-size: 14px;
}

.header-style-5 .extra-nav {
  z-index: 9;
  align-items: center;
  padding: 0;
  display: flex;
  position: relative;
}

.header-style-5 .extra-nav .extra-cell {
  margin: 0 5px;
  display: block;
}

.header-style-5 .extra-nav .extra-cell:last-child {
  margin-right: 0;
}

.header-style-5 .extra-nav .extra-cell .link-inner {
  display: flex;
}

.header-style-5 .navbar-toggler {
  display: none;
}

.header-style-5 .header-nav .nav > li .sub-menu li .fa {
  z-index: -1;
  background-color: #0000;
  padding: 0 10px 0 0;
}

.header-style-5 .header-nav .nav > li .sub-menu li > a:hover + .fa {
  z-index: -1;
}

.header-style-5 .wt-cart.dropdown-toggle:after {
  display: none;
}

.header-style-5 .cart-dropdown-item-wraper {
  top: 17px !important;
}

.header-style-5 .site-search-btn {
  display: block;
}

@media only screen and (max-width: 991px) {
  .header-style-5 .navbar-toggler {
    display: block;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }

  .header-style-5 .extra-nav {
    margin-right: 40px;
  }

  .header-style-5 .header-nav .nav > li .sub-menu li .fa {
    z-index: 1;
    width: 100%;
    text-align: right;
    padding: 10px;
    top: 4px;
  }

  .header-style-5 .header-nav .nav > li .sub-menu li > a:hover + .fa {
    z-index: 0;
  }

  .has-child .submenu-toogle {
    background: none;
  }

  .header-style-5 .header-nav .nav {
    float: none;
    width: 100%;
    height: 100%;
    background: #fff;
    margin: 0;
    display: block;
  }

  .header-style-5 .header-nav .nav > li > a .fa {
    display: none;
  }

  .header-nav .nav > li .sub-menu li .fa {
    top: 1px;
  }

  .header-nav .nav > li .sub-menu li > .sub-menu {
    transition: none !important;
  }

  .header-style-5.mobile-sider-drawer-menu .header-nav {
    z-index: 999;
    background-color: #fff;
    transition: all .5s linear;
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px !important;
    height: 100vh !important;
    overflow-y: inherit !important;
  }

  .header-style-5.mobile-sider-drawer-menu .header-nav .nav > li:hover > .sub-menu, .header-style-5.mobile-sider-drawer-menu .header-nav .nav > li .sub-menu li:hover > .sub-menu {
    transition: none;
  }

  .header-style-5.mobile-sider-drawer-menu.active .header-nav {
    left: 0%;
  }

  .header-style-5.mobile-sider-drawer-menu .icon-bar {
    transition: all .5s linear;
    position: relative;
  }

  #mobile-side-drawer:focus {
    box-shadow: none;
  }

  .header-style-5.mobile-sider-drawer-menu.active .icon-bar.icon-bar-first {
    transform: rotate(45deg)translateX(3px)translateY(2px);
  }

  .header-style-5.mobile-sider-drawer-menu.active .icon-bar.icon-bar-three {
    transform: rotate(-45deg)translateX(3px)translateY(-2px);
  }

  .header-style-5.mobile-sider-drawer-menu .icon-bar.icon-bar-two {
    position: relative;
    right: 0%;
  }

  .header-style-5.mobile-sider-drawer-menu.active .icon-bar.icon-bar-two {
    opacity: 0;
    position: absolute;
    right: 100px;
  }

  .header-style-5.mobile-sider-drawer-menu .is-fixed .header-nav .nav, .header-style-5.mobile-sider-drawer-menu .header-fixed .header-nav .nav {
    max-height: 100%;
    overflow: auto;
  }
}

.embed-responsive {
  height: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

.embed-responsive .ytp-impression-link, .ytp-impression-link {
  opacity: 0;
}

.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.button-model button.close {
  min-width: inherit;
  color: #fff;
  background: none;
  border: none;
  font-size: 26px;
  line-height: 26px;
}

.button-model .modal-header {
  border-radius: 0;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.alert-dismissible .btn-close {
  text-align: center;
  background: none;
  padding: 15px;
  font-size: 24px;
  line-height: 24px;
}

.wt-mid-page {
  max-width: 770px;
  margin: 0 auto;
  position: relative;
}

.twitterSummary a {
  word-break: break-all;
}

@media only screen and (max-width: 1199px) {
  .pricingtable-5 [class*="col-"] {
    width: 33.33%;
  }
}

@media only screen and (max-width: 991px) {
  .pricingtable-5 [class*="col-"] {
    width: 50%;
  }

  .section-full.p-t80 {
    padding-top: 50px;
  }

  .section-full.p-b50 {
    padding-bottom: 20px;
  }

  .section-full.p-t100 {
    padding-top: 50px;
  }

  .section-full.p-b70 {
    padding-bottom: 20px;
  }

  .pricingtable-highlight {
    margin: 0;
  }

  .pricingtable-highlight .pricingtable-price {
    padding: 10px;
  }

  .pricingtable-highlight .pricingtable-footer {
    padding: 20px;
  }

  .pricing-table-style-4 .pricingtable-highlight .pricingtable-price {
    padding: 20px 10px;
  }

  .pricingtable-5 [class*="col-"] {
    width: 100%;
  }

  .block-reverse {
    flex-direction: row-reverse;
    display: flex;
  }

  .block-reverse-wrap .row.m-b50 {
    margin-bottom: 0;
  }

  .counter.font-70 {
    font-size: 44px;
  }
}

@media only screen and (max-width: 767px) {
  .block-reverse {
    flex-direction: column-reverse;
    display: flex;
  }
}

@media only screen and (max-width: 720px) {
  .font-icon-tabs-wrap.wt-tabs.border .tab-pane {
    border: 1px solid #ddd;
  }

  .font-icon-tabs-wrap .row-list.row span, .font-icon-tabs-wrap .row-list.row span.fa {
    text-align: left;
    margin: inherit;
  }

  .font-icon-tabs-wrap [class*="col-"] {
    padding: 10px 20px;
  }

  h1.font-60 {
    font-size: 32px;
    line-height: 32px;
  }

  h3.font-24 {
    font-size: 18px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 575px) {
  .animated-block-w-scroll .wt-icon-box-md {
    margin-bottom: 30px;
    margin-right: 0;
    float: none !important;
  }
}

@media only screen and (max-width: 420px) {
  .b-detail-social {
    display: block !important;
  }

  .b-detail-social .pull-left {
    float: none;
  }
}

.portfolio-wrap .wt-box img {
  width: 100%;
}

.conntact-home {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 1136px) {
  .tp-caption.sld-btn .button-lg {
    padding: 10px 16px;
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 991px) {
  .conntact-home .opening-block h2 {
    font-size: 24px;
  }

  .conntact-home .opening-block .font-60 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 767px) {
  .conntact-home .contact-home4-left {
    margin-top: 50px;
  }

  .conntact-home .contact-home4-right .opening-block {
    width: 97%;
    margin-bottom: 0;
    padding: 25px;
  }
}

@media only screen and (max-width: 575px) {
  .tp-caption.sld-btn .button-lg {
    padding: 8px 10px;
    font-size: 10px !important;
  }
}

.wt-team-full-media img {
  width: 100%;
}

/*# sourceMappingURL=index.8e0e845a.css.map */
